import React from "react";
import logoKSvg from "@/assets/image/base/logo-k.svg";

const MainLoader = ({ isAllPage = true, isPartialPage = false }) => {
  return (
    <>
      {isAllPage && (
        <div className="z-50 fixed w-screen h-screen bg-white">
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              className="main-loader"
              src={logoKSvg}
              width={40}
              height={40}
              alt="Logo First Letter"
            />
          </div>
        </div>
      )}
      {!isAllPage && !isPartialPage && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            className="main-loader"
            src={logoKSvg}
            width={40}
            height={40}
            alt="Logo First Letter"
          />
        </div>
      )}
      {!isAllPage && isPartialPage && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "250px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            className="main-loader"
            src={logoKSvg}
            width={40}
            height={40}
            alt="Logo First Letter"
          />
        </div>
      )}
    </>
  );
};

export default MainLoader;
