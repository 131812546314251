import useAuth from "@/hooks/useAuth";

const useTeam = () => {
  const { getAuthToken } = useAuth();

  const inviteMembers = async (params) => {
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team-members`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            members: params.members,
          }),
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        if (_resData.status === 400) {
          const data = await _resData.json();
          return data;
        }
        throw new Error(_resData);
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const getInvitations = async () => {
    console.log("===============getInvitations===============");

    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team-invitations`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const getTeamMembers = async ({
    searchValue = "",
    filterRole = "",
    sort = {
      sort: -1,
      sortBy: "email",
    },
    status = null,
  }) => {
    console.log("===============getTeamMembers===============");

    let response = null;
    const authToken = await getAuthToken();

    let url = `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team-members?`;

    if (searchValue) {
      url += `searchValue=${searchValue}&`;
    }

    if (filterRole) {
      url += `filterRole=${filterRole}&`;
    }

    if (sort) {
      url += `sort=${sort.sort}&sortBy=${sort.sortBy}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    try {
      const _resData = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const updateTeamMember = async (params) => {
    const {
      creditsMonthly,
      creditsAnnual,
      role,
      id,
      emailCreditLimit,
      phoneCreditLimit,
      validationCreditLimit,
    } = params;
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team-members`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            creditsMonthly,
            creditsAnnual,
            role,
            id,
            emailCreditLimit,
            phoneCreditLimit,
            validationCreditLimit,
          }),
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        if (_resData.status === 400) {
          return await _resData.json();
        }
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const acceptInvitation = async (params) => {
    const { id } = params;
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team-invitations`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        const data = await _resData.json();
        throw new Error(data?.message || "Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  // Reject Invitation
  const rejectInvitation = async (params) => {
    const { id } = params;
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team-invitations`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const getInvitationsCount = async (params) => {
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/getInvitationsCount`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const getCreditsInfo = async (params) => {
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/analytics/getCreditsInfo`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  //   dev/removeMember
  const deleteMember = async (params) => {
    const { id } = params;
    console.log("===============deleteContactList===============");

    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team-members`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const getDataRevealed = async (params) => {
    const { currentUserId, period } = params;
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/analytics/getDataRevealed?currentUserId=${currentUserId}&period=${period}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }

    return response;
  };

  const changeOwner = async (params) => {
    const { newOwnerId } = params;
    console.log("===============deleteContactList===============");
    // console.log(`params: ${JSON.stringify(params)}`);
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/handleTeamOwnershipTransfer`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newOwnerId }),
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  return {
    inviteMembers,
    updateTeamMember,
    getTeamMembers,
    getInvitations,
    acceptInvitation,
    getInvitationsCount,
    rejectInvitation,
    getCreditsInfo,
    deleteMember,
    getDataRevealed,
    changeOwner,
  };
};

export default useTeam;
