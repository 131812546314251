const KompassColor = {
  primary: {
    100: "#E6F6FE",
    200: "#C0EAFC",
    300: "#9ADDFB",
    400: "#4FC3F7",
    500: "#03A9F4",
    600: "#0398DC",
    700: "#026592",
    800: "#014C6E",
    900: "#013349",
  },
  gray: {
    100: "#f7fafc",
    200: "#edf2f7",
    300: "#e2e8f0",
    400: "#cbd5e0",
    500: "#a0aec0",
    600: "#718096",
    700: "#4a5568",
    800: "#2d3748",
    900: "#1a202c",
  },
  teal: {
    950: "#0A2E31",
  },
  zinc: {
    900: "#1C1B1F",
    200: "#D9E7E6",
    100: "#F3F3F3",
  },
  neutral: {
    300: "#B6C8C9",
    400: "#919191",
  },
  stone: {
    200: "#E7E6E6",
    300: "#BEBEBE",
    950: "#090C05",
  },
  amber: {
    500: "#FFA800",
  },
  magenta: {
    50: "#fce4ec",
    100: "#f8bbd0",
    200: "#f48fb1",
    300: "#f06292",
    400: "#ec407a",
    500: "#e91e63",
    600: "#d81b60",
    700: "#c2185b",
    800: "#ad1457",
    900: "#880e4f",
    A100: "#ff80ab",
    A200: "#ff4081",
    A400: "#f50057",
    A700: "#c51162",
  },
  emerald: {
    200: "#ECF4F3",
    300: "#DEEBE9",
    500: "#0A2E31",
  },
};

KompassColor["main"] = KompassColor["primary"];

export { KompassColor };
