import { React, useState, useEffect, useRef } from "react";
import { Button } from "@material-tailwind/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useBilling from "@/hooks/useBilling";
import { ReactComponent as HotelClassSVGIcon } from "@/assets/image/icons/icon-hotel-class.svg";
import { ReactComponent as AccountBalanceSVGIcon } from "@/assets/image/icons/icon-account-balance-wallet.svg";
import { ReactComponent as ReceiptLongSVGIcon } from "@/assets/image/icons/icon-receipt-long.svg";
import { useNavigate } from "react-router-dom";
import useDashboard from "@/hooks/useDashboard";
import BackdropLoader from "../common/BackdropLoader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import PaymentMethodsList from "./BillingTab/PaymentMethodsList";
import ConfirmationModal from "../payment/ConfirmationModal";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const datePrittier = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const modalPresets = {
  unsubscribe: {
    icon: <WarningRoundedIcon className={"!h-full !w-full !fill-white"} />,
    title: "Confirm action",
    message:
      " You are trying to cancel subscription, this action can't be aborted, confirm?",
    footnotes: [
      "Credits that you have will be available until the end of the subscription period",
    ],
  },
};

export default function BillingTabComponent() {
  const { getSubscriptionInfo, getBillingHistory } = useBilling();
  const { getDashboard } = useDashboard();
  const initialValues = {
    email: "anna.a@pinedev.eu",
    password: "",
    role: "Director",
  };
  const getSubscriptionInfoRef = useRef();
  getSubscriptionInfoRef.current = getSubscriptionInfo;
  const getBillingHistoryRef = useRef();
  getBillingHistoryRef.current = getBillingHistory;

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  const navigate = useNavigate();

  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [billingList, setBillingList] = useState();
  const [dashboardInfo, setDashboardInfo] = useState(null);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleCancelSubscription = useHTTPRequest(
    "/billing/cancelSubscription",
    "POST"
  );

  const queryClient = useQueryClient();

  const { mutateAsync: deleteFilesTrigger, isPending } = useMutation({
    mutationFn: handleCancelSubscription,
    onSuccess: () => {
      setCancelModalShow(false);
      queryClient.invalidateQueries({ queryKey: ["Subscription"] });
      const qp = new URLSearchParams();
      qp.set("billing-success", "true");
      qp.set("message", "Subscription was successfully cancelled");
      navigate("/settings?" + qp.toString());
    },
    onError: () => {
      setCancelModalShow(false);
      const qp = new URLSearchParams();
      qp.set("billing-success", "false");
      qp.set("message", "There was an error, try again later");
      navigate("/settings?" + qp.toString());
    },
  });

  const prohibitCancellation =
    subscriptionInfo?.status === "canceled" ||
    subscriptionInfo?.isPlannedForCancellation ||
    subscriptionInfo?.plan?.toLowerCase() === "free";

  useEffect(() => {
    const loadInfo = async () => {
      const res = await getSubscriptionInfoRef.current();
      setSubscriptionInfo(res);
    };
    loadInfo();
  }, []);

  useEffect(() => {
    const loadInfo = async () => {
      const dashboardInfo = (await getDashboard({})).data;
      setDashboardInfo(dashboardInfo);
    };
    loadInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadHistory = async () => {
      const res = await getBillingHistoryRef.current();
      setBillingList(res);
    };
    loadHistory();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }) => (
        <>
          <ConfirmationModal
            showState={[cancelModalShow, setCancelModalShow]}
            loading={isPending}
            modal={{
              ...modalPresets.unsubscribe,
              handleConfirm: () => deleteFilesTrigger(),
            }}
          ></ConfirmationModal>
          <Form className="p-6">
            <div className="mb-4 inline-flex w-full items-center justify-start gap-2.5">
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                <div className="font-Outfit leading-loose self-stretch text-3xl font-normal text-black">
                  Billing
                </div>
                <div className="font-Outfit leading-tight self-stretch text-sm font-normal text-stone-350">
                  Manage your profile and preferences here.
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 ">
              <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                      <AccountBalanceSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                    </div>
                  </div>
                  <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                      Payment card
                    </div>
                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                      Add or change your billing method
                    </div>
                  </div>
                </div>

                <div className="flex w-full flex-col items-start justify-start gap-y-6">
                  <div className="flex w-full flex-col items-start justify-center gap-2 ">
                    <PaymentMethodsList></PaymentMethodsList>
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                      <HotelClassSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                    </div>
                  </div>
                  <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                      Current subscription
                    </div>
                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                      View and edit your subscription
                    </div>
                  </div>
                </div>

                <div className="flex w-full flex-col items-start justify-start gap-y-6">
                  <div className="inline-flex items-center justify-start gap-4 self-stretch">
                    <div className="inline-flex h-32 shrink grow basis-0 flex-col items-center justify-between rounded-lg bg-stone-150 px-6 py-4">
                      <div className="flex h-14 flex-col items-center justify-start gap-2 self-stretch">
                        <div className="font-Outfit leading-tight self-stretch text-center text-sm font-normal text-stone-350">
                          Your plan
                        </div>
                        <div className="font-Outfit leading-relaxed self-stretch text-center text-xl font-normal text-black">
                          {subscriptionInfo?.plan
                            ? subscriptionInfo.plan +
                              (subscriptionInfo.status === "canceled" ||
                              subscriptionInfo?.isPlannedForCancellation
                                ? " (Cancelled)"
                                : "")
                            : "Loading..."}
                        </div>
                      </div>
                      {subscriptionInfo && (
                        <div className="flex gap-10">
                          <button
                            className="font-Outfit leading-tight self-stretch text-center text-base font-normal text-blue-500"
                            onClick={() => {
                              navigate("/upgrade");
                            }}
                          >
                            Change plan
                          </button>
                          {!prohibitCancellation && (
                            <button
                              className="font-Outfit leading-tight self-stretch text-center text-base font-normal text-red-500"
                              onClick={() => {
                                setCancelModalShow(true);
                              }}
                            >
                              Cancel subscription
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="inline-flex h-32 shrink grow basis-0 flex-col items-center justify-between rounded-lg bg-stone-150 px-6 py-4">
                      <div className="flex h-14 flex-col items-center justify-start gap-2 self-stretch">
                        <div className="font-Outfit leading-tight self-stretch text-center text-sm font-normal text-stone-350">
                          Team members seats
                        </div>
                        {dashboardInfo ? (
                          <div className="font-Outfit leading-relaxed self-stretch text-center text-xl font-normal text-black">
                            {dashboardInfo.teamsInfo.used} /{" "}
                            {dashboardInfo.teamsInfo.total}
                          </div>
                        ) : (
                          <div className="font-Outfit leading-relaxed self-stretch text-center text-xl font-normal text-black">
                            Loading...
                          </div>
                        )}
                      </div>
                      <button
                        onClick={() =>
                          navigate(
                            dashboardInfo?.teamsInfo?.total -
                              dashboardInfo?.teamsInfo?.used >=
                              1
                              ? "/team"
                              : "/upgrade"
                          )
                        }
                        className="font-Outfit leading-tight self-stretch text-center text-base font-normal text-blue-500"
                      >
                        {dashboardInfo?.teamsInfo?.total -
                          dashboardInfo?.teamsInfo?.used >=
                        1
                          ? "Invite members to your team"
                          : "Upgrade to invite more members"}
                      </button>
                    </div>
                    <div className="inline-flex h-32 shrink grow basis-0 flex-col items-center justify-between rounded-lg bg-stone-150 px-6 py-4">
                      <div className="flex h-14 flex-col items-center justify-start gap-2 self-stretch">
                        <div className="font-Outfit leading-tight self-stretch text-center text-sm font-normal text-stone-350">
                          {subscriptionInfo?.isPlannedForCancellation
                            ? "Cancellation date"
                            : "Next renewal"}
                        </div>
                        <div className="font-Outfit leading-relaxed self-stretch text-center text-xl font-normal text-black">
                          {subscriptionInfo?.renewal_date
                            ? datePrittier(subscriptionInfo.renewal_date)
                            : "Loading..."}
                        </div>
                      </div>
                      <div className="stone-350 font-Outfit leading-none self-stretch text-center text-xs font-normal">
                        {subscriptionInfo?.isPlannedForCancellation
                          ? "You will be subscribed to free plan."
                          : "You will receive a refill."}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                      <ReceiptLongSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                    </div>
                  </div>
                  <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                      Billing history
                    </div>
                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                      View and download your past invoices
                    </div>
                  </div>
                </div>

                <div className="flex w-full flex-col items-start justify-start gap-y-6">
                  <div className="relative flex min-h-[100px]   flex-col items-start justify-start gap-2 self-stretch">
                    <div className="inline-flex h-14 w-full items-center justify-start gap-4 rounded-lg bg-stone-150 p-4">
                      <div className="font-Outfit leading-snug w-72 text-base font-medium text-black">
                        Description
                      </div>
                      <div className="font-Outfit leading-snug shrink grow basis-0 text-base font-medium text-black">
                        Date
                      </div>
                      <div className="font-Outfit leading-snug shrink grow basis-0 text-base font-medium text-black">
                        Amount
                      </div>
                      <div className="font-Outfit leading-snug shrink grow basis-0 text-base font-medium text-black">
                        Status
                      </div>
                    </div>
                    {billingList && billingList?.length > 0 && (
                      <div className="overflow-auto w-full">
                        {billingList
                          .slice(0, cursor + rowsPerPage)
                          .map((item) => {
                            return (
                              <div className="inline-flex w-full items-center justify-start gap-4 border-b-[1px] bg-white px-4 py-2">
                                <div
                                  className="font-Outfit leading-snug w-72 text-base font-light text-black"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></div>
                                <div className="font-Outfit leading-snug shrink grow basis-0 text-base font-light text-black">
                                  {item.date}
                                </div>
                                <div className="font-Outfit leading-snug shrink grow basis-0 text-base font-light uppercase text-black">
                                  {item.amount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: item.currency,
                                  })}
                                </div>
                                <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center">
                                  <div className="font-Outfit leading-snug text-base font-light capitalize text-black">
                                    {item.status}
                                  </div>
                                  <a
                                    href={`${item.link_to_invoice}`}
                                    className="font-Outfit leading-snug text-base font-normal text-blue-500"
                                  >
                                    View invoice
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {billingList &&
                      billingList?.length !== 0 &&
                      (billingList?.length > cursor + rowsPerPage ? (
                        <Button
                          onClick={() => {
                            setCursor(cursor + rowsPerPage);
                          }}
                          className="flex items-center justify-center gap-2 self-center rounded-md bg-stone-950 px-4 py-2"
                        >
                          <div className="font-Outfit leading-tight text-base font-bold uppercase tracking-tight text-white">
                            Show more
                          </div>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setCursor(0);
                          }}
                          className="flex items-center justify-center gap-2 self-center rounded-md bg-stone-950 px-4 py-2"
                        >
                          <div className="font-Outfit leading-tight text-base font-bold uppercase tracking-tight text-white">
                            Hide
                          </div>
                        </Button>
                      ))}

                    {!billingList && (
                      <BackdropLoader active={true}></BackdropLoader>
                    )}
                    {billingList && billingList.length === 0 && (
                      <div className="font-OutfitMedium w-full p-2 text-center text-xl">
                        Nothing to display
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
