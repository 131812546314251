import * as React from "react";
import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as Checkbox } from "@/assets/image/icons/checkbox_free.svg";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import cl from "classnames";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useState, useEffect, useMemo } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
  },
}));

//Displays result of the payment to the user, purely presentational for now
//could get extra data for payment and could be displayed anywhere
export default function PaymentResultModal() {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const [show, setShow] = useState(false); //check if it's in QP
  const error = query.get("billing-success") === "false" ? true : false;

  const stay = query.get("stay");

  const [message, setMessage] = useState("");
  const handleClose = () => {
    setShow(false);
    if (!stay) {
      navigate("/home");
    } else {
      navigate(pathname);
    }
  };
  useEffect(() => {
    setShow(query.get("billing-success"));
    const message = query.get("message");
    if (message) {
      setMessage(message);
    }
  }, [query]);
  return (
    <BootstrapDialog fullScreen={fullScreen} open={show}>
      <button
        onClick={() => {
          handleClose();
        }}
        className="absolute right-4 top-4 flex flex-row justify-end"
      >
        <CloseSVGIcon className="relative h-4 w-4" />
      </button>
      <DialogContent className="min-w-[600px] !p-8">
        <div className="flex flex-row justify-center">
          {error ? (
            <div className={"rounded-[50%] bg-black"}>
              <CloseSVGIcon
                className={"h-16 w-16 fill-white stroke-white p-4"}
              ></CloseSVGIcon>
            </div>
          ) : (
            <Checkbox
              className={"relative h-16 w-16 fill-black stroke-white"}
            />
          )}
        </div>
        <div className="flex flex-col items-center justify-start gap-2 self-stretch">
          <div className="font-OutfitBold leading-loose self-stretch text-center text-3xl  text-stone-950">
            {error ? "Failed" : "Success"}
          </div>
          <div className="font-OutfitMedium leading-snug mt-4 self-stretch text-center text-lg  text-stone-950">
            {message ||
              (error
                ? "There was an error during processing of your payment, please try again"
                : "Payment successful, credits should appear in your account shortly")}
          </div>
        </div>
        <div className="font-Outfit mt-8 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
          <Button
            className="font-Outfit flex  items-center justify-center rounded-[8px] border-[1px] border-[#4873FA] bg-[#4873FA] py-[12px] text-sm uppercase tracking-wide text-white"
            onClick={handleClose}
          >
            {error ? "Close" : "Great!"}
          </Button>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}
