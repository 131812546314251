import Icon1 from "@/assets/image/competitors/red-flag-1.svg";
import Icon2 from "@/assets/image/competitors/red-flag-2.svg";
import Icon3 from "@/assets/image/competitors/red-flag-3.svg";
import Icon4 from "@/assets/image/competitors/red-flag-4.svg";
import { ReactSVG } from "react-svg";

const RedFlagCard = ({ data }) => {
  const { icon, title, description } = data;

  return (
    <div className="flex flex-col p-6 max-w-[398px] competitors-box font-Outfit text-stone-950 rounded-[8px]">
      <ReactSVG src={icon} width={54} height={54} />
      <p className="mt-8 text-[20px] md:text-[24px] lg:text-[32px] leading-[1.1] font-semibold">
        {title}
      </p>
      <p className="mt-4 text-base opacity-70">{description}</p>
    </div>
  );
};

const infoFirstCol = [
  {
    icon: Icon1,
    title: "Limited Data Coverage",
    description:
      "Many providers will have strong data within one region (e.g., Europe), but very few providers have a strong presence globally. Our propriety AI have enabled us to procure the most complete data-set, which provides best-in-class data globally. ",
  },
  {
    icon: Icon2,
    title: "Minimal Customization Options",
    description:
      "Each business has unique needs, and a one-size-fits-all approach often doesn't work. Sales intelligence tools that offer limited customization for data on plans limit your ability to focus on what your business needs to grow. ",
  },
];

const infoSecondCol = [
  {
    icon: Icon3,
    title: "Complex User Interface",
    description:
      "Many providers haven’t optimized their user Interface in several years, making it difficult and unfriendly to prospect in. KompassAI prides its self on simplicity and ease-of-use. The average user needs under 2 minutes to fully understand how to use the platform.",
  },
  {
    icon: Icon4,
    title: "Hidden Costs",
    description:
      "Many Sales Intelligence providers will still charge you for invalid emails and phone numbers. At KompassAI there is a 100% guarantee you will only be charged credits if you receive valid data. ",
  },
];

const ProvidersRedFlags = () => {
  return (
    <div className="px-12 py-20 w-full flex flex-col items-center gap-10 lg:gap-20">
      <p className="max-w-[1080px] main-title mb-12 lg:mb-20">
        Watch out for these red flags before choosing a Sales Intelligence
        Provider
      </p>
      <div className="w-full flex flex-col sm:flex-row gap-10 lg:gap-20 items-center sm:items-start justify-center">
        <div className="flex flex-col sm:pt-20 lg:pt-28 gap-10 lg:gap-20">
          {infoFirstCol.map((item) => (
            <RedFlagCard data={item} />
          ))}
        </div>
        <div className="flex flex-col gap-10 lg:gap-20">
          {infoSecondCol.map((item) => (
            <RedFlagCard data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProvidersRedFlags;
