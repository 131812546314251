import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { kompassColors } from "@/theme/palette";
import PropTypes from "prop-types";

function CRMUploadTableHeader(props) {
  const { order, orderBy, headCells, onChangeSorting } = props;

  return (
    <TableHead
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "5px 10px",
          fontFamily: "Outfit",
          fontSize: 13,
          color: kompassColors.black,
        },
      }}
    >
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderColor: kompassColors.light,
            bgcolor: "#3b82f6",
            color: "white",
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "!left"}
            padding={"none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id && !headCell.disableSorting}
              direction={order}
              sx={{
                "&.Mui-active": { color: "white " },
                "&:hover": { color: "#e2e8f0 " },
                "& .MuiSvgIcon-root": { color: "white !important" },
              }}
              onClick={() => onChangeSorting(headCell)}
              hideSortIcon={true}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
CRMUploadTableHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default CRMUploadTableHeader;
