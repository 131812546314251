import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { ReactComponent as BurgerMenu } from "@/assets/image/sidebar-icons/burger-menu.svg";
import { ReactComponent as HomeIcon } from "@/assets/image/sidebar-icons/home.svg";
import { ReactComponent as SearchIcon } from "@/assets/image/sidebar-icons/search-loop.svg";
import {
  MAIN_SIDEBAR_LIST,
  SECONDARY_SIDEBAR_LIST,
  TERTIARY_SIDEBAR_LIST,
} from "@/utils/common";
import { SidebarItem } from "@/components/sidebar/SidebarItem";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "@/context/AuthContext";
import { SidebarUserItem } from "@/components/sidebar/SideBarUserItem";

const drawerWidth = 220;
const widestScreenDrawerWidth = 256;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#000",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "8px",
  paddingBottom: "8px",
  "@media (min-width: 1536px)": {
    width: widestScreenDrawerWidth,
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingY: 2,
  overflowX: "hidden",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "8px",
  paddingBottom: "8px",
  width: `60px`,
  "@media (min-width: 1536px)": {
    width: "80px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  backgroundColor: "#000",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "@media (min-width: 1536px)": {
    width: widestScreenDrawerWidth,
  },
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function MainSidebar({ showProfilePopup }) {
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();
  const authContext = useContext(AuthContext);

  const handleDrawerChange = (e) => {
    e.stopPropagation();

    const isSideBarContainer = e.currentTarget.id === "sideBarContainer";
    const isSideBarBurger = e.currentTarget.id === "sideBarBurger";

    if (isSideBarContainer || isSideBarBurger) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Box className="hidden lg:flex">
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <Box
          sx={{ height: "100%", cursor: "pointer" }}
          onClick={handleDrawerChange}
          id="sideBarContainer"
        >
          <List sx={{ paddingY: 0 }}>
            <SidebarItem
              open={open}
              label="KompassAI"
              onClick={handleDrawerChange}
              icon={BurgerMenu}
              pathname={pathname}
              textStyles="!text-white"
              id="sideBarBurger"
            />
            <SidebarItem
              label={"Home"}
              open={open}
              icon={HomeIcon}
              onClick={handleDrawerChange}
              pathname={pathname}
              router="/home"
            />
            <Divider
              sx={{
                "@media (min-width: 1536px)": {
                  marginY: "12px",
                },
                marginY: "9px",
                backgroundColor: "#E8E7E7",
                opacity: 0.3,
              }}
            />
            {MAIN_SIDEBAR_LIST.map(
              ({ label, icon: Icon, router, isComingSoon }, index) => (
                <SidebarItem
                  open={open}
                  key={label}
                  label={label}
                  onClick={handleDrawerChange}
                  icon={Icon}
                  router={router}
                  pathname={pathname}
                  isComingSoon={isComingSoon}
                />
              )
            )}
          </List>
          <Divider
            sx={{
              backgroundColor: "#E8E7E7",
              opacity: 0.3,
              "@media (min-width: 1536px)": {
                marginY: "12px",
              },
              marginY: "9px",
            }}
          />
          <List sx={{ paddingY: 0 }}>
            {SECONDARY_SIDEBAR_LIST.map(
              ({ label, icon: Icon, router, isComingSoon }, index) => (
                <SidebarItem
                  open={open}
                  key={label}
                  label={label}
                  onClick={handleDrawerChange}
                  icon={Icon}
                  router={router}
                  pathname={pathname}
                  isComingSoon={isComingSoon}
                />
              )
            )}
          </List>
          <Divider
            sx={{
              backgroundColor: "#E8E7E7",
              opacity: 0.3,
              "@media (min-width: 1536px)": {
                marginY: "12px",
              },
              marginY: "9px",
            }}
          />
          <List sx={{ paddingY: 0 }}>
            {TERTIARY_SIDEBAR_LIST.map(
              ({ label, icon: Icon, router, isComingSoon }, index) => (
                <SidebarItem
                  open={open}
                  key={label}
                  label={label}
                  onClick={handleDrawerChange}
                  icon={Icon}
                  pathname={pathname}
                  router={router}
                  isComingSoon={isComingSoon}
                />
              )
            )}
          </List>
        </Box>
        <List sx={{ marginTop: "auto" }}>
          {/*<SidebarItem*/}
          {/*  open={open}*/}
          {/*  pathname={pathname}*/}
          {/*  onClick={handleDrawerChange}*/}
          {/*  label="Search"*/}
          {/*  icon={SearchIcon}*/}
          {/*  router="#"*/}
          {/*  isComingSoon={true}*/}
          {/*/>*/}
          <SidebarUserItem
            label={authContext.userInfo?.userName || ""}
            open={open}
            authContext={authContext}
            onClick={(event) => {
              showProfilePopup(event.currentTarget);
            }}
          />
        </List>
      </Drawer>
    </Box>
  );
}
