import { ReactSVG } from "react-svg";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { kompassColors } from "@/theme/palette";
import SuccessIcon from "@/assets/image/bulk/success.svg";
import WaitingIcon from "@/assets/image/email-validation/waiting.svg";
import { Checkbox } from "@mui/material";
import { ReactComponent as ResultIcon } from "@/assets/image/bulk/result.svg";
import ArrowDownIcon from "@/assets/image/email-validation/down-arrow.svg";
import ValidationResultDetailed from "./ValidationResultDetailed";
import { useState } from "react";

function ValidationTableRow(props) {
  const { row, isItemSelected, labelId, handleSelect, tableWidth } = props;
  // const [rowData, setRowData] = useState(row);
  const rowData = row;
  const [showDetailed, setShowDetailed] = useState(false);

  function formattedDate(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = `${date
      .getDate()
      .toString()
      .padStart(2, "0")} ${date.toLocaleString("en-US", {
      month: "short",
    })} ${date.getFullYear()}`;
    return formattedDate;
  }

  return (
    <>
      <TableRow
        hover
        onClick={(event) => setShowDetailed(!showDetailed)}
        role="checkbox"
        className="h-[56px] min-h-[56px]"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rowData.fileId}
        selected={isItemSelected}
        sx={{
          cursor: "pointer",
          background: "white",
          "& .MuiTableCell-root": {
            border: "none",
            borderBottom: "1px solid #E8E7E7",
            borderColor: kompassColors.light,
          },
          "& .MuiTableCell-sizeMedium": {
            padding: "4px 6px",
            "@media (min-width: 540px)": {
              padding: "5px 10px",
            },
          },
          "& .MuiCheckbox-root": {
            padding: 0,
            "@media (min-width: 540px)": {
              padding: "10px",
            },
          },
        }}
      >
        <TableCell
          padding="none"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSelect(e, rowData.fileId);
          }}
        >
          <Checkbox
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            sx={{
              "&.Mui-checked": {
                color: kompassColors.blue,
              },
              "&.MuiCheckbox-indeterminate": {
                color: kompassColors.blue,
              },
            }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          <div className="flex flex-row items-center gap-2">
            <ResultIcon />
            <div className="flex flex-col">
              <span className="font-OutfitLight text-sm font-semibold text-black">
                {rowData?.fileName}
              </span>
              <div className="flex flex-wrap sm:flex-nowrap sm:gap-1">
                {rowData?.status === "failed" ? (
                  <span className="font-OutfitLight text-base text-[#090C0590]">
                    <span className="text-[#b00000]">{`Failed • ${
                      rowData?.message || ""
                    }`}</span>
                  </span>
                ) : (
                  <>
                    <span className="font-OutfitLight text-sm text-[#090C0590]">
                      {rowData?.emails.length + ` E-mails`}
                    </span>
                    <span className="font-OutfitLight text-sm text-[#090C0590]">
                      {` • ` + (rowData?.status ? "Completed" : "Processing")}
                    </span>
                    <span className="font-OutfitLight text-sm text-[#090C0590]">
                      {rowData?.completed && rowData?.completedAt
                        ? ` • ` + formattedDate(rowData?.completedAt)
                        : null}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell align="center">{rowData.emails.length}</TableCell>
        <TableCell padding="none" align="center">
          {rowData?.status === "uploaded" ? (
            <IconButton onClick={(event) => {}} disabled>
              <ReactSVG src={SuccessIcon} />
            </IconButton>
          ) : (
            <IconButton
              onClick={(event) => {}}
              disabled
              className="!p-0"
              sx={{
                svg: {
                  width: "40px !important",
                  height: "40px !important",
                },
              }}
            >
              <ReactSVG src={WaitingIcon} />
            </IconButton>
          )}
        </TableCell>

        <TableCell>
          <div className="flex w-fit flex-col text-sm xs:text-base">
            {formattedDate(rowData.created_at)}
          </div>
        </TableCell>
        <TableCell align="right">
          <IconButton
            onClick={(event) => {}}
            disabled
            className={`mr-2 ${showDetailed ? "rotate-180" : ""}`}
          >
            <ReactSVG src={ArrowDownIcon} />
          </IconButton>
        </TableCell>
      </TableRow>
      <ValidationResultDetailed
        emails={rowData.emails}
        tableWidth={tableWidth}
        open={showDetailed}
      ></ValidationResultDetailed>
    </>
  );
}
export default ValidationTableRow;
