import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Button } from "@material-tailwind/react";
import MainLayout from "@/components/MainLayout";
import TeamIcon from "@/assets/image/team/team.svg";
import SearchIcon from "@/assets/image/team/search.svg";
import EnhancedTable from "@/components/team/TeamTable";
import TeamManagementTabComponent from "@/components/settings/TeamManagementTabComponent";
import AddTeamSeatDialog from "@/components/AddTeamSeatDialog";
import InviteMemberDialog from "@/components/InviteMemberDialog";
import CreateGroupDialog from "@/components/CreateGroupDialog";
import useTeam from "@/hooks/useTeam";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import MainTitleBar from "@/components/MainTitleBar";
import BaseContainer from "@/components/BaseContainer";
import { debounce } from "lodash";
import useAuth from "@/hooks/useAuth";
import { toast } from "react-toastify";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TeamScene({ invite = false }) {
  const [filterRole, setFilterRole] = useState("All Roles");
  const [visibleTeamSeatDialog, setVisibleTeamSeatDialog] = useState(false);
  const [visibleInviteMemberDialog, setVisibleInviteMemberDialog] =
    useState(false);
  const [visibleCreateGroupDialog, setVisibleCreateGroupDialog] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState({
    sort: -1,
    sortBy: "email",
  });
  const location = useLocation();
  const [data, setData] = useState([]);
  const [creditInfo, setCreditInfo] = useState({});
  const [tab, setTab] = useState("member");
  const [userInfo, setUserInfo] = useState();
  const { getUserInfo } = useAuth();
  const getUserInfoRef = useRef();
  getUserInfoRef.current = getUserInfo;

  const { getTeamMembers, getCreditsInfo } = useTeam();
  const navigate = useNavigate();

  // useEffect with async call.
  const fetchData = useCallback(
    async (
      searchValue = "",
      filterRole = "",
      sort = {
        sort: -1,
        sortBy: "email",
      }
    ) => {
      const response = await getTeamMembers({
        searchValue,
        filterRole,
        sort,
      });
      setData(response.data);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const fetchCreditsInfo = useCallback(async () => {
    const response = await getCreditsInfo();
    setCreditInfo(response.data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    fetchData(searchValue, filterRole, sort);
  }, [sort, searchValue, filterRole, fetchData]);

  useEffect(() => {
    fetchCreditsInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/invite") {
      if (!visibleInviteMemberDialog) {
        setVisibleInviteMemberDialog(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getUserInfoRef
      .current()
      .then((response) => {
        setUserInfo(response?.userInfo);
      })
      .catch((error) => {
        toast.error(error?.message, { theme: "colored" });
      });
  }, []);

  // TODO: use isTeamMember for make your conditions short
  // TODO: use if statement for early return if u have so big html
  // TODO: https://gist.githubusercontent.com/zzdjk6/358e4b526b5690376a0bcc43f52e783e/raw/6af56f32dafb47ee9dcba8476860ba0da1e84d7e/example.tsx

  const debounceSearch = useMemo(() => {
    return debounce(
      (currentValue) => fetchData(currentValue, filterRole),
      1500
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {userInfo?.permission !== 1 && (
        <MainLayout>
          <MainTitleBar>
            <div className="flex items-end justify-start gap-2">
              <ReactSVG src={TeamIcon} className="w-[34px] h-[34px]" />
              <p>Team Management</p>
            </div>
          </MainTitleBar>
          <BaseContainer>
            <div className="w-full h-full py-5">
              {/* TODO: remove condition. it`s always true */}
              {userInfo?.permission !== 0 && (
                <>
                  <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2">
                    <div className="flex flex-row gap-2">
                      <div className="flex items-baseline gap-1 bg-white text-[#5E5E5E] font-Outfit rounded-full text-sm p-3">
                        Users & admins:
                        <span className="text-stone-950 text-base font-medium">
                          {creditInfo?.currentInvitedTeamMembers ?? 0}/
                          {creditInfo?.subscription?.seats ?? 0}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 font-Outfit">
                      <Button
                        onClick={() => {
                          navigate("/upgrade");
                          setVisibleTeamSeatDialog(true);
                        }}
                        className="main-button"
                      >
                        Add seats
                      </Button>
                      <Button
                        onClick={() => {
                          setVisibleInviteMemberDialog(true);
                        }}
                        className="main-button"
                      >
                        Invite members
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className="w-full flex flex-col justify-start items-center bg-white rounded-2xl p-3 gap-2 mt-5 border-[1px] border-[#E8E7E7]">
                      <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2">
                        <div>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={tab}
                              onChange={(e, tab) => {
                                setTab(tab);
                              }}
                              aria-label="basic tabs example"
                            >
                              <Tab
                                label="Members"
                                value="member"
                                {...a11yProps(0)}
                              />
                            </Tabs>
                          </Box>
                        </div>

                        <div className="w-full flex flex-col md:flex-row md:justify-end gap-2">
                          <div className="w-45 flex flex-row p-[8px_12px] rounded-[40px] items-center bg-white border-[1px] border-[#E8E7E7]">
                            <ReactSVG src={SearchIcon} />
                            <input
                              type="text"
                              className="w-full rounded-xl outline-none pl-[16px] font-Outfit font-[300px] text-sm"
                              placeholder="Search for member..."
                              value={searchValue}
                              onChange={(e) => {
                                debounceSearch(e.target.value);
                                setSearchValue(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full">
                        <EnhancedTable
                          filterRole={filterRole}
                          searchValue={searchValue}
                          data={data}
                          setData={setData}
                          sort={sort}
                          setSort={setSort}
                          creditInfo={creditInfo}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* TODO: remove condition. it`s always false */}
              {userInfo?.permission === 0 && (
                <div className="w-full mt-4">
                  <TeamManagementTabComponent />
                </div>
              )}
              {/* TODO: remove condition. it`s always true */}
              {userInfo?.permission !== 0 && (
                <>
                  <AddTeamSeatDialog
                    open={visibleTeamSeatDialog}
                    handleClose={() => {
                      setVisibleTeamSeatDialog(false);
                    }}
                    handleAddSeat={() => {
                      navigate("/upgrade");
                    }}
                  />
                  <InviteMemberDialog
                    open={visibleInviteMemberDialog}
                    handleClose={() => {
                      setVisibleInviteMemberDialog(false);
                    }}
                    handleInviteMember={() => {
                      setVisibleInviteMemberDialog(false);
                    }}
                    creditInfo={creditInfo}
                    limitAvailable={
                      creditInfo?.currentInvitedTeamMembers <
                      creditInfo?.subscription?.seats
                    }
                    data={data}
                    setData={setData}
                  />
                  <CreateGroupDialog
                    open={visibleCreateGroupDialog}
                    handleClose={() => {
                      setVisibleCreateGroupDialog(false);
                    }}
                    handleContactSales={() => {
                      setVisibleCreateGroupDialog(false);
                    }}
                  />
                </>
              )}
            </div>
          </BaseContainer>
        </MainLayout>
      )}

      {userInfo?.permission === 1 && (
        <MainLayout>
          <MainTitleBar>
            <div className="flex items-end justify-start gap-2">
              <ReactSVG src={TeamIcon} className="w-[34px] h-[34px]" />
              <p>Team Management</p>
            </div>
          </MainTitleBar>
          <BaseContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontWeight: "bold",
                paddingTop: 20,
              }}
            >
              You do not have access to this view.
            </Box>
          </BaseContainer>
        </MainLayout>
      )}
    </>
  );
}
