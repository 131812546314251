import { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "./Link";
import { v4 } from "uuid";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import BackdropLoader from "@/components/common/BackdropLoader";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { zapierSettingSchema } from "@/utils/zapier";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as cl from "classnames";
import CustomSwitch from "./CustomSwitch";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    maxWidth: "unset",
  },
}));

const defaults = {
  options: {
    cleanData: true,
  },
  links: [{ link: "", label: "Webhook link", id: v4() }],
};

export default function ZapierSettingsModal(props) {
  const { showState, loading, updateSettings, settings } = props;
  const [show, setShow] = showState;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const formattedSettings = {
    links: settings?.data?.links || defaults.links,
    options: settings?.data?.options || defaults.options,
  };
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(zapierSettingSchema, { recursive: true }),
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "links",
  });
  const watchFieldArray = watch("links");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const handleAppend = () => {
    const update = {
      link: "",
      label: "Webhook link",
      id: v4(),
    };
    append(update);
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const handleConfirm = (data) => {
    updateSettings({ settings: data });
  };
  console.log(errors);
  useEffect(() => {
    console.log("rendered");
    return () => {
      console.log("unmounted");
    };
  }, []);
  useEffect(() => {
    if (settings?.data) {
      console.log(formattedSettings, settings);
      reset(formattedSettings);
    }
  }, [settings, show]);
  return (
    <BootstrapDialog
      fullScreen={fullScreen}
      open={show}
      className="relative max-w-[unset]"
    >
      {/* <BackdropLoader active={loading}></BackdropLoader> */}
      <button
        onClick={() => {
          setShow(false);
        }}
        className="z-10 absolute right-4 top-4 flex flex-row justify-end"
      >
        <CloseSVGIcon className="relative h-4 w-4" />
      </button>
      {/* <Tooltip title="Delete">
        <button
          onClick={() => {
            setShow(false);
          }}
          className="absolute right-4 top-4 flex flex-row justify-end"
        >
            <InfoSVGIcon className="relative h-4 w-4 fill-current text-stone-350"/>
        </button>
      </Tooltip> */}

      <DialogContent className="!p-8 flex flex-col  gap-4  max-h-[40%] w-[800px] relative">
        <BackdropLoader active={loading}></BackdropLoader>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit((data) => handleConfirm(data))}
        >
          <div className="flex flex-col items-center justify-start gap-4">
            <div className="font-OutfitBold leading-loose self-stretch text-center text-3xl  text-stone-950">
              Zapier settings
            </div>
          </div>
          <CustomSwitch
            control={control}
            label={"Clean data before sending it to Zapier"}
            tooltip={{
              content:
                "Contacts from Kompass will be formatted for more convenient use in Zapier, but some data might be lost (e.g. extra SNS links)",
            }}
            name={"options.cleanData"}
          ></CustomSwitch>
          <div className="flex flex-col w-full">
            <div
              className={cl(
                "border-1 border-stone-250 flex flex-col gap-4 w-full rounded-lg mt-4",
                {
                  "!border-red-300": errors.links,
                }
              )}
            >
              <div className="p-4 flex flex-col gap-2 w-full border-b-1 border-stone-250">
                <div className="font-Outfit items-center text-md font-bold text-black">
                  Webhook links
                </div>
                <div className="font-Outfit 2xl:text-base text-sm ">
                  Bring in your links from Zapier webhook nodes which will be
                  triggered when you use "Trigger Zapier" in "Push to CRM" menu
                </div>
              </div>
              <div className="p-4 flex flex-col gap-4">
                {controlledFields.map((field, index) => {
                  return (
                    <Link
                      register={register}
                      value={field}
                      error={errors.links?.[index]}
                      key={field.id}
                      index={index}
                      inputKey={`links.${index}.link`}
                      labelKey={`links.${index}.label`}
                      removeLink={handleRemove}
                    ></Link>
                  );
                })}

                <Button
                  className="items-center self-center bg-black w-40 py-2 justify-center gap-2 flex text-black"
                  onClick={() => {
                    handleAppend(controlledFields);
                  }}
                >
                  <AddCircleIcon className="text-white"></AddCircleIcon>
                </Button>
              </div>
            </div>
            {(errors.links) && (
              <p className="mt-2 font-normal text-sm text-red-500">
                {errors.links?.root?.message || errors.links?.message}
              </p>
            )}
            {true && (
              <Button
                type="submit"
                className={
                  " mt-8 font-OutfitBold w-fit self-center min-w-[150px] flex items-center justify-center gap-2 rounded-md bg-stone-950 px-4 py-2 text-sm capitalize leading-[100%] text-white hover:bg-white hover:outline-1 hover:text-black outline outline-[#121212]"
                }
              >
                Save
              </Button>
            )}
          </div>
        </form>
        {/* {footnotes?.length && (
          <div className=" mt-6 flex flex-col items-start gap-1 self-start">
            {footnotes.map((note) => {
              return (
                <span className="font-Outfit flex items-center justify-center text-sm opacity-50">
                  - {note}
                </span>
              );
            })}
          </div>
        )} */}
      </DialogContent>
    </BootstrapDialog>
  );
}
