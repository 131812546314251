import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as ArrowDown } from "@/assets/image/icons/arrowDown.svg";

export default function ListBox({ list, setCurrentIndex }) {
  const [selected, setSelected] = useState(list[0]);

  return (
    <Listbox
      value={selected}
      onChange={(value) => {
        setSelected(value);
        setCurrentIndex(list.findIndex((item) => value.name === item.name));
      }}
    >
      <div className="lg:hidden relative mb-16 w-full">
        <Listbox.Button className="relative w-full cursor-pointer text-left pb-4 after:content-[' '] after:w-full after:h-[4px] after:absolute after:bg-black after:bottom-0 after:rounded-full flex items-center justify-between">
          <span className="block text-gray-950 font-Outfit font-normal text-[18px] leading-[1.2] pb-1">
            {selected.name}
          </span>
          <span className="flex items-center">
            <ArrowDown />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className=" mt-1 max-h-60 w-full overflow-auto pt-5 pb-1 text-gray-950 font-Outfit font-normal text-[18px] leading-[1.2] rounded-xl">
            {list
              .filter((item) => item.name !== selected.name)
              .map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active, selected }) =>
                    `relative cursor-default select-none first:pt-0 py-4 after:content-[' '] after:w-full after:h-[4px] after:absolute  after:bottom-0 after:rounded-full ${
                      selected ? "after:bg-black" : "after:bg-[#E8E7E7]"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate `}>{person.name}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
