import LandingLayout from "@/components/LandingLayout";
import SupportPanel from "@/components/landing/SupportPanel";
import WhyKompassAIPanel from "@/components/landing/WhyKompassAIPanel";
import TopBar from "@/components/landing/TopBar";
import ConnectPanel from "@/components/landing/ConnectPanel";
import TrustedUser from "@/components/landing/base/TrustedUser";
import ProvidersRating from "@/components/competitors/ProvidersRating";
import ProvidersRedFlags from "@/components/competitors/ProvidersRedFlags";

const topBarData = {
  title:
    "Compare and find out the best  Sales Intelligence platform for your business.",
  subTitle:
    "With so many options available, selecting the ideal one for your business can be overwhelming. In today's economic climate, it's increasingly important to choose the most provider for your needs. Various Sales Intelligence cater to different markets, each offering a unique set of features. So, how does KompassAI stand out from the rest? Continue reading to discover the best Sales Intelligence platform solution for your business.",
};

const description = {
  heading:
    "Here is how KompassAI compares against the competition (fill rate per contact).",
  subHeading:
    "KompassAI consistently surpasses its competitors in both performance and value, offering a price that’s sure to make you smile. Why is it so affordable? We are a completely self-funded and profitable company with a mission to help sales professionals. By choosing not to participate in expensive, high-stakes marketing campaigns—a decision that might have kept us out of the spotlight—we save a significant amount of money. We pass these savings directly to you, our customer. As a result, you receive exceptional value and undeniable return on investment with high quality data.",
};

export default function CompetitorsScene() {
  return (
    <LandingLayout pageName="competitors" background="#FFDE38">
      <div>
        <TopBar
          data={topBarData}
          isCompetitors={true}
          background={"bg-accents-yellow2"}
        />
        <SupportPanel title={false} />
        <WhyKompassAIPanel data={description} isCompetitorsPage={true} />
        <ProvidersRating />
        <ProvidersRedFlags />
        <TrustedUser />
        <ConnectPanel
          background={"bg-accents-yellow2"}
          accent={"bg-accents-black"}
        />
      </div>
    </LandingLayout>
  );
}
