import { useEffect, useState } from "react";
import ResultsTable from "./ResultsTable";
import { WEBSITE_VISITORS_REPORT_ITEMS } from "@/utils/constants";

export default function WebsiteReport({ data, currentTab, setAnalyzedData, analyzedData, filterProps, setFilterProps }) {
  const [allDomains, setAllDomains] = useState([]);
  const [chosenDomain, setChosenDomain] = useState(null);

  useEffect(() => {
    const analyzeDataForCompaniesReport = (filteredData) => {
      let processedData = [];

      const processedIds = new Set();

      filteredData.sort((a, b) => b.visits - a.visits);

      filteredData.forEach((obj) => {
        const ipDetails = obj.ipDetails;
        const companyDetails = obj.companyDetails;

        if (ipDetails && companyDetails) {
          const id = companyDetails.pdlId || companyDetails.id;
          if (!processedIds.has(id)) {
            processedData.push({
              companyName: companyDetails.name || "No info available",
              employees: companyDetails.size === "1-10" ? "1 to 10" : companyDetails.size,
              locationCountry:
                ipDetails.location.country || "No info available",
              companyIndustry: companyDetails.industry || "No info available",
              companyWebsite: companyDetails.website,
              visitors: obj.visitors.length,
              count: obj.visits,
            });
            processedIds.add(id);
          }
        }
      });

      return processedData;
    };

    const analyzeDataForIndustriesReport = (filteredData) => {
      let processedData = [];

      filteredData.sort((a, b) => b.visits - a.visits);

      filteredData.forEach((obj) => {
        processedData.push({
          industry: obj.industry || "No info available",
          companies: obj.companies?.length || "No info available",
          countries:
            obj.countries?.length || "No info available",
          locations: obj.locations?.length || "No info available",
          visitors:
            obj.visitors.length || "No info available",
          count: obj.visits,
        });
      });

      return processedData;
    }

    const analyzeDataForEmployeeRangeReport = (filteredData) => {
      let processedData = [];

      filteredData.sort((a, b) => b.visits - a.visits);

      filteredData.forEach((obj) => {
        processedData.push({
          employeeRange: obj.employeeRange === "1-10" ? "1 to 10" : obj.employeeRange,
          companies: obj.companies?.length || "No info available",
          countries:
            obj.countries?.length || "No info available",
          visitors:
            obj.visitors.length || "No info available",
          count: obj.visits,
        });
      });

      return processedData;
    }

    const analyzeDataForLocationsReport = (filteredData) => {
      let processedData = [];

      filteredData.sort((a, b) => b.visits - a.visits);

      filteredData.forEach((obj) => {
        processedData.push({
          employeeLocation: obj.employeeLocation || "No info available",
          companies: obj.companies?.length || "No info available",
          industries:
            obj.industries?.length || "No info available",
          visitors:
            obj.visitors.length || "No info available",
          count: obj.visits,
        });
      });

      return processedData;
    }

    if (data && data.length > 0) {
      const filteredData = data.filter((item) => item.domain === chosenDomain);
      if (!filteredData || filteredData.length <= 0) return;
      if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS)
        setAnalyzedData(analyzeDataForCompaniesReport(filteredData));
      if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES)
        setAnalyzedData(analyzeDataForIndustriesReport(filteredData));
      if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES)
        setAnalyzedData(analyzeDataForEmployeeRangeReport(filteredData));
      if (currentTab === WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS)
        setAnalyzedData(analyzeDataForLocationsReport(filteredData));
    }
    if (data && data.length == 0) {
      setAnalyzedData([])
    }
  }, [data, chosenDomain]);

  useEffect(() => {
    if (data && data.length > 0) {
      const domains = [...new Set(data.map((entry) => entry.domain))];
      if (domains.length > 0) {
        setAllDomains([...new Set(data.map((entry) => entry.domain))]);
        setChosenDomain(domains[0]);
      }
    }
    if (data && data.length == 0) {
      setAllDomains([]);
      setChosenDomain(null);
    }
  }, [data]);

  return (
    <div>
      {allDomains && allDomains.length > 0 && (
        <div className="flex flex-wrap gap-1 max-w-[800px] bg-white p-1.5 mx-10 mt-4 rounded-[6px] border border-[#CBD5E1]">
          {allDomains.map((domain) => (
            <button
              key={domain}
              onClick={() => setChosenDomain(domain)}
              className={`${chosenDomain === domain ? "bg-[#f6f6f6]" : "bg-white"} relative rounded-[4px] text-[14px] text-stone-950 px-3 py-1.5 z-0`}
            >
              <p>{domain}</p>
            </button>
          ))}
        </div>
      )}
      <div className="flex">
        {analyzedData && analyzedData.length > 0 && (
          <div className="px-10 py-8 overflow-x-scroll">
            <ResultsTable data={analyzedData} currentTab={currentTab} filterProps={filterProps} setFilterProps={setFilterProps} />
          </div>
        )}
      </div>
    </div>
  );
}
