import { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { toast } from "react-toastify";
import useList from "@/hooks/useList";
import SubProfileListTable from "@/components/lists/SubProfileListTable";
import { kompassColors } from "@/theme/palette";
import { ReactComponent as BackSVG } from "@/assets/image/integrations/back.svg";
import MainLoader from "../MainLoader";

export default function SubProfileList({ contact, goBack }) {
  const { getProfilesByContactId } = useList();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const reload = async () => {
    setLoading(true);

    const response = await getProfilesByContactId({ contactId: contact._id });
    console.log("PROFILES: ", response.data);

    if (response.status) {
      const newTableData = [...response.data];
      setTableData(newTableData);
    } else {
      toast.error(response.message, { theme: "colored" });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (loaded) return;
    reload();
    setLoaded(true);
  }, [loaded]);

  return (
    <>
      {loading && <MainLoader />}
      <div className="px-3 pt-8 pb-4 shadow-[0px_1px_14px_0px_rgba(0,0,0,0.15)]">
        <div className="w-full flex flex-col text-xl md:text-2xl text-black justify-start font-bold font-Outfit leading-loose gap-2 mb-2 sm:mb-4">
          <Button
            className="flex flex-row items-center text-blue-500 bg-transparent gap-2 p-0"
            onClick={goBack}
          >
            <BackSVG stroke={`${kompassColors.blue}`} />
            <p>Back</p>
          </Button>
        </div>
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <div className="font-Outfit leading-loose ml-6 text-4xl font-bold text-black">
            {contact?.listTitle}
          </div>
        </div>
      </div>
      <SubProfileListTable
        searchFilter={searchFilter}
        tableData={tableData.filter((item) => {
          return JSON.stringify(item).indexOf(searchFilter) >= 0;
        })}
        setSearchFilter={(value) => setSearchFilter(value)}
        listId={contact._id}
        listTitle={contact.listTitle}
        listType={contact.listType}
        setLoading={setLoading}
        reload={reload}
      />
    </>
  );
}
