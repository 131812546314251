import React from "react";
import { Button } from "@material-tailwind/react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "10px",
  },
}));

export default function ConfirmDeleteDialog({ open, handleNo, handleYes }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BootstrapDialog fullScreen={fullScreen} open={open} onClose={handleNo}>
      <DialogContent>
        <div className="font-Outfit p-3 text-xl font-bold text-black">
          Are you sure you want to delete your account?
        </div>
        <div className="font-Outfit mt-4 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
          <Button
            className="font-outfit cursor-pointer rounded-md border border-stone-950 bg-transparent px-4 py-2 text-sm capitalize text-black hover:bg-stone-950 hover:text-white"
            onClick={handleNo}
          >
            Cancel
          </Button>
          <Button
            className="font-outfit cursor-pointer rounded-md border border-stone-950 bg-stone-950 px-4 py-2 text-sm capitalize text-white hover:bg-transparent hover:text-black"
            onClick={handleYes}
          >
            Delete
          </Button>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}
