import { BLOG_TAGS } from "@/utils/constants";
import SearchBar from "../blog/SearchBar";

export default function SortTab({
  selectedTag,
  setSelectedTag,
  filter,
  setFilter,
}) {
  return (
    <div className="h-full flex flex-col-reverse md:flex-row md:justify-between items-center gap-4 py-5 md:py-10 border-b border-[#ddd]">
      <div className="flex flex-wrap gap-2 h-fit">
        {Object.values(BLOG_TAGS).map((tag) => (
          <button
            key={tag}
            className={`font-Outfit py-2 px-3 lg:py-3 lg:px-5 rounded-[20px] border
              ${selectedTag === tag ? "bg-stone-950 border-black text-white" : "bg-white text-[#090C0569] border-[#090C0569]"}`}
            onClick={() => setSelectedTag(tag)}
          >
            <p className="whitespace-nowrap text-[12px] lg:text-[16px] 2xl:text-[18px] leading-[1]">
              {tag}
            </p>
          </button>
        ))}
      </div>
      <SearchBar filter={filter} setFilter={setFilter} />
    </div>
  );
}
