import useAuth from "@/hooks/useAuth";

const useExportProfiles = () => {
    const { getAuthToken } = useAuth();

    const createExportProfile = async params => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(
                `${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/export-profiles/create`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(params)
                }
            );
            response = await _resData.json();
            console.log(response.data)
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    }

    const getExportedProfiles = async () => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(
                `${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/export-profiles/get`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e)
        }
        return response;
    }

    return {
        createExportProfile,
        getExportedProfiles
    }
}

export default useExportProfiles;