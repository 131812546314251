import { ReactComponent as SalesforceSVG } from "@/assets/image/integrations/salesforce.svg";
import { ReactComponent as HubspotSVG } from "@/assets/image/integrations/hubspot.svg";
import { ReactComponent as OutreachSVG } from "@/assets/image/integrations/outreach.svg";
import { ReactComponent as PipedriveSVG } from "@/assets/image/integrations/pipedrive.svg";
import { ReactComponent as ZapierSVG } from "@/assets/image/integrations/zapier.svg";
import { ReactComponent as ZohoSVG } from "@/assets/image/integrations/zoho.svg";

export default function ConnectorPanel(props) {
  return (
    <div className="w-full py-24 px-12 space-y-5 md:space-y-6">
      <div className="mb-20 flex flex-col gap-4 font-Outfit text-stone-950 leading-[1.2] items-center">
        <p className="font-bold text-[28px] md:text-[36px] lg:text-[40px] 2xl:text-[46px]">
          Trusted Partners
        </p>
        <p className="text-black opacity-70 text-xl 2xl:text-[26px] max-w-[632px] text-center">
          Sync the most accurate data and always work with the freshest database
          across our integration network
        </p>
      </div>
      <div className="grid gap-5 md:gap-0 grid-cols-1 md:grid-cols-3">
        <div className="rounded-xl md:rounded-none md:rounded-l-xl border border-stone-250 bg-white px-6 py-4">
          <div className="flex gap-6 pb-4 border-b border-stone-250">
            <SalesforceSVG className="h-12" />
            <div className="font-Outfit text-[20px] text-stone-950 pt-2">
              Salesforce
            </div>
          </div>
          <div className="font-Outfit text-sm 2xl:text-base text-stone-950 mt-6">
            Connect with Salesforce to bulk export and manage prospects while
            associating companies and contacts with conversations.
          </div>
        </div>
        <div className="rounded-xl md:rounded-none border-y border md:border-0 md:border-y border-stone-250 bg-white px-6 py-4">
          <div className="flex gap-6 pb-4 border-b border-stone-250">
            <HubspotSVG className="h-12" />
            <div className="font-Outfit text-[20px] text-stone-950 pt-2">
              Hubspot
            </div>
          </div>
          <div className="font-Outfit text-sm 2xl:text-base text-stone-950 mt-6">
            Integrate with Hubspot to get unparalleled visibility into how your
            reps are interacting with potential prospects and customers.
          </div>
        </div>
        <div className="rounded-xl md:rounded-none md:rounded-r-xl border border-stone-250 bg-white px-6 py-4">
          <div className="flex gap-6 pb-4 border-b border-stone-250">
            <OutreachSVG className="h-12" />
            <div className="font-Outfit text-[20px] text-stone-950 pt-2">
              Outreach <span className="text-stone-350">(In Progress)</span>
            </div>
          </div>
          <div className="font-Outfit text-sm 2xl:text-base text-stone-950 mt-6">
            Want to streamline your customer engagement? Integrate with Outreach
            to bulk export prospect data into automated sequences.
          </div>
        </div>
      </div>
      <div className="grid gap-5 md:gap-0 grid-cols-1 md:grid-cols-3">
        <div className="rounded-xl md:rounded-none md:rounded-l-xl border border-stone-250 bg-white px-6 py-4">
          <div className="flex gap-6 pb-4 border-b border-stone-250">
            <PipedriveSVG className="h-12" />
            <div className="font-Outfit text-[20px] text-stone-950 pt-2">
              Pipedrive <span className="text-stone-350">(In Progress)</span>
            </div>
          </div>
          <div className="font-Outfit text-sm 2xl:text-base text-stone-950 mt-6">
            Integrate with Pipedrive to connect customer data with CRM data, and
            enjoy additional insights on your sales flow.
          </div>
        </div>
        <div className="rounded-xl md:rounded-none border-y border md:border-0 md:border-y border-stone-250 bg-white px-6 py-4">
          <div className="flex gap-6 pb-4 border-b border-stone-250">
            <ZapierSVG className="h-12" />
            <div className="font-Outfit text-[20px] text-stone-950 pt-2">
              Zapier <span className="text-stone-350">(In Progress)</span>
            </div>
          </div>
          <div className="font-Outfit text-sm text-stone-950 mt-6">
            Connect with Zapier to automate your business processes. Bulk enrich
            leads with Zapier and optimize all your workflows.
          </div>
        </div>
        <div className="rounded-xl md:rounded-none md:rounded-r-xl border border-stone-250 bg-white px-6 py-4">
          <div className="flex gap-6 pb-4 border-b border-stone-250">
            <ZohoSVG className="h-12" />
            <div className="font-Outfit text-[20px] text-stone-950 pt-2">
              Zoho CRM <span className="text-stone-350">(In Progress)</span>
            </div>
          </div>
          <div className="font-Outfit text-sm 2xl:text-base text-stone-950 mt-6">
            Integrate with Zoho to save more relevant leads to your Zoho
            database and convert more leads into opportunities with ease.
          </div>
        </div>
      </div>
    </div>
  );
}
