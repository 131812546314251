import cn from "classnames";
export default function SwitchButton(props) {
  const { switchState } = props;
  const [isAnnual, setIsAnnual] = switchState;

  const onAnnualClick = () => {
    setIsAnnual(!isAnnual);
  };
  const options = [
    {
      text: "Annual billing",
      annual: true,
    },
    { text: "Monthly billing", annual: false },
  ];
  return (
    <div className="relative flex w-[294px] items-center justify-center md:w-[380px]">
      <div className="relative flex w-full flex-row rounded-[30px] border-[1px] border-[#E0E0E2] bg-transparent px-[4px] py-[4px] normal-case">
        <div
          className={cn(
            { "translate-x-[calc(100%)]": !isAnnual },
            "left-[4px] absolute top-[50%] z-[1] h-[calc(100%-12px)] w-[calc(50%-4px)] translate-y-[-50%] rounded-[30px] bg-black transition py-2.5 px-3 md:px-4 md:py-3"
          )}
        ></div>
        {options.map((el) => {
          return (
            <div
              onClick={onAnnualClick}
              className={cn(
                "font-Outfit cursor-pointer text-md z-[2] flex h-full w-1/2 items-center justify-center rounded-[30px] py-2.5 px-3 md:px-4 md:py-3"
              )}
            >
              <span
                className={cn("text-gray-950", {
                  "text-white ": !(!isAnnual === el.annual),
                })}
              >
                {el.text}
              </span>
            </div>
          );
        })}

        <div className="font-OutfitSemiBold absolute left-[25%] top-[100%] z-10 flex translate-x-[-50%] translate-y-[-50%] items-center justify-center rounded-[8px] bg-[#F9D861] p-[2.5px_20px] text-[0.625rem] font-semibold leading-[1.4] text-gray-850  shadow-[6.27px_6.27px_10.27px_0px_rgba(3,34,37,0.24)]">
          Save 20%
        </div>
      </div>
    </div>
  );
}
