import { useState } from "react";
import { Button } from "@material-tailwind/react";
import { Backdrop } from "@mui/material";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import BulkResultTable from "@/components/bulk/BulkResultTable";
import BulkUploadWidget from "@/components/bulk/BulkUploadWidget";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import CRMUploadResultsTable from "@/components/common/CRMElements/CRMUploadTable";
import { motion, AnimatePresence } from "framer-motion";
import MainTitleBar from "@/components/MainTitleBar";

export default function ListsScene() {
  const { show, loading: crmLoading } = useCRMPushResultStore();
  const [currentWidgetIndex, setCurrentWidgetIndex] = useState(3);

  return (
    <MainLayout loading={crmLoading}>
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              className="w-full"
              initial={{ opacity: 0, zIndex: 11 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Backdrop
                open
                className="!absolute"
                sx={{
                  backgroundColor: "rgba(0,0,0,0.18)",
                  backdropFilter: "blur(2px)",
                  zIndex: 11,
                }}
              >
                <CRMUploadResultsTable></CRMUploadResultsTable>
              </Backdrop>
            </motion.div>
          )}
        </AnimatePresence>
      </>
      {currentWidgetIndex === 0 && (
        <BaseContainer>
          <div className="flex items-center justify-center w-full grow">
            <div className="my-24 flex items-center w-full sm:max-w-[704px] flex-col rounded-md border-[1px] border-[#E6E6E6] p-6">
              <div className="flex flex-col self-stretch mb-2 text-3xl font-bold text-center font-Outfit">
                Bulk Enrich your Contacts
              </div>
              <div className="font-Outfit flex-wrap text-center text-[1.125rem] leading-[1.35rem] font-light mb-[48px]">
                "Upload your contacts into the CSV enrichment tool to receive
                real-time data. We will provide precise details and categorize
                your contacts into organized lists
              </div>
              <Button
                onClick={() => {
                  setCurrentWidgetIndex(1);
                }}
                className="pl-2 main-button"
              >
                Begin uploading
              </Button>
            </div>
          </div>
        </BaseContainer>
      )}

      {(currentWidgetIndex === 1 || currentWidgetIndex === 2) && (
        <BaseContainer>
          <BulkUploadWidget
            currentWidgetIndex={currentWidgetIndex}
            setCurrentWidgetIndex={setCurrentWidgetIndex}
          />
        </BaseContainer>
      )}

      {currentWidgetIndex === 3 && (
        <>
          <MainTitleBar>
            <div className="flex items-end justify-between">
              <p>CSV Enrich</p>
              <Button
                onClick={() => {
                  setCurrentWidgetIndex(0);
                }}
                className="pl-2 main-button"
              >
                CSV Enrich
              </Button>
            </div>
          </MainTitleBar>
          <BaseContainer>
            <BulkResultTable />
          </BaseContainer>
        </>
      )}
    </MainLayout>
  );
}
