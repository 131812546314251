import { Button } from "@material-tailwind/react";
import { ReactSVG } from "react-svg";
import cl from "classnames";

export default function IntegrateCardSoon({ info }) {
  return (
    <div className="h-full relative flex flex-col gap-4 self-stretch rounded-md border border-stone-250 bg-white p-[16px_32px_32px_32px] transition-all duration-300 hover:m-[-2px] hover:border-blue-500 hover:drop-shadow-lg">
      <div className="flex flex-row justify-between">
        <div className="font-Outfit flex cursor-pointer flex-row items-center gap-2 text-left text-md font-bold text-black">
          <ReactSVG src={info.icon} />
          <span>{info.label}</span>
        </div>
      </div>
      <hr className="w-full rounded-full bg-stone-250" />
      <div className="font-Outfit 2xl:text-base cursor-pointer text-sm">
        {info.description}
      </div>
      <div className="w-full mt-auto gap-4 flex flex-row justify-end">
        <div className="flex justify-end">
          <Button
            className={cl(
              "font-OutfitBold flex capitalize items-center justify-center gap-2 rounded-md bg-stone-350 px-4 py-2 text-sm leading-[100%] text-stone-250"
            )}
          >
            Coming soon
          </Button>
        </div>
      </div>
    </div>
  );
}
