import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function DailyCollectedProfiles({ newProfiles, newProfilesByDays, returningProfilesByDays, duration }) {

    const getDays = () => {
        const lastSevenDays = [];

        for (let i = 1; i <= duration; i++) {
            const date = new Date();
            date.setDate(date.getDate() - i);

            // Format the date as needed (optional)
            const formattedDate = date.toISOString().split('T')[0]; // YYYY-MM-DD format

            lastSevenDays.push(formattedDate);
        }
        return lastSevenDays;
    };


    const legendItems = [
        { color: 'bg-blue-750', label: 'New Profiles' },
        { color: 'bg-yellow-300', label: 'Repeat Profiles' }
    ];

    const data = {
        labels: getDays(),
        datasets: [
            {
                label: 'New Profiles',
                data: newProfilesByDays,
                backgroundColor: 'rgba(59, 130, 246, 0.5)', // Light blue background
                borderColor: 'rgba(59, 130, 246, 1)',
                borderWidth: 1,
            },
            {
                label: 'Repeat Profiles',
                data: returningProfilesByDays,
                backgroundColor: 'rgba(252, 211, 77, 0.7)', // Yellow background
                borderColor: 'rgba(252, 211, 77, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
            },
            x: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: false, // Set to true if you want to show legends
            },
        },
    };

    return (
        <section className="mt-10 flex flex-col text-sm text-black">
            <h2 className="w-full font-medium leading-tight max-md:max-w-full">
                Daily Collected Profiles
            </h2>
            <div className="flex flex-col mt-4 w-full max-md:max-w-full">
                <div className="flex flex-col pt-8 pr-7 pb-14 pl-16 w-full rounded-lg border border-solid border-stone-200 max-md:px-5 max-md:max-w-full">
                    <div className="flex z-10 flex-col self-end w-full leading-5 text-right max-w-[872px] max-md:max-w-full">
                        <div>
                            <span className="text-3xl">{parseFloat((newProfiles / duration).toFixed(2))}</span>
                            <br />
                            Avg new per day
                        </div>
                        {/* <div className="flex w-full items-center gap-[45px] mt-[200px]">
                            <div>0</div>
                            <div className="h-px border border-dashed border-stone-200 flex-shrink-0 w-[60px]" />
                            <div className="h-px border border-dashed border-stone-200 flex-shrink-0 w-[60px]" />
                            <div className="h-px border border-dashed border-stone-200 flex-shrink-0 w-[60px]" />
                            <div className="h-px border border-dashed border-stone-200 flex-shrink-0 w-[60px]" />
                            <div className="h-px border border-dashed border-stone-200 flex-shrink-0 w-[60px]" />
                            <div className="h-px border border-dashed border-stone-200 flex-shrink-0 w-[60px]" />
                            <div className="h-px border border-dashed border-stone-200 flex-shrink-0 w-[60px]" />
                        </div> */}
                    </div>
                    <div className="flex flex-col mt-0 w-full leading-tight max-w-[974px] max-md:max-w-full">
                        <div className="p-4 bg-white shadow-md rounded-lg">
                            <Bar data={data} options={options} />
                        </div>
                        {/* <div className="flex flex-wrap">
                            {dates.map((date, index) => (
                                <div className={`flex-row py-10 px-4`}>
                                    {date}
                                </div>
                            ))}
                        </div> */}
                        <div className="flex gap-10 self-center mt-14 ml-10 max-w-full w-[278px] max-md:mt-10">
                            {legendItems.map((item, index) => (
                                <div className="flex flex-1 gap-4 items-center">
                                    <div className={`flex-shrink-0 w-3 h-3 ${item.color} rounded-full`} />
                                    <div className="whitespace-nowrap">{item.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DailyCollectedProfiles;