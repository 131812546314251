import React from 'react';
import { ReactComponent as RenameIcon } from "@/assets/image/ai_engagement/renameIcon.svg";
import { ReactComponent as CopyIcon } from "@/assets/image/ai_engagement/copyIcon.svg";
import { ReactComponent as MailBlackIcon } from "@/assets/image/ai_engagement/mailBlackIcon.svg";
import { ReactComponent as LinkedinIcon } from "@/assets/image/ai_engagement/linkedinIcon.svg";
import { toast } from 'react-toastify';

export default function ContactDetails({ targetContact }) {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy to clipboard!');
    });
  };

  function getInitials(fullName) {
    if (!fullName) return '';
    const nameParts = fullName.trim().split(' ');
    const firstNameInitial = nameParts[0]?.[0] || '';
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : '';
    return (firstNameInitial + lastNameInitial).toUpperCase();
  }

  const openMailClient = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const openLinkedInProfile = (linkedinUrl) => {
    console.log('linkedinUrl', linkedinUrl);
    window.open(linkedinUrl, '_blank');
  };

  return (
    <div className='flex h-full'>
      <div className='w-full'>
        <div className='bg-dgray-100 py-2 px-4 flex items-center rounded-lg'>
          <h1 className='text-md font-medium'>Contact</h1>
          {/* <div className='text-sm text-primary-blue flex items-center gap-2 ml-auto'>
            <p>Actions</p>
            <DownArrowIcon className='w-3 h-3' />
          </div> */}
        </div>

        <div className='campaign-card mt-2 !p-4'>
          <div className='flex gap-2 flex-shrink-0'>
            <h1 className='bg-primary-blue h-14 w-14 rounded-full text-white text-xl flex items-center justify-center flex-shrink-0'>{getInitials(targetContact?.fullName)}</h1>
            <div>
              <div className='flex items-center gap-3'>
                <h1 className='text-lg font-semibold'>{targetContact?.fullName}</h1>
              </div>
              <p className='text-dgray-500 mt-1 text-sm'>{targetContact?.jobTitle}</p>
              <div
                className='flex items-center gap-2 text-primary-blue mt-5 hover:opacity-90 cursor-pointer'
                onClick={() => copyToClipboard(targetContact?.primaryEmail)}
              >
                <p className='text-sm'>{targetContact?.primaryEmail}</p>
                <CopyIcon className='w-3 h-3' />
              </div>
            </div>
          </div>
          <div className='border-t border-stone-400 my-4' ></div>
          <div className='flex gap-7'>
            <MailBlackIcon
              className='w-6 h-6 cursor-pointer hover:opacity-90'
              onClick={() => openMailClient(targetContact?.primaryEmail)}
            />
            <LinkedinIcon
              className='w-6 h-6 cursor-pointer hover:opacity-90'
              onClick={() => openLinkedInProfile(targetContact?.linkedinUrl)}
            />
          </div>
        </div>

        <div className='bg-dgray-100 py-2 px-4 flex items-center rounded-lg mt-8'>
          <h1 className='text-md font-medium'>Company</h1>
          {/* <div className='text-sm text-primary-blue flex items-center gap-1 ml-auto'>
            <p className='text-xl '>+</p>
            <p>Add</p>
          </div> */}
        </div>

        <div className='campaign-card mt-2 !p-4'>
          <span className='bg-[#e8e7e7] px-3 py-1.5 rounded'>Primary</span>
          <div className='flex gap-5 mt-4 items-center'>
            <img src={`https://logo.clearbit.com/${targetContact?.companyName}`} className='w-[70px] h-[70px]' alt={`${targetContact?.companyName} logo`} onError={(e) => e.target.style.display = 'none'} />
            <div>
              <h1 className='text-lg font-semibold'>{targetContact?.companyName}</h1>
              <div
                className='flex items-center flex-wrap text-primary-blue gap-2 cursor-pointer hover:opacity-90'
                onClick={() => copyToClipboard(targetContact?.companyUrl)}
              >
                <p className='text-md break-all break-after-all'>{targetContact?.companyUrl}</p>
                <CopyIcon className='w-4 h-4' />
              </div>
            </div>
          </div>
          {targetContact?.companyDescription && <p className='mt-2 text-sm'>{targetContact?.companyDescription}</p>}
        </div>
      </div>
    </div>
  )
}
