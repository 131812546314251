import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import useAuth from "@/hooks/useAuth";
import { ReactComponent as EditWarmup } from "@/assets/image/edit-warmup.svg";
import { toast } from "react-toastify";

export const WarmupReport = ({ emailAccount, setEmailAccount }) => {
  const { getAuthToken } = useAuth();
  const [emailsPerDay, setEmailsPerDay] = useState(
    emailAccount.email_per_day || 0
  );
  const [responseRate, setResponseRate] = useState(
    emailAccount.response_rate || 0
  );

  const totalEmailsInputRef = useRef(null);
  const replyRateInputRef = useRef(null);

  const handleSetEmailsPerDay = (event) => {
    const value = event.target.value;

    setEmailsPerDay(value);
  };

  const handleSetResponseRate = (event) => {
    const value = event.target.value;

    console.log(value);

    setResponseRate(value);
  };
  const getLastNDays = (days) => {
    const dates = [];
    const today = new Date();
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      const dayName = daysOfWeek[date.getDay()];
      const dayNumber = date.getDate();
      dates.push(`${dayName} ${dayNumber}`);
    }
    return dates;
  };

  const formatMetricsForChart = (metrics, days) => {
    const interactions = [];
    const scheduled = [];
    const inbox = [];
    const spam = [];

    const today = new Date();
    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const dateString = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD

      // Warmup data
      const warmup = metrics[dateString]?.["Warm Up"];
      if (warmup) {
        interactions.push(warmup.Total || 0);
        scheduled.push(warmup["Landed In"]?.inbox || 0); // Assuming 'Scheduled' means emails landed in the inbox
      } else {
        interactions.push(0);
        scheduled.push(0);
      }

      // Cold emails data
      const coldEmails = metrics[dateString]?.["Replies"];
      if (coldEmails) {
        inbox.push(coldEmails["Landed In"]?.inbox || 0);
        spam.push(coldEmails["Landed In"]?.spam || 0);
      } else {
        inbox.push(0);
        spam.push(0);
      }
    }

    return {
      interactions,
      scheduled,
      inbox,
      spam,
    };
  };

  const getMaxValue = (firstValues, secondValues) => {
    // Flatten and combine data from both series

    const combined = firstValues.map(
      (value, index) => value + secondValues[index]
    );

    return Math.max(...combined);
  };

  useEffect(() => {
    const { interactions, scheduled, inbox, spam } = formatMetricsForChart(
      emailAccount.metrics,
      14
    );

    const {
      interactions: interactions7,
      scheduled: scheduled7,
      inbox: inbox7,
      spam: spam7,
    } = formatMetricsForChart(emailAccount.metrics, 7);

    Highcharts.chart("warmup-report", {
      chart: {
        type: "column",
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
        events: {
          render: function () {
            // Set minimum width and height for the container
            const container = this.container;
            container.style.minWidth = "800px"; // Set your minimum width
            container.style.minHeight = "400px"; // Set your minimum height
          },
        },
        spacing: [24, 24, 24, 24],
      },

      title: {
        text: "14 Days warm up report",
        align: "left",
        style: {
          fontFamily: "Outfit",
          fontSize: "14px",
          fontWeight: 600,
          color: "#000",
        },
      },
      xAxis: {
        gridLineWidth: 0,
        categories: getLastNDays(14),
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0,
        plotLines: [
          {
            color: "#D6D6D6",
            width: 1,
            dashStyle: "Dash",
            value: 0,
          },
          {
            color: "#D6D6D6",
            dashStyle: "Dash",
            width: 1,
            value: getMaxValue(interactions, scheduled),
          },
        ],
        labels: {
          enabled: false,
        },
        stackLabels: {
          enabled: false,
        },
      },
      legend: {
        align: "right",
        verticalAlign: "top",
        y: -5,
        floating: true,
        backgroundColor: "transparent",
        borderColor: "transparent",
        squareSymbol: true,
        symbolRadius: "0px",
        shadow: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            formatter: function () {
              if (!this.y) {
                return "";
              }
              let color;
              if (this.series.name === "Interactions") {
                color = "#fff";
              } else if (this.series.name === "Scheduled") {
                color = "#000";
              }
              return '<span style="color:' + color + '">' + this.y + "</span>";
            },
            enabled: true,
            pointPlacement: true,
            style: {
              fontFamily: "Outfit",
              fontSize: "14px",
              fontWeight: "400",
              textOutline: "none",
            },
          },
        },
      },
      series: [
        {
          name: "Interactions",
          data: interactions,
          color: "#4873FA",
        },
        {
          name: "Scheduled",
          data: scheduled,
          color: "#C8D5FD",
        },
      ],
      credits: { enabled: false },
    });
    Highcharts.chart("warmup-cold-emails", {
      chart: {
        type: "column",
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
        spacing: [24, 24, 24, 24],
      },

      title: {
        text: "Where are you cold emails landing?",
        align: "left",
        style: {
          fontFamily: "Outfit",
          fontSize: "14px",
          fontWeight: 600,
          color: "#000",
        },
      },
      xAxis: {
        gridLineWidth: 0,
        categories: getLastNDays(7),
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0,
        plotLines: [
          {
            // Add a plot line for the start
            color: "#D6D6D6",
            width: 1,
            dashStyle: "Dash",
            value: 0, // Position of the start line
          },
          {
            // Add a plot line for the maximum value
            color: "#D6D6D6",
            dashStyle: "Dash",
            width: 1,
            value: getMaxValue(inbox7, spam7), // Position of the maximum value line
          },
        ],
        labels: {
          enabled: false,
        },
        stackLabels: {
          enabled: false,
        },
      },
      legend: {
        align: "right",
        verticalAlign: "top",
        y: -5,
        floating: true,
        backgroundColor: "transparent",
        borderColor: "transparent",
        shadow: false,
        squareSymbol: true,
        symbolRadius: "0px",
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            formatter: function () {
              if (!this.y) {
                return "";
              }

              let color;

              if (this.series.name === "Inbox") {
                color = "#fff";
              } else if (this.series.name === "Spam") {
                color = "#000";
              }
              return '<span style="color:' + color + '">' + this.y + "</span>";
            },
            enabled: true,
            style: {
              fontFamily: "Outfit",
              fontSize: "14px",
              fontWeight: "400",
              textOutline: "none",
            },
          },
        },
      },
      series: [
        {
          name: "Inbox",
          data: inbox7,
          color: "#4873FA",
        },
        {
          name: "Spam",
          data: spam7,
          color: "#E19908",
        },
      ],
      credits: { enabled: false },
    });
  }, []);

  const handleTotalEmailsOnBlur = async () => {
    let valueToSet = emailsPerDay;

    if (!emailsPerDay) {
      valueToSet = 1;
    } else if (Number(valueToSet) > 25) {
      valueToSet = 25;
    }

    setEmailsPerDay(valueToSet);

    try {
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            campaign: {
              email_per_day: Number(valueToSet),
              campaignId: emailAccount.id,
              action: "updateEmailPerDay",
            },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong try again");
      }

      const parsedRes = await res.json();

      setEmailAccount((prev) => ({
        ...prev,
        email_per_day: parsedRes.data.email_per_day,
      }));
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
  const handleReplyRateOnBlur = async () => {
    let valueToSet = responseRate;

    if (!responseRate) {
      valueToSet = 1;
    } else if (Number(valueToSet) > 30) {
      valueToSet = 30;
    }

    setResponseRate(valueToSet);

    try {
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            campaign: {
              response_rate: Number(valueToSet),
              campaignId: emailAccount.id,
              action: "updateResponseRate",
            },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong try again");
      }

      const parsedRes = await res.json();

      setEmailAccount((prev) => ({
        ...prev,
        response_rate: parsedRes.data.response_rate,
      }));
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  return (
    <div>
      <div className="flex justify-between mt-[35px] 2xl:mt-[44px]">
        <div id="warmup-report"></div>
        <div className="flex flex-col w-[350px] max-h-[295px] 2xl:w-[460px] p-[20px] 2xl:p-[24px] border border-stone-250 rounded-[8px]">
          <p className="font-OutfitSemiBold text-black text-[14px] 2xl:text-[16px]">
            Frequency
          </p>
          <div className="warmup-frequency-item-container mt-[45px] 2xl:mt-[60px]">
            <div>
              <div>
                <input
                  min={1}
                  max={25}
                  type="number"
                  ref={totalEmailsInputRef}
                  className="warmup-frequency-number-input font-OutfitSemiBold"
                  value={emailsPerDay}
                  onChange={handleSetEmailsPerDay}
                  onBlur={handleTotalEmailsOnBlur}
                />
              </div>
              <p className="warmup-frequency-description font-Outfit">
                Total emails per day
              </p>
            </div>

            <button
              onClick={() => {
                totalEmailsInputRef.current.focus();
              }}
            >
              <EditWarmup className="warmup-edit-frequency" />
            </button>
          </div>
          <hr className="h-[1px] my-[23px] 2xl:my-[30px] w-full bg-[#EDEDED]" />
          <div className="warmup-frequency-item-container">
            <div>
              <div className="relative w-fit group">
                <input
                  min={1}
                  max={30}
                  type="number"
                  ref={replyRateInputRef}
                  className="warmup-frequency-number-input font-OutfitSemiBold"
                  value={responseRate}
                  onChange={handleSetResponseRate}
                  onBlur={handleReplyRateOnBlur}
                />
                <p className="absolute right-0 top-1/2 transform -translate-y-1/2 font-OutfitSemiBold font-semibold text-[20px] 2xl:text-[24px] text-accents-blue2 group-hover:opacity-0 group-focus-within:opacity-0 group-hover:-z-10 group-focus-within:-z-10 transition-opacity">
                  %
                </p>
              </div>
              <p className="warmup-frequency-description font-Outfit">
                Reply rate (on Warmup emails)
              </p>
            </div>

            <button
              onClick={() => {
                replyRateInputRef.current.focus();
              }}
            >
              <EditWarmup className="warmup-edit-frequency" />
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-10">
        <div
          id="warmup-cold-emails"
          className="mt-[24px] 2xl:mt-[32px] col-span-4 mb-20"
        />
        <div className="col-span-6" />
      </div>
    </div>
  );
};
