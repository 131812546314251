import { Button } from "@material-tailwind/react";
import { useState, useContext } from "react";
import SwitchButton from "@/components/common/SwitchButton";
import { Link } from "react-router-dom";
import LandingLayout from "@/components/LandingLayout";
import { useResizeDetector } from "react-resize-detector";
import { ReactComponent as TextDecorIcon } from "@/assets/image/icons/text-decor.svg";
import Datapoints from "@/components/landing/common/Datapoints";
import TrustedUser from "@/components/landing/base/TrustedUser";
import { Box } from "@mui/material";
import FullPricingBlock from "@/components/pricing/FullPricingBlock";
import { useQuery } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import BaseContainer from "@/components/BaseContainer";
import { AuthContext } from "@/context/AuthContext";
import MainLoader from "@/components/MainLoader";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useKompassRouter from "@/hooks/useKompassRouter";

export default function PricingScene() {
  const [annual, setAnnual] = useState(true);
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { kompassNavigate } = useKompassRouter();

  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: "debounce",
    refreshRate: 1000,
  });
  //Selecting a plan by clicking a button in a table or on a Plan card
  const subscriptionInfoFunction = useHTTPRequest(
    `/billing/getSubscriptionInfo`
  );

  const tariffsInfoFunction = useHTTPRequest(
    `/billing/getTariffs?product=enrich`,
    "GET",
    true
  );

  const params = new URLSearchParams();
  params.set("mode", "customize_credits");
  params.set("product", "enrich");
  const customCreditsInfoFunction = useHTTPRequest(
    `/billing/getTariffs?` + params.toString(),
    "GET",
    true
  );
  const { data: subscriptionInfo, isLoading: isLoadingSubscription } = useQuery(
    {
      queryKey: ["Subscription"],
      queryFn: async () => {
        const signedIn = await authContext.isSignIn();
        if (signedIn) return subscriptionInfoFunction();
        else
          return {
            data: [],
            isLoading: false,
          };
      },
    }
  );

  const { data: tariffsList, isLoading: isLoadingTariffs } = useQuery({
    queryKey: ["Tariffs"],
    queryFn: () => tariffsInfoFunction(),
  });
  const { data: customCreditsInfo, isLoading: isLoadingCustomCredits } =
    useQuery({
      queryKey: ["CustomCredits"],
      queryFn: () => customCreditsInfoFunction(),
    });

  const loading =
    isLoadingCustomCredits || isLoadingSubscription || isLoadingTariffs;

  return (
    <LandingLayout>
      {loading && <MainLoader />}
      {!loading && (
        <BaseContainer>
          <div
            ref={ref}
            className="flex w-full flex-col items-center pt-12 md:pt-[3.75rem] pb-[4.75rem]"
          >
            <h1 className="mb-9 md:mb-4 px-4 py-2.5 flex items-center justify-center rounded-[40px] bg-[#D9D9D9] font-Outfit text-[#0D0D0D] text-base md:text-[20px] leading-[1.4] uppercase">
              Pricing
            </h1>
            <h2 className="mb-12 md:mb-6 font-OutfitBold font-bold relative text-center text-[38px] md:text-[64px] leading-[1.3] md:leading-[1.1] text-stone-950">
              Accelerate your
              <span className="relative inline-block ml-2">
                growth
                <TextDecorIcon className="absolute -bottom-[10%] -right-[20%] md:-bottom-[10%] md:-right-[10%]" />
              </span>
            </h2>
            <p className="mb-10 hidden md:block font-Outfit text-center text-[26px] leading-[1.2] text-gray-950 opacity-70">
              Reach millions of decision makers and their teams
            </p>
            <SwitchButton switchState={[annual, setAnnual]} />
          </div>
          <FullPricingBlock
            subscriptionInfo={subscriptionInfo?.data}
            annual={annual}
            customCreditsInfo={customCreditsInfo?.data}
            tariffList={tariffsList?.data}
            topPosition={isTablet ? 0 : 75}
          />
          <div className="flex flex-col gap-20">
            <Datapoints background={"bg-[#9AB2FF]"} opacity={"bg-opacity-25"} />
            <div className="hidden lg:flex pt-20 pb-8 w-full flex-col gap-12 items-center rounded-[2.5rem] bg-[#9AB2FF]">
              <div className="flex-col flex gap-4">
                <h3 className="font-OutfitBold relative text-center text-7xl xl:text-8xl leading-[1.1] text-stone-950">
                  Need unlimited data?
                </h3>
                <p
                  className="font-Outfit text-[1.625rem] font-normal leading-[1.2] flex items-center justify-center text-stone-950"
                  style={{ textAlign: "center" }}
                >
                  We’ll be happy to discuss a tailored packaged that
                  <br /> matches your needs
                </p>
              </div>
              <div className="flex">
                <Link to="/signup">
                  <Button
                    className="normal-case font-OutfitBold px-6 py-5 font-bold rounded-lg border border-stone-950 bg-black text-base tracking-[0.32px] leading-[1.2] text-white"
                    onClick={() => kompassNavigate("/demo")}
                  >
                    Book a Demo
                  </Button>
                </Link>
              </div>
            </div>
            <Box className="relative pb-20">
              <TrustedUser />
            </Box>
          </div>
        </BaseContainer>
      )}
    </LandingLayout>
  );
}
