import * as React from "react";
import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { SidebarItem } from "@/components/sidebar/SidebarItem";
import {
  MAIN_SIDEBAR_LIST,
  SECONDARY_SIDEBAR_LIST,
  TERTIARY_SIDEBAR_LIST,
} from "@/utils/common";
import { SidebarUserItem } from "@/components/sidebar/SideBarUserItem";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "@/context/AuthContext";
import { ReactComponent as BurgerMenu } from "@/assets/image/sidebar-icons/burger-menu.svg";
import { ReactComponent as HomeIcon } from "@/assets/image/sidebar-icons/home.svg";
import { ReactComponent as SearchIcon } from "@/assets/image/sidebar-icons/search-loop.svg";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 300;

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: "#000",
  },
}));

export function MobileHeader({ showProfilePopup }) {
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();
  const authContext = useContext(AuthContext);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List sx={{ paddingY: 0 }}>
        <SidebarItem
          open={open}
          label="KompassAI"
          onClick={toggleDrawer(false)}
          icon={BurgerMenu}
          pathname={pathname}
          textStyles="!text-white"
        />
        <SidebarItem
          label={"Home"}
          open={open}
          icon={HomeIcon}
          pathname={pathname}
          router="/home"
        />
        <Divider
          sx={{
            backgroundColor: "#E8E7E7",
            opacity: 0.3,
            marginY: 2,
          }}
        />
        {MAIN_SIDEBAR_LIST.map(({ label, icon: Icon, router }, index) => (
          <SidebarItem
            open={open}
            key={label}
            label={label}
            icon={Icon}
            router={router}
            pathname={pathname}
          />
        ))}
      </List>
      <Divider sx={{ backgroundColor: "#E8E7E7", opacity: 0.3, marginY: 2 }} />
      <List sx={{ paddingY: 0 }}>
        {SECONDARY_SIDEBAR_LIST.map(({ label, icon: Icon, router }, index) => (
          <SidebarItem
            open={open}
            key={label}
            label={label}
            icon={Icon}
            router={router}
            pathname={pathname}
          />
        ))}
      </List>
      <Divider sx={{ backgroundColor: "#E8E7E7", opacity: 0.3, marginY: 2 }} />
      <List sx={{ paddingY: 0 }}>
        {TERTIARY_SIDEBAR_LIST.map(({ label, icon: Icon, router }, index) => (
          <SidebarItem
            open={open}
            key={label}
            label={label}
            icon={Icon}
            pathname={pathname}
            router={router}
          />
        ))}
      </List>
      <List sx={{ marginTop: "auto" }}>
        {/*<SidebarItem*/}
        {/*  open={open}*/}
        {/*  pathname={pathname}*/}
        {/*  label="Search"*/}
        {/*  icon={SearchIcon}*/}
        {/*  router="#"*/}
        {/*/>*/}
        <SidebarUserItem
          label={authContext.userInfo?.userName || ""}
          open={open}
          authContext={authContext}
          onClick={(event) => {
            showProfilePopup(event.currentTarget);
          }}
        />
      </List>
    </Box>
  );

  return (
    <div className="lg:hidden">
      <Button onClick={toggleDrawer(true)}>
        <BurgerMenu className="text-black" />
      </Button>
      <Drawer open={open}>{DrawerList}</Drawer>
    </div>
  );
}
