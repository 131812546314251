import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DynamicComparisonIcon from './DynamicComparisonIcon';

const StatBlock = ({ value, percentage, color, label, className='' }) => (
  <div className={`flex flex-col items-center ${className}`}>
    <div className='flex items-center'>
      <h1 className='md:text-3xl font-medium'>{value}</h1>
      <p className='text-blue-500 ml-2 text-xs md:text-base'>({percentage}%)</p>
    </div>
    <div className='flex items-center'>
      <span className='h-3 w-3 rounded-full' style={{ backgroundColor: color }}></span>
      <p className='ml-2 text-xs sm:text-base'>{label}</p>
      <InfoOutlinedIcon className='!h-4 !w-4 text-dgray-500 ml-2 !hidden sm:!block' />
    </div>
  </div>
);

export default function LeadQuality() {
  return (
    <div className='mt-12 flex flex-col md:flex-row bg-dgray-100 p-5 rounded-lg'>
      <div className='flex items-center gap-2'>
        <h1 className='text-lg font-semibold'>Lead Quality</h1>
        <InfoOutlinedIcon className='!h-5 !w-5 text-dgray-500 mt-1' />
      </div>

      {/* only visible on mobile */}
      <StatBlock value={69} percentage={17.87} color='#3b82f6' label='Leads Bounced' className='md:hidden !items-start mt-3' />

      <div className='md:ml-auto flex flex-row gap-5 md:mt-0 mt-7 md:gap-3 lg:gap-10 items-center justify-center md:justify-start'>
        <StatBlock value={69} percentage={17.87} color='#3b82f6' label='Leads Bounced' className='hidden md:flex ' /> 
        <div className='border-l-2 border-gray-600 h-full hidden md:block'></div>
        <StatBlock value={50} percentage={17.87} color='#7d9bfa' label='Leads Unsubscribed' />
        {/* value1 is 'leads reached' and value2 is 'leads bounced' */}
        <DynamicComparisonIcon value1={80} value2={50} color1='#3b82f6' color2='#7d9bfa' className='' />
        <StatBlock value={80} percentage={17.87} color='#3b82f6' label='Leads Reached' />
      </div>
    </div>
  );
}
