import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";

import { pageContentWidth } from "@/utils/common";
import useStripe from "@/hooks/useStripe";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import { UserSlider } from "@/components/usersslider/UserSlider";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import ArrowLeftIcon from "../../assets/image/icons/Arrow_Left.svg";

import CustomCreditsSelector from "@/components/pricing/CustomCreditsSelector";
import useBillingStore from "@/store/useBillingStore";
import BackdropLoader from "@/components/common/BackdropLoader";
import { ReactComponent as StripeIcon } from "@/assets/image/logos/stripe.svg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function BillingPayScene() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { selectedPrices, plan, users, changeUsers } = useBillingStore();
  const annual = selectedPrices.main.interval === "year";
  const customizable = plan.may_be_customized === 1;
  const [clientSecret, setClientSecret] = useState("");
  const [id, setId] = useState(0);

  const [stripeError, setStripeError] = useState("");

  const { getPaymentLink } = useStripe();

  //WARN: if index file has React.StrictMode wrapper in it, this will break, as it would try to do it twice
  //and doing that breaks the Stripe embed, shouldn't affect production, as it's turned off by default in build
  const loadPaymentLink = async () => {
    setLoading(true);
    setStripeError("");
    if (selectedPrices) {
      const filteredPrices = Object.values(selectedPrices).filter(
        (t) => !!t?.lookup_key
      );
      let res = await getPaymentLink(filteredPrices, users);
      if (!res.status) {
        setLoading(false);
        setStripeError(res.message);
      }
      if (res?.data?.client_secret) {
        setLoading(false);
        setClientSecret((cs) => {
          if (cs) {
            console.log("had secret");
            window.clearTimeout(id);
            setId(
              setTimeout(() => {
                setClientSecret(res?.data?.client_secret);
              }, 500)
            );
            return "";
          }
          console.log("didn't have secret");
          return res?.data?.client_secret;
        });
      }
      setLoading(false);
    }
  };

  const subscriptionInfoFunction = useHTTPRequest(
    `/billing/getSubscriptionInfo?product=enrich`
  );

  useQuery({
    queryKey: ["Subscription"],
    queryFn: () => subscriptionInfoFunction(),
  });

  const params = new URLSearchParams();
  params.set("mode", "customize_credits");
  params.set("product", "enrich");
  const customCreditsInfoFunction = useHTTPRequest(
    `/billing/getTariffs?` + params.toString(),
    "GET",
    true
  );
  const { data: customCreditsInfo, isLoading: isLoadingCustomCredits } =
    useQuery({
      queryKey: ["CustomCredits"],
      queryFn: async () => await customCreditsInfoFunction(),
      enabled: customizable,
    });

  useEffect(() => {
    loadPaymentLink();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, selectedPrices]);

  useEffect(() => {
    if (plan.product === "ip") changeUsers(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  //Extra precautions against multiple Stripe forms
  useEffect(() => {
    setClientSecret("");
    return () => {
      setClientSecret("");
      window.clearTimeout(id);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const redirectToSuccessPage = () => {
    navigate("/home");
  };
  return (
    <MainLayout containerClassName="items-center">
      <div
        className="font-Outfit leading-loose flex w-full items-center bg-white
        px-10 pb-4 pt-4 text-2xl text-black drop-shadow-xl filter md:px-16 "
      >
        <button className="flex items-center" onClick={() => navigate(-1)}>
          <img
            src={ArrowLeftIcon}
            className="mr-2 inline text-blue-500"
            alt=""
          />
          <span className="text-m font-Outfit leading-tight cursor-pointer font-normal text-blue-500">
            Back
          </span>
        </button>
        <div className="ml-10">Payment</div>
      </div>
      {loading && <BackdropLoader active={true}></BackdropLoader>}

      {!customizable && (
        <div className="mt-4 gap-4 px-5 w-full">
          <UserSlider />
        </div>
      )}

      {customizable && customCreditsInfo && plan.product === "enrich" && (
        <div className="mt-4 grid md:grid-cols-2 items-start justify-center gap-4 px-4">
          <UserSlider />
          <CustomCreditsSelector
            annual={annual}
            currentPlan={plan}
            optionsKey="customize_phone_credits"
            priceKey="phones"
            sliderTitle="Phone credits"
            customCreditsInfo={customCreditsInfo?.data}
            timeout={200}
          />
          <CustomCreditsSelector
            annual={annual}
            currentPlan={plan}
            priceKey="emails"
            optionsKey="customize_email_credits"
            sliderTitle="Email credits"
            customCreditsInfo={customCreditsInfo?.data}
            timeout={200}
          />
          <CustomCreditsSelector
            annual={annual}
            currentPlan={plan}
            priceKey="validations"
            optionsKey="customize_email_validation_credits"
            sliderTitle="Validation credits"
            customCreditsInfo={customCreditsInfo?.data}
            timeout={200}
          />
        </div>
      )}

      {customizable && customCreditsInfo && plan.product === "ip" && (
        <div className="mt-4 grid md:grid-cols-2 items-start justify-center gap-4 px-4">
          <CustomCreditsSelector
            annual={annual}
            currentPlan={plan}
            optionsKey="customize_ip_credits"
            priceKey="ips"
            sliderTitle="IP credits"
            customCreditsInfo={customCreditsInfo?.data}
            timeout={200}
          />
        </div>
      )}

      {!stripeError.length && clientSecret && (
        <div className="my-10 flex h-full w-full items-center bg-white">
          <BaseContainer width={pageContentWidth}>
            <div id="checkout">
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret }}
              >
                <EmbeddedCheckout
                  onSuccess={() => redirectToSuccessPage()}
                  successUrl={`${window.location.origin}/home`}
                />
              </EmbeddedCheckoutProvider>
            </div>
          </BaseContainer>
        </div>
      )}
      {!stripeError && !clientSecret && (
        <div
          style={{ maxWidth: pageContentWidth }}
          className={`mx-8 mt-8 flex h-[600px] w-full items-center justify-center px-5`}
        >
          <div
            className={`flex h-full w-full items-center justify-center rounded-lg border-1 border-stone-250`}
          >
            <StripeIcon></StripeIcon>
          </div>
        </div>
      )}
      {(stripeError.length || !selectedPrices) && (
        <div className="flex w-full flex-col items-center justify-center">
          <div className="my-24 flex max-w-[500px] flex-col gap-3 rounded-md border-[1px] border-[#E6E6E6] p-10">
            <div className="font-Outfit flex flex-col self-stretch text-center text-[16px] lg:text-[20px] font-extrabold">
              Sorry, we got problems
            </div>
            <div className="font-Outfit flex-wrap text-center text-[16px] lg:text-[20px]">
              {stripeError}
            </div>
            <div className="mt-10 text-center">
              <Button
                onClick={() => navigate(-1)}
                className="font-Outfit h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-[16px] lg:text-[20px] font-medium capitalize text-white"
              >
                Go back
              </Button>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
}
