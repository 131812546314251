import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { ReactComponent as UploadSVG } from "@/assets/image/bulk/upload.svg";
import { bulkEnrichTemplateHeader } from "@/utils/common";

const FileUploaderWidget = ({ attachedFileName, onFileContent }) => {
  const isValidateContent = (content, fileType) => {
    if (fileType === "csv") {
      for (const key of Object.keys(bulkEnrichTemplateHeader)) {
        const headerJoined = bulkEnrichTemplateHeader[key]
          .map((str) => str.trim()) // Trim spaces
          .join(",");
        const contentFieldsJoined = content?.meta?.fields
          ?.map((str) => str.trim()) // Trim spaces
          .join(",");

        if (headerJoined === contentFieldsJoined) {
          return true;
        }
      }
      return false;
    }
    if (fileType === "xlsx" || fileType === "xls") {
      try {
        for (const key of Object.keys(bulkEnrichTemplateHeader)) {
          const headerJoined = bulkEnrichTemplateHeader[key]
            .map((str) => str.trim()) // Trim spaces
            .join(",");
          const contentJoined = Object.keys(content[0])
            .map((str) => str.trim()) // Trim spaces
            .join(",");

          if (headerJoined === contentJoined) {
            return true;
          }
        }
      } catch (error) {
        console.log(false);
        return false;
      }
      console.log(false);
      return false;
    }
  };
  const getValidBulkType = (content, fileType) => {
    if (fileType === "csv") {
      for (const key of Object.keys(bulkEnrichTemplateHeader)) {
        const headerJoined = bulkEnrichTemplateHeader[key]
          .map((str) => str.trim()) // Trim spaces
          .join(",");
        const contentFieldsJoined = content?.meta?.fields
          ?.map((str) => str.trim()) // Trim spaces
          .join(",");

        if (headerJoined === contentFieldsJoined) {
          return key;
        }
      }
      return null;
    }
    if (fileType === "xlsx" || fileType === "xls") {
      try {
        for (const key of Object.keys(bulkEnrichTemplateHeader)) {
          const headerJoined = bulkEnrichTemplateHeader[key]
            .map((str) => str.trim()) // Trim spaces
            .join(",");
          const contentJoined = Object.keys(content[0])
            .map((str) => str.trim()) // Trim spaces
            .join(",");

          if (headerJoined === contentJoined) {
            return key;
          }
        }
      } catch (error) {
        return null;
      }
      return null;
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      try {
        if (acceptedFiles?.length > 0) {
          const file = acceptedFiles[0];
          const fileType = file.name.split(".").pop().toLowerCase();

          const reader = new FileReader();

          reader.onabort = () => {
            throw new Error("file reading was aborted");
          };
          reader.onerror = () => {
            throw new Error("file reading has failed");
          };
          reader.onload = (e) => {
            const fileContent = e.target.result;

            switch (fileType) {
              case "csv":
                Papa.parse(fileContent, {
                  complete: (result) => {
                    const filteredResults = {
                      ...result,
                      data: result.data.filter((row) =>
                        Object.values(row).some((val) => val.trim() !== "")
                      ), // Ensure that we don't use empty rows
                    };
                    console.log("Parsed CSV:", filteredResults);
                    if (isValidateContent(filteredResults, fileType))
                      onFileContent(
                        file.name,
                        getValidBulkType(filteredResults, fileType),
                        filteredResults.data
                      );
                    else
                      toast.error("Wrong formatted file 2", {
                        theme: "colored",
                      });
                  },
                  header: true,
                });
                break;
              case "txt":
                // console.log("Read TXT:", fileContent);
                toast.error(`Unsupported file type:${fileType}`, {
                  theme: "colored",
                });
                break;
              // throw new Error(`Unsupported file type:${fileType}`);
              // onFileContent(
              //   file.name,
              //   getValidEnrichType(result, fileType),
              //   fileContent
              // );
              case "xls":
              case "xlsx":
                let workbook;
                try {
                  workbook = XLSX.read(fileContent, { type: "binary" });
                } catch (err) {
                  toast.error("Wrong formatted file 3", { theme: "colored" });
                  break;
                }
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                console.log("Read XLS/XLSX:", data);
                if (isValidateContent(data, fileType))
                  onFileContent(
                    file.name,
                    getValidBulkType(data, fileType),
                    data
                  );
                else
                  toast.error("Wrong formatted file 1", { theme: "colored" });
                break;
              default:
                throw new Error(`Unsupported file type:${fileType}`);
            }
          };

          if (fileType === "csv" || fileType === "txt") {
            reader.readAsText(file);
          } else if (fileType === "xls" || fileType === "xlsx") {
            reader.readAsBinaryString(file);
          } else {
            throw new Error(`Unsupported file type:${fileType}`);
          }
        } else {
          throw new Error("Something went wrong!");
        }
      } catch (error) {
        toast.error(error?.message, { theme: "colored" });
      }
    },
    [onFileContent]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv, .txt, .xls, .xlsx",
    multiple: false, // This will restrict to only one file
  });

  if (attachedFileName) {
    return (
      <>
        <div className="flex flex-col items-center justify-center w-full p-8 mt-2 cursor-pointer rounded-xl border-2 border-dashed border-[#E8E7E7] text-center text-lg font-light select-none">
          <div className="text-xl text-black text-opacity-40 font-Outfit">
            Attached
          </div>
          <div className="text-2xl text-black font-Outfit">
            {attachedFileName}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          {...getRootProps()}
          className="flex flex-col items-center justify-center w-full py-6 px-4 cursor-pointer rounded-xl border-2 border-dashed border-[#E8E7E7] select-none"
        >
          <input {...getInputProps()} />
          <UploadSVG />
          <div className="font-Outfit text-gray-950 text-base leading-md">
            Click to upload or drag and drop
          </div>
          <div className="font-Outfit text-gray-950 text-opacity-40 text-base font-light">
            CSV, TXT, XLS, or XLSX
          </div>
        </div>
      </>
    );
  }
};

export default FileUploaderWidget;
