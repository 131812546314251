import BackdropLoader from '@/components/common/BackdropLoader';
import DateRange from '@/components/common/DateRange';
import HtmlEditor from '@/components/common/HtmlEditor';
import SelectDropdown from '@/components/common/SelectDropdown';
import { WarmupModal } from '@/components/email_warmup/summary/WarmupModal';
import { CampainSvg, Compose, Delete, Engaged, FilterSvg, ForwardSvg, ImportantIcon, NoPreview, ReloadEmails, ReplySvg, Sent, UnReadIcon } from '@/components/icons/InboxIcon';
import useAiEngagement from '@/hooks/useAIEngagement';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const initialDefaultEmail = {
  emailBody: "",
  emailSubject: "",
  toEmail: "",
  from: "",
  ccEmail: "",
  bccEmail: ""
}

const initialState = {
  activeState: "engaged",
  apiEndPoint: "inbox",
  isFilter: false,
  loading: false,
  mailData: {},
  mailList: [],
  fetchEmails: false,
  appendProperty: false,
  currentPageNo: 1,
  isMoreDataToLoad: true,
  isComposeEmail: false,
  emailBody: "",
  isCCOn: false,
  isBCCOn: false,
  currentSendType: "",
  draftMailData: {
    ...initialDefaultEmail
  }
}

const defaultColor = ["#eaf180", "#fdce94", "#fdcf8c", "#dbd186", "#e6d788", "#eaef76", "#b4eeb4", "#cfead9", "#ffb2d7", "#cbb2ff"];

const stringCheck = (str) => {
  let i = 0;
  while (i < str.length) {
    if (/[a-zA-Z]/.test(str[i])) {
      return str[i];
    }
    i++;
  }
  return false;
}


const reterivStringName = (senderName) => {
  const splitedName = (senderName || "").split(" ");
  let firstChar = "", secondChar = "";
  if (splitedName[0]) {
    const reult = stringCheck(splitedName[0]);
    if (reult) {
      firstChar = reult
    }
  }
  if (splitedName[1]) {
    const reult = stringCheck(splitedName[1]);
    if (reult) {
      secondChar = reult
    }
  }

  return firstChar + secondChar
}

const toStylelessDocument = htmlString => {
  const startIndex = htmlString.indexOf("<html");
  if (startIndex < 0) {
    const newlineText = htmlString.replace(/\n/g, '<br />');
    return newlineText;
  }

  const doc = new DOMParser().parseFromString(htmlString, "text/html");

  var treeWalker = document.createTreeWalker(
    doc,
    NodeFilter.SHOW_ELEMENT,
    {
      acceptNode: node => {
        if (node.tagName === "STYLE") {
          return NodeFilter.FILTER_ACCEPT;
        }
      },
    },
    false,
  );

  let currentNode = treeWalker.currentNode;
  const styles = [];
  while (currentNode) {
    if (currentNode.tagName === "STYLE") {
      styles.push(currentNode);
    }
    currentNode = treeWalker.nextNode();
  }
  for (let style of styles) {
    style.parentElement.removeChild(style);
  }
  return doc.body.innerHTML;
};

const retiverEmailAddressFromName = (name) => {
  const startingIndex = (name || "").indexOf("<") || "";
  const endingIndex = (name || "").indexOf(">") || "";

  return (name || "").substring(startingIndex + 1, endingIndex);

}

function formatDate(dateString, type = "both") {
  const date = new Date(dateString);
  const day = (date.getUTCDate()).toString().padStart(2, '0');
  const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  const monthNumber = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear(); // Get the year in UTC
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  const period = hours >= 12 ? 'pm' : 'am';
  const hour12 = hours % 12 || 12;

  return type === "dateonly" ? `${year}-${monthNumber}-${day}` : `${day} ${month}, ${hour12}:${minutes} ${period}`;
}


const initialSearchState = {
  dateRang: [null, null],
  campainId: "",
  emailAddress: "",
  emailList: [],
  campainList: []
}
const Inbox = () => {

  const { getEmailListAccordingToType, sendEmail, getEmailsData, getEmailCampain, reFetchAllEmail } = useAiEngagement();

  const [state, setState] = useState(initialState);

  const [openFilter, setOpenFilter] = useState(false);

  const [fiterOption, setFilterOption] = useState(initialSearchState);

  const { dateRang, campainId, emailAddress, emailList, campainList } = fiterOption || {}

  const { activeState, loading, mailList, mailData, apiEndPoint, isMoreDataToLoad, fetchEmails, currentPageNo, isComposeEmail, draftMailData, isBCCOn, isCCOn, currentSendType, isFilter } = state || {};

  const { emailBody, toEmail, ccEmail, bccEmail, emailSubject, from } = draftMailData || {};

  const setActiveStateData = (type, endPoint) => {
    setState((prevState) => ({
      ...prevState,
      activeState: type,
      apiEndPoint: endPoint,
      mailList: [],
      mailData: {},
      fetchEmails: true,
      appendProperty: false,
      currentPageNo: 1,
      isMoreDataToLoad: true
    }));
  }

  const convertInStandardStructureData = (emailArray = []) => {
    let updatedEmailList = [];
    (emailArray || [])?.forEach((i) => {
      let updateObj = {};
      const cName = (campainList || [])?.find((e) => e._id === i?.campaignId)?.campaignName || "";
      if (i?.is_gmail === false) {
        updateObj = {
          senderEmail: i?.sender?.emailAddress?.address || "",
          senderName: i?.sender?.emailAddress?.name || "",
          subject: i?.subject,
          sentDateAndTime: formatDate(i?.sentDateTime ?? ""),
          bodyText: i?.body.content,
          bgColor: defaultColor[Math.floor(Math.random() * 10)],
          reciverEmail: i?.userEmailAddress,
          campaignName: cName ? cName : "",
          campaignId: i?.campaignId ? i?.campaignId : "",
          shortName: reterivStringName(i?.sender?.emailAddress?.address || ""),
          isGmail: false,
        }
      } else {
        const result = retiverEmailAddressFromName(i?.headers?.from);
        updateObj = {
          senderEmail: result || "",
          senderName: i?.headers?.from || "",
          subject: i?.headers?.subject,
          sentDateAndTime: formatDate(i?.headers?.date ?? ""),
          bodyText: i?.body,
          bgColor: defaultColor[Math.floor(Math.random() * 10)],
          reciverEmail: i?.receiverEmailAddress,
          campaignName: cName ? cName : "",
          campaignId: i?.campaignId ? i?.campaignId : "",
          shortName: reterivStringName(i?.headers?.from || ""),
          isGmail: true
        }
      }
      updatedEmailList.push(updateObj);
    })
    return updatedEmailList
  }

  const fetchEmailListAccordingToType = async () => {
    try {
      setState((prevState) => ({ ...prevState, fetchEmails: true }));
      let limit = Math.round(window.innerHeight / 64);
      const response = await getEmailListAccordingToType({
        queryString: `?page=${currentPageNo}&limit=${limit}&campaignId=${campainId}&mailBoxAccount=${emailAddress?.split("_")[0] ? emailAddress?.split("_")[0] : ""}&fromDate=${dateRang?.[0] ? formatDate(dateRang?.[0], "dateonly") : ""}&toDate=${dateRang?.[1] ? formatDate(dateRang?.[1], "dateonly") : ""}&is_gmail=${emailAddress?.split("_")[1] ? emailAddress?.split("_")[1] : ""}&filter=${isFilter}`, endPoint: apiEndPoint
      });
      if ((response?.data?.emails || [])?.length > 0) {
        const updatedList = convertInStandardStructureData(response?.data?.emails);
        setState((prevState) => {
          let updatedEmailList = cloneDeep(updatedList);

          if (prevState?.appendProperty) {
            updatedEmailList = (prevState?.mailList || []).concat(updatedEmailList || []);
          }

          return {
            ...prevState,
            mailList: updatedEmailList || [],
            isMoreDataToLoad: (prevState.currentPageNo < response?.data?.totalPages),
          }
        })
      }
    } catch (error) {
      console.log("Error", error)
    } finally {
      setState((prevState) => ({ ...prevState, fetchEmails: false }));
    }
  }
  const reFetchEmails = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const response = await reFetchAllEmail();
      if (response?.status === true) {
        toast.success(response?.message, {
          theme: "colored",
        })
      } else {
        toast.error(response?.message, {
          theme: "colored",
        })
      }
    } catch (error) {
      console.log("Error", error)
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }
  const mailSend = async () => {
    if (!toEmail) {
      toast.error("Sender Email required", {
        theme: "colored",
      })
      return;
    }
    if (!emailSubject) {
      toast.error("Email Subject is required", {
        theme: "colored",
      })
      return;
    }
    const fromEmail = currentSendType === "forward" ? mailData?.reciverEmail : from;
    if (!fromEmail) {
      toast.error("From Email required", {
        theme: "colored",
      })
      return;
    }

    try {
      const mail = {
        "from": fromEmail,
        "to": [toEmail],
        "subject": emailSubject,
        "content": emailBody,
        "is_gmail": mailData?.isGmail
      };
      setState((prevState) => ({ ...prevState, loading: true }));
      const response = await sendEmail(mail);

      if (response?.status === true) {
        toast.success(response?.message, {
          theme: "colored",
        })
        setState((prevState) => ({ ...prevState, isComposeEmail: false, isBCCOn: false, isCCOn: false, draftMailData: { ...initialDefaultEmail } }))
      } else {
        toast.error(response?.message, {
          theme: "colored",
        })
      }
    } catch (error) {
      console.log("Error", error)
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }

  const fetchEmailList = async () => {
    try {
      const listData = await getEmailsData();
      let updatedListData = listData?.data;
      if (listData?.data || false) {
        setFilterOption((prevState) => {

          return {
            ...prevState,
            emailList: updatedListData || []
          }
        })
      }
      if (updatedListData) {
        setState((prevState) => ({ ...prevState, draftMailData: { ...prevState.draftMailData, from: updatedListData[0].emailAddress } }))
      }
    } catch (error) {
      console.log("-error", error)
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }
  const fetchEmailCampain = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const listData = await getEmailCampain();
      if (listData?.data?.response || false) {
        setFilterOption((prevState) => {
          let updatedListData = listData?.data?.response;
          return {
            ...prevState,
            campainList: updatedListData || [],

          }
        })
        setState((prevState) => ({ ...prevState, fetchEmails: true }))
      }
    } catch (error) {
      console.log("-error", error)
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }

  const scrollEvent = (e) => {
    if (e?.currentTarget?.contains(e?.target)) {
      const tolerance = 1; // Allowable tolerance in pixels
      const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= tolerance;
      if (bottom && isMoreDataToLoad && fetchEmails === false) {
        setState((prevState) => {
          return {
            ...prevState,
            fetchEmails: true,
            appendProperty: true,
            currentPageNo: prevState.currentPageNo + 1
          };
        });
      }
    }
  };

  useEffect(() => {
    if (fetchEmails) {
      fetchEmailListAccordingToType();
    }
  }, [fetchEmails])

  useEffect(() => {
    fetchEmailList();
    fetchEmailCampain();
  })
  return (
    <>
      {loading && <BackdropLoader active={true}></BackdropLoader>}
      <div className="grid grid-cols-6 gap-4 h-100vh">
        <div className="mb-4 h-full border-r border-stone-250 bg-white col-span-1 pt-4 sm:mb-1 px-2">
          <p className={`mail_service compose `} onClick={() => setState((prevState) => ({ ...prevState, isComposeEmail: true, currentSendType: "new", draftMailData: { ...initialDefaultEmail } }))}><Compose />Compose Email</p>
          <hr />
          <p className={`mail_service${activeState === "engaged" ? " active" : ""}`} onClick={() => setActiveStateData("engaged", "inbox")}><Engaged />Inbox</p>
          <p className={`mail_service${activeState === "unread" ? " active" : ""}`} onClick={() => setActiveStateData("unread", "unread")}><UnReadIcon />Unread Replies</p>
          <p className={`mail_service${activeState === "sent" ? " active" : ""}`} onClick={() => setActiveStateData("sent", "sent")}><Sent />Sent</p>
          <hr />
          <p className={`mail_service${activeState === "important" ? " active" : ""}`} onClick={() => setActiveStateData("important", "important")}><ImportantIcon />Important</p>
          {/* <p className={`mail_service${activeState === "snoozed" ? " active" : ""}`} onClick={() => setActiveStateData("snoozed", "snoozed")}><Snoozed />Snoozed</p> */}
          {/* <p className={`mail_service${activeState === "reminder" ? " active" : ""}`} onClick={() => setActiveStateData("reminder", "inbox")}><Reminder />Reminders</p> */}
          {/* <p className={`mail_service${activeState === "schedule" ? " active" : ""}`} onClick={() => setActiveStateData("schedule", "scheduled")}><Scheduled />Scheduled</p> */}
          {/* <p className={`mail_service${activeState === "archived" ? " active" : ""}`} onClick={() => setActiveStateData("archived", "archived")}><Archived />Archived</p> */}
        </div>

        <div className={`mb-4 h-full border-r border-stone-250  pt-2 sm:mb-1 col-span-2`} >
          <div className='px-2 flex justify-between '>
            <span className='font-bold'>Unibox</span>
            {/* <span onClick={() => setOpenFilter(true)}><FilterSvg /></span> */}
            <div className='flex gap-2 items-center mb-1'>
              <span className='bg-[#edf1ff] p-[3px] flex items-start rounded	 cursor-pointer' onClick={() => reFetchEmails()}><ReloadEmails /></span>
              <span className='p-[3px] flex items-start cursor-pointer' onClick={() => setOpenFilter(true)}><FilterSvg /></span>
            </div>
          </div>
          <hr />
          <div className='mailList_container' onScrollCapture={scrollEvent}>
            {((mailList || []).map((i, ind) => {
              return <div className={`mail_cover_container cursor-pointer ${mailData?.bodyText === i?.bodyText ? "border-r-4 border-blue-500 bg-gradient-to-r from-white to-blue-100" : ""}`} key={ind} onClick={() => setState((prevState) => ({ ...prevState, mailData: { ...i, bodyText: i?.bodyText }, isComposeEmail: false }))}>
                {i?.campaignName ? <div className='flex justify-end'>
                  <div className='campain_container'>
                    <CampainSvg />
                    <span title={i?.campaignName}>{i?.campaignName || ""}</span>
                  </div>
                </div> : ""}
                <div className='mail_cover'>
                  <span style={{ backgroundColor: i?.bgColor }}>{i?.shortName ?? ""}</span>
                  {/* <img src={custom5} /> */}
                  <div>
                    <p className='sender'>{i?.senderName || ""}</p>
                    <p className={`max-w-[255px] title`}>
                      {i?.subject || ""}
                    </p>
                  </div>
                </div>
                <div className='flex justify-end mr-3'><span className='time'>{i?.sentDateAndTime || ""}</span></div>
              </div>
            }))}
            {fetchEmails && <div className='w-100 flex justify-center'>Fetching Email Data ...</div>}
          </div>

        </div>

        {isComposeEmail && <div className="mb-4 h-full border-r border-stone-250 pt-4 sm:mb-1 p-2 col-span-3">
          <div className='grid grid-cols-12 border-1 border-b-0 border-stone-250 '>
            <div className='col-span-1 bg-[#E8E7E7] flex items-center justify-center p-1'>
              <span className='font-bold '>From :</span>
            </div>
            <div className='col-span-11 p-0'>
              <SelectDropdown
              options={emailList?.length ? (emailList || []).map(i => {
                return {
                  label: i?.emailAddress,
                  value: i?.emailAddress,
                };
              }) : []}
              selectedOption={from}
              disabled={currentSendType === "forward"}
              onChange={(fromEmail) => setState((prevState) => ({ ...prevState, draftMailData: { ...prevState.draftMailData, from: fromEmail } }))}
            />
            </div>

          </div>

          <div className='grid grid-cols-12 border-1 border-b-0 border-stone-250 '>
            <div className='col-span-1 bg-[#E8E7E7] flex items-center justify-center p-1'>
              <span className='font-bold '>To :</span>
            </div>
            <div className='col-span-9 p-0'>
              <input className='focus:outline-none border-0 mail_title_text pl-2 h-full' value={toEmail} onChange={(e) => setState((prevState) => ({ ...prevState, draftMailData: { ...prevState.draftMailData, toEmail: e.target.value } }))} />
            </div>
            <div className='col-span-2 flex justify-end gap-x-3 items-center p-0 pr-2'>
              <span className={`cursor-pointer ${isCCOn ? "font-bold" : ""}`} onClick={() => setState((prevState) => ({ ...prevState, isCCOn: !isCCOn }))}>CC</span>
              <span className={`cursor-pointer ${isBCCOn ? "font-bold" : ""}`} onClick={() => setState((prevState) => ({ ...prevState, isBCCOn: !isBCCOn }))}>BCC</span>
            </div>
          </div>

          {isCCOn && <div className='grid grid-cols-12 border-1 border-b-0 border-stone-250 '>
            <div className='col-span-1 bg-[#E8E7E7] flex items-center justify-center p-1'>
              <span className='font-bold '>CC :</span>
            </div>
            <div className='col-span-9 p-0'>
              <input className='focus:outline-none border-0 mail_title_text pl-2 h-full' value={ccEmail} onChange={(e) => setState((prevState) => ({ ...prevState, draftMailData: { ...prevState.draftMailData, ccEmail: e.target.value } }))} />
            </div>

          </div>}

          {isBCCOn && <div className='grid grid-cols-12 border-1 border-b-0 border-stone-250 '>
            <div className='col-span-1 bg-[#E8E7E7] flex items-center justify-center p-1'>
              <span className='font-bold '>BCC :</span>
            </div>
            <div className='col-span-9 p-0'>
              <input className='focus:outline-none border-0 mail_title_text pl-2 h-full' value={bccEmail} onChange={(e) => setState((prevState) => ({ ...prevState, draftMailData: { ...prevState.draftMailData, bccEmail: e.target.value } }))} />
            </div>
          </div>}


          <div className='grid grid-cols-12 border-1 border-b-0 border-stone-250'>
            <div className='col-span-2 bg-[#E8E7E7] flex items-center justify-center p-1'>
              <span className='font-bold '>Subject :</span>
            </div>
            <div className='col-span-10'>
              <input className='focus:outline-none border-0 mail_title_text pl-2 h-full p-0' value={emailSubject} onChange={(e) => setState((prevState) => ({ ...prevState, draftMailData: { ...prevState.draftMailData, emailSubject: e.target.value } }))} />
            </div>
          </div>
          <div className='bg-[#F6F6F6]'>
            <div className='flex items-center mb-5'>
              <HtmlEditor value={emailBody} onChange={(value) => setState((prevState) => ({ ...prevState, draftMailData: { ...prevState.draftMailData, emailBody: value } }))} />
            </div>

          </div>
          <div className='flex justify-start w-fit mt-2 gap-1'>
            <button
              className="rounded-[8px] py-1 flex items-center gap-3 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[#fff]"
              onClick={() => mailSend("new")}
            >
              <ForwardSvg />
              send
            </button>

            <button
              className="rounded-[8px] flex items-center gap-3 py-1 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white border-[#4873FA] focus:border-white hover:text-[#4873FA]"
              onClick={() => setState((prevState) => ({ ...prevState, isComposeEmail: false, currentSendType: "new", draftMailData: { ...initialDefaultEmail } }))}
            >
              cancel
            </button>
          </div>
        </div>}
        {Object.keys(mailData || {})?.length && !isComposeEmail ?
          <div className="mb-4 h-full border-r border-stone-250 pt-4 sm:mb-1 p-2 col-span-3">
            <div className='bg-black text-white px-3 py-2 flex justify-between'>
              <p className='size-[20px] w-full truncate' title={mailData?.subject || ""}>{mailData?.subject || ""}</p>
              <div className='flex gap-x-2'>
                {/* <Stared /> */}
                <span className='cursor-pointer' onClick={() => setState((prevState) => ({ ...prevState, mailData: {} }))}><Delete /></span>
              </div>
            </div>
            <div className='bg-[#F6F6F6] h-full p-4 mail_content_conatiner'>
              <div className='flex items-center mb-5 gap-2'>
                <div className='mail_cover'>
                  <span style={{ backgroundColor: mailData?.bgColor }}>{mailData?.shortName ?? ""}</span>
                </div>
                {/* <img src={custom5} className='w-[44px] h-[44px] me-5' /> */}
                <p className='text-[16px] font-bold me-2'>{mailData?.senderName || ""}</p>
                <span className='text-[12px]'>{mailData?.sentDateAndTime || ""}</span>
              </div>
              <div className='text-[14px] break-words'
                dangerouslySetInnerHTML={{
                  __html: toStylelessDocument(mailData?.bodyText),
                }}>
              </div>
            </div>
            <div className='flex justify-start w-fit mt-2 gap-1'>
              <button
                className="rounded-[8px] py-1 flex items-center gap-3 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[rgb(255,255,255)]"
                onClick={() => {console.log("Forward mailData", mailData);setState((prevState) => ({ ...prevState, isComposeEmail: true, currentSendType: "forward", draftMailData: { ...prevState.draftMailData, from: mailData?.reciverEmail, emailBody: mailData?.bodyText, emailSubject: mailData?.subject } }))}}
              >
                <ForwardSvg />
                Forward
              </button>

              <button
                className="rounded-[8px] flex items-center gap-3 py-1 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white border-[#4873FA] focus:border-white hover:text-[#4873FA]"
                onClick={() => setState((prevState) => ({ ...prevState, isComposeEmail: true, currentSendType: "forward", draftMailData: { ...initialDefaultEmail, from: mailData?.reciverEmail, toEmail: mailData?.senderEmail, emailSubject: mailData?.subject } }))}
              >
                Reply
                <ReplySvg />
              </button>
            </div>
          </div> : isComposeEmail === false ? <div className="mb-4 h-full border-r border-stone-250 pt-4 sm:mb-1 p-2 col-span-3">
            <div className='no_mail_found flex justify-center items-center'>
              <NoPreview />
            </div>
          </div> : ""}
      </div>

      <WarmupModal
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
        titleClassName="w-full"
        title={<><div className='w-full flex justify-between'>
          <span>Filter options</span>
          <button
            className="rounded-[8px] text-base flex items-center gap-3 py-1 px-4 border-0 focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white focus:border-white hover:text-[#4873FA]"
            onClick={() => setFilterOption((prevState) => ({ ...prevState, campainId: "", emailAddress: "", dateRang: [null, null] }))}
          >
            Clear
          </button>
        </div></>}
      >
        <div className='w-full'>

          <div className="font-Outfit text-base	 p-1 text-left text-black">
            Email Campaign
            <SelectDropdown
              options={campainList?.length ? (campainList || []).map(i => {
                return {
                  label: i?.campaignName,
                  value: i?._id,
                };
              }) : []}
              selectedOption={campainId}
              onChange={(value) => setFilterOption((prevState) => ({ ...prevState, campainId: value }))}
            />
          </div>
          <div className="font-Outfit text-base	 p-1 text-left text-black">
            Email Account
            <SelectDropdown
              options={emailList?.length ? (emailList || []).map(i => {
                return {
                  label: i?.emailAddress,
                  value: `${i?.emailAddress}_${i?.account === "gmail" ? true : false}`,
                };
              }) : []}
              selectedOption={emailAddress}
              onChange={(value) => setFilterOption((prevState) => ({ ...prevState, emailAddress: value }))}
            />
          </div>
          <div className="font-Outfit text-base flex flex-col	 p-1 text-left text-black">
            Reply Date Range
            <DateRange value={dateRang} onChange={(value) => setFilterOption((prevState) => ({ ...prevState, dateRang: value }))} />
          </div>
          <div className='flex justify-start w-fit mt-2 gap-1'>
            <button
              className="rounded-[8px] py-1 text-base flex items-center gap-3 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[rgb(255,255,255)]"
              onClick={() => {
                setState((prevState) => ({ ...initialState, activeState: prevState?.activeState, apiEndPoint: prevState?.apiEndPoint, isFilter: true, fetchEmails: true }))
                // fetchEmailListAccordingToType()
                setOpenFilter(false);
              }}
            >
              save
            </button>

            <button
              className="rounded-[8px] flex text-base items-center gap-3 py-1 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto focus:text-white  text-[#4873FA] bg-white border-[#4873FA] focus:border-white hover:text-[#4873FA]"
              onClick={() => setOpenFilter(false)}
            >
              cancel
            </button>
          </div>
        </div>
      </WarmupModal>
    </>

  )
}

export default Inbox