import LandingLayout from '@/components/LandingLayout'
import React from 'react'
import { Link } from 'react-router-dom'


export default function PrivacyPolicy() {
  return (
    <LandingLayout>
      <div className='max-w-[1050px] mx-auto my-5 p-4 md:p-10 flex flex-col gap-y-4'>
        <h1 className='text-xl font-semibold'>Privacy Policy</h1>
        <h1 className='text-xl font-semibold'>Last updated: July 15, 2024</h1>
        <h1 className='text-xl font-semibold'>Welcome!</h1>
        <p>This Privacy Policy ("Policy") outlines how we collect, process, share, and protect your Personal Data when you use our website <Link to='https://kompassai.com' className='text-blue-500'>Kompassai.com</Link> ("Website") and our related services ("Services"). This Policy applies to both users of our Services and professionals whose contact details appear in our Services. Please read this Policy carefully to understand how we handle your Personal Data and your rights regarding it.</p>

        <h1 className='text-xl font-semibold'>Personal Data We Collect</h1>
        <h1 className='text-xl font-semibold'>1. Personal Data You Provide:</h1>

        <ul className='list-disc ml-5 flex flex-col gap-y-1'>
          <li> <strong>Account Information: </strong> When you create an account, we collect your name, email address, password, mobile number, and other necessary details to maintain your account.</li>
          <li> <strong>Contact Information: </strong> Any Personal Data you provide through emails, contact forms, or other communications.</li>
          <li> <strong>Support Information: </strong> Details provided when requesting technical support, including your name, contact details, and support request specifics.</li>
          <li> <strong>Financial Information: </strong> If you purchase a subscription, we collect credit card information through secure payment providers. We do not store credit card details.</li>
        </ul>

        <h1 className='text-xl font-semibold'>2. Information Collected Automatically:</h1>
        <ul className='list-disc ml-5 flex flex-col gap-y-1'>
          <li> <strong>Single Sign-On: </strong>Data from Internet service providers when using single sign-on services.</li>
          <li> <strong>Cookies and Similar Technologies: </strong>We use cookies to collect data about your interactions with our Services. For details, see our Cookie Policy.</li>
          <li> <strong>Device and Usage Information: </strong>Information about your device and how you use our Services, including IP address, browser type, and usage statistics.</li>
        </ul>
        <h1 className='text-xl font-semibold'>3. Information from Other Sources:</h1>
        <ul className='list-disc ml-5 flex flex-col gap-y-1'>
          <li> <strong>Publicly Available Information:</strong> When you create an account, we collect your name, email address, password, mobile number, and other necessary details to maintain your account.</li>
          <li> <strong>Google API:  </strong> Any Personal Data you provide through emails, contact forms, or other communications.
          </li>
          <li> <strong>Data Providers: </strong> Details provided when requesting technical support, including your name, contact details, and support request specifics.</li>
          <li> <strong>Community Program: </strong> If you purchase a subscription, we collect credit card information through secure payment providers. We do not store credit card details.</li>
        </ul>
        <h1 className='text-xl font-semibold'>How We Use Your Personal Data</h1>
        <ul className='list-disc ml-5 flex flex-col gap-y-1'>
          <li> <strong>To Operate and Administer Services: </strong> Manage your account, provide and support our Services, and communicate with you.</li>
          <li> <strong>To Improve and Personalize Services: </strong> Analyze usage, develop new features, and enhance security. </li>
          <li> <strong>For Marketing: </strong> Send promotional materials and display targeted ads. You can opt out of marketing communications.</li>
          <li> <strong>For Legal Compliance: </strong>Fulfill legal obligations and protect our rights and interests.</li>
        </ul>

        <h1 className='text-xl font-semibold'>Who We Share Your Data With</h1>
        <ul className='list-disc ml-5 flex flex-col gap-y-1'>
          <li><strong>Customers and Users: </strong>Personal data may be shared with users of our Services.</li>
          <li><strong>Group Entities: </strong>Shared with our affiliates and subsidiaries.</li>
          <li><strong>Service Providers: </strong>Includes payment processors and cloud storage providers.</li>
          <li><strong>Legal Requirements: </strong>Disclosed to comply with legal obligations or in legal proceedings.</li>
          <li><strong>Business Transactions: </strong>Shared during mergers, acquisitions, or similar transactions.</li>
          <li><strong>Aggregated Information: </strong>Shared in anonymized form for statistical purposes.</li>
        </ul>

        <h1 className='text-xl font-semibold'>Your Choices</h1>
        <ul className='list-disc ml-5 flex flex-col gap-y-1'>
          <li><strong>Marketing Communications: </strong>Opt-out by contacting us or following unsubscribe instructions.</li>
          <li><strong>Access, Correction, and Deletion: </strong>Request access to, correction of, or deletion of your Personal Data by contacting us. Note that deleting Personal Data may require deleting your account.</li>
          <li><strong>Opt-Out of Selling Personal Information: </strong>Use the "Claim Your Profile" form or our "Do Not Sell or Share My Info" tool.</li>
          <li><strong>Additional Rights: </strong>Depending on your location, you may have further rights under applicable laws. For more details, contact us.</li>
        </ul>

        <h1 className='text-xl font-semibold'>International Data Transfers</h1>
        <p>We may transfer your Personal Data internationally, including to the U.S. and other jurisdictions. We implement safeguards to ensure adequate protection for your data in compliance with applicable laws.</p>
        <h1 className='text-xl font-semibold'>Children's Privacy</h1>
        <p>Our Services are not intended for children under 18. If we learn that we have collected data from a child, we will take steps to delete it.</p>
        <h1 className='text-xl font-semibold'>Data Security</h1>
        <p>We use physical, managerial, and technical safeguards to protect your Personal Data. However, no online transmission is completely secure. You are responsible for maintaining the confidentiality of your password.</p>
        <h1 className='text-xl font-semibold'>Data Retention</h1>
        <p>We retain Personal Data only as long as necessary to fulfill the purposes outlined in this Policy, comply with legal obligations, and manage backups and fraud prevention.</p>
        <h1 className='text-xl font-semibold'>Third-Party Services</h1>
        <p>Our Services may include links to third-party sites. We are not responsible for their privacy practices. Review their policies before providing Personal Data.</p>
        <h1 className='text-xl font-semibold'>Changes to This Policy</h1>
        <p>We may update this Policy and will post changes on our Website. Significant changes will be communicated via email or a notice on our Website.</p>
        <h1 className='text-xl font-semibold'>Contact Us</h1>
        <p>For any questions or requests regarding this Policy:</p>
        
        <ul className='list-disc ml-5 flex flex-col gap-y-1'>
          <li><strong>California Residents: </strong>ca-privacy@Kompassai.com</li>
          <li><strong>EU/UK Residents: </strong>article27@verasafe.com</li>
          <li><strong>All Other Privacy Requests: </strong>privacy@Kompassai.com</li>
        </ul>

        <hr className='border-t border-dgray-500' />

        <p>Feel free to adjust any specifics or add additional details that may better reflect your practices.</p>

      </div>
    </LandingLayout>
  )
}
