import { Button } from "@material-tailwind/react";
import mainArrowSvg from "@/assets/image/base/mainArrow.svg";
import ShiningIcon from "@/assets/image/icons/shiny.svg";
import useKompassRouter from "@/hooks/useKompassRouter";
import advancedsearchCover from "@/assets/image/products_covers/search-kompass.png";
import csvexportCover from "@/assets/image/products_covers/bulk-enrich.png";
import integrationCover from "@/assets/image/products_covers/integrations.png";
import emailverificationCover from "@/assets/image/products_covers/email-validation.png";
import prospectingCover from "@/assets/image/products_covers/chrome-extension.png";

export default function TopBar({
  data,
  background,
  productName,
  isCompetitors,
}) {
  const bgButtonColor = data.invertColor ? "bg-white" : "bg-stone-950";
  const textButtonColor = data.invertColor ? "text-stone-950" : "text-white";
  const { kompassNavigate } = useKompassRouter();

  const getCoverImage = (productName) => {
    switch (productName) {
      case "advancedsearch":
        return advancedsearchCover;
      case "csvexport":
        return csvexportCover;
      case "integration":
        return integrationCover;
      case "emailverification":
        return emailverificationCover;
      case "prospecting":
        return prospectingCover;
      default:
        return advancedsearchCover;
    }
  };

  return (
    <div
      className={`w-full topbar-hero ${background} ${productName} overflow-x-hidden flex items-center`}
    >
      <div
        className={`font-Outfit text-stone-950 w-full flex flex-col items-center ${!isCompetitors && "justify-center lg:flex-row"} py-12 px-12 lg:px-16 ${!isCompetitors && "lg:pr-0"}`}
      >
        <div
          className={`${isCompetitors ? "max-w-[1152px]" : "max-w-[843px]"}`}
        >
          {!isCompetitors && (
            <Button
              className={`block rounded-full px-4 py-2.5 text-gray-950 text-[16px] 2xl:text-[20px] normal-case leading-[1.4] font-[400] font-Outfit bg-white/60 mb-4 lg:mb-6`}
            >
              {data.tag}
            </Button>
          )}
          <div
            className={
              isCompetitors
                ? "text-center max-w-[1152px]"
                : "w-fit align-top whitespace-nowrap"
            }
          >
            <h1
              className={`inline whitespace-normal font-bold text-[36px] lg:text-[42px] xl:text-[50px] 2xl:text-[56px] leading-[1.2] ${data.invertColor ? "text-white" : ""}
                `}
            >
              {data.title}
            </h1>
            {!isCompetitors && (
              <div className="w-[30px] inline-block mb-auto align-top">
                <img
                  src={ShiningIcon}
                  alt="shining icon"
                  width={30}
                  height={30}
                />
              </div>
            )}
          </div>
          <p
            className={`${isCompetitors && "text-center max-w-[1129px]"} block text-stone-950 text-[20px] lg:text-[22px] xl:text-[24px] 2xl:text-[26px] leading-[1.2] font-normal font-Outfit mt-6 lg:mt-10
                ${data.invertColor ? "text-white" : ""}`}
          >
            {data.subTitle}
          </p>
          {!isCompetitors && (
            <Button
              id="mainButton"
              onClick={() => kompassNavigate("/demo")}
              className={`relative flex items-end justify-center py-5 px-8 pr-[3.5rem] rounded-lg ${bgButtonColor} mt-6 lg:mt-12 xl:mt-16 2xl:mt-20`}
            >
              <p
                className={`font-Outfit normal-case text-base lg:text-md 2xl:text-2xl font-semibold ${textButtonColor}`}
              >
                Book a Demo
              </p>
              <div
                id="firstIcon"
                className="flex items-center justify-center w-6 h-6"
              >
                <img
                  src={mainArrowSvg}
                  alt="RightArrowIcon"
                  width={24}
                  height={24}
                />
              </div>
              <div
                id="secondIcon"
                className="flex items-center justify-center w-6 h-6"
              >
                <img
                  src={mainArrowSvg}
                  alt="RightArrowIcon"
                  width={24}
                  height={24}
                />
              </div>
            </Button>
          )}
          {isCompetitors && (
            <div className="w-full flex flex-wrap items-center justify-center gap-6 mt-12">
              <Button
                id="mainButton"
                className="relative flex items-end justify-center py-5 px-8 pr-[3.5rem] rounded-lg bg-stone-950"
              >
                <p
                  className={`font-Outfit normal-case text-base lg:text-md 2xl:text-2xl font-semibold text-white`}
                >
                  Request for Free Migration
                </p>
                <div
                  id="firstIcon"
                  className="flex items-center justify-center w-6 h-6"
                >
                  <img
                    src={mainArrowSvg}
                    alt="RightArrowIcon"
                    width={24}
                    height={24}
                  />
                </div>
                <div
                  id="secondIcon"
                  className="flex items-center justify-center w-6 h-6"
                >
                  <img
                    src={mainArrowSvg}
                    alt="RightArrowIcon"
                    width={24}
                    height={24}
                  />
                </div>
              </Button>
              <Button
                onClick={() => {
                  kompassNavigate("/pricing");
                }}
                id="mainButton"
                className="relative flex items-end justify-center py-5 px-8 border border-stone-950 rounded-lg bg-transparent"
              >
                <p
                  className={`font-Outfit normal-case text-base lg:text-md 2xl:text-2xl font-semibold text-stone-950`}
                >
                  See Plans and Pricing
                </p>
              </Button>
            </div>
          )}
        </div>
        {!isCompetitors && (
          <div className="w-full lg:w-1/2 xl:w-2/3 rounded-l-[10px] max-lg:rounded-r-[10px] overflow-hidden shrink-0">
            <img src={getCoverImage(productName)} alt="ProductCoverImage" />
          </div>
        )}
      </div>
    </div>
  );
}
