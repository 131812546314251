import useKompassRouter from "@/hooks/useKompassRouter";
import ComparisonGraph from "./ComparisonGraph";

const KompassAi = () => {
  const { kompassNavigate } = useKompassRouter();

  return (
    <div className="inline-flex w-full items-center flex-col px-6 lg:px-12 gap-8 lg:gap-16 font-Outfit">
      <h2 className="main-subtitle text-stone-950 text-center max-w-[1121px]">
        See how KompassAI stacks up against other sales intelligence providers
      </h2>
      <button
        onClick={() => {
          kompassNavigate("/competitors");
        }}
        className="text-base lg:text-[22px] 2xl:text-[28px] font-semibold lg:font-bold leading-[1.2] tracking-[0.56px] text-white flex p-4 2xl:p-5 w-fit items-center justify-center rounded-lg lg:rounded-full bg-[#4873FA]"
      >
        Compare KompassAI
      </button>
      <div>
        <ComparisonGraph />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="kompassai-cta-item max-lg:pb-6 lg:pr-20">
          <p className="kompassai-cta-item-title">
            “30% more accurate emails and phone numbers”
          </p>
          <p className="kompassai-cta-item-description">
            on average compared to Zoominfo¹
          </p>
        </div>
        <div className="kompassai-cta-item border-[#ABCEC9] max-lg:py-6 max-lg:border-y lg:border-x lg:px-20">
          <p className="kompassai-cta-item-title">
            “It gives me more time to focus on selling”
          </p>
          <p className="kompassai-cta-item-description">
            Save 5 hours a month on data sanitation vs. typical enrichment
            tools¹
          </p>
        </div>
        <div className="kompassai-cta-item max-lg:pt-6 lg:pl-20">
          <p className="kompassai-cta-item-title">
            “Switch to KompassAI in less than{" "}
            <span className="whitespace-nowrap">5 minutes</span>”
          </p>
          <p className="kompassai-cta-item-description">
            Simple set-up and optimized UI, allows customers to start
            prospecting instantly¹
          </p>
        </div>
      </div>
      <p className="text-center text-sm leading-[1.4] font-bold lg:pt-4">
        ¹Based on survey of KompassAI customers
      </p>
    </div>
  );
};

export default KompassAi;
