import { Button } from "@material-tailwind/react";
import ProductImage1 from "@/assets/image/products_covers/search-kompass.png";
import ProductImage2 from "@/assets/image/products_covers/bulk-enrich.png";
import ProductImage3 from "@/assets/image/products_covers/chrome-extension.png";
import ProductImage4 from "@/assets/image/products_covers/email-validation.png";
import { Link } from "react-router-dom";
import { goToTop } from "@/utils/common";

const initialProducts = [
  {
    title: "Advanced search",
    description:
      "Easily narrow down your search with filters, which include skill sets, technologies used, those who recently switched roles, and more.",
    link: "/advancedsearch",
    image: ProductImage1,
  },
  {
    title: "Create lead list",
    description:
      "Quickly create custom lead generation list for your search needs. Need more, ask about our competitive bulk rates.",
    link: "/csvexport",
    image: ProductImage2,
  },
  {
    title: "Chrome Extension",
    description:
      "Our desktop Chrome extension is the easiest way to look up contact and company information, while you’re searching.",
    link: "/prospecting",
    image: ProductImage3,
  },
  {
    title: "Email Verification",
    description:
      "Bulk validate emails quickly and cheaply. Protect your email reputation score and avoid being flagged as spam.",
    link: "/emailverification",
    image: ProductImage4,
  },
];

export default function Product({ pageName }) {
  const getProducts = () => {
    switch (pageName) {
      case "advancedsearch":
        return initialProducts.filter((p) => p.link !== "/advancedsearch");
      case "csvexport":
        return initialProducts.filter((p) => p.link !== "/csvexport");
      case "prospecting":
        return initialProducts.filter((p) => p.link !== "/prospecting");
      default:
        return initialProducts.filter((p) => p.link !== "/emailverification");
    }
  };

  return (
    <div className="mt-12 flex flex-col">
      <p className="main-title">More products</p>
      <div className="mb-40 mt-16 flex flex-wrap gap-2 items-center justify-center">
        {getProducts().map((product) => {
          return (
            <div className="flex w-full flex-col justify-between rounded-[30px] bg-bluegrey-100 p-[40px] md:h-[550px] md:w-[300px] lg:h-[600px] lg:w-[350px] xl:w-[350px] 2xl:w-[450px]">
              <div>
                <p className="font-Outfit text-2xl font-[700] text-stone-950">
                  {product.title}
                </p>
                <p className="font-Outfit mt-[8px] text-lg font-[400] text-stone-950 md:text-[20px]">
                  {product.description}
                </p>
              </div>
              <img src={product.image} alt="ProductImage" />
              <div className="flex justify-center">
                <Link to={product.link}>
                  <Button
                    className="normal-case text-base 2xl:text-md flex flex-row rounded-full border-[1px] border-[#090C05] bg-transparent p-[14px_20px] 2xl:p-[16px_24px] font-[700] text-stone-950"
                    onClick={goToTop}
                  >
                    Learn More
                  </Button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
