import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Button } from "@material-tailwind/react";
import MainLayout from "@/components/MainLayout";
import IntegrateCard from "@/components/integrations/IntegrateCard";
import IntegrateCardSoon from "@/components/integrations/IntegrateCardSoon";
import IntegrateIcon from "@/assets/image/integrations/integrate.svg";
import HubspotIcon from "@/assets/image/integrations/hubspot.svg";
import SalesforceIcon from "@/assets/image/integrations/salesforce.svg";
import ZapierIcon from "@/assets/image/integrations/zapier.svg";
import OutreachIcon from "@/assets/image/integrations/outreach.svg";
import PipedriveIcon from "@/assets/image/integrations/pipedrive.svg";
import ZohoIcon from "@/assets/image/integrations/zoho.svg";
import CopyKeyIcon from "@/assets/image/integrations/copykey.svg";
import { ReactComponent as InfoSVGIcon } from "@/assets/image/icons/Info.svg";
import { parseHashDelimitedParams } from "@/utils/common";
import { ReactComponent as BackSVG } from "@/assets/image/integrations/back.svg";
import GetStartedCard from "@/components/integrations/GetStartedCard";
import {
  handleAuthCheck,
  handleCodeAuth,
  handleConnect,
  handleDisconnect,
} from "@/utils/integrationOAuth2";
import MainTitleBar from "@/components/MainTitleBar";
import IntegrateCardZapier from "@/components/integrations/Zapier/ZapierIntegrationCard";

export default function IntegrationScene() {
  const [connectTabVisible, setConnectTabVisible] = useState(false);
  const [params] = useSearchParams();

  const basicCodeGet = () => {
    return { code: params.get("code") };
  };
  const basicConnect = async (token, service) => {
    return await handleConnect(token, service);
  };
  const basicDisconnect = async (token, service) => {
    return await handleDisconnect(token, service);
  };
  const basicCheckConnect = async (method, code, token, service) => {
    return await handleCodeAuth(method, code, token, service);
  };
  const basicAuthCheck = async (token, service) => {
    return await handleAuthCheck(token, service);
  };

  const integrations = [
    {
      label: "Salesforce",
      handleConnect: basicConnect,
      checkConnect: basicCheckConnect,
      checkAuth: basicAuthCheck,
      disconnect: basicDisconnect,
      getCode: basicCodeGet,
      getTokens: () => {
        return parseHashDelimitedParams(window.location.href);
      },
      service: "salesforce",
      description:
        "Connect with Salesforce to bulk export and manage prospects while associating companies and contacts with conversations.",
      icon: SalesforceIcon,
      method: "token",
    },
    {
      label: "Hubspot",
      handleConnect: basicConnect,
      checkConnect: basicCheckConnect,
      disconnect: basicDisconnect,
      checkAuth: basicAuthCheck,
      getCode: basicCodeGet,
      getTokens: () => {
        return parseHashDelimitedParams(window.location.href);
      },
      service: "hubspot",
      description:
        "Integrate with Hubspot to get unparalleled visibility into how your reps are interacting with potential prospects and customers.",
      icon: HubspotIcon,
      method: "code",
    },
    {
      label: "Zapier",
      service: "zapier",
      description:
        "Setup Zapier webhooks to use Kompass data in any Zap you want",
      icon: HubspotIcon,
    },
    // {
    //   label: "Outreach",
    //   handleConnect: basicConnect,
    //   checkConnect: basicCheckConnect,
    //   disconnect: basicDisconnect,
    //   checkAuth: basicAuthCheck,
    //   getCode: basicCodeGet,
    //   service: "outreach",
    //   description: "Create prospects from KompassAI contacts",
    //   icon:  OutreachIcon,
    //   method: "code",
    // },
  ];

  const integrationsPlaceholders = [
    {
      label: "Outreach",
      description:
        "Want to streamline your customer engagement? Integrate with Outreach to bulk export prospect data into automated sequences.",
      icon: OutreachIcon,
    },

    {
      label: "Pipedrive",
      description:
        "Integrate with Pipedrive to connect customer data with CRM data, and enjoy additional insights on your sales flow.",
      icon: PipedriveIcon,
    },
    {
      label: "Zoho CRM",
      description:
        "Integrate with Zoho to save more relevant leads to your Zoho database and convert more leads into opportunities with ease.",
      icon: ZohoIcon,
    },
  ];

  return (
    <MainLayout>
      {!connectTabVisible ? (
        <>
          <MainTitleBar>
            <div className="flex items-center gap-3">
              <ReactSVG src={IntegrateIcon} className="h-6 w-6 self-start	" />
              <div className="flex flex-col">
                <p>Integrations</p>
                <p className="font-Outfit text-sm text-[#5E5E5E] font-normal">
                  Quit switching tools and work from one place. Integrate to any
                  tool in 60 seconds and supercharge your workflows in 1 click.
                </p>
              </div>
            </div>
          </MainTitleBar>

          <div>
            <div className="grid w-full grid-cols-1 items-center justify-start gap-3 p-6 md:grid-cols-2 lg:grid-cols-3">
              {integrations.map((item, index) => {
                return item.service === "zapier" ? (
                  <IntegrateCardZapier info={item}></IntegrateCardZapier>
                ) : (
                  <IntegrateCard
                    info={item}
                    disconnect={item.disconnect}
                    onConnect={basicConnect}
                    checkConnect={basicCheckConnect}
                    getCode={basicCodeGet}
                    getTokens={item.getTokens}
                    method={item.method}
                    service={item.service}
                    checkAuth={basicAuthCheck}
                  />
                );
              })}
              {integrationsPlaceholders.map((item) => {
                return <IntegrateCardSoon info={item} />;
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="font-Outfit leading-loose flex w-full flex-row items-center text-center text-sm">
            <Button
              className="flex flex-row items-center justify-center gap-2 p-0 text-magenta-500"
              onClick={() => {
                setConnectTabVisible(false);
              }}
            >
              <BackSVG />
              <p>Back</p>
            </Button>
          </div>

          <div className="font-Outfit leading-loose mt-1 flex w-full flex-row items-center justify-center gap-2 text-center text-xl font-bold text-black md:justify-start md:text-2xl">
            <ReactSVG
              src={ZapierIcon}
              beforeInjection={(svg) => {
                svg.setAttribute("style", "width: 35px");
              }}
            />
            <p>Get started with Zapier</p>
          </div>
          <div className="font-Outfit leading-loose flex w-full flex-row items-center text-center text-sm text-[#5E5E5E] md:ml-10">
            <p>
              Push contacts to over 5000+ apps including Google Sheets in one
              click.
            </p>
          </div>

          <hr
            className="my-4 h-[2px] rounded-full"
            width="100%"
            color="#E8E7E7"
          />

          <div>
            <div className="grid w-full grid-cols-1 items-center justify-start gap-3 md:grid-cols-2 lg:grid-cols-3">
              <GetStartedCard
                id={1}
                title="Copy your Zapier key"
                description="You'll enter this key later on Zapier's editor."
              >
                <div className="flex w-full flex-row items-center rounded-[40px] border-[1px] border-[#E8E7E7] bg-white p-[8px_12px]">
                  <input
                    type="text"
                    className="font-Outfit w-full rounded-xl pl-[16px] text-sm font-[300px] outline-none"
                    placeholder=""
                  />
                  <ReactSVG src={CopyKeyIcon} />
                </div>
              </GetStartedCard>
              <GetStartedCard
                id={2}
                title="Create your Zap"
                description="Add the KompassAI app to your Zapier account."
              >
                <Button className="font-Outfit flex w-full items-center justify-center rounded-full border-[1px] border-[#E7436A] bg-magenta-500 px-1 py-2 text-sm font-bold text-white">
                  add kompassai to zapier
                </Button>
              </GetStartedCard>
              <GetStartedCard
                id={3}
                title="Sync a contact"
                description="Syncing any contact or list will push the contact(s) to your Zapier automation."
              ></GetStartedCard>
            </div>
          </div>

          <hr
            className="my-4 h-[2px] rounded-full"
            width="100%"
            color="#E8E7E7"
          />

          <div className="flex flex-row items-center justify-center gap-2">
            <InfoSVGIcon className="relative h-5 w-5" />
            <div className="leading-none font-['Outfit'] text-xs font-normal text-black opacity-60">
              Still have questions? Reach out to our
              <span className="ml-1 cursor-pointer select-none text-magenta-500">
                support
              </span>{" "}
              and we'll help you get started with Zapier.
            </div>
          </div>
        </>
      )}
    </MainLayout>
  );
}
