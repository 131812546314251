import { fetchWrapper } from "./common";
import * as yup from "yup";

export const handleZapierPushData = async (profileType, selectedIds, token) => {
  const urlParams = new URLSearchParams();
  urlParams.set("profileType", profileType);

  const res = await fetch(
    `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/zapier/data?${urlParams.toString()}`,
    {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ids: selectedIds }),
    }
  );
  if (res.ok) {
    const parsed = await res.json();
    const fullSuccess = JSON.parse(parsed.body).every((res) => {
      return res.status === "success";
    });
    if (fullSuccess) {
      return { result: parsed, success: true };
    } else {
      return {
        result: parsed,
        success: true,
        extraMessage: "Some webhooks were not triggered",
      };
    }
  } else {
    return { error: "Something went wrong", success: false };
  }
};
export const getZapierSettingsFunction = (token) => {
  const func = fetchWrapper({
    link: "/zapier/settings",
    headers: { Authorization: token },
  });

  return func;
};
export const validateZapierSettings = async (value) => {
  console.log(value);
  try {
    return zapierSettingSchema.validateSync(value, { abortEarly: true });
  } catch (err) {
    console.log(err);
    return false;
  }
};

const linkRegex = /https:\/\/hooks.zapier.com\/hooks\/catch\/\S+\/\S+\//;

export const zapierSettingSchema = yup.object().shape({
  options: yup.object().shape({
    cleanData: yup.boolean().required(),
  }),
  links: yup
    .array()
    .min(1, "Add at least one link")
    .of(
      yup.object().shape({
        link: yup
          .string()
          .matches(linkRegex, {
            message:
              "Link doesn't match the pattern e.g. https://hooks.zapier.com/hooks/catch/{user id}/{hook id}/",
          })
          .required("Link can't be empty"),
        id: yup.string().required(),
      })
    )
    .test("no-duplicates", "Duplicate links", function (links) {
      const set = new Set();
      const filtered = links.filter((t) => !!t.link);

      filtered.forEach((link) => {
        if (link.link) set.add(link.link);
      });
      console.log(set.size === filtered.length);
      return set.size === filtered.length;
    }),
});
