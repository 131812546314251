import React from "react";
import { Box, useMediaQuery, Rating } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import person1 from "@/assets/image/about/Person1.webp";
import person2 from "@/assets/image/about/Person2.webp";
import person3 from "@/assets/image/about/Person3.webp";
import person4 from "@/assets/image/about/Person4.webp";
import person5 from "@/assets/image/about/Person5.webp";
import person6 from "@/assets/image/about/Person6.webp";
import { ReactComponent as DoubleQuote } from "@/assets/image/icons/double-quote.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const User = ({ isLargeScreen, face, feedback, name, position }) => {
  return (
    <div className="w-full h-full flex max-sm:flex-col max-lg:flex-col p-8 lg:p-12 2xl:p-16 max-sm:py-8 max-sm:px-2.5 items-center gap-5 2xl:gap-6 bg-white max-lg:rounded-[20px] rounded-[30px] box-shadow4 lg:box-Shadow3">
      <div className="flex flex-col items-center self-center">
        <img
          src={face}
          alt="person"
          width={isLargeScreen ? 150 : 120}
          height={isLargeScreen ? 150 : 120}
        />
        <Rating name="read-only" className="mt-4" value={5} readOnly />
      </div>
      <div className=" flex flex-col items-center xl:items-start flex-1 flex-shrink-0 max-sm:mt-8 gap-6 2xl:gap-8">
        <p className="text-center lg:text-left text-base xl:!text-md 2xl:!text-lg font-Outfit font-normal !text-gray-950 ">
          {feedback}
        </p>
        <p className="text-center lg:text-left text-base lxl!text-md 2xl:!text-lg font-OutfitSemiBold font-semibold lg:font-Outfit lg:font-normal text-black">
          {name}
          <span className="block text-stone-350 text-sm xl:text-base 2xl:text-md font-Outfit font-normal leading-[1.4] mt-0.5">
            {position}
          </span>
        </p>
      </div>
    </div>
  );
};
const TrustedUser = ({ isShowDots = false }) => {
  const isTablet = useMediaQuery("(min-width:768px)");
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const isLargeScreen = useMediaQuery("(min-width:1536px)");

  return (
    <>
      <Box className="flex flex-col items-center px-4 md:px-4 lg:px-9 pt-24 lg:pt-20 w-full">
        <Box className="text-center rounded-[40px] bg-black px-4 py-2.5 mb-4 lg:mb-5">
          <p className="!text-base lg:!text-base 2xl:!text-md text-white font-normal font-Outfit">
            Testimonials
          </p>
        </Box>

        <h2 className="main-subtitle pb-20">What clients says about us</h2>
        <div className="w-full">
          <Swiper
            spaceBetween={isLargeScreen ? 60 : 40}
            centeredSlides={!isDesktop}
            slidesPerView={isLargeScreen ? "auto" : isTablet ? 2 : 1}
            modules={[Pagination, Autoplay]}
            loop={true}
            pagination={
              !isDesktop & isShowDots
                ? {
                    clickable: true,
                  }
                : false
            }
            autoplay={{ delay: 5000 }}
            className="feedback-slider-wrapper"
          >
            <SwiperSlide className="feedback-slide">
              <User
                isLargeScreen={isLargeScreen}
                face={person1}
                feedback="If you do an ROI assessment on KompassAI it had well over 20 times return on capital for my startup - would highly recommend!"
                name="Amish Taploo, Chief Executive Officer"
                position="Veritao Skincare"
                width={150}
                height={150}
              />
              <DoubleQuote className="double-quote-icon" />
            </SwiperSlide>
            <SwiperSlide className="feedback-slide">
              <User
                isLargeScreen={isLargeScreen}
                face={person2}
                feedback="After implementing KompassAI across our sales team we saw our revenue grow rapidly, as we had 0% bounce rates on email outreach"
                name="Liam Jeys, Operations Manager"
                position="RocketWallet"
                width={150}
                height={150}
              />
              <DoubleQuote className="double-quote-icon" />
            </SwiperSlide>
            <SwiperSlide className="feedback-slide">
              <User
                isLargeScreen={isLargeScreen}
                face={person3}
                feedback="Truly the best tool on the market - allows me to efficiently build prospect lists with my SDRs and close deals at at a rapid pace!"
                name="Kyle De Souza"
                position="Vena Solutions"
                width={150}
                height={150}
              />
              <DoubleQuote className="double-quote-icon" />
            </SwiperSlide>
            <SwiperSlide className="feedback-slide">
              <User
                isLargeScreen={isLargeScreen}
                face={person4}
                feedback="Have had great success with the email warm-up tool, my emails that previously went into spam are now reaching client inboxes!"
                name="Matthew Plante"
                position="Rocket Mortgage"
                width={150}
                height={150}
              />
              <DoubleQuote className="double-quote-icon" />
            </SwiperSlide>
            <SwiperSlide className="feedback-slide">
              <User
                isLargeScreen={isLargeScreen}
                face={person6}
                feedback="Love the email LinkedIn prospecting tool - helps me
                  connect with potential partners without going straight to
                  spam"
                name="John Abraham"
                position="CIBC"
                width={150}
                height={150}
              />
              <DoubleQuote className="double-quote-icon" />
            </SwiperSlide>
          </Swiper>
        </div>
      </Box>
    </>
  );
};

export default TrustedUser;
