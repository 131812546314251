import * as React from "react";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button } from "@material-tailwind/react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "theme/palette";
import useList from "@/hooks/useList";
import {
  downloadKompassProfilesCSV,
  downloadLiveProfilesCSV,
} from "@/utils/common";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import SearchInput from "@/components/common/SearchInput";
import MenuIcon from "@/assets/image/team/menu.svg";
import { ReactComponent as NewListIcon } from "@/assets/image/lists/newlist.svg";
import { CONTACT_LIST_TYPE } from "@/utils/constants";
import SortIcon from "@/components/icons/SortIcon";
import { PaginationItem } from "@mui/material";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function getFormattedDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate();
  const suffix = getDaySuffix(day);

  // Function to get the day suffix (st, nd, rd, or th)
  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const formattedDateWithSuffix = `${date.toLocaleString("en-US", {
    month: "long",
  })} ${day}${suffix}, ${date.getFullYear()}`;
  return formattedDateWithSuffix;
}
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "listTitle",
    numeric: false,
    disablePadding: true,
    label: "List name",
  },
  {
    id: "contacts",
    numeric: false,
    disablePadding: true,
    label: "Contacts",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created date",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Last updated",
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "5px 8px",
          fontFamily: "Outfit",
          fontSize: 16,
          fontWeight: 600,
          color: "#090C05",
          lineHeight: 1.4,
        },
        "& .MuiTableRow-head": {
          bgcolor: "#E8E7E7",
        },
      }}
    >
      <TableRow
        sx={{
          "& .MuiTableCell-root:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          },
          "& .MuiTableCell-root:last-child": {
            borderBottomRightRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
      >
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={SortIcon}
                sx={{ display: "flex", alignItems: "center", gap: 4 }}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                )}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    onSelectAllClick,
    onDeleteSelected,
    setSearchFilter,
    rowCount,
    onCreateNewList,
  } = props;

  return (
    <>
      <Toolbar
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          borderRadius: "15px",
          bgcolor: "white",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          mb: { xs: "8px", md: "0" },
          gap: 1,
          "& .css-jysmwz-MuiToolbar-root": {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <SearchInput
          placeholder="Search for lists..."
          onInputChanged={(searchFilter) => {
            setSearchFilter(searchFilter);
          }}
        />
        <Tooltip title="Create new list">
          <Button
            onClick={() => {
              onCreateNewList(true);
            }}
            className="rounded-[8px] ml-auto mr-auto capitalize text-base font-medium sm:mr-0 flex items-center justify-center gap-2 shrink-0 py-[6px] px-[16px] 2xl:py-[8px] 2xl:px-[20px] border bg-accents-blue2 font-Outfit leading-[1.4] text-white focus:text-accents-blue2 focus:bg-transparent focus:border-accents-blue2 hover:text-accents-blue2 hover:bg-transparent hover:border-accents-blue2 transition-colors"
          >
            Create new list
            <NewListIcon className="w-4 h-4 text-white fill-current" />
          </Button>
        </Tooltip>
      </Toolbar>
      <Toolbar
        sx={{
          px: { xs: "8px", sm: "8px" },
          borderRadius: "8px",
          backgroundColor: numSelected > 0 ? "#F5F5F5" : "transparent",
        }}
      >
        <Typography
          sx={{ flex: "1 1", fontFamily: "Outfit" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            sx={{
              "&.Mui-checked": {
                color: kompassColors.black,
              },
              "&.MuiCheckbox-indeterminate": {
                color: kompassColors.black,
                fill: kompassColors.black,
              },
            }}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
          {numSelected} selected
        </Typography>
        {numSelected > 0 ? (
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                onDeleteSelected();
              }}
              className="px-2 text-base text-center text-blue-500 cursor-pointer font-Outfit disabled:text-stone-350"
            >
              Delete from list
            </button>
          </div>
        ) : null}
      </Toolbar>
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ContactListRow(props) {
  const {
    row,
    isItemSelected,
    labelId,
    handleClick,
    handleItemClick,
    handleDeleteItem,
    handleDownloadItem,
  } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const menuOptions = [
    {
      icon: null,
      label: "Download",
    },
    {
      icon: null,
      label: "Delete",
    },
  ];

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const openMenu = () => {
    return menuAnchorEl !== null;
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row._id}
        selected={false}
        sx={{
          cursor: "pointer",
          background: "white",
          "& .MuiTableCell-root": {
            border: "none",
          },
          "& .MuiTableCell-root:first-child": {
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
          },
          "& .MuiTableCell-root:last-child": {
            borderBottomRightRadius: "15px",
            borderTopRightRadius: "15px",
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            sx={{
              "&.Mui-checked": {
                color: kompassColors.blue,
              },
              "&.MuiCheckbox-indeterminate": {
                color: kompassColors.blue,
                fill: kompassColors.blue,
              },
            }}
            onClick={(event) => handleClick(event, row._id)}
          />
        </TableCell>
        <TableCell
          onClick={() => handleItemClick(row)}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <div className="flex flex-col w-full">
            <p className="font-Outfit text-base text-stone-950 font-light leading-[1.4]!">
              {row.listTitle}
            </p>
          </div>
        </TableCell>
        <TableCell
          onClick={() => handleItemClick(row)}
          padding="none"
          align="left"
        >
          <p className="text-base font-light text-stone-950">{row.contacts}</p>
        </TableCell>
        <TableCell
          onClick={() => handleItemClick(row)}
          padding="none"
          align="left"
        >
          <p className="text-base font-light text-stone-950">
            {getFormattedDateFromTimestamp(row.createdAt)}
          </p>
        </TableCell>
        <TableCell
          onClick={() => handleItemClick(row)}
          padding="none"
          align="left"
        >
          <p className="text-base font-light text-stone-950">
            {getFormattedDateFromTimestamp(row.updatedAt)}
          </p>
        </TableCell>
        <TableCell padding="none" align="right">
          <IconButton
            onClick={(event) => {
              setMenuAnchorEl(event.currentTarget);
            }}
          >
            <ReactSVG src={MenuIcon} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={menuAnchorEl}
        open={openMenu()}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            // width: "20ch",
          },
        }}
      >
        <MenuItem
          key={menuOptions[0]}
          onClick={() => {
            handleMenuClose();
            handleDownloadItem(row);
          }}
        >
          <Typography variant="inherit">{menuOptions[0].label}</Typography>
        </MenuItem>
        <MenuItem
          key={menuOptions[1]}
          onClick={() => {
            handleMenuClose();
            handleDeleteItem(row._id);
          }}
        >
          <Typography variant="inherit">{menuOptions[1].label}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default function ContactListTable(props) {
  const {
    setLoading,
    contactTableData,
    onContactSelected,
    reload,
    onCreateNewList,
  } = props;

  const { deleteContactList, getProfilesByContactId } = useList();
  const [visibleDeleteDialog, handleVisibleDeleteDialog] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [searchFilter, setSearchFilter] = React.useState("");

  const tableData = contactTableData.filter((item) => {
    return item?.listTitle?.toLowerCase()?.indexOf(searchFilter) >= 0;
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n._id);
      setSelected(newSelected);
      return;
    } else {
      setSelected([]);
    }
  };

  const handleDeleteSelected = async (confirmed) => {
    if (!(confirmed === true)) {
      handleVisibleDeleteDialog(true);
    } else {
      setLoading(true);
      const response = await deleteContactList({ ids: selected });
      setLoading(false);
      if (response?.status) {
        toast.success(response?.message, { theme: "colored" });
        setSelected([]);
        reload();
      } else {
        toast.error(response?.message, { theme: "colored" });
      }
    }
  };

  const handleDeleteItem = async (itemId) => {
    setLoading(true);
    const response = await deleteContactList({ ids: [itemId] });
    setLoading(false);
    if (response?.status) {
      toast.success(response?.message, { theme: "colored" });
      setSelected([]);
      reload();
    } else {
      toast.error(response?.message, { theme: "colored" });
    }
  };

  const handleDownloadSelected = async () => {
    tableData.forEach((item) => {
      const index = selected.indexOf(item?._id);
      if (index >= 0) {
        handleDownloadItem(item);
      }
    });
  };

  const handleDownloadItem = async (listItem) => {
    setLoading(true);

    const response = await getProfilesByContactId({ contactId: listItem?._id });
    console.log("PROFILES: ", response.data);

    if (response.status) {
      if (listItem?.listType === CONTACT_LIST_TYPE.KOMPASS_SEARCH)
        downloadKompassProfilesCSV(listItem?.listTitle, [...response.data]);
      if (listItem?.listType === CONTACT_LIST_TYPE.CHROME_EXTENSION)
        downloadLiveProfilesCSV(listItem?.listTitle, [...response.data]);
    } else {
      toast.error(response.message, { theme: "colored" });
    }

    setLoading(false);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(tableData, getComparator(order, orderBy)).slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, tableData]
  );

  return (
    <>
      <Box sx={{ width: "100%", mt: "10px" }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={tableData.length}
          onDeleteSelected={handleDeleteSelected}
          onDownloadSelected={handleDownloadSelected}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          onCreateNewList={onCreateNewList}
        />
        <TableContainer>
          <Table
            sx={{
              minWidth: {
                xs: 300,
                lg: 750,
              },
              borderCollapse: "separate",
              borderSpacing: "0 0.5em",
              border: "none",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              selected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
            <TableBody
              sx={{
                "& .MuiTableCell-sizeMedium": {
                  padding: "5px 8px",
                  fontFamily: "Outfit",
                  fontSize: 14,
                  // font-family: Outfit;
                },
              }}
            >
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <ContactListRow
                    isItemSelected={isItemSelected}
                    row={row}
                    labelId={labelId}
                    handleClick={handleClick}
                    handleItemClick={onContactSelected}
                    handleDeleteItem={handleDeleteItem}
                    handleDownloadItem={handleDownloadItem}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="flex justify-center w-full py-4">
          <Pagination
            className="font-Outfit"
            count={Math.ceil(tableData.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            boundaryCount={1}
            siblingCount={0}
            renderItem={(item) => (
              <PaginationItem
                slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-text": {
                fontFamily: "Outfit",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: 1.43,
                color: "#090C05",
              },
              "& .MuiPaginationItem-text.Mui-selected": {
                color: "#fff",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "gray",
                },
              },
            }}
          />
        </div>
        {/* </Paper> */}
      </Box>
      <ConfirmDeleteDialog
        open={visibleDeleteDialog}
        handleClose={() => {
          handleVisibleDeleteDialog(false);
        }}
        handleYes={() => {
          handleDeleteSelected(true);
          handleVisibleDeleteDialog(false);
        }}
      />
    </>
  );
}
