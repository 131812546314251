import { useEffect, useRef, useState, useContext } from "react";
import { Button } from "@material-tailwind/react";
import { AuthContext } from "@/context/AuthContext";
import { useNavigate } from "react-router-dom";

import MainLayout from "@/components/MainLayout";
import useStripe from "@/hooks/useStripe";
import CreditSlider from "@/components/credits/CreditSlider";
import BaseContainer from "@/components/BaseContainer";
import { pageContentWidth } from "@/utils/common";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as TextDecorIcon } from "@/assets/image/icons/text-decor.svg";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import MainLoader from "@/components/MainLoader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function CreditsScene() {
  const navigate = useNavigate();

  const { isTeamOwner } = useContext(AuthContext);

  const [clientSecret, setClientSecret] = useState("");
  const [stripeError, setStripeError] = useState("");
  const [emailsCustomIndex, setEmailsCustomIndex] = useState(0);
  const [phonesCustomIndex, setPhonesCustomIndex] = useState(0);
  const [validationCustomIndex, setValidationCustomIndex] = useState(0);

  const subscriptionInfoFunction = useHTTPRequest(
    `/billing/getSubscriptionInfo`
  );

  const { data: subscriptionInfo } = useQuery({
    queryKey: ["Subscription"],
    queryFn: () => subscriptionInfoFunction(),
  });

  const isEnterprise = subscriptionInfo?.data?.plan === "Enterprise";

  const canBuyCredits = isEnterprise && isTeamOwner;

  useEffect(() => {
    if (!canBuyCredits) {
      navigate("/home");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canBuyCredits]);

  const { getCreditPaymentLink } = useStripe();
  const [loading, setLoading] = useState(false);
  const loadStripe = async (data) => {
    setLoading(true);
    let res = await getCreditPaymentLink(data);
    // if (res.status === false) navigate("/home");
    if (res.status) {
      setClientSecret(res.data.client_secret);
    } else {
      setStripeError(res.message);
    }
    setLoading(false);
  };

  const redirectToSuccessPage = () => {
    // Редирект на страницу успешного завершения оплаты
    navigate("/home");
  };

  const handleCustomSelection = () => {
    let constructedPrice = [];
    if (phonesCustomIndex) {
      constructedPrice.push({
        lookup_key: "phone_credits_key",
        quantity: options.phone_credits[phonesCustomIndex],
      });
    }
    if (emailsCustomIndex) {
      constructedPrice.push({
        lookup_key: "email_credits_key",
        quantity: options.email_credits[emailsCustomIndex],
      });
    }
    if (validationCustomIndex) {
      constructedPrice.push({
        lookup_key: "email_validation_credits_key",
        quantity: options.email_validation_credits[validationCustomIndex],
      });
    }
    if (validationCustomIndex || emailsCustomIndex || phonesCustomIndex)
      loadStripe(constructedPrice);
  };

  const fieldRef = useRef(null);

  const params = new URLSearchParams();
  params.set("mode", "payment");
  params.set("product", "enrich");
  const OTPCreditsInfoFunction = useHTTPRequest(
    `/billing/getTariffs?` + params.toString(),
    "GET",
    true
  );
  const { data: OTPCreditsInfo, isLoading: isLoadingOTPCredits } = useQuery({
    queryKey: ["OTPCredits"],
    queryFn: async () => await OTPCreditsInfoFunction(),
  });

  const options = {
    phone_credits: [0, 150, 500, 1000, 5000, 10000, 25000, 50000, 100000],
    email_credits: [
      0, 1000, 5000, 10000, 50000, 1000000, 250000, 500000, 1000000,
    ],
    email_validation_credits: [
      0, 500, 2000, 5000, 10000, 25000, 100000, 250000, 500000,
    ],
  };

  const clearStripe = () => {
    setClientSecret("");
    setStripeError("");
  };

  if (!canBuyCredits) {
    return null;
  }

  return (
    <MainLayout>
      {(isLoadingOTPCredits || loading) && <MainLoader />}
      {!isLoadingOTPCredits && !loading && (
        <BaseContainer>
          <div className="mt-[18px] flex w-full flex-col items-center md:mt-[64px]">
            <div className="mt-[8px] flex flex-row items-center rounded-[40px] bg-[#D9D9D9] p-[5px_16px] ">
              <div className="font-Outfit  whitespace-nowrap font-[#0D0D0D] text-[20px] uppercase  ">
                Credits
              </div>
            </div>
            <span className="font-OutfitBold relative px-4 text-center text-[3.2rem] xs:text-[4rem] text-stone-950">
              Buy extra credits
              <TextDecorIcon className="absolute bottom-0 right-0" />
            </span>
          </div>
          {OTPCreditsInfo && !clientSecret && (
            <div className="mb-6">
              <AnimatePresence>
                <motion.div
                  ref={fieldRef}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="mb-[30px] mt-[72px] flex w-full flex-col"
                >
                  <div className="flex flex-col gap-12">
                    <CreditSlider
                      currentTariff={OTPCreditsInfo.data}
                      name="Phone credits"
                      key={"phone"}
                      credit_key="phone_credits"
                      options={options["phone_credits"]}
                      state={[phonesCustomIndex, setPhonesCustomIndex]}
                    ></CreditSlider>
                    <CreditSlider
                      currentTariff={OTPCreditsInfo.data}
                      key={"email"}
                      name="Email credits"
                      credit_key="email_credits"
                      options={options["email_credits"]}
                      state={[emailsCustomIndex, setEmailsCustomIndex]}
                    ></CreditSlider>
                    <CreditSlider
                      currentTariff={OTPCreditsInfo.data}
                      name="Validation credits"
                      key={"validation"}
                      credit_key="email_validation_credits"
                      options={options["email_validation_credits"]}
                      state={[validationCustomIndex, setValidationCustomIndex]}
                    ></CreditSlider>
                  </div>
                </motion.div>

                {(validationCustomIndex ||
                  emailsCustomIndex ||
                  phonesCustomIndex) && (
                  <Button
                    onClick={handleCustomSelection}
                    className={`text-md font-Outfit ml-auto mr-auto  mt-6 flex w-[280px] items-center justify-center self-center rounded-lg bg-black p-[16px_24px] font-[700] uppercase tracking-wide text-white transition-all  `}
                  >
                    To Checkout
                  </Button>
                )}
              </AnimatePresence>
            </div>
          )}

          {clientSecret && (
            <BaseContainer width={pageContentWidth}>
              <Button
                onClick={() => clearStripe()}
                className="font-Outfit mb-6 ml-auto mr-auto h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-lg font-medium uppercase text-white"
              >
                go back
              </Button>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                  clientSecret,
                }}
              >
                <EmbeddedCheckout
                  onSuccess={() => redirectToSuccessPage()}
                  successUrl={`${window.location.origin}/home`}
                />
              </EmbeddedCheckoutProvider>
            </BaseContainer>
          )}

          {stripeError && (
            <div className="flex w-full flex-col items-center justify-center">
              <div className="my-24 flex w-5/12 flex-col gap-3 rounded-md border-[1px] border-[#E6E6E6] p-10">
                <div className="font-Outfit flex flex-col self-stretch text-center text-3xl font-extrabold">
                  Sorry, we got problems
                </div>
                <div className="font-Outfit flex-wrap text-center text-lg">
                  {stripeError}
                </div>
                <div className="font-Outfit mt-10 flex-wrap text-center text-lg">
                  <Button
                    onClick={() => navigate(-1)}
                    className="font-Outfit h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-lg font-medium uppercase text-white"
                  >
                    go back
                  </Button>
                </div>
              </div>
            </div>
          )}
        </BaseContainer>
      )}
    </MainLayout>
  );
}
