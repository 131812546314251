import Product from "@/components/prospecting/Products";
import LandingLayout from "@/components/LandingLayout";
import SupportPanel from "@/components/landing/SupportPanel";
import WhyKompassAIPanel from "@/components/landing/WhyKompassAIPanel";
import HoursSavedPanel from "@/components/integrations/HoursSavedPanel";
import ConnectorsPanel from "@/components/integrations/ConnectorsPanel";
import TopBar from "@/components/landing/TopBar";
import ConnectPanel from "@/components/landing/ConnectPanel";

const topBarData = {
  tag: "Intergration",
  title: "Busy importing and exporting spreadsheets of data?",
  subTitle:
    "Optimize your prospecting experience with these partner integrations to help your reps be more productive and save time so they can focus on selling.",
};

const description = {
  heading: "Connect KompassAI with your favorite software",
  subHeading:
    "Directly integrate into your existing workflow & enhance your prospecting and sales campaigns. KompassAI’s seamless one-click integrations with leading data management will save time to help you focus on growing your business.",
};

export default function IntegrationScene() {
  return (
    <LandingLayout pageName="integration" background="#FF9665">
      <div>
        <TopBar
          data={topBarData}
          background="bg-accents-orange"
          productName="integration"
        />
        <SupportPanel title={false} />
        <WhyKompassAIPanel data={description} />
        <HoursSavedPanel />
        <ConnectorsPanel />
        <Product pageName="integration" />
        <ConnectPanel
          background="bg-accents-orange"
          accent="bg-accents-darkOrange"
        />
      </div>
    </LandingLayout>
  );
}
