import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "@/context/AuthContext";
import MainHeader from "../components/MainHeader";
import ProfilePopup from "./popup/ProfilePopup";
import GuidePopup from "./popup/GuidePopup";
import LogoutDialog from "@/components/LogoutDialog";
import PaymentResultModal from "./payment/PaymentResultModal";
import useProtectLayout from "@/hooks/useProtectLayout";
import MainLoader from "./MainLoader";
import { MainSidebar } from "@/components/sidebar/MainSidebar";
import { MobileHeader } from "@/components/mobile_header/MobileHeader";

const MainLayout = ({
  children,
  loading = false,
  className = "",
  containerClassName = "",
}) => {
  const protectionFlag = useProtectLayout("/home");
  const authContext = useContext(AuthContext);
  const [bSignin, handleSignin] = useState(false);
  const [profilePopupAnchorEl, setProfilePopupAnchorEl] = useState(null);
  const [guidePopupAnchorEl, setGuidePopupAnchorEl] = useState(null);
  const [contentMinHeight, setContentMinHeight] = useState(0);
  const [visibleLogout, setVisibleLogout] = useState(false);

  const profilePopupClosed = () => {
    setProfilePopupAnchorEl(null);
  };

  const guidePopupClosed = () => {
    setGuidePopupAnchorEl(null);
  };

  const getHeight = () => {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    );
  };

  useEffect(() => {
    const resized = () => {
      setContentMinHeight(getHeight() - 100);
    };
    resized();
    window.addEventListener("resize", resized);
    return () => {
      window.removeEventListener("resize", resized);
    };
  }, []);

  useEffect(() => {
    const func = async () => {
      if (!(await authContext.isSignIn())) {
        authContext.signOut();
        handleSignin(false);
      } else {
        handleSignin(true);
      }
    };
    func();
  }, [authContext]);

  return (
    <>
      {bSignin && protectionFlag && !loading && (
        <>
          <PaymentResultModal />
          <main className="relative lg:flex m-auto min-h-screen w-full overflow-hidden bg-white">
            <MainSidebar showProfilePopup={setProfilePopupAnchorEl} />
            <MobileHeader showProfilePopup={setProfilePopupAnchorEl} />
            <ProfilePopup
              anchorEl={profilePopupAnchorEl}
              handleClose={profilePopupClosed}
              handleLogout={() => {
                setVisibleLogout(true);
              }}
            />
            <GuidePopup
              anchorEl={guidePopupAnchorEl}
              handleClose={guidePopupClosed}
            />
            <LogoutDialog
              open={visibleLogout}
              handleClose={() => {
                setVisibleLogout(false);
              }}
              handleLogout={() => {
                authContext.signOut();
              }}
            />

            <div
              className={`flex flex-col grow bg-white ${containerClassName}`}
              style={{ minHeight: `${contentMinHeight}px` }}
            >
              {children}
            </div>
          </main>
        </>
      )}
      {(!bSignin || !protectionFlag || loading) && (
        <main className="relative lg:flex z-100 m-auto min-h-screen w-full overflow-hidden bg-white">
          <MainSidebar showProfilePopup={setProfilePopupAnchorEl} />
          <MobileHeader showProfilePopup={setProfilePopupAnchorEl} />
          <MainLoader />
        </main>
      )}
    </>
  );
};

export default MainLayout;
