import { useState } from "react";
import { Button } from "@material-tailwind/react";
import { styled } from "@mui/material/styles";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cn from "classnames";
import { toast } from "react-toastify";
import ConfirmDialog from "@/components/email_validation/ConfirmDialog";
import { ReactComponent as CsvSVGIcon } from "@/assets/image/bulk/csv.svg";
import { ReactComponent as ContactSVGIcon } from "@/assets/image/bulk/contact.svg";
import StyledInput from "./StyledInput";
import FileUploadValidationWidget from "./FileUploadValidationWidget";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import { S3_URL } from "@/utils/constants";
import MainLoader from "../MainLoader";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Yup's one is very forgiving, so we use another one

export default function EmailUploadWidget({
  currentWidgetIndex,
  setCurrentWidgetIndex,
}) {
  const [validateOption, setValidateOption] = useState(0); // 0: csv/xls/xlsx, 1: linkedin profile
  const [visibleConfirmDlg, setVisibleConfirmDlg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const options = [
    {
      icon: "csv",
      header: "I have a CSV to Upload",
      content: "Upload your e-mails in a CSV file",
    },
    {
      icon: "contact",
      header: "I’d like to paste a list of e-mails",
      content: "Give us a list of e-mails to validate",
    },
  ];
  const sendDataAsList = useHTTPRequest("/email-validation?", "PUT");
  const { mutateAsync: sendDataAsListMutation } = useMutation({
    mutationFn: sendDataAsList,
  });

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: calc(50% - 12px);
    max-height: 800px;
    overflow: auto !important;
    padding: 16px;
    border-radius: 8px;
    color: #090C05;
    background: #fff;
    border: 1px solid #E8E7E7 ;
    resize: none;
    transition: background-color 0.2s;
    &:hover {
      background-color: #F9F9F9;
    }
    &:focus {
      border-color: #4873FA;
      background-color: white;
    }
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );
  const initialValues = {
    list: "",
    emailListName: "",
  };
  const validationSchema = Yup.object().shape({
    list: Yup.string()
      .required("Please add at least one email")
      .test(
        "isValidEmailList",
        "Email list formatting is invalid",
        (value, context) => {
          const listValidity = value
            .split(",")
            .map((value) => value.trim())
            .map((email) => {
              return Yup.string().matches(emailRegex).isValidSync(email);
            });
          return !listValidity.includes(false);
        }
      ),
    emailListName: Yup.string()
      .required("Please name the list")
      .max(80, "List name is too long!"),
  });

  const onOptionSelected = (option) => {
    setValidateOption(option);
  };

  const handleInitiateLoading = async (values) => {
    setFormValues(values);
    setVisibleConfirmDlg(true);
  };

  const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed?", "GET");
  const { data: creditsData } = useQuery({
    queryKey: ["CreditsUsage"],
    queryFn: () => getUsageData(),
  });

  const handleForm = async () => {
    try {
      setLoading(true);
      const formattedEmails = formValues.list.split(",").map((value) => {
        return { email: value.trim() };
      });
      if (creditsData?.data?.validations.available < formattedEmails.length) {
        toast.error(
          "You don't have enough email validation credits, please refill your credits"
        );
        setLoading(false);
        return;
      }
      await sendDataAsListMutation(
        JSON.stringify({
          emails: formattedEmails,
          fileName: formValues.emailListName,
        })
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something went wrong, try again");
      return;
    }
    toast.success("Successfully uploaded!");
    setLoading(false);
    setTimeout(() => {
      setCurrentWidgetIndex(0); //TODO: Add additional screen with list?
    }, 2000);
  };

  return (
    <>
      {loading && <MainLoader />}
      {currentWidgetIndex === 1 && (
        <div className="flex w-full xl:max-w-[1090px] flex-col items-center justify-center mx-auto">
          <div className="my-16 lg:my-24 w-full flex flex-col gap-8 rounded-md border-[1px] border-[#E6E6E6] p-6">
            <div className="font-OutfitBold flex flex-col text-left text-3xl font-extrabold">
              What format is your contact list in?
            </div>
            <div className="font-Outfit flex flex-col md:flex-row justify-between gap-5 text-center text-lg">
              {options.map((option, index) => {
                return (
                  <div
                    onClick={() => {
                      onOptionSelected(index);
                    }}
                    className="group flex w-full cursor-pointer justify-start gap-4 rounded-lg border-[1px] border-[#E6E6E6] bg-white p-8 transition-all duration-300 hover:bg-[#000000] aria-selected:bg-[#000000]"
                    aria-selected={index === validateOption}
                  >
                    {index === 0 ? (
                      <CsvSVGIcon className="w-[42px] h-[42px] shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300" />
                    ) : (
                      <ContactSVGIcon className="w-[42px] h-[42px] shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300" />
                    )}
                    <div className="shrink grow basis-0 flex-col gap-1">
                      <div className="font-Outfit select-none self-stretch text-left text-xl font-medium text-gray-950 transition-all duration-300 group-hover:text-white  group-aria-selected:text-white">
                        {option.header}
                      </div>
                      <div className="font-Outfit select-none text-left text-xl font-light text-gray-950 transition-all duration-300 group-hover:text-white group-aria-selected:text-white">
                        {option.content}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr></hr>
            {validateOption === 0 ? (
              <>
                <div className="font-Outfit flex flex-col gap-4 items-start">
                  <p className="text-3xl font-bold">What KompassAI Needs</p>
                  <div className="font-Outfit flex flex-col items-start gap-2 text-base font-light leading-md">
                    <p>Please upload your contact list in CSV or TXT format</p>
                    <p>
                      CSV file must contain header row and a field that contains
                      'email' in its name
                    </p>
                    <p>
                      TXT file should contain list of emails separated by comma
                    </p>
                  </div>
                </div>
                <div className="font-Outfit flex flex-col md:flex-row gap-5 text-center text-xl">
                  <div className="items-start flex w-full flex-col gap-2 rounded-lg border-[1px] border-stone-250 p-4">
                    <span className="color-[black] text-xl">email</span>
                    <hr className="w-full"></hr>
                    <span className="color-[black] text-base font-light leading-md">
                      maikal.yamauchi@kompassai.com
                    </span>
                    <span className="color-[black] text-base font-light leading-md">
                      john.field@kompassai.com
                    </span>
                  </div>
                  <div className="flex h-full m-auto items-center text-xl">
                    <p>CSV or TXT</p>
                  </div>
                  <div className="items-start flex w-full flex-col gap-2 rounded-lg border-[1px] border-stone-250 p-4">
                    <span className="color-[black] text-base font-light leading-md">
                      maikal.yamauchi@kompassai.com,
                    </span>
                    <span className="color-[black] text-base font-light leading-md">
                      john.field@kompassai.com
                    </span>
                  </div>
                </div>
                <hr></hr>
                <div className="font-Outfit flex flex-col text-left">
                  <p className="text-3xl font-bold mb-2">
                    Use our template for the most accurate results
                  </p>
                  <p className="text-3xl font-bold mb-4 text-base font-light leading-md">
                    Download our CSV template for correct column format.
                  </p>
                  <Button
                    onClick={() => {
                      window.open(S3_URL.KOMPASS_EMAIL_TEMPLATE, "_blank");
                    }}
                    className="font-Outfit w-fit py-3 px-6 rounded-lg border-[1px] border-[#000] bg-white text-base leading-md font-bold tracking-[0.32px] normal-case text-gray-950"
                  >
                    Kompass AI CSV Template
                  </Button>
                </div>
                <hr></hr>
                <div className="flex flex-row justify-between">
                  <Button
                    onClick={() => {
                      setCurrentWidgetIndex(0);
                    }}
                    className="font-Outfit rounded-md border-[1px] border-[#000] bg-white px-6 py-2 font-medium text-base leading-md normal-case text-black"
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => {
                      setCurrentWidgetIndex(2);
                    }}
                    className="font-Outfit rounded-md bg-blue-500 px-6 py-2 font-medium text-base leading-md normal-case text-white"
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleInitiateLoading}
                >
                  {({ errors }) => (
                    <Form className="flex flex-col gap-6">
                      <div>
                        <label className=" flex flex-col gap-4">
                          <div className="font-OutfitBold flex flex-col text-left text-3xl">
                            Paste a list of e-mails
                          </div>
                          <div className="font-Outfit mb-8 flex flex-col text-left text-base font-light leading-md">
                            Paste e-mail addresses separated by a comma (,) up
                            to 100 addresses
                          </div>
                        </label>
                        <Field
                          as={Textarea}
                          className={cn(
                            "font-OutfitLight !w-full p-0 text-base outline-none placeholder:text-[#929292] before:content-['']",
                            !!errors["list"] && "!border-[#dc2626]"
                          )}
                          variant="filled"
                          id="list"
                          name="list"
                          minRows={6}
                          placeholder="youremail1@gmail.com, youremail2@gmail.com"
                        />
                        <ErrorMessage
                          name="list"
                          component="div"
                          className="font-Outfit leading-tight text-sm font-normal text-red-600"
                        />
                      </div>
                      <div>
                        <label className="flex flex-col gap-2">
                          <div className="font-OutfitBold flex flex-col text-left text-base font-medium">
                            List name
                          </div>
                          <div className="font-Outfit mb-2 flex flex-col text-left text-base font-light">
                            Provide a name for the list
                          </div>
                        </label>
                        <StyledInput
                          name={"emailListName"}
                          error={errors["emailListName"]}
                          placeholder={"My list"}
                          className={"mb-2"}
                        ></StyledInput>
                        <ErrorMessage
                          name="emailListName"
                          component="div"
                          className="font-Outfit leading-tight text-sm font-normal text-red-600"
                        />
                      </div>
                      <hr></hr>
                      <Button
                        type="submit"
                        className="font-Outfit mx-auto h-11 w-56 flex items-center justify-center rounded-md bg-blue-500 text-base font-bold leading-md capitalize text-white"
                      >
                        Finish
                      </Button>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </div>
        </div>
      )}

      {currentWidgetIndex === 2 && (
        <FileUploadValidationWidget
          setCurrentWidgetIndex={setCurrentWidgetIndex}
        ></FileUploadValidationWidget>
      )}

      <ConfirmDialog
        open={visibleConfirmDlg}
        handleClose={() => {
          setVisibleConfirmDlg(false);
        }}
        handleOK={() => {
          setVisibleConfirmDlg(false);
          handleForm();
        }}
      />
    </>
  );
}
