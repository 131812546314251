import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import FilterDropdown from "@/components/common/FilterDropdown";
import { Button } from "@material-tailwind/react";
import { CircularProgress, TextField } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { FilterInputProps } from "@/theme/typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "10px",
  },
}));

const GrayTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#E8E7E7",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#E8E7E7",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E8E7E7",
    },
    "&:hover fieldset": {
      borderColor: "#E8E7E7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E8E7E7",
    },
  },
  "& input:-webkit-autofill": {
    transition: "background-color 5000s ease-in-out 0s",
    boxShadow: "inset 0 0 0px 1000px transparent",
  },
});

export const SaveSearchDialog = ({
  open,
  close,
  isSaving,
  title,
  setTitle,
  onSave,
}) => {
  return (
    <BootstrapDialog open={open} onClose={close}>
      <button
        onClick={close}
        className="absolute right-0 top-0 flex flex-row justify-end pr-4 pt-4"
      >
        <CloseSVGIcon className="relative h-4 w-4" />
      </button>

      <DialogContent sx={{ minWidth: "500px" }}>
        <div className="font-Outfit p-3 text-center text-2xl font-bold text-black">
          Save search
        </div>
        <div className="mt-1 w-full">
          <GrayTextField
            value={title}
            onChange={e => setTitle(e.target.value)}
            className="w-full"
            placeholder="Title"
            InputProps={FilterInputProps}
          />
        </div>
        <div className="font-Outfit mt-4 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
          <Button
            disabled={isSaving || !title.trim()}
            className="font-Outfit flex items-center justify-center rounded-full border-[1px] border-none bg-blue-500 py-2 text-base text-white disabled:bg-[#929292]"
            onClick={onSave}
          >
            {isSaving ? (
              <CircularProgress
                size="1.2rem"
                className="mr-2"
                style={{ color: "white" }}
              />
            ) : null}
            Save
          </Button>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};
