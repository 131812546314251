import { useState, useEffect, useMemo } from "react";
import { useRef } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import { CircularProgress, PaginationItem } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Pagination from "@mui/material/Pagination";
import { kompassColors } from "@/theme/palette";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import useKompassSearch from "@/hooks/useKompassSearch";
import useEnrichment from "@/hooks/useEnrichment";
import useList from "@/hooks/useList";
import {
  getShortName,
  indexString,
  downloadKompassProfilesCSV,
  extractLocationsFromStr,
  mergeKompassEmails,
  cleanLinkedUrl,
} from "@/utils/common";
import MoveGroupIcon from "@/assets/image/team/move-group.svg";
import RemoveTeamIcon from "@/assets/image/team/remove-team.svg";
import TwitterIcon from "@/assets/image/search/twitter.svg";
import FacebookIcon from "@/assets/image/search/facebook.svg";
import LinkedinIcon from "@/assets/image/search/linkedin.svg";
import { ReactComponent as DownIcon } from "@/assets/image/search/down.svg";
import { ReactComponent as UserIcon } from "@/assets/image/search/user.svg";
import { ReactComponent as LocationIcon } from "@/assets/image/search/location.svg";
import { ReactComponent as USDIcon } from "@/assets/image/search/usd.svg";
import { ReactComponent as HomeIcon } from "@/assets/image/search/home.svg";
import { ReactComponent as JobTitleIcon } from "@/assets/image/search/jobtitle.svg";
import { ReactComponent as EmployeeIcon } from "@/assets/image/search/employee.svg";
import { ReactComponent as IndustryIcon } from "@/assets/image/search/industry.svg";
import { ReactComponent as LevelIcon } from "@/assets/image/search/level.svg";
import { ReactComponent as DepartmentIcon } from "@/assets/image/search/department.svg";
import { ReactComponent as AddListIcon } from "@/assets/image/search/icon-addlist.svg";
import { ReactComponent as LibraryAddIcon } from "@/assets/image/search/icon-library-add.svg";
import { ReactComponent as LockIcon } from "@/assets/image/search/lock.svg";
import { ReactComponent as OpenLockIcon } from "@/assets/image/search/open-lock.svg";
import { ReactComponent as LinkIcon } from "@/assets/image/search/link.svg";
import SaveToListDialog from "@/components/search/SaveToListDialog";
import CRMPushMenu from "../common/CRMElements/CRMPushMenu";
import { CONTACT_LIST_TYPE, ENRICHMENT_TYPE } from "@/utils/constants";
import { EnhancedTableHead } from "./EnhancedTableHead";
import MainLoader from "../MainLoader";
import CreateNewListDialog from "../lists/CreateNewListDialog";
import { BulkPopup } from "./BulkPopup";
import { TextField } from "@mui/material";
import useBulk from "@/hooks/useBulk";
import FilterDropdown from "@/components/common/FilterDropdown";
import { useKompassFilters } from "@/hooks/useKompassFilters";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label: "Company name",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: "Location",
  },
  {
    id: "",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

function EnrichPopup({
  anchorEl,
  onClose,
  onPhoneEnrich,
  onEmailEnrich,
  onEmailAndPhoneEnrich,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "& .MuiList-root": {
            ml: 1,
            mr: 1,
          },
        },
      }}
    >
      <div className="cursor-pointer font-Outfit" onClick={onEmailEnrich}>
        Email
      </div>
      <div className="cursor-pointer font-Outfit" onClick={onPhoneEnrich}>
        Phone
      </div>
      <div
        className="cursor-pointer font-Outfit"
        onClick={onEmailAndPhoneEnrich}
      >
        Email & Phone
      </div>
    </Menu>
  );
}

function EnhancedTableToolbar(props) {
  const {
    selected,
    tableData,
    numSelected,
    numSelectedOnPage,
    totalRowsOnPage,
    totalCount,
    onSelectAllClick,
    rowCount,
    onSelectionPhoneEnrich,
    onSelectionEmailEnrich,
    onSelectionEmailAndPhoneEnrich,
    onSaveSelected,
    onExportSelected,
    handleBulkEnrich,
  } = props;
  const [enrichPopupAnchor, setEnrichPopupAnchor] = useState(null);
  const [bulkPopupOpen, setBulkPopupOpen] = useState(null);
  const [isEmailIncluded, setEmailIncluded] = useState(true);
  const [isPhoneIncluded, setPhoneIncluded] = useState(true);
  const [contactsNumber, setContactsNumber] = useState(1);
  const [existingBulkListTitle, setExistingBulkListTitle] = useState(null);
  const [newBulkListTitle, setNewBulkListTitle] = useState(null);
  const [contactList, setContactList] = useState([]);
  const selectedData = selected.map((_id) =>
    tableData.find((item) => item.pdlId === _id)
  );
  const { getContactList } = useList();
  const getContactListRef = useRef();
  getContactListRef.current = getContactList;

  useEffect(() => {
    const fetchContactList = async () => {
      try {
        const response = await getContactListRef.current({
          listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
        });

        if (response?.data?.length > 0) {
          setContactList(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch contact list:", error);
      }
    };

    fetchContactList();
  }, []);

  return (
    <>
      <div className="w-full pb-3 mt-3 border-b border-stone-250">
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { sm: 4 },
          }}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-row items-center font-Outfit">
                <Checkbox
                  indeterminate={
                    numSelectedOnPage > 0 && numSelectedOnPage < totalRowsOnPage
                  }
                  checked={
                    totalRowsOnPage > 0 && numSelectedOnPage === totalRowsOnPage
                  }
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all contacts",
                  }}
                  sx={{
                    color: kompassColors.textLight,
                    "&.Mui-checked": {
                      color: kompassColors.blue,
                    },
                    "&.MuiCheckbox-indeterminate": {
                      color: kompassColors.blue,
                    },
                  }}
                />
              </div>
              <div className="flex flex-row flex-wrap gap-2 text-sm font-Outfit justify-items-center">
                <div
                  className={
                    "flex cursor-pointer flex-row gap-1 rounded-md border px-2 py-1 text-white" +
                    (numSelected > 0
                      ? " border-stone-950 bg-stone-950"
                      : " border-stone-350 bg-stone-350")
                  }
                  onClick={() => {
                    if (numSelected > 0) onExportSelected();
                  }}
                >
                  <div>Export</div>
                </div>
                <CRMPushMenu
                  selected={selectedData}
                  profileType={"profile"}
                  isPushedFromKompassSearch={true}
                ></CRMPushMenu>
                <div
                  className={
                    "flex cursor-pointer flex-row gap-1 rounded-md border bg-white px-2 py-1" +
                    (numSelected > 0
                      ? " border-stone-950 text-stone-950"
                      : " border-stone-350 text-stone-350 cursor-not-allowed pointer-events-none")
                  }
                  onClick={() => {
                    onSaveSelected();
                  }}
                >
                  <AddListIcon
                    className={
                      "h-5 w-5" +
                      (numSelected > 0
                        ? " fill-current text-stone-950"
                        : " fill-current text-stone-350 disabled:cursor-not-allowed")
                    }
                  />
                  <div>Save to list</div>
                </div>
                <div
                  className={
                    "flex cursor-pointer flex-row gap-2 rounded-md border bg-white px-2 py-1" +
                    (numSelected > 0
                      ? " border-stone-950 text-stone-950"
                      : " border-stone-350 text-stone-350")
                  }
                  onClick={(event) => {
                    if (numSelected > 0)
                      setEnrichPopupAnchor(event.currentTarget);
                  }}
                >
                  <LibraryAddIcon
                    className={
                      "mt-0.5 h-4 w-4" +
                      (numSelected > 0
                        ? " fill-current text-stone-950"
                        : " fill-current text-stone-350")
                    }
                  />
                  <div>Enrich</div>
                  <DownIcon
                    className={
                      "mt-1 h-3 w-3" +
                      (numSelected > 0
                        ? " stroke-current text-stone-950"
                        : " stroke-current text-stone-350")
                    }
                  />
                </div>
                <div
                  className={
                    "flex cursor-pointer flex-row gap-2 rounded-md border bg-white px-2 py-1" +
                    (tableData?.length && !numSelected > 0
                      ? " border-stone-950 text-stone-950"
                      : " border-stone-350 text-stone-350 pointer-events-none")
                  }
                  onClick={() => {
                    if (tableData?.length > 0) {
                      if (tableData?.length && !numSelected > 0) {
                        setBulkPopupOpen(true);
                      }
                    }
                  }}
                >
                  <LibraryAddIcon
                    className={
                      "mt-0.5 h-4 w-4" +
                      (tableData?.length && !numSelected > 0
                        ? " fill-current text-stone-950"
                        : " fill-current text-stone-350")
                    }
                  />
                  Bulk Enrich
                  <DownIcon
                    className={
                      "mt-1 h-3 w-3" +
                      (tableData?.length && !numSelected > 0
                        ? " stroke-current text-stone-950"
                        : " stroke-current text-stone-350")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="text-sm select-none font-OutfitMedium">
              {numSelected > 0
                ? `${numSelected.toLocaleString("en-US")} Selected / ${totalCount?.toLocaleString("en-US")} Records Found`
                : totalCount > 0
                  ? `${totalCount?.toLocaleString("en-US")} Records Found`
                  : null}
            </div>
          </div>
        </Toolbar>
      </div>
      <EnrichPopup
        anchorEl={enrichPopupAnchor}
        onClose={() => {
          setEnrichPopupAnchor(null);
        }}
        onPhoneEnrich={() => {
          setEnrichPopupAnchor(null);
          onSelectionPhoneEnrich();
        }}
        onEmailEnrich={() => {
          setEnrichPopupAnchor(null);
          onSelectionEmailEnrich();
        }}
        onEmailAndPhoneEnrich={() => {
          setEnrichPopupAnchor(null);
          onSelectionEmailAndPhoneEnrich();
        }}
      />
      <BulkPopup
        open={bulkPopupOpen}
        onClose={() => {
          setBulkPopupOpen(false);
        }}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <p className="font-medium text-left text-black font-Outfit text-md">
              Total Number Of Contacts
            </p>
            <TextField
              type="number"
              value={contactsNumber}
              onChange={(e) => {
                setContactsNumber(e.target.value);
              }}
              inputProps={{
                min: 1,
                max: 500,
                step: 1,
              }}
              helperText="Enter a number between 1 and 500"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                  fontSize: "13px",
                  fontFamily: "Outfit",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  fontSize: "16px",
                },
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-medium text-left text-black font-Outfit text-md">
              Save to Existing Contacts Lists
            </p>
            <FilterDropdown
              options={
                contactList
                  ? contactList.map((contact) => {
                      return {
                        label: contact.listTitle,
                        value: contact.listTitle,
                      };
                    })
                  : []
              }
              onChange={(value) => {
                setExistingBulkListTitle(value);
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-medium text-left text-black font-Outfit text-md">
              Create New Contacts List
            </p>
            <TextField
              type="text"
              value={newBulkListTitle}
              onChange={(e) => {
                setNewBulkListTitle(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                  fontSize: "13px",
                  fontFamily: "Outfit",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-input": {
                  fontSize: "16px",
                  padding: "14px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                },
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-medium text-left text-black font-Outfit text-md">
              Select Fields to Enrich
            </p>
            <div className="flex flex-row gap-4">
              <div className="flex flex-row items-center gap-1 font-Outfit text-md">
                <Checkbox
                  checked={isEmailIncluded}
                  onChange={(event) => {
                    setEmailIncluded(event.currentTarget.checked);
                  }}
                  className="w-5 h-5"
                  size="small"
                />
                Email
              </div>
              <div className="flex flex-row items-center gap-1 font-Outfit text-md">
                <Checkbox
                  checked={isPhoneIncluded}
                  onChange={(event) => {
                    setPhoneIncluded(event.currentTarget.checked);
                  }}
                  className="w-5 h-5"
                  size="small"
                />
                Phone
              </div>
            </div>
          </div>
          <p>
            You will not be charged for the contacts that you have already
            enriched.
          </p>
          <Button
            className="w-full px-2 py-2 my-6 text-base font-normal text-white capitalize rounded-md font-OutfitMedium bg-stone-950 2xl:px-2"
            onClick={() => {
              if (contactsNumber <= 0 || contactsNumber > 500) {
                toast.error(
                  "Number of contacts you want to enrich should be betweeen 1 and 500"
                );
                return;
              }
              if (!existingBulkListTitle && !newBulkListTitle) {
                toast.error(
                  "Please indicate name of the list you want to save data to"
                );
                return;
              }
              const isExistingListFound = contactList.some(
                (list) => list.listTitle === newBulkListTitle
              );
              if (isExistingListFound) {
                toast.error(
                  "You already have an existing list with the same name"
                );
                return;
              }

              setBulkPopupOpen(false);
              handleBulkEnrich(
                contactsNumber,
                { existingBulkListTitle, newBulkListTitle },
                { isEmailIncluded, isPhoneIncluded }
              );
            }}
          >
            Enrich
          </Button>
        </div>
      </BulkPopup>
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const ContactTableRow = (props) => {
  const { postEnrichContact } = useEnrichment();

  const {
    row,
    isItemSelected,
    labelId,
    handleClick,
    handleClickDetail,
    detailVisible,
    handleSaveToList,
    isVisibleNewList,
    setVisibleNewList,
    updateTableDataAfterEnrich,
  } = props;
  const [rowData, setRowdata] = useState(row);
  const [emailEnriching, setEmailEnriching] = useState(false);
  const [phoneEnriching, setPhoneEnriching] = useState(false);
  const [bothEnriching, setBothEnriching] = useState(false);
  const [deepEnriching, setDeepEnriching] = useState(false);
  const [emailEnriched, setEmailEnriched] = useState(false);
  const [phoneEnriched, setPhoneEnriched] = useState(false);
  const [deepEnriched, setDeepEnriched] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(undefined);

  const enrichContact = async (enrichType) => {
    try {
      handleClickDetail(rowData.pdlId);

      switch (enrichType) {
        case ENRICHMENT_TYPE.EMAIL_ONLY:
          setEmailEnriching(true);
          break;
        case ENRICHMENT_TYPE.PHONE_ONLY:
          setPhoneEnriching(true);
          break;
        case ENRICHMENT_TYPE.ALL:
          setBothEnriching(true);
          break;
        case ENRICHMENT_TYPE.DEEP_SEARCH:
          setDeepEnriching(true);
          break;
        default:
          break;
      }

      const enrichRequestParams = {
        inputs: {
          url: rowData?.linkedin_url || "",
          company_website: rowData?.job_company_website || "",
          company_name: rowData?.job_company_name || "",
          full_name: rowData.full_name || "",
          from: "kompass_search",
        },
      };

      const response = await postEnrichContact(enrichRequestParams, enrichType);

      if (response.status && response.data) {
        let newEmails = [];
        let newPhones = [];
        let isEmailFound;
        let isPhoneFound;
        let emailEnriched;
        let phoneEnriched;

        if (
          enrichType !== ENRICHMENT_TYPE.PHONE_ONLY &&
          response?.data?.emails
        ) {
          if (
            Object.values(response.data.emails).some(
              (array) => array.length > 0
            )
          ) {
            newEmails = response.data.emails;
            isEmailFound = true;
          } else {
            isEmailFound = false;
          }
          emailEnriched = true;
        }

        if (
          enrichType !== ENRICHMENT_TYPE.EMAIL_ONLY &&
          response?.data?.phones
        ) {
          if (response.data.phones.length > 0) {
            newPhones = response.data.phones;
            isPhoneFound = true;
          } else {
            isPhoneFound = false;
          }
          phoneEnriched = true;
        }

        const updatedRowData = {
          ...rowData,
          ...(Object.values(newEmails).some((array) => array.length > 0) && {
            workEmails: newEmails.workEmails ?? rowData.workEmails,
            personalEmails: newEmails.personalEmails ?? rowData.personalEmails,
            riskyEmails: newEmails.riskyEmails ?? rowData.riskyEmails,
            unknownEmails: newEmails.unknownEmails ?? rowData.unknownEmails,
            doNotEmails: newEmails.doNotEmails ?? rowData.doNotEmails,
            invalidEmails: newEmails.invalidEmails ?? rowData.invalidEmails,
          }),
          ...(newPhones.length > 0 && {
            validPhones: newPhones,
          }),
          ...(isEmailFound !== undefined && { isEmailFound }),
          ...(isPhoneFound !== undefined && { isPhoneFound }),
          ...(emailEnriched !== undefined && { emailEnriched }),
          ...(phoneEnriched !== undefined && { phoneEnriched }),
        };

        if (enrichType === ENRICHMENT_TYPE.DEEP_SEARCH) {
          setDeepEnriched(true);
        }

        return updatedRowData;
      } else {
        throw new Error(response?.message || "Something went wrong!");
      }
    } catch (error) {
      console.error("Enrichment error: ", error);
      toast.error(error.message, {
        theme: "colored",
      });
    } finally {
      switch (enrichType) {
        case ENRICHMENT_TYPE.EMAIL_ONLY:
          setEmailEnriching(false);
          break;
        case ENRICHMENT_TYPE.PHONE_ONLY:
          setPhoneEnriching(false);
          break;
        case ENRICHMENT_TYPE.ALL:
          setBothEnriching(false);
          break;
        case ENRICHMENT_TYPE.DEEP_SEARCH:
          setDeepEnriching(false);
          break;
        default:
          break;
      }
    }
  };

  const handleEnrichButtonClick = async (enrichType) => {
    const updatedRowData = await enrichContact(enrichType);
    updateTableDataAfterEnrich(updatedRowData);
  };

  const onSaveToList = async () => {
    if (
      rowData?.isEmailFound ||
      rowData?.isPhoneFound ||
      (rowData?.enrichedPhones && rowData?.enrichedPhones.length > 0) ||
      (rowData?.enrichedEmails && rowData?.enrichedEmails.length > 0)
    ) {
      handleSaveToList({ pdlId: rowData.pdlId });
    } else {
      toast.error("Only enriched contacts can be moved to list", {
        theme: "colored",
      });
    }
  };

  const memoizedRow = useMemo(() => ({ ...row }), [row]);

  useEffect(() => {
    if (!memoizedRow) return;

    const newRow = { ...memoizedRow };
    console.log("newRow: ", newRow);

    if (newRow?.isEmailFound) {
      newRow.enrichedEmails = mergeKompassEmails(newRow);
    }

    if (newRow?.isPhoneFound) {
      newRow.enrichedPhones = (newRow.validPhones || []).map((item) => ({
        phone_number: item.phoneNumber || item.phone_number,
        status: item.status,
      }));
    }

    setRowdata(newRow);

    setEmailEnriched(newRow?.emailEnriched ?? false);
    setPhoneEnriched(newRow?.phoneEnriched ?? false);
    setEmailEnriching(newRow?.emailEnriching ?? false);
    setPhoneEnriching(newRow?.phoneEnriching ?? false);
    setBothEnriching(newRow?.bothEnriching ?? false);
  }, [memoizedRow]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rowData.pdlId}
        selected={isItemSelected}
        sx={
          detailVisible
            ? {
                cursor: "pointer",
                background: kompassColors.bgLight,
                height: "96px",
                "& .MuiTableCell-root": {
                  padding: "5px 10px",
                  border: "none",
                  borderTop: 1,
                  borderColor: kompassColors.light,
                },
              }
            : {
                cursor: "pointer",
                background: "white",
                height: "96px",
                "& .MuiTableCell-root": {
                  padding: "5px 10px",
                  border: "none",
                  borderTop: 1,
                  borderColor: kompassColors.light,
                },
              }
        }
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            sx={{
              "&.Mui-checked": {
                color: kompassColors.blue,
              },
              "&.MuiCheckbox-indeterminate": {
                color: kompassColors.blue,
              },
            }}
            onClick={(event) => handleClick(event, rowData.pdlId)}
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          onClick={(event) => {
            handleClickDetail(event, rowData.pdlId);
          }}
        >
          <div className="flex flex-row items-center w-full gap-2">
            <div className="flex">
              {rowData?.profilePictureUrl ? (
                <img
                  src={rowData?.profilePictureUrl}
                  width={40}
                  height={40}
                  alt={rowData?.profilePictureUrl}
                />
              ) : (
                <div
                  className={
                    "text-md font-OutfitMedium flex h-10 w-10 items-center justify-center rounded-full" +
                    [
                      " bg-accents-yellow text-black",
                      " bg-accents-purple text-black",
                      " bg-accents-green text-black",
                      " bg-accents-blue text-black",
                      " bg-accents-orange text-black",
                    ][indexString(rowData.full_name, 5)]
                  }
                >
                  {getShortName(rowData.full_name)}
                </div>
              )}
            </div>
            <div className="flex flex-col w-full text-sm lg:max-w-[140px] xl:max-w-[240px] 2xl:max-w-[360px]">
              <p
                className="capitalize custom-text-ellipsis"
                title={rowData.full_name}
              >
                {rowData.full_name}
              </p>
              <p
                className="capitalize custom-text-ellipsis text-stone-350"
                title={rowData.job_title}
              >
                {rowData.job_title}
              </p>
            </div>
          </div>
        </TableCell>
        <TableCell
          padding="none"
          align="left"
          onClick={(event) => {
            handleClickDetail(event, rowData.pdlId);
          }}
        >
          <div className="flex flex-row items-center gap-2 text-sm">
            <div className="flex h-[40px] w-[40px] shrink-0">
              <img
                src={`https://logo.clearbit.com/${rowData?.job_company_website}`}
                alt="Logo"
                className="relative object-contain object-center"
                onLoad={() => setImageLoaded(true)}
                onError={() => setImageLoaded(false)}
                style={{
                  display: imageLoaded ? "block" : "none",
                }}
              />
              {imageLoaded === false && (
                <div
                  className={
                    "text-md font-OutfitMedium flex h-[40px] w-[40px] items-center justify-center rounded-full" +
                    [
                      " bg-accents-yellow text-black",
                      " bg-accents-purple text-black",
                      " bg-accents-green text-black",
                      " bg-accents-blue text-black",
                      " bg-accents-orange text-black",
                    ][indexString(rowData?.job_company_name, 5)]
                  }
                >
                  {rowData?.job_company_name
                    ? getShortName(rowData?.job_company_name)
                    : "-"}
                </div>
              )}
            </div>
            <p className="capitalize">{rowData?.job_company_name}</p>
          </div>
        </TableCell>
        <TableCell
          padding="none"
          align="left"
          onClick={(event) => {
            handleClickDetail(event, rowData.pdlId);
          }}
        >
          <p className="text-sm capitalize">{rowData?.location || "-"}</p>
        </TableCell>
        <TableCell padding="none" align="left">
          <div className="flex flex-row items-center justify-end w-full gap-1">
            <IconButton onClick={onSaveToList}>
              <AddListIcon fill={kompassColors.black} />
            </IconButton>
            <Button className="flex items-center justify-center px-4 py-1 text-sm bg-white border rounded-full font-Outfit border-stone-950">
              {!rowData?.enrichedPhones?.length &&
                !rowData?.enrichedEmails?.length && (
                  <LockIcon className="w-4 h-4 fill-current text-stone-950" />
                )}
              {(rowData?.enrichedPhones?.length > 0 ||
                rowData?.enrichedEmails?.length > 0) && (
                <OpenLockIcon className="w-4 h-4 fill-current text-stone-950" />
              )}
            </Button>
            <IconButton
              onClick={(event) => {
                handleClickDetail(event, rowData.pdlId);
              }}
            >
              {detailVisible ? (
                <DownIcon className="rotate-180 stroke-current text-stone-950" />
              ) : (
                <DownIcon className="stroke-current text-stone-950" />
              )}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      {detailVisible ? (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
            }}
            colSpan={10}
          >
            <div className="w-full grid grid-cols-12 grid-rows-[auto, auto] my-8 font-Outfit text-stone-950">
              <div className="flex w-full col-span-1 col-span-3 row-span-1 pt-4 pl-4 pr-4 border-r border-stone-400">
                <div className="flex items-center w-full h-full gap-4 pb-4 border-b border-stone-400">
                  <div className="flex w-10 h-10 shrink-0">
                    <img
                      src={`https://logo.clearbit.com/${rowData?.job_company_website}`}
                      alt="Logo"
                      className="relative object-contain object-center"
                      onLoad={() => setImageLoaded(true)}
                      onError={() => setImageLoaded(false)}
                      style={{
                        display: imageLoaded ? "block" : "none",
                      }}
                    />
                    {imageLoaded === false && (
                      <div
                        className={
                          "text-md font-OutfitMedium flex h-10 w-10 items-center justify-center rounded-full" +
                          [
                            " bg-accents-yellow text-black",
                            " bg-accents-purple text-black",
                            " bg-accents-green text-black",
                            " bg-accents-blue text-black",
                            " bg-accents-orange text-black",
                          ][indexString(rowData?.job_company_name, 5)]
                        }
                      >
                        {rowData?.job_company_name
                          ? getShortName(rowData?.job_company_name)
                          : "-"}
                      </div>
                    )}
                  </div>
                  <p className="capitalize">{rowData?.job_company_name}</p>
                </div>
              </div>
              <div className="w-full col-span-1 col-span-9 row-span-1 pt-4 pl-4 pr-4">
                <div className="flex flex-col justify-between w-full h-full gap-1 pb-4 border-b md:flex-row md:items-center border-stone-400">
                  <div className="flex flex-col text-sm w-full">
                    <div className="flex flex-row w-full">
                      <div className="capitalize font-OutfitMedium">
                        {rowData.full_name}
                      </div>
                      <div className="flex flex-row gap-2 pl-2 pt-0.5">
                        {typeof rowData.linkedin_url === "string" ? (
                          <Link
                            target="_blank"
                            to={"https://" + rowData.linkedin_url}
                          >
                            <ReactSVG
                              src={LinkedinIcon}
                              style={{ height: 13, width: 13 }}
                            />
                          </Link>
                        ) : null}
                        {typeof rowData.twitter_url === "string" ? (
                          <Link
                            target="_blank"
                            to={"https://" + rowData.twitter_url}
                          >
                            <ReactSVG
                              src={TwitterIcon}
                              style={{ height: 13, width: 13 }}
                            />
                          </Link>
                        ) : null}
                        {typeof rowData.facebook_url === "string" ? (
                          <Link
                            target="_blank"
                            to={"https://" + rowData.facebook_url}
                          >
                            <ReactSVG
                              src={FacebookIcon}
                              style={{ height: 13, width: 13 }}
                            />
                          </Link>
                        ) : null}
                      </div>
                    </div>
                    <div className="overflow-hidden lg:max-w-[140px] xl:max-w-[200px] 2xl:max-w-[260px]">
                      <p
                        className="text-[#929292] capitalize custom-text-ellipsis whitespace-nowrap"
                        title={rowData?.job_title || ""}
                      >
                        {rowData?.job_title || ""}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-full gap-1 md:justify-end">
                    <Button
                      className="px-4 py-2 text-xs text-white capitalize border-none rounded-md font-Outfit bg-stone-950 disabled:bg-gray-700"
                      onClick={() =>
                        handleEnrichButtonClick(ENRICHMENT_TYPE.EMAIL_ONLY)
                      }
                      disabled={
                        deepEnriching ||
                        emailEnriching ||
                        bothEnriching ||
                        phoneEnriching ||
                        deepEnriching ||
                        emailEnriched
                      }
                    >
                      Access email
                    </Button>
                    <Button
                      className="px-4 py-2 text-xs text-white capitalize border-none rounded-md font-Outfit bg-stone-950 disabled:bg-gray-700"
                      onClick={() =>
                        handleEnrichButtonClick(ENRICHMENT_TYPE.PHONE_ONLY)
                      }
                      disabled={
                        deepEnriching ||
                        phoneEnriching ||
                        bothEnriching ||
                        emailEnriching ||
                        deepEnriching ||
                        phoneEnriched
                      }
                    >
                      Access Phone
                    </Button>
                    <Button
                      className="px-4 py-2 text-xs text-white capitalize border-none rounded-md font-Outfit bg-stone-950 disabled:bg-gray-700"
                      onClick={() =>
                        handleEnrichButtonClick(ENRICHMENT_TYPE.ALL)
                      }
                      disabled={
                        deepEnriching ||
                        bothEnriching ||
                        emailEnriching ||
                        phoneEnriching ||
                        (emailEnriched && phoneEnriched)
                      }
                    >
                      All contact
                    </Button>
                    <Button
                      className="px-4 py-2 text-xs text-white capitalize border-none rounded-md font-Outfit bg-stone-950 disabled:bg-gray-700"
                      onClick={() =>
                        handleEnrichButtonClick(ENRICHMENT_TYPE.DEEP_SEARCH)
                      }
                      disabled={
                        deepEnriching ||
                        bothEnriching ||
                        emailEnriching ||
                        phoneEnriching ||
                        deepEnriched
                      }
                    >
                      AI Search
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col col-span-1 col-span-3 gap-2 p-4 border-r border-stone-400">
                <div className="mb-2 text-sm text-stone-350">
                  Company Details
                </div>
                {rowData?.job_company_name ? (
                  <div className="flex items-center gap-1 text-sm flex-nowrap">
                    <HomeIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium">Company</p>
                    <p
                      className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={rowData?.job_company_name}
                    >
                      {rowData?.job_company_name}
                    </p>
                  </div>
                ) : null}
                {rowData?.job_company_website || rowData?.job_company_name ? (
                  <div className="flex items-center gap-1 text-sm flex-nowrap">
                    <LinkIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium shrink-0">
                      Company website
                    </p>
                    <p
                      className="font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={
                        rowData?.job_company_website ||
                        rowData?.job_company_name
                      }
                    >
                      {rowData?.job_company_website ? (
                        <Link
                          target="_blank"
                          className="text-blue-500 capitalize"
                          to={
                            rowData?.job_company_website.startsWith("http")
                              ? rowData.job_company_website
                              : `http://${rowData.job_company_website}`
                          }
                        >
                          {rowData?.job_company_name}
                        </Link>
                      ) : (
                        <p>{rowData?.job_company_name}</p>
                      )}
                    </p>
                  </div>
                ) : null}
                {typeof rowData?.job_company_location_name === "string" ? (
                  <div className="flex items-center gap-1 text-sm flex-nowrap">
                    <LocationIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium">Location</p>
                    <p
                      className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={rowData?.job_company_location_name || ""}
                    >
                      {rowData?.job_company_location_name || ""}
                    </p>
                  </div>
                ) : null}

                {typeof rowData?.industry === "string" ? (
                  <div className="flex items-center gap-1 text-sm flex-nowrap">
                    <IndustryIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium">Industry</p>
                    <p
                      className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={rowData?.industry}
                    >
                      {rowData?.industry}
                    </p>
                  </div>
                ) : null}

                {rowData?.job_company_employee_count === "string?" ? (
                  <div className="flex gap-1 text-sm flex-nowrapitems-center">
                    <EmployeeIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium shrink-0">
                      Company head count
                    </p>
                    <p
                      className="font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={rowData?.job_company_employee_count}
                    >
                      {rowData?.job_company_employee_count}
                    </p>
                  </div>
                ) : null}
                {typeof rowData?.job_company_inferred_revenue === "string" ? (
                  <div className="flex items-center gap-1 text-sm flex-nowrap">
                    <USDIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium shrink-0 ">
                      Company revenue
                    </p>
                    <p
                      className="font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={rowData?.job_company_inferred_revenue}
                    >
                      {rowData?.job_company_inferred_revenue}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="flex w-full col-span-1 col-span-9 gap-4 p-4 md:gap-2">
                <div className="flex flex-col w-full gap-2 md:w-4/12">
                  <div className="mb-2 text-sm text-stone-350">
                    Contact Details
                  </div>
                  <div className="flex items-center gap-1 text-sm flex-nowrap">
                    <UserIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium">Name</p>
                    <p
                      className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={rowData.full_name}
                    >
                      {rowData.full_name}
                    </p>
                  </div>
                  {rowData?.job_title ? (
                    <div className="flex items-center gap-1 text-sm flex-nowrap">
                      <JobTitleIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                      <p className="font-OutfitMedium">Title</p>
                      <p
                        className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                        title={rowData?.job_title}
                      >
                        {rowData?.job_title}
                      </p>
                    </div>
                  ) : null}
                  {rowData?.job_title_role ? (
                    <div className="flex items-center gap-1 text-sm flex-nowrap">
                      <DepartmentIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                      <p className="font-OutfitMedium">Department</p>
                      <p
                        className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                        title={rowData?.job_title_role}
                      >
                        {rowData?.job_title_role}
                      </p>
                    </div>
                  ) : null}
                  {rowData?.job_title_levels &&
                  rowData.job_title_levels.length ? (
                    <div className="flex items-center gap-1 text-sm flex-nowrap">
                      <LevelIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                      <p className="font-OutfitMedium">Level</p>
                      <p
                        className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                        title={rowData.job_title_levels?.join(", ")}
                      >
                        {rowData.job_title_levels?.join(", ")}
                      </p>
                    </div>
                  ) : null}
                  <div className="flex items-center gap-1 text-sm flex-nowrap">
                    <LocationIcon className="w-3 h-3 fill-current text-stone-950 shrink-0" />
                    <p className="font-OutfitMedium">Location</p>
                    <p
                      className="capitalize font-OutfitLight shrink-0 custom-text-ellipsis"
                      title={rowData?.location || ""}
                    >
                      {rowData?.location || ""}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-2 md:w-4/12">
                  <div className="flex flex-row text-sm text-center text-stone-350">
                    {phoneEnriching || bothEnriching || deepEnriching ? (
                      <CircularProgress size={15} />
                    ) : null}
                    <p className="">Phone</p>
                  </div>
                  {rowData?.enrichedPhones?.length > 0 &&
                    rowData.enrichedPhones.map((item, key) => {
                      return (
                        <div
                          key={key}
                          className="flex flex-row items-center text-sm text-black"
                        >
                          {item.phone_number}
                          <p className="ml-1 font-Outfit opacity-80">
                            {item.status === "valid" ? (
                              <Chip
                                icon={<CheckCircleIcon />}
                                label="Direct dial"
                                color="success"
                                size="small"
                                style={{ fontSize: 12, fontFamily: "Outfit" }}
                              />
                            ) : null}
                            {item.status === "hq" ? (
                              <Chip
                                icon={<CheckCircleIcon />}
                                label="HQ"
                                color="primary"
                                size="small"
                                style={{ fontSize: 12, fontFamily: "Outfit" }}
                              />
                            ) : null}
                          </p>
                        </div>
                      );
                    })}
                  {rowData?.isPhoneFound === false && (
                    <div>Phone not available</div>
                  )}
                </div>
                <div className="flex flex-col w-full gap-2 md:w-4/12">
                  <div className="flex flex-row text-sm text-center text-stone-350">
                    {emailEnriching || bothEnriching || deepEnriching ? (
                      <CircularProgress size={15} />
                    ) : null}
                    <p className="">Email</p>
                  </div>
                  {rowData?.enrichedEmails?.length > 0 &&
                    rowData.enrichedEmails.map((item, key) => {
                      return (
                        <div
                          key={key}
                          className="flex flex-row items-center text-sm text-black"
                        >
                          {item.email}
                          <p className="ml-1 font-Outfit opacity-80">
                            {item.type === "Valid" ? (
                              <Chip
                                icon={<CheckCircleIcon />}
                                label="Valid"
                                color="success"
                                size="small"
                                style={{ fontSize: 12, fontFamily: "Outfit" }}
                              />
                            ) : null}
                            {item.type === "Personal" ? (
                              <Chip
                                icon={<CheckCircleIcon />}
                                label="Personal"
                                color="primary"
                                size="small"
                                style={{ fontSize: 12, fontFamily: "Outfit" }}
                              />
                            ) : null}
                            {item.type === "Risky" ? (
                              <Chip
                                icon={<WarningIcon />}
                                label="Risky"
                                color="warning"
                                size="small"
                                style={{ fontSize: 12, fontFamily: "Outfit" }}
                              />
                            ) : null}
                            {item.type === "Unknown" ? (
                              <Chip
                                icon={<WarningIcon />}
                                label="Unknown"
                                color="secondary"
                                size="small"
                                style={{ fontSize: 12, fontFamily: "Outfit" }}
                              />
                            ) : null}
                            {item.type === "Do not email" ? (
                              <Chip
                                icon={<CancelIcon />}
                                label="Do not email"
                                color="error"
                                size="small"
                                style={{ fontSize: 12, fontFamily: "Outfit" }}
                              />
                            ) : null}
                          </p>
                        </div>
                      );
                    })}
                  {rowData?.isEmailFound === false && (
                    <div>Email not available</div>
                  )}
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      ) : null}
      <CreateNewListDialog
        open={isVisibleNewList}
        close={() => {
          setVisibleNewList(false);
        }}
        onsave={() => handleSaveToList({ pdlId: rowData.pdlId })}
      />
    </>
  );
};

const submitDialogParams = {
  listData: null,
  contactList: null,
};

export default function ContactTable({
  filter,
  visible = true,
  selected,
  setSelected,
}) {
  const { postKompassSearchContactsByFilters, postSaveKompassSearchContacts } =
    useKompassSearch();
  const { planInfo } = useKompassFilters();
  const { bulkEnrichByLinkedinFetch } = useBulk();
  const { getContactList, createContactList } = useList();

  const dense = false;
  const rowsPerPage = 10;
  const [tableData, setTableData] = useState([]);
  const [cursorMark, setCursorMark] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [detailVisible, setDetailVisible] = useState([]);
  const [page, setPage] = useState(0);
  const [visibleRows, setVisibleRows] = useState([]);
  const [pushDialogOpened, handlePushDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleNewList, setVisibleNewList] = useState(false);
  const [scrollToken, setScrollToken] = useState("");
  const [isBulkLoading, setBulkLoading] = useState(false);
  const { postEnrichContact } = useEnrichment();

  const numSelectedOnPage = visibleRows.filter((row) =>
    selected.includes(row.pdlId)
  ).length;

  const menuOptions = [
    {
      icon: MoveGroupIcon,
      label: "Move to the group",
    },
    {
      icon: RemoveTeamIcon,
      label: "Remove team member",
    },
  ];

  const clearTable = () => {
    setVisibleRows([]);
    setTableData([]);
    setTotalCount(null);
    setCursorMark(null);
    setSelected([]);
  };

  const handleSearchByFilter = async (contactFilter, scrollToken = null) => {
    if (!contactFilter || Object.keys(contactFilter).length === 0) {
      clearTable();
      return;
    }

    contactFilter = {
      ...contactFilter,
      ...(extractLocationsFromStr([contactFilter?.location]) ?? {}),
    };
    delete contactFilter.location;

    let _tableData = tableData;

    if (scrollToken) {
      contactFilter["scroll_token"] = scrollToken;
    } else {
      setTableData([]);
      _tableData = [];
    }

    setIsLoading(true);
    try {
      console.log(contactFilter);
      // return;
      const _response = await postKompassSearchContactsByFilters(contactFilter);

      if (!_response.status && !_response?.response) {
        throw new Error(_response.message);
      }

      if (!_response.error) {
        const newTableData = _response.response.data.map((item) => {
          const newItem = {
            ...item,
            name: item?.full_name,
            companyName: item?.job_company_name,
            location: item?.location_name,
          };
          return newItem;
        });

        setTableData([..._tableData, ...newTableData]);
        setScrollToken(_response.response.scroll_token);
        setTotalCount(_response.response.total);

        setPage(0);
      } else {
        setTableData([..._tableData]);
      }
    } catch (e) {
      console.log({ e });
      if (e.message === "Credit balace can not be negative.") {
        toast.error(
          "For free plan, 10 searches available, please upgrade plan"
        );
      }
      setTableData([..._tableData]);
      setTotalCount(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelected([]);
    if (filter) {
      handleSearchByFilter({ ...filter });
    }
  }, [filter]);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const openMenu = () => {
    return menuAnchorEl !== null;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // Select all contacts on the current page
      const newSelected = [
        ...selected,
        ...visibleRows
          .map((n) => n.pdlId)
          .filter((id) => !selected.includes(id)),
      ];
      setSelected(newSelected);
    } else {
      // Deselect all contacts on the current page
      const newSelected = selected.filter(
        (id) => !visibleRows.some((n) => n.pdlId === id)
      );
      setSelected(newSelected);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClickDetail = (event, id) => {
    const detailIndex = detailVisible.indexOf(id);
    let newDetailVisible = [];

    if (detailIndex === -1) {
      newDetailVisible = newDetailVisible.concat(detailVisible, id);
    } else if (detailIndex === 0) {
      newDetailVisible = newDetailVisible.concat(detailVisible.slice(1));
    } else if (detailIndex === detailVisible.length - 1) {
      newDetailVisible = newDetailVisible.concat(detailVisible.slice(0, -1));
    } else if (detailIndex > 0) {
      newDetailVisible = newDetailVisible.concat(
        detailVisible.slice(0, detailIndex),
        detailVisible.slice(detailIndex + 1)
      );
    }

    setDetailVisible(newDetailVisible);
  };

  const handleFavorite = (index, bSelected, favoriteId = null) => {
    const newTableData = [...tableData];
    newTableData[page * rowsPerPage + index]["favoriteId"] = favoriteId;
    setTableData(newTableData);
  };

  const handleChangePage = async (event, newPage) => {
    if (planInfo?.name === "Free" && newPage === 2) {
      toast.info("Please get a paid plan to view additional pages on search.");
      return; // Prevent actual page change
    }

    if (
      newPage > Math.floor(tableData.length / rowsPerPage) &&
      totalCount > tableData.length
    ) {
      await handleSearchByFilter(filter, scrollToken);
    }

    setPage(newPage - 1);
    setDetailVisible([]);
  };

  const onSelectionEnrich = async (enrichType) => {
    let emailEnriching;
    let phoneEnriching;
    let bothEnriching;

    switch (enrichType) {
      case ENRICHMENT_TYPE.EMAIL_ONLY:
        emailEnriching = true;
        break;
      case ENRICHMENT_TYPE.PHONE_ONLY:
        phoneEnriching = true;
        break;
      case ENRICHMENT_TYPE.ALL:
        bothEnriching = true;
        break;
      default:
        break;
    }

    const updatedTableDataBeforeEnrich = tableData.map((row) => {
      const indexOfRow = selected.findIndex(
        (selectedId) => row.pdlId === selectedId
      );
      if (indexOfRow >= 0) {
        return { ...row, emailEnriching, phoneEnriching, bothEnriching };
      } else {
        return row;
      }
    });

    setTableData(updatedTableDataBeforeEnrich);

    switch (enrichType) {
      case ENRICHMENT_TYPE.EMAIL_ONLY:
        emailEnriching = false;
        break;
      case ENRICHMENT_TYPE.PHONE_ONLY:
        phoneEnriching = false;
        break;
      case ENRICHMENT_TYPE.ALL:
        bothEnriching = false;
        break;
      default:
        break;
    }

    try {
      const updatedTableDataAfterEnrich = await Promise.all(
        tableData.map(async (row) => {
          const indexOfRow = selected.findIndex(
            (selectedId) => row.pdlId === selectedId
          );
          if (indexOfRow >= 0) {
            const enrichRequestParams = {
              inputs: {
                url: row?.linkedin_url || "",
                company_website: row?.job_company_website || "",
                company_name: row?.job_company_name || "",
                full_name: row.full_name || "",
                from: "kompass_search",
              },
            };

            const response = await postEnrichContact(
              enrichRequestParams,
              enrichType
            );

            if (response.status && response.data) {
              let newEmails = [];
              let newPhones = [];
              let isEmailFound;
              let isPhoneFound;
              let emailEnriched;
              let phoneEnriched;

              if (
                enrichType !== ENRICHMENT_TYPE.PHONE_ONLY &&
                response?.data?.emails
              ) {
                if (
                  Object.values(response.data.emails).some(
                    (array) => array.length > 0
                  )
                ) {
                  newEmails = response.data.emails;
                  isEmailFound = true;
                } else {
                  isEmailFound = false;
                }
                emailEnriched = true;
              }

              if (
                enrichType !== ENRICHMENT_TYPE.EMAIL_ONLY &&
                response?.data?.phones
              ) {
                if (response.data.phones.length > 0) {
                  newPhones = response.data.phones;
                  isPhoneFound = true;
                } else {
                  isPhoneFound = false;
                }
                phoneEnriched = true;
              }

              const updatedRowData = {
                ...row,
                ...(Object.values(newEmails).some(
                  (array) => array.length > 0
                ) && {
                  workEmails: newEmails.workEmails ?? row.workEmails,
                  personalEmails:
                    newEmails.personalEmails ?? row.personalEmails,
                  riskyEmails: newEmails.riskyEmails ?? row.riskyEmails,
                  unknownEmails: newEmails.unknownEmails ?? row.unknownEmails,
                  doNotEmails: newEmails.doNotEmails ?? row.doNotEmails,
                  invalidEmails: newEmails.invalidEmails ?? row.invalidEmails,
                }),
                ...(newPhones.length > 0 && {
                  validPhones: newPhones,
                }),
                ...(isEmailFound !== undefined && { isEmailFound }),
                ...(isPhoneFound !== undefined && { isPhoneFound }),
                ...(emailEnriched !== undefined && { emailEnriched }),
                ...(phoneEnriched !== undefined && { phoneEnriched }),
                ...(emailEnriching !== undefined && { emailEnriching }),
                ...(phoneEnriching !== undefined && { phoneEnriching }),
                ...(bothEnriching !== undefined && { bothEnriching }),
              };

              return updatedRowData;
            } else {
              console.error("Enrichment error for row: ", row);
              toast.error(
                `Enrichment error for contact: ${row?.linkedin_url || row?.full_name || ""}`,
                {
                  theme: "colored",
                }
              );
              return { ...row, emailEnriching, phoneEnriching, bothEnriching };
            }
          } else {
            return row;
          }
        })
      );

      setTableData(updatedTableDataAfterEnrich);
    } catch (e) {
      console.log(e);
      toast.error(`Error during enrich: ${e?.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const isSelected = (pdlId) => selected.indexOf(pdlId) !== -1;
  const isDetailVisible = (pdlId) => detailVisible.indexOf(pdlId) !== -1;

  const visibleRowsMemo = useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const rows = tableData.slice(startIndex, endIndex);

    return stableSort(rows, getComparator(order, orderBy));
  }, [order, orderBy, page, rowsPerPage, tableData]);

  useEffect(() => {
    setVisibleRows(visibleRowsMemo);
  }, [visibleRowsMemo]);

  const saveToList = async (selectedPdlIds) => {
    console.log("tableData before save to list: ", tableData);
    const filteredTableData = tableData.filter((item) =>
      selectedPdlIds.includes(item.pdlId)
    );
    if (
      filteredTableData.some(
        (item) =>
          !item.hasOwnProperty("personalEmails") &&
          !item.hasOwnProperty("validPhones")
      )
    ) {
      toast.error("Only enriched records can be saved", { theme: "colored" });
      return;
    }

    submitDialogParams.listData = filteredTableData;

    if (!submitDialogParams.contactList) {
      setIsLoading(true);
      const response = await getContactList({
        listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
      });
      setIsLoading(false);

      if (!response.status) {
        toast.error(response.message, { theme: "colored" });
        return;
      }

      const contactList = [...response.data];

      if (contactList.length <= 0) {
        setVisibleNewList(true);
        return;
      }

      submitDialogParams.contactList = contactList;
    }

    handlePushDialog(true);
  };

  const onSaveSelected = async (params) => {
    const selectedPdlIds = params?.pdlId ? [params.pdlId] : selected;
    await saveToList(selectedPdlIds);
  };

  const onExportSelected = async () => {
    const selectedData = selected.map((_id) =>
      tableData.find((item) => item.pdlId === _id)
    );
    debugger;
    downloadKompassProfilesCSV(`export_${Date.now()}`, selectedData);
  };

  const updateTableDataAfterEnrich = async (newRowData) => {
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        row.pdlId === newRowData.pdlId ? { ...row, ...newRowData } : row
      )
    );
  };

  const handleBulkEnrich = async (bulkNumber, listTitles, enrichParams) => {
    setBulkLoading(true);
    let _scrollToken = scrollToken;
    let _tableData = tableData;
    let _totalCount = totalCount;
    let totalProfilesLoaded = tableData.length;
    let enrichType;
    const maxSize = 100; // Maximum profiles per PDL request
    let totalProfilesNeeded = bulkNumber > 500 ? 500 : bulkNumber;
    if (totalCount < totalProfilesNeeded) totalProfilesNeeded = totalCount;
    const { existingBulkListTitle, newBulkListTitle } = listTitles;
    const { isEmailIncluded, isPhoneIncluded } = enrichParams;

    if (isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.ALL;
    if (isEmailIncluded && !isPhoneIncluded)
      enrichType = ENRICHMENT_TYPE.EMAIL_ONLY;
    if (!isEmailIncluded && isPhoneIncluded)
      enrichType = ENRICHMENT_TYPE.PHONE_ONLY;

    try {
      // Continue fetching data until we've loaded enough profiles
      while (totalProfilesLoaded < totalProfilesNeeded) {
        // Calculate the size dynamically for each fetch
        const size = Math.max(
          maxSize,
          totalProfilesNeeded - totalProfilesLoaded
        );

        const contactFilter = {
          ...filter,
          ...(extractLocationsFromStr([filter?.location]) ?? {}),
          size,
        };
        delete contactFilter.location;

        if (_scrollToken) {
          contactFilter["scroll_token"] = _scrollToken;
        }

        const _response =
          await postKompassSearchContactsByFilters(contactFilter);

        if (_response?.response?.data?.length) {
          const newTableData = _response.response.data.map((item) => ({
            ...item,
            name: item?.full_name,
            companyName: item?.job_company_name,
            location: item?.location_name,
          }));

          _tableData = [..._tableData, ...newTableData];

          _scrollToken = _response.response.scroll_token;
          _totalCount = _response.response.total;

          // Update the totalProfilesLoaded with the newly fetched data count
          totalProfilesLoaded += newTableData.length;
        } else if (!_response.status) {
          throw new Error(_response.message);
        }
      }

      // Calculate the page number based on the number of profiles loaded
      const newPage = Math.max(0, Math.floor(totalProfilesLoaded / 10) - 1);
      setPage(newPage);

      setScrollToken(_scrollToken);
      setTotalCount(_totalCount);

      _tableData = stableSort(_tableData, getComparator(order, orderBy));

      const dataForBulkRequest = _tableData
        .slice(0, totalProfilesNeeded)
        .filter((profile) => {
          switch (enrichType) {
            case ENRICHMENT_TYPE.ALL:
              // Only include profiles that don't have both email and phone information
              return !(profile.isEmailFound && profile.isPhoneFound);
            case ENRICHMENT_TYPE.EMAIL_ONLY:
              // Only include profiles that don't have email information
              return !profile.isEmailFound;
            case ENRICHMENT_TYPE.PHONE_ONLY:
              // Only include profiles that don't have phone information
              return !profile.isPhoneFound;
            default:
              return true;
          }
        });

      if (dataForBulkRequest?.length > 0) {
        const bulkResponse = await bulkEnrichByLinkedinFetch({
          inputs: dataForBulkRequest?.map((data) => ({
            ...data,
            url: data.linkedin_url,
          })),
          enrichType,
        });

        if (bulkResponse?.status && bulkResponse?.data?.length > 0) {
          const enrichedData = bulkResponse?.data.flat();

          const mergedData = _tableData.map((profile) => {
            const match = enrichedData.find(
              (item) =>
                cleanLinkedUrl(profile?.linkedin_url || "") ===
                cleanLinkedUrl(item?.linkedinUrl || "")
            );
            if (match) {
              return {
                ...profile,
                ...(Object.values(match.emails).some(
                  (array) => array.length > 0
                ) && {
                  workEmails: match.emails.workEmails ?? [],
                  personalEmails: match.emails.personalEmails ?? [],
                  riskyEmails: match.emails.riskyEmails ?? [],
                  unknownEmails: match.emails.unknownEmails ?? [],
                  doNotEmails: match.emails.doNotEmails ?? [],
                  invalidEmails: match.emails.invalidEmails ?? [],
                  isEmailFound: true,
                }),
                ...(Object.values(match.emails).every(
                  (array) => array.length <= 0
                ) && {
                  isEmailFound: false,
                }),
                ...(match.phones.length > 0 && {
                  validPhones: match.phones,
                  isPhoneFound: true,
                }),
                ...(match.phones.length <= 0 && {
                  isPhoneFound: false,
                }),
              };
            }
            return profile;
          });
          console.log("mergedData: ", mergedData);

          _tableData = stableSort(mergedData, getComparator(order, orderBy));
          setTableData(_tableData);
        } else {
          setTableData(_tableData);
          toast.error(bulkResponse.message);
        }
      } else {
        setTableData(_tableData);
      }

      if (newBulkListTitle) {
        const createResult = await createContactList({
          listTitle: newBulkListTitle,
          listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
        });

        if (createResult.status) {
          toast.success(
            `"${newBulkListTitle}" Contact List has been created.`,
            {
              theme: "colored",
            }
          );
          const saveResult = await postSaveKompassSearchContacts({
            listTitle: newBulkListTitle,
            listData: _tableData.slice(0, totalProfilesNeeded),
          });

          if (saveResult.status) {
            toast.success(saveResult.message, { theme: "colored" });
          } else toast.error(saveResult.message, { theme: "colored" });
        } else toast.error(createResult.message, { theme: "colored" });
      }

      if (existingBulkListTitle) {
        console.log("existingBulkListTitle: ", newBulkListTitle);
        const result = await postSaveKompassSearchContacts({
          listTitle: existingBulkListTitle,
          listData: _tableData.slice(0, totalProfilesNeeded),
        });

        if (result.status) {
          toast.success(result.message, { theme: "colored" });
        } else toast.error(result.message, { theme: "colored" });
      }
    } catch (e) {
      toast.error(`Error during bulk enrich: ${e.message}`);
      setTableData(_tableData);
    } finally {
      setBulkLoading(false);
    }
  };

  const someConditionToDisablePage = (pageNumber) => {
    // For free users, disable all pages except page 2
    if (planInfo?.name === "Free") {
      return pageNumber !== 2;
    }
    return false; // No pages are disabled for non-free users
  };

  return (
    <>
      {(isLoading || isBulkLoading) && visible && (
        <div className="relative w-full main-loader-wrapper">
          <MainLoader isAllPage={false} />
        </div>
      )}
      {!isLoading && !isBulkLoading && (
        <>
          <Box sx={{ width: "100%" }} className={visible ? "" : "hidden"}>
            <EnhancedTableToolbar
              selected={selected}
              tableData={tableData}
              numSelected={selected.length}
              numSelectedOnPage={numSelectedOnPage}
              totalRowsOnPage={visibleRows.length}
              totalCount={totalCount}
              onSelectAllClick={handleSelectAllClick}
              rowCount={visibleRows.length}
              onSelectionPhoneEnrich={() =>
                onSelectionEnrich(ENRICHMENT_TYPE.PHONE_ONLY)
              }
              onSelectionEmailEnrich={() =>
                onSelectionEnrich(ENRICHMENT_TYPE.EMAIL_ONLY)
              }
              onSelectionEmailAndPhoneEnrich={() =>
                onSelectionEnrich(ENRICHMENT_TYPE.ALL)
              }
              onSaveSelected={onSaveSelected}
              onExportSelected={onExportSelected}
              handleBulkEnrich={handleBulkEnrich}
            />
            <TableContainer>
              <Table
                sx={{
                  width: "100%",
                  borderCollapse: "separate",
                }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                />
                <TableBody
                  sx={{
                    "& .MuiTableCell-sizeMedium": {
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: "18px",
                      paddingRight: "24px",
                      fontFamily: "Outfit",
                      fontSize: 14,
                    },
                  }}
                >
                  {visibleRows?.length > 0 ? (
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.pdlId);
                      const detailVisible = isDetailVisible(row.pdlId);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <ContactTableRow
                          key={index}
                          isItemSelected={isItemSelected}
                          row={row}
                          index={index}
                          labelId={labelId}
                          handleClick={handleClick}
                          handleClickDetail={handleClickDetail}
                          detailVisible={detailVisible}
                          handleFavorite={handleFavorite}
                          handleSaveToList={onSaveSelected}
                          isVisibleNewList={isVisibleNewList}
                          setVisibleNewList={setVisibleNewList}
                          updateTableDataAfterEnrich={
                            updateTableDataAfterEnrich
                          }
                        />
                      );
                    })
                  ) : (
                    <TableRow>
                      {!isLoading && (
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            border: "none",
                          }}
                          colSpan={10}
                        >
                          <div className="col-span-10 p-5 text-xl text-center font-Outfit">
                            <p>
                              No results
                              {filter !== null && (
                                <>
                                  , please try
                                  <Link
                                    target="_blank"
                                    to={`https://www.linkedin.com/search/results/people/?keywords=${filter?.hasOwnProperty("name") ? encodeURI(filter?.name[0]) : ""}${filter?.hasOwnProperty("companyName") ? `&compay=${encodeURI(filter?.companyName[0])}` : ""}${`&titleFreeText=${filter?.hasOwnProperty("title") ? encodeURI(filter?.title[0]) : ""}`}`}
                                    className="text-blue-500"
                                  >
                                    {" "}
                                    Linkedin
                                  </Link>
                                </>
                              )}
                            </p>
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Menu
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={menuAnchorEl}
              open={openMenu()}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  // width: "20ch",
                },
              }}
            >
              {menuOptions.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={handleMenuClose}
                >
                  <ListItemIcon>
                    <ReactSVG src={option.icon} />
                  </ListItemIcon>
                  <Typography variant="inherit">{option.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <div className="flex justify-center w-full py-4">
              <Pagination
                className="font-Outfit"
                disabled={!planInfo || planInfo?.name === "Free"}
                count={
                  Math.ceil(tableData.length / rowsPerPage) +
                  (totalCount > tableData.length ? 1 : 0)
                }
                page={page + 1}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-text": {
                    fontFamily: "Outfit",
                    fontSize: "14px",
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    disabled={someConditionToDisablePage(item.page)}
                    sx={{
                      "& .MuiPaginationItem-text": {
                        fontFamily: "Outfit",
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Box>

          <SaveToListDialog
            params={submitDialogParams}
            open={pushDialogOpened}
            close={() => {
              handlePushDialog(false);
            }}
          />
        </>
      )}
    </>
  );
}
