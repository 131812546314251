import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import * as React from "react";
import ListItem from "@mui/material/ListItem";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

export const SidebarItem = ({
  icon: Icon,
  label,
  open,
  router = "#",
  pathname,
  btnStyles = "",
  textStyles = "",
  id,
  onClick = () => {},
  isComingSoon = false,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    handleClose();
  }, [open]);

  const handleClose = () => {
    setIsTooltipOpen(false);
  };

  const handleOpen = () => {
    if (!open) {
      setIsTooltipOpen(true);
    } else if (open && isComingSoon) {
      setIsTooltipOpen(true);
    }
  };

  const isActivePath = pathname === router;

  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        "&:not(:last-child)": {
          marginBottom: "5px", // Example of a style applied to all but the last child
        },
      }}
    >
      <Tooltip
        title={`${open ? "" : label} ${isComingSoon ? "(coming soon)" : ""}`}
        placement="right"
        open={isTooltipOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <ListItemButton
          component={"a"}
          href={router}
          sx={{
            minHeight: 40,
            rowGap: open ? 0 : 2,
            columnGap: open ? 2 : 0,
            overflow: "hidden",
            justifyContent: "center",
            transition: "all 100ms cubic-bezier(0.1, 0.03, 0.4, 0.97)",
            backgroundColor: isActivePath
              ? "rgba(188, 188, 188, 0.26)"
              : "transparent",
            borderRadius: 2,
            "&:hover": {
              backgroundColor: "rgba(188, 188, 188, 0.26)",
              "& .mui-item-text, & .mui-icon": {
                color: "white",
              },
            },
          }}
          className={btnStyles}
          onClick={onClick}
          id={id}
        >
          <Icon
            className={`w-[24px] h-[24px] mui-icon shrink-0 inline-flex justify-center ${isActivePath ? "text-white" : "text-[#7E7E7E]"} transition-all`}
          ></Icon>
          <ListItemText
            className={`!font-Outfit mui-item-text !text-[20px] flex ${isActivePath ? "text-white" : "text-[#7E7E7E]"} ${textStyles}`}
            primaryTypographyProps={{
              style: {
                fontWeight: 400,
                font: "Outfit",
                fontSize: "14px",
                margin: 0,
              },
            }}
            primary={label}
            sx={{
              opacity: open ? 1 : 0,
              fontWeight: 500,
              margin: 0,
              transition: "all 150ms cubic-bezier(0.1, 0.03, 0.4, 0.97)",
            }}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};
