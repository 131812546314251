import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmailWarmupContext } from "../../../pages/main/EmailWarmupScene";
import { WarmupReport } from "@/components/email_warmup/email_account/WarmupReport";
import { WarmupSubHeader } from "@/components/email_warmup/WarmupSubHeader";
import { WarmupProfile } from "@/components/email_warmup/WarmupProfile";
import useAuth from "@/hooks/useAuth";
import { toast } from "react-toastify";
import MainLoader from "@/components/MainLoader";
import { WARMUP_SETTINGS_TITLES } from "@/utils/common";

export const EmailAccount = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const {
    emailAccount,
    setEmailAccount,
    allCampaigns,
    userInfo,
    setUserInfo,
    isMaximumEmailsUsed,
    setIsBuyWarmupAccountsModalOpen,
  } = useContext(EmailWarmupContext);

  const { getAuthToken } = useAuth();
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  // Fetch email account data based on campaignId if not already set
  useEffect(() => {
    const fetchEmailAccount = async () => {
      setIsDataLoading(true);
      // Ensure campaignId and emailAccount.id are of the same type
      const campaignIdNumber = parseInt(campaignId, 10);

      let account = allCampaigns.find(
        (c) => c.id === campaignId || c.id === campaignIdNumber
      );

      if (!account) {
        // If not found in allCampaigns, consider fetching from API
        try {
          const authToken = await getAuthToken();
          const res = await fetch(
            `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup/${campaignId}`,
            {
              method: "GET",
              headers: { Authorization: `Bearer ${authToken}` },
            }
          );

          if (res.ok) {
            const data = await res.json();
            account = data;
          } else {
            throw new Error("Email account not found");
          }
        } catch (error) {
          console.error(error);
          // Handle error (e.g., show an error message or redirect)
        }
      }

      setEmailAccount(account);
      setIsDataLoading(false);
    };

    fetchEmailAccount();
  }, [campaignId, setEmailAccount, allCampaigns]);

  useEffect(() => {
    if (
      isMaximumEmailsUsed &&
      allCampaigns > 1 &&
      emailAccount.status_code !== "paused"
    ) {
      handleStopWarmup();
    }
  }, []);

  const handleStartWarmup = async () => {
    if (isMaximumEmailsUsed) {
      setIsBuyWarmupAccountsModalOpen(true);
      return;
    }

    try {
      setIsStatusLoading(true);
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            campaign: { campaignId: emailAccount.id, action: "startWarmup" },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong, try again");
      }

      setEmailAccount((prev) => ({ ...prev, status_code: "active" }));
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setIsStatusLoading(false);
    }
  };

  const handleStopWarmup = async () => {
    try {
      setIsStatusLoading(true);
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            campaign: { campaignId: emailAccount.id, action: "pauseWarmup" },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong, try again");
      }
      setEmailAccount((prev) => ({ ...prev, status_code: "paused" }));
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setIsStatusLoading(false);
    }
  };

  const handleChangeStatusButtonClick = async () => {
    if (
      emailAccount.status_code === "paused" ||
      emailAccount.status_code === "pending"
    ) {
      await handleStartWarmup();
    } else if (emailAccount.status_code === "active") {
      await handleStopWarmup();
    }
  };

  // Conditional rendering after Hooks
  if (isDataLoading) {
    return <MainLoader />;
  }

  if (!emailAccount) {
    return <div>Email account not found.</div>;
  }

  return (
    <>
      <WarmupSubHeader
        title={WARMUP_SETTINGS_TITLES}
        activeTitle="Email Account"
        onClick={(label) => {
          if (label === "Email Settings") {
            navigate(`/email-warmup/settings/${campaignId}`);
          }
        }}
      />
      <div className="pt-[18px] 2xl:pt-[22px]">
        <WarmupProfile
          isStatusLoading={isStatusLoading}
          handleChangeStatusButtonClick={handleChangeStatusButtonClick}
          emailAccount={emailAccount}
        />
        <WarmupReport
          emailAccount={emailAccount}
          setEmailAccount={setEmailAccount}
        />
      </div>
    </>
  );
};
