import DataAnalyst from "@/components/csvexport/DataAnalyst";
import Product from "@/components/prospecting/Products";
import LandingLayout from "@/components/LandingLayout";
import SupportPanel from "@/components/landing/SupportPanel";
import WhyKompassAIPanel from "@/components/landing/WhyKompassAIPanel";
import TopBar from "@/components/landing/TopBar";
import ConnectPanel from "@/components/landing/ConnectPanel";

const topBarData = {
  tag: "CSV Enrich",
  title: "Get a holistic view of your customer",
  subTitle:
    "Our CSV enrichment ensures you know exactly whom you're prospecting so that you can personalize your outreach and maximize conversion.",
};

const description = {
  heading: "Why CSV Enrichment?",
  subHeading:
    "We believe in quality over quantity. When you turn to us, you know that your CRM will be populated with comprehensive and up-to-date data to ensure that your outreach is precise. We add over 30 different fields so that you can tailor your lead assignments and campaigns for maximum productivity.",
};

export default function CSVExportScene() {
  return (
    <LandingLayout pageName="csvexport" background="#9AB2FF">
      <div>
        <TopBar
          data={topBarData}
          background="bg-accents-blue"
          productName="csvexport"
        />
        <SupportPanel title={false} />
        <WhyKompassAIPanel data={description} />
        <DataAnalyst />
        <Product pageName="csvexport" />
        <ConnectPanel
          background="bg-accents-blue"
          accent="bg-accents-darkBlue"
        />
      </div>
    </LandingLayout>
  );
}
