import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";

export default ({ firstName, lastName, title, company }) => {
  const [initial, setInitial] = useState("");
  useEffect(() => {
    let name = firstName + " " + lastName;
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    setInitial(initials.toUpperCase());
  }, []);

  return (
    <div className="flex flex-row justify-start gap-5 w-full">
      <div className="flex items-center gap-5 2xl:gap-6 bg-white max-lg:rounded-[10px] rounded-[15px] box-shadow4 lg:box-Shadow3">
        <h1 className="p-4 font-[700] text-[50px]">{initial}</h1>
      </div>
      <div className="flex flex-col justify-center">
        <h1 className="font-[600] text-[25px]">{`${firstName} ${lastName}'s Email & Phone Number`}</h1>
        <p className="font-[500] text-[17px]">{`${title} at ${company.name}.`}</p>
        <Button className="mt-2 w-fit">{`View ${firstName}'s contact data for free`}</Button>
      </div>
    </div>
  );
};
