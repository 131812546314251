import React, { useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import { pink } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import LockIcon from "@/assets/image/search/lock.svg";
import PlusIcon from "@/assets/image/search/plus.svg";
import MinusIcon from "@/assets/image/search/minus.svg";
import ChevronDown from "@/assets/image/search/chevron-down.svg";
import ChevronUp from "@/assets/image/search/chevron-up.svg";
import BlueSwitch from "../team/BlueSwitch";

function AccordianComponent({
  title,
  icon,
  children,
  expand = false,
  isAddBorder = true,
  titleStyles = "",
  isSectionComponent = false,
  expandAll = false,
}) {
  const [expanded, setExpanded] = React.useState(expand);
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the first render
      isInitialRender.current = false;
    } else {
      // Run your effect code after the first render
      setExpanded(expandAll);
    }
  }, [expandAll]);

  return (
    <>
      <div
        className={classNames(
          "w-full flex flex-col py-[9px] 2xl:py-3 last:pb-0",
          {
            "border-b border-stone-250": isAddBorder,
          },
        )}
      >
        <div className="w-full flex flex-row justify-between items-center mb-0.5 2xl:mb-1">
          <p
            className={`flex flex-row gap-0.5 2xl:gap-1 items-center text-xs 2xl:text-sm font-OutfitMedium ${titleStyles}`}
          >
            {icon && (
              <ReactSVG
                src={icon}
                beforeInjection={svg => {
                  svg.setAttribute("style", "width: 18px");
                  svg.setAttribute("fill", "#090C05");
                }}
              />
            )}
            {title}
          </p>
          <IconButton
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? (
              <ReactSVG src={isSectionComponent ? ChevronDown : MinusIcon} />
            ) : (
              <ReactSVG src={isSectionComponent ? ChevronUp : PlusIcon} />
            )}
          </IconButton>
        </div>
        {expanded ? children : null}
      </div>
    </>
  );
}

function DataEnrichedComponent() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <div className="w-full flex flex-col border-b border-b-[#E8E7E7] px-2 pt-2 pb-3">
        <div className="w-full flex flex-row justify-between items-center">
          <p className="flex flex-row gap-1 items-center text-xs font-[600] font-Outfit">
            <ReactSVG
              src={LockIcon}
              beforeInjection={svg => {
                svg.setAttribute("style", "width: 14px");
                svg.setAttribute("fill", "#090C05");
              }}
            />
            {"Data Enriched"}
          </p>
          <BlueSwitch />
        </div>

        <div className="w-full flex flex-col gap-1">
          <div className="w-full flex flex-row items-center gap-1 font-Outfit text-sm">
            <Checkbox
              className="w-5 h-5"
              {...label}
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
              size="small"
            />
            Email
          </div>
          <div className="w-full flex flex-row items-center gap-1 font-Outfit text-sm">
            <Checkbox
              className="w-5 h-5"
              {...label}
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
              size="small"
            />
            Phone
          </div>
        </div>
      </div>
    </>
  );
}

export { AccordianComponent, DataEnrichedComponent };
