import React, { useMemo } from "react";
import { Affix } from "rsuite";
import { Button } from "@material-tailwind/react";
import { ReactComponent as CheckIcon } from "@/assets/image/icons/check.svg";
import cn from "classnames";

export default function PricingTable({
  tiers,
  annual,
  current,
  hideFreePlan,
  subscriptionInfo,
  products,
  planState,
  handleSelection,
  topPosition,
}) {
  const [selectedPlan] = planState;
  const container = React.useRef();
  const benefits = useMemo(() => {
    const benefitMap = new Map();
    const tiersKeys = Object.keys(tiers);
    for (let key in tiers) {
      const features =
        key === "free" && products
          ? products.find((prod) => prod.key === key)?.features
          : products.find((prod) => prod.key === key)?.additionalFeatures;
      features?.forEach((benefit) => {
        tiersKeys.forEach((tierKey) => {
          if (benefitMap.has(benefit)) {
            benefitMap.set(benefit, [...benefitMap.get(benefit), tierKey]);
            return;
          }
          benefitMap.set(benefit, [tierKey]);
        });
      });
      tiersKeys.shift();
    }
    let array = Array.from(benefitMap, ([key, value]) => ({ key, value }));
    return array;
  }, [tiers, products]);

  return (
    <div className="w-full mt-24 md:mt-40 flex flex-col hidden 3xs:block">
      <Affix
        top={topPosition || 0}
        container={() => {
          return container.current;
        }}
      >
        <div
          ref={container}
          className="flex w-full justify-between border-b border-black bg-white"
        >
          <div className="font-OutfitMedium mb-2 xl:mb-4 2xl:mb-6 mr-6 xl:mr-10 2xl:mr-20 flex-1 self-end text-base xs:text-md md:text-xl lg:text-2xl xl:text-3xl 2xl:text-[2.125rem] xl:leading-[2.55rem]">
            Get search & AI-driven insights on your company data
          </div>
          <div className="flex rounded-lg border border-b-0 border-black">
            {products.map((product) => {
              const price_obj =
                product.variations.length > 1
                  ? product.variations.find(
                      (x) => x.interval === (annual ? "year" : "month")
                    )
                  : product.variations[0];
              const selected = selectedPlan.planKey === product.key;
              const customizable =
                product.may_be_customized === 1 ? true : false;
              const current =
                subscriptionInfo?.plan?.toLowerCase() ===
                  product?.name?.toLowerCase() &&
                subscriptionInfo?.status !== "canceled";
              if (product.key === "free" && hideFreePlan) return <></>;
              return (
                <div
                  className={`${
                    tiers[product.key].theme.main
                  } flex h-full 2xs:w-[80px] xs:w-[104px] md:w-[143px] lg:w-[183px] xl:w-[243px] 2xl:w-[253px] flex-col items-center justify-center border-r-[1px] border-gray-950 p-2 md:p-4 xl:p-8`}
                >
                  <div className="font-OutfitSemiBold text-sm xs:text-md md:text-2xl lg:text-4xl">
                    {product.name}
                  </div>
                  <div className="font-Outfit flex items-end text-black">
                    <span className="font-Outfit mr-1 mt-2 text-sm md:text-md lg:text-2xl leading-[120%] text-black">
                      ${price_obj.price / 100}
                    </span>
                    <span className="font-OutfitBold text-xs md:text-sm">
                      {annual ? "/year" : "/month"}
                    </span>
                  </div>
                  {product.key === "free" ? (
                    <Button
                      disabled={true}
                      className={cn(
                        `text-xs xs:text-sm md:text-base lg:text-md font-Outfit mt-6 flex w-full items-center justify-center rounded-lg border-1 border-stone-950 bg-stone-950 py-2 xl:py-4 px-3 xl:px-6 font-bold uppercase text-white transition-all`,
                        {
                          "bg-white text-stone-950": true,
                        }
                      )}
                    >
                      Default
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleSelection(product, price_obj)}
                      disabled={current && !customizable}
                      className={cn(
                        `text-xs xs:text-sm md:text-base lg:text-md font-Outfit mt-6 flex w-full items-center justify-center rounded-lg border-1 border-stone-950 bg-stone-950 py-2 xl:py-4 px-3 xl:px-6 font-bold uppercase text-white transition-all`,
                        {
                          "bg-white text-stone-950": current || selected,
                        },
                        {
                          "!opacity-100": selected,
                        }
                      )}
                    >
                      {current && !selected
                        ? customizable
                          ? "Customize plan"
                          : "Current plan"
                        : ""}
                      {selected && "Selected"}
                      {!(current || selected) && "Select"}
                    </Button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Affix>
      {/* Benefits table */}
      <div ref={container} className="my-15 flex w-full flex-col ">
        {benefits.map(({ value, key }) => {
          return (
            <div
              key={key}
              className="grid w-full 2xs:grid-cols-[auto_repeat(4,80px)] xs:grid-cols-[auto_repeat(4,104px)] md:grid-cols-[auto_repeat(4,143px)] lg:grid-cols-[auto_repeat(4,183px)] xl:grid-cols-[auto_repeat(4,243px)] 2xl:grid-cols-[auto_repeat(4,253px)] gap-y-3 odd:bg-bluegrey-100"
            >
              <div className="mr-auto font-Outfit my-auto px-2 py-2 md:px-8 md:py-6 text-sm xs:text-base md:text-md xl:text-[1.5rem] text-stone-950">
                {key}
              </div>
              {Object.keys(tiers).map((tier) => {
                if (tier === "free" && hideFreePlan) return <span></span>;
                return (
                  <div className="flex items-center justify-center px-5 py-3">
                    {value.includes(tier) ? (
                      <CheckIcon width={36} height={36} />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
