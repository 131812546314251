import React from "react";
import { useNavigate } from "react-router-dom";

function SearchProspectButton({ level, department, filterProps }) {
  const navigate = useNavigate();

  const goToKompassSearch = () => {
    const companyFilter = [];
    const industryFilter = [];
    const sizeFilter = [];
    console.log(filterProps);
    filterProps.map((obj) => {
      if (obj.companyName) companyFilter.push(obj.companyName);
      if (obj.companyIndustry) industryFilter.push(obj.companyIndustry);
      if (obj.employees) {
        let size = obj.employees.replace(" to ", "-");
        sizeFilter.push(size);
      }
    });
    navigate("/search", {
      state: { level, department, companyFilter, industryFilter, sizeFilter },
    });
  };
  return (
    <button
      className="flex gap-2 justify-center items-center self-stretch px-6 py-2 my-auto leading-tight text-white capitalize rounded bg-stone-950 min-h-[40px] max-md:px-5"
      onClick={goToKompassSearch}
    >
      {/* <img
        loading="lazy"
        alt=""
        className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
      /> */}
      <span className="self-stretch my-auto">Search Prospects</span>
    </button>
  );
}

export default SearchProspectButton;
