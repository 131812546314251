import { useState } from "react";
import * as React from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { BASE_BUTTON_STYLES } from "@/utils/common";
import { CircularProgress } from "@mui/material";
import useAuth from "@/hooks/useAuth";

export const WarmupCheckout = ({
  handleDecrementEmailsBuy,
  emailsToBuy,
  handleIncrementEmailsBuy,
  pricePerOneAccount,
  isMaximumEmailsUsed,
}) => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { getAuthToken } = useAuth();
  const price = emailsToBuy * pricePerOneAccount;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    try {
      setIsLoading(true);
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(error.message);
      }

      const authToken = await getAuthToken();
      const requestBody = {
        currency: "usd",
        amount: price * 100,
        paymentMethodType: "card",
        quantity: emailsToBuy,
      };
      // Create the PaymentIntent and obtain clientSecret from your server endpoint
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/warmup-checkout`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const { clientSecret } = await res.json();

      const { error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret,
        confirmParams: {
          return_url: "http://localhost:3001/email-warmup",
        },
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (e) {
      console.log(e);
      toast.error("Something get wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex w-full flex-col gap-3">
      {step > 1 ? <PaymentElement /> : null}
      {step === 1 ? (
        <>
          <div className="w-full flex items-center justify-between">
            <div className="flex gap-2 items-center">
              <button
                className="quantity-button font-Outfit"
                type="button"
                onClick={handleDecrementEmailsBuy}
              >
                -
              </button>
              <p className="warmup-title-text !text-accents-blue2 text-right font-OutfitSemiBold">
                {emailsToBuy}
              </p>
              <button
                className="quantity-button font-Outfit"
                type="button"
                onClick={handleIncrementEmailsBuy}
              >
                +
              </button>
            </div>
            <p className="warmup-title-text !text-accents-blue2 text-right font-OutfitSemiBold">
              x {pricePerOneAccount}$
            </p>
          </div>
          <div className="flex w-full items-center justify-between">
            <p className="warmup-title-text font-OutfitBold">Total:</p>
            <p className="warmup-title-text font-OutfitBold">
              {emailsToBuy * pricePerOneAccount}$
            </p>
          </div>
          <Button
            sx={{
              ...BASE_BUTTON_STYLES,
              backgroundColor: "transparent",
              color: "#090C05",
              ":hover": {
                backgroundColor: "#090C05",
                color: "#fff",
              },
            }}
            type="button"
            onClick={() => setStep(2)}
          >
            Next
          </Button>
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-between">
            <p className="warmup-title-text font-OutfitBold">Total:</p>
            <p className="warmup-title-text font-OutfitBold">
              {emailsToBuy * pricePerOneAccount}$
            </p>
          </div>
          <Button
            disabled={!stripe || !elements || isLoading}
            sx={{
              ...BASE_BUTTON_STYLES,
              backgroundColor: "transparent",
              color: "#090C05",
              ":hover": {
                backgroundColor: "#090C05",
                color: "#fff",
              },
            }}
            type="submit"
          >
            {isLoading ? (
              <CircularProgress
                size="1.2rem"
                className="mr-2"
                style={{ color: "#090C05" }}
              />
            ) : null}
            Buy
          </Button>
        </>
      )}
    </form>
  );
};
