import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import { Link, NavLink } from "react-router-dom";
import { Records } from "@/components/landing/base/Records";
import {
  PerfectLead,
  PowerCompatibility,
} from "@/components/landing/base/PerfectLead";
import TrustedUser from "@/components/landing/base/TrustedUser";
import KompassAi from "@/components/landing/base/KompassAi";
import LandingLayout from "@/components/LandingLayout";
import Text from "@/components/Text";
import Companines from "./Companies";
import { ReactComponent as HighLight } from "@/assets/image/base/highlight.svg";
import Lottie from "react-lottie";
import mapAnimated from "@/assets/animations/map.json";
import mainArrowSvg from "@/assets/image/base/mainArrow.svg";
import heroBackground from "@/assets/image/bg-hero.jpg";
import googleSvg from "@/assets/image/icons/google.svg";
import g2Svg from "@/assets/image/icons/g2.svg";
import Introduction from "@/components/landing/Introduction";
import { goToTop } from "@/utils/common";
import { Rating } from "@material-tailwind/react";

const ColorChangingButton = () => {
  const colors = ["#F8e665", "#f1a9ff", "#4cd66e", "#9ab2ff", "#ff9665"];
  const [hoverCount, setHoverCount] = useState(0);

  const handleHover = () => {
    let ChangeButton = document.getElementById("changeButton");
    setHoverCount(hoverCount => (hoverCount + 1) % colors.length);
    ChangeButton.style.backgroundColor = `${colors[hoverCount]}`;
  };

  const handleOut = () => {
    let ChangeButton = document.getElementById("changeButton");
    ChangeButton.style.backgroundColor = `#4873FA`;
  };

  return (
    <Link to="/signup">
      <button
        id="changeButton"
        className="relative font-OutfitMedium flex items-center rounded-md bg-[#4873FA] p-3 2xl:p-4 !pr-[3rem] text-white "
        onMouseOver={handleHover}
        onMouseOut={handleOut}
      >
        <p>Try AI Search</p>
        <div
          id="firstIcon"
          className="flex items-center justify-center w-5 h-5"
        >
          <img src={mainArrowSvg} alt="RightArrowIcon" width={20} height={20} />
        </div>
        <div
          id="secondIcon"
          className="flex items-center justify-center w-5 h-5"
        >
          <img src={mainArrowSvg} alt="RightArrowIcon" width={20} height={20} />
        </div>
      </button>
    </Link>
  );
};

export default function LandingScene() {
  const [playAnimation, setPlayAnimation] = useState(false);

  const animationOptions = {
    loop: false,
    autoplay: false,
    animationData: mapAnimated,
  };

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector("#animated");
      if (!section) return; // Exit if section not found

      const rect = section.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Calculate the position of the section relative to the viewport
      const sectionTopInView = rect.top <= windowHeight && rect.bottom >= 0;

      if (sectionTopInView) {
        setPlayAnimation(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let quotes = document.getElementsByClassName("quotes");
    let quoteIndex = 0;
    if (quotes.length > 0) {
      let intervalId = setInterval(() => {
        let quote = quotes[quoteIndex % quotes.length];
        quote.classList.remove("hide");
        quote.classList.add("slideUpShow");

        setTimeout(() => {
          quote.classList.remove("slideUpShow");
          quote.classList.add("slideUpHide");
        }, 2500);
        quote.classList.remove("slideUpHide");
        quoteIndex++;
        if (quoteIndex === quotes.length) {
          quoteIndex = 0;
        }
      }, 3000); // Adjust the interval time as needed based on the transition time and delay

      return () => {
        clearInterval(intervalId); // Cleanup the interval on component unmount
      };
    }
  }, []);

  return (
    <div
      id="main"
      style={{
        backgroundImage: `url(${heroBackground})`,
        backgroundSize: "100% 100vh",
        backgroundRepeat: "no-repeat",
      }}
    >
      <LandingLayout>
        <div className="w-full overflow-x-hidden antialiased text-gray-600">
          <Box className="flex items-center justify-center sm:justify-start">
            <Box className="relative flex flex-col items-center justify-center w-full gap-16 pt-20 pb-20 mx-auto my-0 kompass-animation 2xl:pt-16 2xl:pb-32 sm:flex-row sm:gap-4 md:gap-8 lg:gap-12 xl:gap-32 2xl:gap-40">
              <Box className="relative flex flex-col items-center gap-0.5">
                <Box className="mt-4 xl:mt-6 h-fit">
                  <HighLight className="absolute 2xl:ml-32 xl:ml-28 lg:ml-24 md:ml-12 sm:-ml-8 -ml-5 -mt-3 w-[20px] h-[20px] xl:w-[24px] xl:h-[24px]" />
                  <h1 className="whitespace-nowrap text-center text-[26px] md:text-[36px] lg:text-[54px] xl:text-[60px] 2xl:text-[80px] leading-[1.1] font-bold text-stone-950">
                    The world's smartest
                    <span className="block">
                      revenue{" "}
                      <span className="text-[#4873FA] max-lg:block">
                        automation platform.
                      </span>
                    </span>
                  </h1>
                </Box>
                <Box className="mt-6 xl:mt-8 h-fit">
                  <p className="text-[14px] lg:text-[16px] text-center xl:text-[18px] 2xl:text-[24px] max-w-[320px] md:max-w-[420px] lg:max-w-[660px] xl:max-w-[860px] 2xl:max-w-[1016px] font-normal font-Outfit leading-[1.4] text-stone-950">
                    Join revenue teams using KompassAI to expand their
                    businesses across local and international markets. Get
                    started with optimizing your data coverage by tapping into
                    the world's largest pool of 800M+ contacts. Next, use AI to
                    engage with and convert clients at scale.
                  </p>
                </Box>
                <Box className="relative !bg-white w-full mt-8 lg:mt-12 2xl:mt-16 flex max-w-[306px] xs:max-w-[380px] lg:max-w-[480px] xl:max-w-[540px] items-center justify-between rounded-md border border-stone-950">
                  <Box className="ml-6 flex w-[140px] lg:w-[200px] flex-1 items-center justify-start">
                    <Typewriter
                      options={{
                        strings: [
                          "Elon Musk",
                          "Mark Zuckerberg",
                          "Warren Buffett",
                          "Jay Z",
                          "Stephen Curry",
                        ],
                        autoStart: true,
                        loop: true,
                        skipAddStyles: true,
                        wrapperClassName: "font-Outfit text-stone-350",
                      }}
                    />
                  </Box>
                  <Box className="p-1.5">
                    <ColorChangingButton />
                  </Box>
                </Box>
                <Box className="mt-10 xl:mt-8 h-fit">
                  <Box className="flex flex-col items-center gap-6 lg:gap-12 lg:flex-row">
                    <Box className="flex items-center justify-center gap-2">
                      <img
                        src={googleSvg}
                        alt="GoogleIcon"
                        width={40}
                        height={40}
                      />
                      <Box className="flex flex-col justify-start gap-0.5">
                        <p className="text-black/50 text-[13px] capitalize">
                          4.9 Stars
                        </p>
                        <Rating
                          value={5}
                          readonly
                          className="text-[#FCC153]"
                          unratedColor="yellow"
                          ratedColor="yellow"
                        />
                      </Box>
                    </Box>

                    {/* <Divider
                      orientation="vertical"
                      className="w-[1px] h-[27px] text-black opacity-20 lg:block hidden"
                    /> */}
                    <div className="border-l h-[27px] border-black opacity-20"></div>

                    {/* <Divider
                      orientation="horizontal"
                      className="w-[150px] h-[1px] text-black bg-black opacity-20 lg:hidden flex"
                    /> */}

                    <Box className="flex items-center justify-center gap-2">
                      <img src={g2Svg} alt="G2Icon" width={40} height={40} />
                      <Box className="flex flex-col justify-start gap-0.5">
                        <p className="text-black/50 text-[13px] capitalize">
                          5 Stars
                        </p>
                        <Rating
                          value={5}
                          readonly
                          className="text-[#FCC153]"
                          unratedColor="yellow"
                          ratedColor="yellow"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Introduction />
          {/* KOMPASSAI Component.....................................  */}
          <section className="pt-20 pb-12 lg:py-20">
            <KompassAi />
          </section>
          <div className="relative flex flex-col justify-center hidden gap-8 mb-24">
            <div className="flex flex-row justify-center px-6">
              <Text
                variant="SubHeader1"
                className="text-base font-normal text-center text-stone-950 md:text-xl 2xl:text-2xl font-Outfit leading-loos"
              >
                Join sales and recruiting professionals from startups to Fortune
                500 companies on our beta list!
              </Text>
            </div>
            <Companines />
          </div>
          <section className="relative">
            <div className="count-animation m-2 flex flex-col w-full gap-8 rounded-3xl bg-[#f6f6f6] py-12 px-10 xl:py-12 xl:px-16">
              <div className="flex flex-col gap-12 xl:gap-14">
                <div className="flex flex-col items-center gap-4">
                  <h2 className="max-xl:text-center main-subtitle">
                    We provide the most accurate data
                  </h2>
                  <p className="font-Outfit text-[22px] 2xl:text-[27px] max-xl:text-center leading-[1.2] text-black justify-left flex items-center">
                    AI-powered data models enable us to deliver unparalleled
                    data quality
                  </p>
                </div>
              </div>
              <div className="relative flex flex-col items-center justify-between w-full gap-12 lg:flex-row lg:items-end">
                <Records />
                <div id="animated" className="w-full lg:w-3/4">
                  <Lottie
                    options={animationOptions}
                    isStopped={!playAnimation}
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Perfect Leads Component.....................................  */}
          <Box className="relative">
            <PerfectLead />
            <PowerCompatibility />
          </Box>

          {/* Trusted Component */}
          <section className="pb-[120px] lg:pb-[144px]">
            <TrustedUser isShowDots={true} />
          </section>

          {/* Questions? Meet answers! Component */}
          <Box className="flex w-full flex-col items-center justify-center gap-12 rounded-t-[2.5rem] bg-accents-yellow px-8 py-24">
            <Box className="flex flex-col items-center justify-center">
              <p className="text-center main-title">
                Get started with KompassAI
              </p>
            </Box>
            <Box className="flex flex-row items-center justify-center gap-4 max-sm:flex-col">
              <NavLink
                to="/demo"
                onClick={goToTop}
                className="text-black bg-white border border-white kompassai-get-started-button bg-opacity-80 border-opacity-80"
              >
                Book a Demo
              </NavLink>
              <NavLink
                to="/signup"
                onClick={goToTop}
                className="text-white bg-black border border-black kompassai-get-started-button"
              >
                Sign up for free
              </NavLink>
            </Box>
          </Box>
        </div>
      </LandingLayout>
    </div>
  );
}
