import React, { useState, useRef, useEffect } from "react";
import useExportProfiles from "@/hooks/useExportProfiles";

function ExportsTable({ csvs }) {

    // const { getExportedProfiles } = useExportProfiles();
    // const getExportedProfilesRef = useRef();
    // getExportedProfilesRef.current = getExportedProfiles;
    // const [csvs, setCsvs] = useState([]);

    // Function to convert the array of objects to a CSV string
    function convertArrayToCSV(array) {
        const headers = Object.keys(array[0]).join(','); // Get headers
        const rows = array.map(obj => Object.values(obj).join(',')); // Get rows
        return [headers, ...rows].join('\n');
    }

    // Function to download CSV file
    async function downloadCSV(csvContent, filename) {
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // await createExportProfileRef.current({ filename, recordCount, csv_content: data, created_at: new Date() })
    }

    const startDownload = async (index) => {
        // console.log(dataToExport)
        if (csvs[index]?.csv_content && csvs[index].csv_content.length) {
            const csvContent = convertArrayToCSV(csvs[index]?.csv_content);
            await downloadCSV(csvContent, csvs[index].filename);
        }
    }

    // const fetchCSVData = async () => {
    //     const response = await getExportedProfilesRef.current();
    //     if (response) {
    //         response.profiles.sort((a, b) => b.created_at - a.created_at);
    //         setCsvs(response.profiles);
    //     }
    // }

    // useEffect(() => {
    //     fetchCSVData();
    // }, [csvs])
    return (
        <section className="rounded-lg border border-solid border-black border-opacity-10 min-h-[207px]">
            <section className="w-full p-2 text-lg font-medium leading-tight text-black border-b border-gray-400">
                Exports
            </section>
            <table className="w-full table-auto border-collapse">
                <thead>
                    <tr className="border-b">
                        <th className="p-2 text-left border-gray-300">
                            <div className="text-sm leading-tight text-black">File</div>
                        </th>
                        <th className="p-2 pl-32 text-left border-gray-300">
                            <section className="text-sm leading-tight text-black max-w-[293px]">
                                <h2>Record Count <span className="text-gray-700 text-xs">(Includes new and repeat visitors)</span></h2>
                            </section>
                        </th>
                        <th className="p-2 text-left border-gray-300">
                            <div className="text-sm leading-tight text-black">Created At</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {csvs && csvs.length > 0 && csvs.map((csv, index) => {
                        return <tr className="border-b">
                            <td className="p-2 cursor-pointer underline hover:text-blue-500" onClick={() => { startDownload(index) }}>{csv.filename}</td>
                            <td className="p-2  pl-32">{csv.record_count}</td>
                            <td className="p-2">{new Date(csv.created_at).toLocaleString()}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </section>

    );
}

export default ExportsTable;