import { ReactComponent as UserSVG } from "@/assets/image/base/user.svg";
import { ReactComponent as CompanySVG } from "@/assets/image/base/company.svg";
import { ReactComponent as FieldsSVG } from "@/assets/image/base/fields.svg";

const Records = () => {
  return (
    <div className="flex flex-col items-center xl:items-start xl:gap-8">
      <div className="statistic-count-container">
        <p className="statistic-count-title xl:font-OutfitBold">800 Mn+</p>
        <div className="statistic-count-description-container">
          <UserSVG className="statistic-count-icon" />
          <p className="statistic-count-description">Individual records</p>
        </div>
      </div>

      <div className="statistic-count-container">
        <p className="statistic-count-title xl:font-OutfitBold">20 Mn+</p>
        <div className="statistic-count-description-container">
          <CompanySVG className="statistic-count-icon" />
          <p className="statistic-count-description">Company records</p>
        </div>
      </div>

      <div className="statistic-count-container">
        <p className="statistic-count-title xl:font-OutfitBold">200 Mn+</p>
        <div className="statistic-count-description-container">
          <FieldsSVG className="statistic-count-icon" />
          <p className="statistic-count-description">Contact records</p>
        </div>
      </div>
    </div>
  );
};

export { Records };
