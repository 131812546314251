import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmailWarmupContext } from "../../../pages/main/EmailWarmupScene";
import { WarmupSubHeader } from "@/components/email_warmup/WarmupSubHeader";
import { WarmupProfile } from "@/components/email_warmup/WarmupProfile";
import { CircularProgress, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import MainLoader from "@/components/MainLoader";
import {
  SCHEDULE_PRESET,
  TIMEZONES,
} from "@/components/email_warmup/constants";
import { ReactComponent as DownChevron } from "@/assets/image/down-chevron.svg";
import useAuth from "@/hooks/useAuth";
import { toast } from "react-toastify";
import { WARMUP_SETTINGS_TITLES } from "@/utils/common";

export const WarmupSettings = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const {
    emailAccount,
    setEmailAccount,
    userInfo,
    setUserInfo,
    allCampaigns,
    isMaximumEmailsUsed,
    setIsBuyWarmupAccountsModalOpen,
  } = useContext(EmailWarmupContext);

  const { getAuthToken, getUserInfo } = useAuth();

  const [isScheduleLoading, setIsScheduleLoading] = useState(false);
  const [isWeekendsLoading, setIsWeekendsLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  // Fetch email account data based on campaignId if not already set
  useEffect(() => {
    const fetchEmailAccount = async () => {
      setIsDataLoading(true);
      const campaignIdNumber = parseInt(campaignId, 10);

      let account = allCampaigns.find(
        (c) => c.id === campaignId || c.id === campaignIdNumber
      );

      if (!account) {
        // Fetch from API if not found
        try {
          const authToken = await getAuthToken();
          const res = await fetch(
            `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup/${campaignId}`,
            {
              method: "GET",
              headers: { Authorization: `Bearer ${authToken}` },
            }
          );

          if (res.ok) {
            const data = await res.json();
            account = data;
          } else {
            throw new Error("Email account not found");
          }
        } catch (error) {
          console.error(error);
          // Handle error (e.g., show an error message or redirect)
        }
      }

      setEmailAccount(account);
      setIsDataLoading(false);
    };

    fetchEmailAccount();
  }, [campaignId, setEmailAccount, allCampaigns]);

  const defaultTimezoneValue = () => {
    if (!emailAccount || !emailAccount.timezone) {
      return null;
    }
    return TIMEZONES.find(
      ({ label }) => label.toLowerCase() === emailAccount.timezone.toLowerCase()
    );
  };

  const defaultPresetValue = () => {
    if (!userInfo || !userInfo.sending_schedule_preset) {
      return SCHEDULE_PRESET[0];
    }
    return (
      SCHEDULE_PRESET.find(
        ({ label }) =>
          label.toLowerCase() === userInfo.sending_schedule_preset.toLowerCase()
      ) || SCHEDULE_PRESET[0]
    );
  };

  const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  }));

  const autocompleteStyles = {
    width: "100%",
    maxWidth: "640px",
    borderColor: "transparent",
    borderRadius: "8px",
    pr: "8px",
    pl: "12px",
    backgroundColor: "#F9F9F9",
  };

  const handleStartWarmup = async () => {
    if (isMaximumEmailsUsed) {
      setIsBuyWarmupAccountsModalOpen(true);
    }

    try {
      setIsStatusLoading(true);
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            campaign: { campaignId: emailAccount.id, action: "startWarmup" },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong, try again");
      }

      setEmailAccount((prev) => ({ ...prev, status_code: "active" }));
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setIsStatusLoading(false);
    }
  };

  const handleStopWarmup = async () => {
    try {
      setIsStatusLoading(true);
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            campaign: { campaignId: emailAccount.id, action: "pauseWarmup" },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong, try again");
      }
      setEmailAccount((prev) => ({ ...prev, status_code: "paused" }));
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setIsStatusLoading(false);
    }
  };
  const handleUpdateSendingSchedule = async (field, value) => {
    if (!value) {
      return;
    }

    const isTimezoneField = field === "timezone";

    if (isTimezoneField) {
      setIsScheduleLoading(true);
    } else {
      setIsWeekendsLoading(true);
    }
    try {
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            campaign: { campaignId: emailAccount.id, [field]: value.label },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong, try again");
      }

      const { data } = await res.json();

      setEmailAccount((prev) => ({ ...prev, ...data }));
      const { userInfo } = await getUserInfo();
      setUserInfo(userInfo);
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      if (isTimezoneField) {
        setIsScheduleLoading(false);
      } else {
        setIsWeekendsLoading(false);
      }
    }
  };

  const handleChangeStatusButtonClick = async () => {
    if (
      emailAccount.status_code === "paused" ||
      emailAccount.status_code === "pending"
    ) {
      await handleStartWarmup();
    } else if (emailAccount.status_code === "active") {
      await handleStopWarmup();
    }
  };

  if (isDataLoading) {
    return <MainLoader />;
  }

  if (!emailAccount) {
    return <div>Email account not found.</div>;
  }

  return (
    <>
      <WarmupSubHeader
        title={WARMUP_SETTINGS_TITLES}
        activeTitle="Email Settings"
        onClick={(label) => {
          if (label === "Email Account") {
            navigate(`/email-warmup/email/${campaignId}`);
          }
        }}
      />
      <div className="pt-[18px] 2xl:pt-[22px]">
        <WarmupProfile
          emailAccount={emailAccount}
          isStatusLoading={isStatusLoading}
          handleChangeStatusButtonClick={handleChangeStatusButtonClick}
        />
        <section className="mt-[38px] 2xl:mt-[50px]">
          <div className="p-[20px] 2xl:p-[24px] border border-stone-250 rounded-[8px] grid grid-cols-2 gap-3">
            <div>
              <p className="warmup-time-setup-title">Timezone</p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={TIMEZONES}
                popupIcon={<DownChevron />}
                sx={autocompleteStyles}
                disabled={isScheduleLoading}
                loading={isScheduleLoading}
                defaultValue={defaultTimezoneValue()}
                onChange={async (event, value) => {
                  await handleUpdateSendingSchedule("timezone", value);
                }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isScheduleLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div>
              <p className="warmup-time-setup-title">Sending schedule preset</p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={SCHEDULE_PRESET}
                loading={isWeekendsLoading}
                disabled={isWeekendsLoading}
                popupIcon={<DownChevron />}
                sx={autocompleteStyles}
                defaultValue={defaultPresetValue()}
                onChange={async (event, value) => {
                  await handleUpdateSendingSchedule(
                    "sending_schedule_preset",
                    value
                  );
                }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isWeekendsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <p className="font-Outfit font-normal leading-[1.4] text-[#0D0D0D] text-[14px] mt-[20px] 2xl:text-[16px] 2xl:mt-[24px">
            This is the default schedule that will be used for sending emails if
            no specific times defined in the email campaign settings.
          </p>
        </section>
      </div>
    </>
  );
};
