import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { EmailWarmupContext } from "../../../pages/main/EmailWarmupScene";
import useAuth from "@/hooks/useAuth";
import * as Yup from "yup";
import { WarmupCount } from "@/components/email_warmup/summary/WarmupCount";
import { SearchBar } from "@/components/email_warmup/summary/SearchBar";
import { EmailWarmupTable } from "@/components/email_warmup/summary/EmailWarmupTable";
import { WarmupSubHeader } from "@/components/email_warmup/WarmupSubHeader";
import { WarmupModal } from "@/components/email_warmup/summary/WarmupModal";
import { CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import { BaseInputProps } from "@/theme/typography";
import { ADD_EMAIL_WARMUP_INPUTS, BASE_BUTTON_STYLES } from "@/utils/common";
import { ReactComponent as LinkIcon } from "@/../src/assets/image/link-icon.svg";
import { ReactComponent as RemoveIcon } from "@/../src/assets/image/remove-icon.svg";
import { toast } from "react-toastify";
import { DeleteConfirmationWindow } from "@/components/email_warmup/summary/DeleteConfirmationWindow";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { WarmupCheckout } from "@/components/email_warmup/WarmupCheckout";
import { loadStripe } from "@stripe/stripe-js";
import Button from "@mui/material/Button";

const pricePerOneAccount = 3;

export const WarmupSummary = () => {
  const navigate = useNavigate();
  const {
    setEmailAccount,
    allCampaigns,
    setAllCampaigns,
    setFetchTrigger,
    maximumEmails,
    isMaximumEmailsUsed,
    isBuyWarmupAccountsModalOpen,
    setIsBuyWarmupAccountsModalOpen,
  } = useContext(EmailWarmupContext);

  const { getAuthToken } = useAuth();
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [isAddNewAccountLoading, setIsAddNewAccountLoading] = useState(false);
  const [isDeleteAccountLoading, setIsDeleteAccountLoading] = useState(false);
  const [sortedCampaigns, setSortedCampaigns] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [sortBy, setSortBy] = useState("newest");
  const [emailsToBuy, setEmailsToBuy] = useState(1);
  const [addAccountStep, setAddAccountStep] = useState(1);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isOutLookLinkLoading, setIsOutLookLinkLoading] = useState(false);
  const [isAddNewAccountModalOpen, setIsAddNewAccountModalOpen] =
    useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);

  const [deleteConfirmationWindow, setDeleteConfirmationOpened] =
    useState(null);
  const [embedLink, setEmbedLink] = useState(null);

  useEffect(() => {
    if (isMaximumEmailsUsed && allCampaigns.length && !maximumEmails) {
      setEmailsToBuy(allCampaigns.length);
    }
  }, []);

  useEffect(() => {
    const getEmbedLink = async () => {
      // const res =
    };
  }, [selectedProvider, addAccountStep]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "First name should be at least 2 characters")
      .required("First name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    appPassword: Yup.string().required("App password is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      appPassword: "",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        setIsAddNewAccountLoading(true);
        const authToken = await getAuthToken();

        const res = await fetch(
          `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              campaign: {
                provider: "gmail",
                first_name: values.firstName,
                ...(values.lastName ? { last_name: values.lastName } : {}),
                email: values.email,
                app_password: values.appPassword,
              },
            }),
          }
        );

        if (!res.ok) {
          throw new Error("Something get wrong, check all your info");
        }

        setFetchTrigger((prev) => prev + 1);
        setIsAddNewAccountModalOpen(false);
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsAddNewAccountLoading(false);
      }
    },
  });

  const sortByNewestCreated = (data) => {
    return data
      .slice()
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  };

  const sortByOldestCreated = (data) => {
    return data
      .slice()
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  };

  const sortByLatestUpdated = (data) => {
    return data
      .slice()
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  };

  useEffect(() => {
    let dataToSort = isFiltered ? filteredCampaigns : allCampaigns;

    // Apply search filtering
    if (searchTerm) {
      const regex = new RegExp(searchTerm, "i"); // Case-insensitive regex
      dataToSort = dataToSort.filter((campaign) => regex.test(campaign.email));
    }

    let sortedData;
    if (sortBy === "newest") {
      sortedData = sortByNewestCreated(dataToSort);
    } else if (sortBy === "oldest") {
      sortedData = sortByOldestCreated(dataToSort);
    } else if (sortBy === "updated") {
      sortedData = sortByLatestUpdated(dataToSort);
    }

    setSortedCampaigns(sortedData);
  }, [allCampaigns, filteredCampaigns, isFiltered, sortBy, searchTerm]);

  const handleFilterEmails = (filterBy) => {
    let filtered;
    if (filterBy === "all") {
      filtered = allCampaigns;
      setIsFiltered(false);
    } else if (filterBy === "active") {
      filtered = allCampaigns.filter(
        (campaign) => campaign.status_code === "active"
      );
      setIsFiltered(true);
    } else if (filterBy === "paused") {
      filtered = allCampaigns.filter(
        (campaign) => campaign.status_code === "paused"
      );
      setIsFiltered(true);
    }
    setFilteredCampaigns(filtered);
  };

  const handleSortEmails = (sortOption) => {
    setSortBy(sortOption);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const campaignsMetrics = allCampaigns.reduce(
    (acc, record) => {
      acc.emailsPerDayLimit += record.email_per_day;
      acc.emailsSentToday += record.outreach_today;

      return acc;
    },
    {
      emailsPerDayLimit: 0,
      emailsSentToday: 0,
    }
  );

  const metrics = [
    {
      count: `${allCampaigns.length.toString()} / ${maximumEmails}`,
      title: "Email accounts",
    },
    {
      count: "0",
      title: "Email Accounts Need Attentions",
      icon: LinkIcon,
    },
    {
      count: campaignsMetrics.emailsSentToday.toString(),
      maxCount: campaignsMetrics.emailsPerDayLimit.toString(),
      title: "Emails Per Day Limit",
    },
    {
      count: campaignsMetrics.emailsSentToday.toString(),
      title: "Emails Sent Today",
    },
  ];

  const handleDeleteCampaign = async (id) => {
    try {
      setIsDeleteAccountLoading(true);
      const authToken = await getAuthToken();

      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: { Authorization: `Bearer ${authToken}` },
          body: JSON.stringify({
            campaign: { campaignId: id, action: "deleteCampaign" },
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Something get wrong try again");
      }
      setAllCampaigns((prev) => prev.filter((campaign) => campaign.id !== id));
      setIsDeleteAccountModalOpen(false);
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setIsDeleteAccountLoading(false);
    }
  };

  const handleIncrementEmailsBuy = () => {
    setEmailsToBuy((prev) => prev + 1);
  };

  const handleDecrementEmailsBuy = () => {
    if (emailsToBuy > 1) setEmailsToBuy((prev) => prev - 1);
  };

  const handleRedirectToEmbedLink = async () => {
    try {
      setIsOutLookLinkLoading(true);
      const authToken = await getAuthToken();
      const res = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`,
        {
          method: "PATCH",
          headers: { Authorization: `Bearer ${authToken}` },
          body: JSON.stringify({
            campaign: { action: "getEmbedLink" },
          }),
        }
      );
      if (!res.ok) {
        throw new Error("Something get wrong try again");
      }

      const { data } = await res.json();

      setEmbedLink(data.url);
      setAddAccountStep(2);
    } catch (e) {
      console.log(e.message);
      toast.error(e.message || "Something get wrong, try again");
    } finally {
      setIsOutLookLinkLoading(false);
    }
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  const options = {
    mode: "payment",
    amount: emailsToBuy * pricePerOneAccount * 100,
    currency: "usd",
  };

  const isSecondStep = addAccountStep === 2;

  const handleNavigateToEmailAccount = (emailAccount) => {
    setEmailAccount(emailAccount);
    navigate(`email/${emailAccount.id}`);
  };

  return (
    <>
      <WarmupSubHeader title="Summary" />
      <div className="pt-[20px]">
        <div className="py-[38px] w-full bg-stone-150 flex items-center justify-center gap-10 border-b border-[#DDD]">
          {metrics.map(({ title, maxCount, count, icon }) => (
            <WarmupCount
              title={title}
              count={count}
              maxCount={maxCount}
              icon={icon}
            />
          ))}
        </div>
      </div>
      <div className="pt-[30px]">
        <SearchBar
          handleOpenAddNewAccount={() => setIsAddNewAccountModalOpen(true)}
          handleOpenDeleteAccount={() => setIsDeleteAccountModalOpen(true)}
          handleOpenBuyWarmups={() => setIsBuyWarmupAccountsModalOpen(true)}
          handleFilterEmails={handleFilterEmails}
          handleSortEmails={handleSortEmails}
          handleSearch={handleSearch}
          isMaximumEmailsUsed={isMaximumEmailsUsed}
        />
      </div>
      <div className="pt-[25px]">
        <EmailWarmupTable campaigns={sortedCampaigns} />
      </div>
      <WarmupModal
        onSave={formik.handleSubmit}
        open={isAddNewAccountModalOpen}
        onClose={() => {
          setIsAddNewAccountModalOpen(false);
          setAddAccountStep(1);
          setEmbedLink(null);
        }}
        isLoading={isAddNewAccountLoading}
        disabled={Boolean(Object.keys(formik.errors).length)}
        buttonLabel={isSecondStep && !embedLink ? "Add account" : ""}
        title={isSecondStep ? "Add new account" : "Choose your email provider"}
      >
        {!isSecondStep ? (
          <div>
            <Button
              disabled={isOutLookLinkLoading}
              type="button"
              sx={{
                ...BASE_BUTTON_STYLES,
                backgroundColor: "transparent",
                color: "#090C05",
                ":hover": {
                  backgroundColor: "#090C05",
                  color: "#fff",
                },
              }}
              onClick={async () => {
                setSelectedProvider("outlook");
                await handleRedirectToEmbedLink();
              }}
            >
              {isOutLookLinkLoading ? (
                <CircularProgress
                  size="1.2rem"
                  className="mr-2"
                  style={{ color: "#fff" }}
                />
              ) : null}
              Outlook
            </Button>{" "}
            <Button
              type="button"
              disabled={isOutLookLinkLoading}
              sx={{
                ...BASE_BUTTON_STYLES,
                backgroundColor: "transparent",
                color: "#090C05",
                ":hover": {
                  backgroundColor: "#090C05",
                  color: "#fff",
                },
              }}
              onClick={() => {
                setAddAccountStep(2);
                setSelectedProvider("gmail");
              }}
            >
              Gmail
            </Button>
          </div>
        ) : null}
        {isSecondStep && !embedLink ? (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
            {ADD_EMAIL_WARMUP_INPUTS.map(({ label, name, placeholder }) => (
              <>
                <label
                  className="font-Outfit text-md p-1 text-left text-black w-full"
                  htmlFor={name}
                >
                  {label}
                </label>
                <div className="mt-1 min-w-[400px] w-full relative">
                  <TextField
                    name={name}
                    id={name}
                    className="w-full"
                    placeholder={placeholder}
                    value={formik[name]}
                    onChange={formik.handleChange}
                    InputProps={BaseInputProps}
                  />
                  {formik.errors[name] ? (
                    <p className="absolute -bottom-4 font-Outfit text-[12px] text-red-600">
                      {formik.errors[name]}
                    </p>
                  ) : null}
                </div>
              </>
            ))}
          </form>
        ) : null}
        {isSecondStep && embedLink ? (
          <iframe src={embedLink} height="500" width="400"></iframe>
        ) : null}
      </WarmupModal>
      <WarmupModal
        onSave={() => {}}
        open={isDeleteAccountModalOpen}
        onClose={() => {
          setIsDeleteAccountModalOpen(false);
        }}
        isLoading={false}
        buttonLabel={"Delete account"}
      >
        {allCampaigns.map((campaign) => (
          <div className="flex justify-between items-center w-full">
            <p className="font-Outfit text-black text-[14px] font-normal leading-[1.4]">
              {campaign.email}
            </p>
            <button
              onClick={() => {
                setDeleteConfirmationOpened(campaign);
              }}
            >
              <RemoveIcon className="w-[24px] h-[24px]" />
            </button>
            <DeleteConfirmationWindow
              open={deleteConfirmationWindow?.id === campaign.id}
              onClose={() => setDeleteConfirmationOpened(null)}
              isLoading={isDeleteAccountLoading}
              onSubmit={async () => {
                await handleDeleteCampaign(campaign.id);
              }}
            />
          </div>
        ))}
      </WarmupModal>
      <WarmupModal
        onSave={() => {}}
        open={isBuyWarmupAccountsModalOpen}
        onClose={() => {
          setIsBuyWarmupAccountsModalOpen(false);
        }}
        isLoading={isAddNewAccountLoading}
      >
        <Elements stripe={stripePromise} options={options}>
          <WarmupCheckout
            handleDecrementEmailsBuy={handleDecrementEmailsBuy}
            emailsToBuy={emailsToBuy}
            handleIncrementEmailsBuy={handleIncrementEmailsBuy}
            pricePerOneAccount={pricePerOneAccount}
          />
        </Elements>
      </WarmupModal>
    </>
  );
};
