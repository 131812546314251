import React from "react";
import { Controller } from "react-hook-form";
import { Switch, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 39,
  height: 22,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 21,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(17px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgb(59 130 246)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 17,
    height: 17,
    borderRadius: 12,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function CustomSwitch({ control, label, name, tooltip }) {
  return (
    <div className="border-b-1 border-stone-250 justify-between px-4 pr-0 py-2 font-OutfitMedium text-base flex w-full font-medium; ">
      <label className="relative">
        {label}
        {tooltip?.content && (
          <Tooltip title={tooltip.content}>
            <HelpIcon className="opacity-30 !w-4 !h-4 absolute translate-x-[100%] -translate-y-2 -right-1 top-0 text-black cursor-pointer text-md" />
          </Tooltip>
        )}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AntSwitch onChange={onChange} checked={value}></AntSwitch>
        )}
      />
    </div>
  );
}

export default CustomSwitch;
