const useStrapi = () => {
  const fetchAllPosts = async () => {
    let response = null;
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_STRAPI_ENDPOINT}api/blogs?populate=postCover&sort=id`
      );
      if (_resData.ok) {
        const { data } = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const fetchPostById = async (id) => {
    let response = null;
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_STRAPI_ENDPOINT}api/blogs/${id}?populate[0]=postCover&populate[1]=postContent.image&sort=id`
      );
      if (_resData.ok) {
        const { data } = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  return {
    fetchAllPosts,
    fetchPostById,
  };
};

export default useStrapi;
