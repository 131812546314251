import connectorImage from "@/assets/image/integrations/connector.png";

export default function HoursSavedPanel(props) {
  return (
    <div className="w-full p-20 bg-stone-150 grid md:grid-cols-5 xl:flex gap-8 md:gap-16 xl:gap-20 justify-center items-center">
      <h3 className="max-w-[793px] md:col-span-3 text-center md:text-left font-Outfit leading-[1.3] text-3xl lg:text-[36px] 2xl:text-[46px] text-stone-950">
        Clients save on average{" "}
        <span className="font-OutfitBold"> 50+ hours a month </span> on
        prospecting by integrating KompassAI into their CRM and Outreach
        workflows!
      </h3>
      <div className="xl:w-[645px] xl:h-[384px] md:col-span-2 flex justify-center items-center flex-row-reverse">
        <img
          src={connectorImage}
          width={645}
          height={234}
          alt="connectorImage"
        />
      </div>
    </div>
  );
}
