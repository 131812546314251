import SortTab from "@/components/blog/SortTab";
import LandingLayout from "@/components/LandingLayout";
import BaseContainer from "@/components/BaseContainer";
import { useEffect, useMemo, useRef, useState } from "react";
import ConnectPanel from "@/components/landing/ConnectPanel";
import { BLOG_TAGS } from "@/utils/constants";
import MainLoader from "@/components/MainLoader";
import Card from "@/components/blog/Card";
import MainCard from "@/components/blog/MainCard";
import useStrapi from "@/hooks/useStrapi";

export default function BlogScene() {
  const [selectedTag, setSelectedTag] = useState(BLOG_TAGS.ALL);
  const [filter, setFilter] = useState("");
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setLoading] = useState(null);
  const { fetchAllPosts } = useStrapi();
  const fetchAllPostsRef = useRef();
  fetchAllPostsRef.current = fetchAllPosts;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedPosts = await fetchAllPostsRef.current();
        setBlogPosts(fetchedPosts);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = useMemo(() => {
    return blogPosts?.filter(({ attributes }) => {
      if (selectedTag !== BLOG_TAGS.ALL && attributes.postTag !== selectedTag) {
        return false;
      }
      if (
        filter &&
        !attributes.postTitle.toLowerCase().includes(filter.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
  }, [selectedTag, filter, blogPosts]);

  return (
    <LandingLayout background="#090C05" inverse={true} pageName="blog">
      <div className="flex flex-col items-center py-12 bg-stone-950">
        <div className="rounded-[40px] px-2 py-1 lg:px-4 text-[16px] 2xl:text-[20px] normal-case leading-[1.4] font-[400] font-Outfit text-white bg-accents-blue2">
          Blog
        </div>
        <p className="mt-4 main-title !text-white">KompassAI insights</p>
        <p className="mt-6 text-[18px] lg:text-[22px] xl:text-[26px] text-white opacity-70">
          Explore the KompassAI knowledge base
        </p>
      </div>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <>
          <BaseContainer>
            <SortTab
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              filter={filter}
              setFilter={setFilter}
            />
            {filteredData.length === 0 && (
              <div className="my-10 md:my-20">
                No blog posts found for these criterias.
              </div>
            )}
            {filteredData.length >= 1 && <MainCard data={filteredData[0]} />}
            {filteredData.length > 1 && (
              <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mb-20 md:mb-40">
                {filteredData.slice(1).map((data, index) => (
                  <Card key={index} data={data} />
                ))}
              </div>
            )}
          </BaseContainer>
          <ConnectPanel
            background={"bg-accents-blue"}
            accent={"bg-accents-darkBlue"}
          />
        </>
      )}
    </LandingLayout>
  );
}
