import { useMemo, useEffect, useState } from "react";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { Slider, styled, Box } from "@mui/material";
import { toast } from "react-toastify";
import { throttle, debounce } from "lodash";
import useBillingStore from "@/store/useBillingStore";

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#e8e7e7 ",
  "& .MuiSlider-thumb": {
    backgroundColor: "white",
    width: "1.5rem",
    height: "1.5rem",
    boxShadow: "0 4px 8px -2px rgba(16, 24, 40, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0 4px 8px 4px rgba(16, 24, 40, 0.1)",
    },
  },
  ".MuiSlider-rail": {
    backgroundColor: "#E8E7E7 ",
    height: "0.5rem",
    border: "none",
  },
  ".MuiSlider-mark": {
    display: "none",
  },
  ".MuiSlider-track": {
    backgroundColor: "#9AB2FF ",
    height: "0.5rem",
    border: "none",
  },
}));

export function UserSlider() {
  const { changeUsers, users } = useBillingStore();
  const [userSliderValue, setUserSliderValue] = useState(users);
  const [toastId, setToastId] = useState(0);

  const dashboardInfoFunction = useHTTPRequest(`/analytics/getLandingPageInfo`);
  const { data: dashboardInfo } = useQuery({
    queryKey: ["Dashboard"],
    queryFn: () => dashboardInfoFunction(),
  });

  const throttleToast = useMemo(() => {
    return throttle(() => {
      toast.dismiss(toastId);
      setToastId(
        toast.error(
          `Your team currently has ${dashboardInfo?.data?.teamsInfo?.used} members, please deactivate additional team members to downgrade.`
        )
      );
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardInfo]);

  useEffect(() => {
    const teamInfo = dashboardInfo?.data?.teamsInfo;

    if (teamInfo) {
      changeUsers(teamInfo?.total - teamInfo?.used || 1);
      setUserSliderValue(teamInfo?.total - teamInfo?.used || 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardInfo]);

  const options = new Array(100).fill(0).map((_, index) => {
    return { user: index + 1 };
  });

  const formatTicks = useMemo(() => {
    const mappedValues = options.map((option) => {
      return option.user % 10 === 0 || option.user === 1
        ? { value: option.user }
        : { value: "" };
    });
    return mappedValues;
  }, [options]);

  const formatValues = useMemo(() => {
    const mappedValues = options.map((option) => {
      return { value: option.user };
    });
    return mappedValues;
  }, [options]);

  const debounceSetSliderValue = useMemo(() => {
    return debounce((sliderValue) => changeUsers(sliderValue), 1500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserSliderValueChange = (value) => {
    if (value < dashboardInfo?.data?.teamsInfo?.used) {
      throttleToast();
    } else setUserSliderValue(value);
  };

  useEffect(() => {
    debounceSetSliderValue(userSliderValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSliderValue]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex h-full w-full scroll-m-[64px] flex-col"
      >
        <div className="text-stone-950 font-Outfit flex h-full w-full flex-col rounded-lg border-1 border-stone-250 px-6 py-7">
          <div className="mb-7 flex flex-col justify-between gap-[10px]">
            <div className="flex gap-[10px]">
              <div className="text-[18px] lg:text-[20px]">Users</div>
            </div>
            <div className="flex justify-between gap-[10px]">
              <div className="text-[14px] lg:text-[16px]">
                Select how many seats for users you want to include in your
                subscription
              </div>
              <div className="whitespace-nowrap self-start text-[14px] lg:text-[16px]">
                {userSliderValue} user(s)
              </div>
            </div>
          </div>
          <div className="mt-auto flex flex-col">
            <StyledSlider
              value={userSliderValue}
              step={null}
              min={1}
              max={options[options.length - 1].user}
              marks={formatValues}
              onChange={(event, value) => {
                handleUserSliderValueChange(value);
              }}
            ></StyledSlider>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                width: "100%",
                marginBottom: "1.53rem",
              }}
            >
              {formatTicks.map((value, index) => {
                return (
                  <span
                    className="absolute top-0 hidden cursor-pointer select-none md:block"
                    onClick={() => handleUserSliderValueChange(value.value)}
                    style={{
                      left: `${(index / (formatTicks.length - 1)) * 100}%`,
                      transform: `translateX(${!index ? "0%" : "-50%"})`,
                      display:
                        !index || index === formatTicks.length - 1
                          ? "block"
                          : "",
                    }}
                  >
                    <span className="flex items-end gap-[2px] whitespace-nowrap font-medium">
                      <span className="text-[10px] lg:text-[12px]">
                        {value.value}
                      </span>
                    </span>
                  </span>
                );
              })}
            </Box>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
