import AllTabs from '@/components/ai_engagement/AllTabs'
import React from 'react'
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import MailBox from '@/components/ai_engagement/mailbox/MailBox';

export default function MailBoxScene() {
  
  const handleNewAccount = () => {
    console.log('New Account button clicked');
  }

  return (
    <MainLayout>
      <MainTitleBar>
        <p>AI Engagement</p>
      </MainTitleBar>

      <div className="w-full bg-white mt-4 pb-10">
        <BaseContainer>
          <AllTabs isNewAccountButton={true} onClickNewAccount={handleNewAccount} />
          <MailBox/>
        </BaseContainer>
      </div>
    </MainLayout>
  )
}