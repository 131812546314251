export default function WhyKompassAIPanel({ data, isCompetitorsPage }) {
  return (
    <div className="flex flex-col items-center gap-6 py-20 md:py-40 px-12">
      <div
        className={`main-title ${isCompetitorsPage ? "max-w-[1381px]" : "max-w-[842px]"}`}
      >
        {data.heading}
      </div>
      <p
        className={`${isCompetitorsPage ? "max-w-[1151px]" : "max-w-[790px]"} text-center font-Outfit text-base lg:text-[22px] 2xl:text-[26px] leading-[1.2] text-black opacity-70`}
      >
        {data.subHeading}
      </p>
    </div>
  );
}
