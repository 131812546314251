import useAuth from "@/hooks/useAuth";

const useKompassSearch = () => {
  const { getAuthToken } = useAuth();

  const postKompassSearchContactsByFilters = async (params) => {
    console.log(
      "===============postKompasssearchContactByFilters==============="
    );
    console.log(`params: ${JSON.stringify(params)}`);
    const { subscriptionName, params: filters } = params;

    // Now, you can use 'subscriptionName' and 'filters' as needed
    console.log(subscriptionName); // "Professional"
    console.log(filters["certifications.name"]); // "Full Stack"
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/contacts`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      const res = await _resData.json();

      if (_resData.ok) {
        response = res.data;
        console.log(
          "_resDta from postKompassSearchContactsByFilters",
          _resData
        );
        console.log("res from postKompassSearchContactsByFilters", res);
        console.log(
          "response from postKompassSearchContactsByFilters",
          response
        );
      } else {
        throw new Error(
          res?.message?.error?.message ||
            res?.message ||
            "Something went wrong!"
        );
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const getKompassSearchFavorite = async (params) => {
    // console.log('===============getKompassSearchFavorite===============');
    // console.log(`params: ${JSON.stringify(params)}`);
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/favorite`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const postKompassSearchFavorite = async (params) => {
    // console.log('===============postKompassSearchFavorite===============');
    // console.log(`params: ${JSON.stringify(params)}`);
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/favorite`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );

      response = await _resData.json();
      if (!response?.status) {
        throw new Error(response?.message);
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const deleteKompassSearchFavorite = async (params) => {
    // console.log('===============deleteKompassSearchFavorite===============');
    // console.log(`params: ${JSON.stringify(params)}`);
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/favorite`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );

      if (_resData.ok) {
        const data = await _resData.json();
        response = data;
      } else {
        throw new Error("Something went wrong !");
      }
    } catch (e) {
      console.log(e);
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const postSaveKompassSearchContacts = async (params) => {
    // console.log('===============postSaveKompassSearchContacts===============');
    // console.log(`params: ${JSON.stringify(params)}`);
    let response = null;
    const authToken = await getAuthToken();
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/save`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      response = await _resData.json();
      if (!_resData.ok) throw new Error(response?.message);
    } catch (e) {
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }
    return response;
  };

  const saveSearchFilters = async (params) => {
    let response;
    try {
      const authToken = await getAuthToken();
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/saveSearch`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      if (!_resData.ok) throw new Error(response?.message);
      response = await _resData.json();
    } catch (e) {
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }

    return response;
  };

  const getSavedSearchFilters = async () => {
    let response;
    try {
      const authToken = await getAuthToken();
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/saveSearch`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!_resData.ok) throw new Error(response?.message);
      response = await _resData.json();
    } catch (e) {
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }

    return response;
  };

  const deleteSavedSearchFilter = async (id) => {
    let response;
    try {
      const authToken = await getAuthToken();
      const _resData = await fetch(
        `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/kompass_search/saveSearch/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (!_resData.ok) throw new Error(response?.message);
      response = await _resData.json();
    } catch (e) {
      response = {
        status: false,
        message: e.message || "Something went wrong !",
      };
    }

    return response;
  };

  return {
    postKompassSearchContactsByFilters,
    getKompassSearchFavorite,
    postKompassSearchFavorite,
    deleteKompassSearchFavorite,
    postSaveKompassSearchContacts,
    saveSearchFilters,
    getSavedSearchFilters,
    deleteSavedSearchFilter,
  };
};

export default useKompassSearch;
