import React from 'react';

const VisitorsAnalytics = () => {

    return (
        <div className="flex mt-10 gap-10 text-sm leading-tight">
            <section className="flex-col self-stretch my-auto min-w-[240px] w-[527px] max-md:max-w-full">
                <h2 className="font-medium text-stone-950 max-md:max-w-full">Top Page visits</h2>
                <div className="gap-2 self-stretch px-3 py-5 mt-2 w-full text-black rounded-lg border border-solid border-stone-200 min-h-[55px] max-md:max-w-full">
                    There's nothing here.
                </div>
            </section>
            <section className="flex-col self-stretch my-auto min-w-[240px] w-[527px] max-md:max-w-full">
                <h2 className="font-medium text-stone-950 max-md:max-w-full">Visitors by state</h2>
                <div className="gap-2 self-stretch px-3 py-5 mt-2 w-full text-black rounded-lg border border-solid border-stone-200 min-h-[55px] max-md:max-w-full">
                    There's nothing here.
                </div>
            </section>
        </div>
    );
};

export default VisitorsAnalytics;