import { useEffect, useRef, useState } from "react";
import CardButton from "../button/CardButton";
import useStrapi from "@/hooks/useStrapi";
import slugify from "slugify";
import useKompassRouter from "@/hooks/useKompassRouter";

export default function RelatedPannel({ tag, currentId }) {
  const [recentPosts, setRecentPosts] = useState([]);
  const { fetchAllPosts } = useStrapi();
  const getThreeRecentPostsByTagRef = useRef();
  const { kompassNavigate } = useKompassRouter();

  const slugifyOptions = {
    lower: true,
    strict: true,
    remove: /[:]/g,
  };

  const getThreeRecentPostsByTag = async (tag, id) => {
    const allPosts = await fetchAllPosts();
    return allPosts
      .filter((post) => post?.attributes?.postTag === tag && post?.id !== id)
      .slice(0, 3);
  };
  getThreeRecentPostsByTagRef.current = getThreeRecentPostsByTag;

  useEffect(() => {
    if (!tag || !currentId) return;
    const fetchData = async () => {
      const fetchedPosts = await getThreeRecentPostsByTagRef.current(
        tag,
        currentId
      );
      setRecentPosts(fetchedPosts);
    };

    fetchData();
  }, [tag, currentId]);

  return (
    <div className="pl-10 border-l border-[#DDDDDD] hidden lg:block">
      <p className="font-Outfit text-[26px] leading-[1.2] text-[#0A2E31] mb-10">
        Related stories
      </p>
      <div className="flex flex-col gap-10">
        {recentPosts.length > 0 &&
          recentPosts.map((post) => (
            <div
              key={post.id}
              className="flex flex-col gap-4 cursor-pointer"
              onClick={() => {
                kompassNavigate(
                  `/blog/${post?.id}/${slugify(post?.attributes?.postTitle, slugifyOptions)}`
                );
              }}
            >
              <div className="rounded-[16px] overflow-hidden">
                <img
                  src={post?.attributes?.postCover?.data?.attributes?.url}
                  alt="BlogImage"
                  className="w-full"
                />
              </div>
              <div>
                <CardButton children={post?.attributes?.postTag || ""} />
              </div>
              <p className="font-Outfit text-[22px] leading-[1.2] font-bold text-start">
                {post?.attributes?.postTitle}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}
