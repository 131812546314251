import { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import FileUploaderWidget from "@/components/email_validation/FileUploaderWidget";
import { ReactComponent as PerformanceSVG } from "@/assets/image/email-validation/performance.svg";
import { ReactComponent as BulkValidateSVG } from "@/assets/image/email-validation/email-icon.svg";
import { ReactComponent as InformationSVG } from "@/assets/image/bulk/info.svg";
import StyledInput from "./StyledInput";
import { toast } from "react-toastify";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  emailValidationTemplateHeader,
  parseStringCSVtoArray,
} from "@/utils/common";
import MainLoader from "../MainLoader";

function FileUploadValidationWidget({ setCurrentWidgetIndex }) {
  const [loading, setLoading] = useState(false);
  const [attachedFileInfo, setAttachedFileInfo] = useState(null);
  const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed?", "GET");
  const sendDataAsList = useHTTPRequest("/email-validation/", "PUT", false, {
    // type: "file",
  });
  const { mutateAsync: sendDataAsListMutation } = useMutation({
    mutationFn: sendDataAsList,
  });
  const { data: creditsData } = useQuery({
    queryKey: ["CreditsUsage"],
    queryFn: () => getUsageData(),
  });
  const initialValues = {
    file: {},
    fileName: "",
  };

  const handleFileContent = (fileName, fileContent) => {
    let attachedInfo = {
      name: fileName,
      content: fileContent,
    };

    const inputs = [];
    for (let i = 0; i < fileContent.length; i++) {
      if (
        emailValidationTemplateHeader !== fileContent[i][0] &&
        fileContent[i][0] !== ""
      ) {
        inputs.push({ email: fileContent[i][0] });
      }
    }
    attachedInfo.content = inputs;
    setAttachedFileInfo(attachedInfo);
  };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .transform((v) => (!v.name ? undefined : v))
      .required("No file or it's broken")
      .test("Test file size", "File", (value, text) => {
        if (value.size >= 5000000) return false;
        return true;
      }),
    fileName: Yup.string()
      .required("Please name the list")
      .max(80, "List name is too long!"),
  });

  const handleUploading = async (values, evt) => {
    const res = await parseStringCSVtoArray(evt?.currentTarget?.result);
    if (creditsData?.data?.validations.available <= res.length) {
      toast.error(
        "You don't have enough email validation credits, please refill your credits"
      );
      setLoading(false);
      return;
    }
    // let formData = new FormData();
    // fileInput.disabled = true;
    // formData.append("image", file);
    // formData.append("photo_name", "profilePhoto"); // Changed key to 'type' for clarity
    // let formData = new FormData();
    // formData.append("extension", values.file.name.split(".").pop());
    // formData.append("files", values.file);
    // formData.append("fileName", [values.fileName]);

    // await sendDataAsListMutation(formData);
    // setLoading(false);
    // setTimeout(() => {
    //   setCurrentWidgetIndex(0);
    // }, 2000);
    // toast.success("Successfully uploaded!");
    let resFileName,
      fileName = attachedFileInfo.name;
    resFileName = fileName.slice(0, fileName.lastIndexOf("."));
    await sendDataAsListMutation(
      JSON.stringify({
        emails: attachedFileInfo.content,
        fileName: resFileName,
      })
    );
    toast.success("Successfully uploaded!");
    setLoading(false);
    setTimeout(() => {
      setCurrentWidgetIndex(0); //TODO: Add additional screen with list?
    }, 2000);
  };

  const handleInitiateLoading = async (values) => {
    try {
      setLoading(true);
      var reader = new FileReader();
      reader.readAsText(values.file, "UTF-8");
      reader.onload = async function (evt) {
        await handleUploading(values, evt);
      };
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, try again");
      return;
    }
  };

  useEffect(() => {
    setAttachedFileInfo(null);
  }, []);

  return (
    <>
      {loading && <MainLoader />}
      <div className="flex w-full md:max-w-[775px] mx-auto flex-col items-center justify-center">
        <div className="my-24 flex w-full flex-col gap-8 rounded-md border-[1px] border-[#E8E7E7] p-6">
          <div className="font-Outfit flex flex-col text-left text-3xl font-bold">
            Email Validation
          </div>
          <div className="flex gap-4 items-center">
            <div className="flex-row items-center justify-center">
              <BulkValidateSVG className="h-15 w-15" />
            </div>
            <div className="font-Outfit flex-col justify-between">
              <div className="text-lg font-Outfit select-none text-left text-gray-950">
                Validate
              </div>
              <div className="font-Outfit select-none text-left text-base leading-md text-gray-950">
                View results
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleInitiateLoading}
          >
            {({ setFieldValue, errors, handleChange }) => (
              <Form className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                  <div className="font-Outfit text-base flex flex-col text-left font-mdium">
                    List name
                  </div>
                  <StyledInput
                    name="fileName"
                    error={errors["fileName"]}
                    placeholder="e.g. My Emails"
                  ></StyledInput>
                  <ErrorMessage
                    name="fileName"
                    component="div"
                    className="font-Outfit leading-tight text-sm font-normal text-red-600"
                  ></ErrorMessage>
                </div>
                <hr className="border-[#E8E7E7]"></hr>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row justify-between">
                    <span className="font-Outfit text-base font-medium text-gray-950">
                      Or upload your file (Maximum 1000)
                    </span>
                    <div className="font-Outfit text-sm font-light text-gray-500">
                      Uploader supports CSV and TXT files
                    </div>
                  </div>
                  <FileUploaderWidget
                    error={errors["file"]}
                    onChange={(file) => setFieldValue("file", file)}
                    attachedFileName={attachedFileInfo?.name}
                    onFileContent={handleFileContent}
                    name="file"
                    reset={() => {
                      setAttachedFileInfo(null);
                    }}
                  />
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="font-Outfit leading-tight text-sm font-normal text-red-600"
                  ></ErrorMessage>
                  <div className="flex flex-row items-center gap-1">
                    <InformationSVG />
                    <span className="font-Outfit text-left text-xs text-[#929292]">
                      If your file contains multple columnes, we`ll
                      automatically detect the one containing the email
                      addresses
                    </span>
                  </div>
                </div>
                <Button
                  type="submit"
                  className="font-Outfit mx-auto h-11 w-56 flex items-center justify-center rounded-md bg-blue-500 text-base font-bold capitalize text-white"
                >
                  Finish
                </Button>
                <div className="flex items-center gap-2">
                  <PerformanceSVG
                    width={"24px"}
                    height={"24px"}
                  ></PerformanceSVG>
                  <div className="flex flex-col pl-4">
                    {[
                      "You can only verify up to 200 email addresses per domain name every 24 hours.",
                      "You can verify up to 50,000 email addresses per bulk. Additional rows will be skipped.",
                      "Duplicate email addresses will be skipped.",
                      "File size max is 5 MB",
                    ].map((text, index) => {
                      return (
                        <span
                          key={index}
                          className="before:left font-Outfit text-sm relative rounded-[50%] text-stone-350 before:absolute before:left-[-10px]  before:top-[50%] before:h-[4px]  before:w-[4px] before:translate-x-[-50%] before:translate-y-[-50%] before:bg-stone-350 "
                        >
                          {text}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <hr></hr>
                <div className="flex flex-row justify-between">
                  <Button
                    onClick={() => {
                      setCurrentWidgetIndex(1);
                    }}
                    className="font-Outfit rounded-md border-[1px] border-[#000] bg-white px-6 py-2 font-medium text-base leading-md normal-case text-black"
                  >
                    Previous
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default FileUploadValidationWidget;
