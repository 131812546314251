import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export default function CustomSingleSelectAutoComplete({
  id,
  options,
  onSelectionChange,
  selectedValue,
  placeholder = 'Select items',
  isLabelAvailable = false,
  isIconVisible = true,
  isBorderVisible = true,
  bgColor = '#f5f5f5',
  sx = {},
}) {
  return (
    <div>
      <Autocomplete
        id={id}
        options={options}
        value={selectedValue}
        getOptionLabel={isLabelAvailable ? (option) => option.label : (option) => option.toString()}
        onChange={onSelectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: isBorderVisible ? "#E8E7E7" : 'transparent', // Control border visibility
                },
                backgroundColor: bgColor, // Set the background color
                padding: '1px 8px',
                fontSize: '0.875rem', // Matches text-sm
                borderRadius: '0.375rem', // Matches rounded-md
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: isBorderVisible ? "#E8E7E7" : 'transparent',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: isBorderVisible ? "#E8E7E7" : 'transparent', 
                },
              },
              '& .MuiInputAdornment-root': {
                marginRight: isIconVisible ? '8px' : '0', // Adjust margin if icon is visible
              },
              '& .MuiAutocomplete-input': {
                padding: 0,
              },
              ...sx, // Allow additional styling to be passed in
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: isIconVisible ? (
                <>
                  <SearchOutlinedIcon sx={{ color: '#a1a1aa' }} />
                  {params.InputProps.startAdornment}
                </>
              ) : (
                params.InputProps.startAdornment
              ),
            }}
          />
        )}
        renderTags={() => null}
      />
    </div>
  );
}
