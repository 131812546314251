import ProspectImg from "../../assets/image/prospecting/prospect.png";
import IntegratedImg from "../../assets/image/prospecting/intergrated.png";
import ImmediateImg from "../../assets/image/prospecting/immediate.png";

export default function TabBar(props) {
  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-5 gap-12">
        <div className="flex-col col-span-2 text-stone-950">
          <div className="w-fit mb-20 font-Outfit flex items-center whitespace-nowrap p-2.5 justify-center py-1 text-md rounded-full text-stone-950 bg-accents-yellow">
            Best in Class Capabilities
          </div>
          <div className="font-OutfitBold text-3xl md:text-[2.5rem] leading-[1.2]">
            Integrated with Linkedin Sales Navigator
          </div>
          <div className="font-Outfit text-xl md:text-xl md:text-[1.625rem] leading-[1.4] mt-12">
            Augment your prospecting efforts through our LinkedIn prospecting
            tool. Automatically update and enrich your contact list when
            navigating on LinkedIn cleanly and painlessly.
          </div>
        </div>
        <div className="col-span-3 flex justify-center items-center drop-shadow-lg">
          <img src={IntegratedImg} alt="IntegratedImg" />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-col md:grid md:grid-cols-5 mt-12 gap-12 items-center">
        <div className="col-span-3 flex justify-center items-center drop-shadow-lg">
          <img src={ProspectImg} alt="ProspectImg" />
        </div>
        <div className="col-span-2 flex-col text-stone-950">
          <div className="font-OutfitBold text-3xl md:text-[2.5rem] leading-[1.2]">
            Prospect on LinkedIn and search a database with 950+ million members
          </div>
          <div className="font-Outfit text-xl md:text-[1.625rem] leading-[1.4] mt-12">
            Immediately see critical change data such as job changes or title
            promotions on LinkedIn and incorporate into your database.{" "}
          </div>
        </div>
      </div>

      <div className="flex flex-col md:grid md:grid-cols-5 mt-12 gap-12 items-center">
        <div className="col-span-2 flex-col text-stone-950">
          <div className="font-OutfitBold text-3xl md:text-[2.5rem] leading-[1.2]">
            Immediately see job changes of your prospects in LinkedIn
          </div>
          <div className="font-Outfit text-xl md:text-[1.625rem] leading-[1.4] mt-12">
            Immediately see critical change data such as job changes or title
            promotions on LinkedIn and incorporate into your database.
          </div>
        </div>
        <div className="col-span-3 flex justify-center items-center drop-shadow-lg">
          <img src={ImmediateImg} alt="ImmediateImg" />
        </div>
      </div>
    </>
  );
}
