import { ReactComponent as Checkmark } from "@/assets/image/icons/checkbox_circle.svg";

const points = {
  row1: [
    "name",
    "company",
    "job title",
    "location",
    "work email",
    "personal email",
    "phone number",
    "more",
  ],
  // row2: ["personal email", "phone number", "more"],
};
export default function Datapoints({ background, opacity, paddingTop }) {
  return (
    <div
      className={`flex flex-col w-full bg-white gap-8 pb-20 pt-44 md:pt-56 lg:pt-60 xl:pt-64 ${paddingTop}`}
    >
      <div className="main-title">What data do I get?</div>
      <div className="flex flex-col space-y-4 ">
        <div className="font-Outfit text-stone-950 uppercase text-md flex items-center justify-center">
          <div className="flex flex-wrap items-center justify-center max-w-[880px] gap-4">
            {points.row1.map((item, ind) => {
              return (
                <div
                  className={`flex rounded-full ${background} ${opacity} px-2 py-1 gap-1 items-center`}
                >
                  <Checkmark className="flex h-4" />
                  <p className="font-Outfit font-normal leading-[1.4] uppercase text-[16px] 2xl:text-[20px]">
                    {item}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
