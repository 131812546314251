import * as React from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import { CircularProgress, PaginationItem } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Pagination from "@mui/material/Pagination";
import { visuallyHidden } from "@mui/utils";
import { KompassColor } from "@/theme/color";
import { kompassColors } from "@/theme/palette";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import useEnrichment from "@/hooks/useEnrichment";
import useList from "@/hooks/useList";
import {
  getShortName,
  indexString,
  downloadKompassProfilesCSV,
  downloadLiveProfilesCSV,
  mergeKompassEmails,
} from "@/utils/common";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import MoveGroupIcon from "@/assets/image/team/move-group.svg";
import RemoveTeamIcon from "@/assets/image/team/remove-team.svg";
import { ReactComponent as TwitterIcon } from "@/assets/image/search/twitter.svg";
import { ReactComponent as FacebookIcon } from "@/assets/image/search/facebook.svg";
import { ReactComponent as LinkedinIcon } from "@/assets/image/search/linkedin.svg";
import DownIcon from "@/assets/image/search/down.svg";
import { ReactComponent as UserIcon } from "@/assets/image/search/user.svg";
import { ReactComponent as LocationIcon } from "@/assets/image/search/location.svg";
import { ReactComponent as USDIcon } from "@/assets/image/search/usd.svg";
import { ReactComponent as HomeIcon } from "@/assets/image/search/home.svg";
import { ReactComponent as JobTitleIcon } from "@/assets/image/search/jobtitle.svg";
import { ReactComponent as EmployeeIcon } from "@/assets/image/search/employee.svg";
import { ReactComponent as IndustryIcon } from "@/assets/image/search/industry.svg";
import { ReactComponent as LevelIcon } from "@/assets/image/search/level.svg";
import { ReactComponent as DepartmentIcon } from "@/assets/image/search/department.svg";
import { ReactComponent as LockIcon } from "@/assets/image/search/lock.svg";
import { ReactComponent as OpenLockIcon } from "@/assets/image/search/open-lock.svg";
import { ReactComponent as LinkIcon } from "@/assets/image/search/link.svg";
import DownloadIcon from "@/assets/image/lists/download.svg";
import { CONTACT_LIST_TYPE, ENRICHMENT_TYPE } from "@/utils/constants";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import SearchInput from "@/components/common/SearchInput";
import CRMPushMenu from "../common/CRMElements/CRMPushMenu";

const headCells = (listType) => {
  const cells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "companyname",
      numeric: false,
      disablePadding: true,
      label: "Company name",
    },
    {
      id: "location",
      numeric: false,
      disablePadding: true,
      label: "Location",
    },
    {
      id: "industry",
      numeric: false,
      disablePadding: true,
      label: "Industry",
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  if (listType === CONTACT_LIST_TYPE.CHROME_EXTENSION) cells.splice(3, 1);

  return cells;
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, listType } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "5px 8px",
          fontFamily: "Outfit",
          fontSize: 14,
          fontWeight: 700,
        },
        "& .MuiTableRow-head": {
          bgcolor: "#F3F3F3",
        },
      }}
    >
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            border: "none",
          },
        }}
      >
        <TableCell padding="checkbox"></TableCell>
        {headCells(listType).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    onSelectAllClick,
    rowCount,
    onDeleteSelected,
    onDownloadList,
    setSearchFilter,
    selected,
    visibleRows,
    tableData,
  } = props;

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: "flex",
          flexDirection: "column",
          borderRadius: "15px",
          alignItems: "center",
          bgcolor: "white",
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <div className="flex w-full flex-col md:flex-row gap-2 md:items-center justify-between py-4">
          <div className="flex flex-col items-center gap-2 xl:flex-row">
            <div className="flex w-full flex-row items-center">
              <SearchInput
                placeholder="Search for lists..."
                onInputChanged={(value) => setSearchFilter(value)}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={onDownloadList}
              className="font-OutfitBold flex items-center self-end justify-center max-w-[200px] gap-2 rounded-md border-none bg-stone-950 px-4 py-2 text-sm text-white disabled:bg-stone-350"
            >
              <div className="capitalize">Download List</div>
              <ReactSVG src={DownloadIcon} />
            </Button>
            <CRMPushMenu
              selected={visibleRows.filter((row) => selected.includes(row._id))}
              profileType={"profile"}
            />
          </div>
        </div>

        <div className="w-full flex flex-row justify-between items-center py-4">
          <div className="font-Outfit flex flex-row items-center justify-between">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && selected.length === tableData.length}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{
                "&.Mui-checked": {
                  color: KompassColor.black,
                },
                "&.MuiCheckbox-indeterminate": {
                  color: KompassColor.black,
                  fill: KompassColor.black,
                },
              }}
            />
            <div className="font-Outfit select-none">
              {numSelected} selected
            </div>
          </div>
          <button
            onClick={onDeleteSelected}
            disabled={numSelected <= 0}
            className="font-Outfit mr-2 cursor-pointer select-none px-2 text-center text-base text-blue-500 disabled:text-stone-350 disabled:cursor-default"
          >
            Delete from list
          </button>
        </div>
      </Toolbar>
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SubProfileListTableRow = (props) => {
  const { row, isItemSelected, labelId, handleClick, listType } = props;

  const [rowData, setRowdata] = React.useState([]);
  const [isDetailVisible, setDetailVisible] = React.useState(false);
  const [emailEnriching, setEmailEnriching] = React.useState(false);
  const [phoneEnriching, setPhoneEnriching] = React.useState(false);
  const [bothEnriching, setBothEnriching] = React.useState(false);
  const [deepEnriching, setDeepEnriching] = React.useState(false);
  const [emailEnriched, setEmailEnriched] = React.useState(false);
  const [phoneEnriched, setPhoneEnriched] = React.useState(false);
  const [deepEnriched, setDeepEnriched] = React.useState(false);
  const { postEnrichContact } = useEnrichment();

  const enrichContact = async (enrichType) => {
    try {
      setDetailVisible(true);

      switch (enrichType) {
        case ENRICHMENT_TYPE.EMAIL_ONLY:
          setEmailEnriching(true);
          break;
        case ENRICHMENT_TYPE.PHONE_ONLY:
          setPhoneEnriching(true);
          break;
        case ENRICHMENT_TYPE.ALL:
          setBothEnriching(true);
          break;
        case ENRICHMENT_TYPE.DEEP_SEARCH:
          setDeepEnriching(true);
          break;
        default:
          break;
      }

      const enrichRequestParams = {
        inputs: {
          url: rowData?.linkedin || "",
          company_website: rowData?.company?.website || "",
          company_name: rowData?.company?.name || "",
          full_name: rowData.name || "",
          from: "kompass_search",
        },
      };

      const response = await postEnrichContact(enrichRequestParams, enrichType);
      if (response.status && response.data) {
        let newEmails = [];
        let newPhones = [];

        if (
          enrichType !== ENRICHMENT_TYPE.PHONE_ONLY &&
          response?.data?.emails
        ) {
          newEmails =
            response.data.emails.length === 0
              ? [{ email: "Email not available" }]
              : mergeKompassEmails(response.data.emails);
          setEmailEnriched(true);
        }

        if (
          enrichType !== ENRICHMENT_TYPE.EMAIL_ONLY &&
          response?.data?.phones
        ) {
          newPhones =
            response.data.phones.length === 0
              ? [{ phone_number: "Phone not available" }]
              : response.data.phones;
          setPhoneEnriched(true);
        }

        const updatedRowData = {
          ...rowData,
          ...(newEmails.length > 0 && { emails: newEmails }),
          ...(newPhones.length > 0 && { phones: newPhones }),
        };

        setRowdata(updatedRowData);

        if (enrichType === ENRICHMENT_TYPE.DEEP_SEARCH) {
          setDeepEnriched(true);
        }
      } else {
        throw new Error(response?.message || "Something went wrong!");
      }
    } catch (error) {
      console.error("Enrichment error: ", error);
      toast.error(error.message, {
        theme: "colored",
      });
    } finally {
      switch (enrichType) {
        case ENRICHMENT_TYPE.EMAIL_ONLY:
          setEmailEnriching(false);
          break;
        case ENRICHMENT_TYPE.PHONE_ONLY:
          setPhoneEnriching(false);
          break;
        case ENRICHMENT_TYPE.ALL:
          setBothEnriching(false);
          break;
        case ENRICHMENT_TYPE.DEEP_SEARCH:
          setDeepEnriching(false);
          break;
        default:
          break;
      }
    }
  };

  React.useEffect(() => {
    const newRow = { ...row }; // Create a shallow copy of the row

    if (newRow?.isEmailFound) {
      newRow.emails = mergeKompassEmails(newRow);
    }

    if (newRow?.isPhoneFound) {
      newRow.phones = (newRow.validPhones || []).map((item) => ({
        phone_number: item.phoneNumber || item.phone_number,
        status: item.status,
      }));
    }

    setRowdata(newRow);
  }, [row]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rowData._id}
        selected={isItemSelected}
        sx={{
          cursor: "pointer",
          background: "white",
          "& .MuiTableCell-root": {
            border: "none",
          },
          "& .MuiTableCell-root:first-child": {
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
          },
          "& .MuiTableCell-root:last-child": {
            borderBottomRightRadius: "15px",
            borderTopRightRadius: "15px",
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            sx={{
              "&.Mui-checked": {
                color: kompassColors.blue,
              },
              "&.MuiCheckbox-indeterminate": {
                color: kompassColors.blue,
              },
            }}
            onClick={(event) => {
              handleClick(event, rowData._id);
            }}
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          onClick={(event) => {
            setDetailVisible(!isDetailVisible);
          }}
        >
          <div className="flex w-full flex-row items-center gap-2">
            <div className="flex">
              {rowData?.photo ? (
                <img
                  src={rowData?.photo}
                  width={40}
                  height={40}
                  alt="Company"
                />
              ) : (
                <div
                  className={
                    "text-md font-OutfitMedium flex h-10 w-10 items-center justify-center rounded-full" +
                    [
                      " bg-accents-yellow text-black",
                      " bg-accents-purple text-black",
                      " bg-accents-green text-black",
                      " bg-accents-blue text-black",
                      " bg-accents-orange text-black",
                    ][
                      rowData.name
                        ? indexString(rowData.name, 5)
                        : indexString(
                            rowData.firstName + " " + rowData.lastName,
                            5
                          )
                    ]
                  }
                >
                  {rowData?.name
                    ? getShortName(rowData?.name)
                    : getShortName(
                        rowData?.firstName + " " + rowData?.lastName
                      )}
                </div>
              )}
            </div>
            <div className="flex w-full flex-col text-sm capitalize">
              <p>
                {rowData?.name || rowData?.firstName + " " + rowData?.lastName}
              </p>
              <p className="text-[#929292] capitalize">{rowData.title}</p>
            </div>
          </div>
        </TableCell>
        <TableCell
          padding="none"
          align="left"
          onClick={(event) => {
            setDetailVisible(!isDetailVisible);
          }}
        >
          <div className="flex flex-row items-center gap-2 text-sm capitalize">
            {rowData?.company?.logo ? (
              <img
                src={rowData?.company?.logo}
                className="w-10"
                alt="Company logo"
              />
            ) : (
              <div
                className={
                  "font-OutfitMedium flex h-10 w-10 items-center justify-center rounded-full bg-stone-250 text-lg uppercase text-stone-350"
                }
              >
                {rowData?.company?.name && rowData?.company?.name[0]}
              </div>
            )}
            {rowData?.company?.name}
          </div>
        </TableCell>
        <TableCell
          padding="none"
          align="left"
          onClick={(event) => {
            setDetailVisible(!isDetailVisible);
          }}
        >
          <p className="text-sm capitalize">
            {rowData?.location ||
              (rowData?.city ? rowData?.city + ", " : "") +
                (rowData?.country ?? null)}
          </p>
        </TableCell>
        <TableCell
          padding="none"
          align="left"
          onClick={(event) => {
            setDetailVisible(!isDetailVisible);
          }}
        >
          <p className="text-sm capitalize">{rowData?.company?.industry}</p>
        </TableCell>
        <TableCell padding="none" align="left">
          <div className="flex w-full flex-row items-center justify-end gap-1">
            <Button className="font-Outfit flex items-center justify-center rounded-full border-[1px] border-[#929292] bg-transparent px-3 py-1 text-sm text-black">
              {!rowData?.phones?.length && !rowData?.emails?.length && (
                <LockIcon className="h-4 w-4 fill-current text-stone-950" />
              )}
              {(rowData?.phones?.length > 0 || rowData?.emails?.length > 0) && (
                <OpenLockIcon className="h-4 w-4 fill-current text-stone-950" />
              )}
            </Button>
            <IconButton
              onClick={() => {
                setDetailVisible(!isDetailVisible);
              }}
            >
              {isDetailVisible ? (
                <ReactSVG src={DownIcon} style={{ rotate: "180deg" }} />
              ) : (
                <ReactSVG src={DownIcon} />
              )}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      {isDetailVisible ? (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            colSpan={10}
          >
            <div className="flex w-full flex-row">
              <div className="w-3/12 border-r border-stone-250">
                <div className="flex w-full items-center gap-4 px-6 py-6">
                  {rowData?.company?.logo ? (
                    <img
                      src={rowData?.company?.logo}
                      className="w-10"
                      alt="Company logo"
                    />
                  ) : (
                    <div
                      className={
                        "font-OutfitMedium flex h-10 w-10 items-center justify-center rounded-full bg-stone-250 text-lg uppercase text-stone-350"
                      }
                    >
                      {rowData?.company?.name && rowData?.company?.name[0]}
                    </div>
                  )}
                  <div className="font-OutfitMedium text-lg capitalize">
                    {rowData?.company?.name}
                  </div>
                </div>
                <hr className="mx-6 h-px bg-stone-250" />
                <div className="flex flex-col gap-2 p-6">
                  <div className="mb-2 text-sm text-stone-350">
                    Company Details
                  </div>
                  <div className="flex flex-row items-center gap-1 text-sm text-black">
                    <HomeIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                    <p className="font-OutfitMedium">Company</p>
                    <p className="font-OutfitLight capitalize">
                      {rowData?.company?.name}
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-1 text-sm text-black">
                    <LinkIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                    <p className="font-OutfitMedium">Company website</p>
                    <p className="font-OutfitLight capitalize">
                      {listType === CONTACT_LIST_TYPE.KOMPASS_SEARCH &&
                        (rowData?.company?.website ? (
                          <Link
                            target="_blank"
                            className="text-blue-500"
                            to={rowData?.company?.website}
                          >
                            {rowData?.company?.name}
                          </Link>
                        ) : (
                          <p>{rowData?.company?.name}</p>
                        ))}
                      {listType === CONTACT_LIST_TYPE.CHROME_EXTENSION &&
                        (rowData?.company?.domain ? (
                          <Link
                            target="_blank"
                            className="text-blue-500"
                            to={rowData?.company?.domain}
                          >
                            {rowData?.company?.name}
                          </Link>
                        ) : (
                          <p className="capitalize">{rowData?.company?.name}</p>
                        ))}
                    </p>
                  </div>
                  {listType === CONTACT_LIST_TYPE.KOMPASS_SEARCH &&
                    rowData?.company?.location && (
                      <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                        <LocationIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                        <p className="font-OutfitMedium">Location</p>
                        <p className="font-OutfitLight capitalize">
                          {rowData?.company?.location}
                        </p>
                      </div>
                    )}
                  {listType === CONTACT_LIST_TYPE.CHROME_EXTENSION &&
                    rowData?.location && (
                      <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                        <LocationIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                        <p className="font-OutfitMedium">Location</p>
                        <p className="font-OutfitLight capitalize">
                          {rowData?.location}
                        </p>
                      </div>
                    )}
                  {rowData?.company?.industry && (
                    <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                      <IndustryIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                      <p className="font-OutfitMedium">Industry</p>
                      <p className="font-OutfitLight capitalize">
                        {rowData?.company?.industry}
                      </p>
                    </div>
                  )}
                  {rowData?.company?.headCount && (
                    <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                      <EmployeeIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                      <p className="font-OutfitMedium">Company head count</p>
                      <p className="font-OutfitLight">
                        {rowData?.company?.headCount}
                      </p>
                    </div>
                  )}
                  {rowData?.company?.revenue && (
                    <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                      <USDIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                      <p className="font-OutfitMedium">Company revenue</p>
                      <p className="font-OutfitLight">
                        {rowData?.company?.revenue}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-9/12">
                <div className="mt-1 flex w-full flex-row justify-between p-6">
                  <div className="flex w-full flex-col text-sm">
                    <div className="flex w-full flex-row">
                      <div className="font-OutfitMedium capitalize">
                        {rowData?.name ||
                          rowData?.firstName + " " + rowData?.lastName}
                      </div>
                      <div className="flex flex-row gap-2 pl-2 pt-0.5">
                        {rowData?.linkedin ? (
                          <Link
                            target="_blank"
                            to={"https://" + rowData.linkedin}
                          >
                            <ReactSVG
                              src={LinkedinIcon}
                              style={{ height: 13, width: 13 }}
                            />
                          </Link>
                        ) : null}
                        {rowData?.twitter ? (
                          <Link
                            target="_blank"
                            to={"https://" + rowData.twitter}
                          >
                            <ReactSVG
                              src={TwitterIcon}
                              style={{ height: 13, width: 13 }}
                            />
                          </Link>
                        ) : null}
                        {rowData?.facebook ? (
                          <Link
                            target="_blank"
                            to={"https://" + rowData.facebook}
                          >
                            <ReactSVG
                              src={FacebookIcon}
                              style={{ height: 13, width: 13 }}
                            />
                          </Link>
                        ) : null}
                      </div>
                    </div>
                    <p className="text-[#929292] capitalize">{rowData.title}</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-end gap-1">
                    <Button
                      className="font-Outfit ml-4 rounded-md  border-none bg-stone-950 px-4 py-2 text-xs capitalize text-white disabled:bg-gray-500"
                      onClick={() => enrichContact(ENRICHMENT_TYPE.EMAIL_ONLY)}
                      disabled={
                        deepEnriching ||
                        emailEnriching ||
                        bothEnriching ||
                        emailEnriched
                      }
                    >
                      Access email
                    </Button>
                    <Button
                      className="font-Outfit rounded-md border-none  bg-stone-950 px-4 py-2 text-xs capitalize text-white disabled:bg-gray-500"
                      onClick={() => enrichContact(ENRICHMENT_TYPE.PHONE_ONLY)}
                      disabled={
                        deepEnriching ||
                        phoneEnriching ||
                        bothEnriching ||
                        phoneEnriched
                      }
                    >
                      Access Phone
                    </Button>
                    <Button
                      className="font-Outfit rounded-md border-none  bg-stone-950 px-4 py-2 text-xs capitalize text-white disabled:bg-gray-500"
                      onClick={() => enrichContact(ENRICHMENT_TYPE.ALL)}
                      disabled={
                        deepEnriching ||
                        bothEnriching ||
                        (emailEnriched && phoneEnriched)
                      }
                    >
                      All contact
                    </Button>
                    <Button
                      className="font-Outfit rounded-md  border-none bg-stone-950 px-4 py-2 text-xs capitalize text-white disabled:bg-gray-500"
                      onClick={() => enrichContact(ENRICHMENT_TYPE.DEEP_SEARCH)}
                      disabled={
                        deepEnriching ||
                        bothEnriching ||
                        (emailEnriching && phoneEnriching) ||
                        deepEnriched
                      }
                    >
                      AI Search
                    </Button>
                  </div>
                </div>
                <hr className="mx-6 h-px bg-stone-250" />
                <div className="flex w-full flex-row p-6">
                  <div className="flex w-4/12 flex-col gap-2">
                    <div className="mb-2 text-sm text-stone-350">
                      Contact Details
                    </div>
                    <div className="flex flex-row items-center gap-1 text-sm text-black">
                      <UserIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                      <p className="font-OutfitMedium">Name</p>
                      <p className="font-OutfitLight capitalize">
                        {rowData?.name ||
                          rowData?.firstName + " " + rowData?.lastName}
                      </p>
                    </div>
                    {rowData.title && (
                      <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                        <JobTitleIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                        <p className="font-OutfitMedium">Title</p>
                        <p className="font-OutfitLight capitalize">
                          {rowData?.title}
                        </p>
                      </div>
                    )}
                    {rowData.department && (
                      <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                        <DepartmentIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                        <p className="font-OutfitMedium">Department</p>
                        <p className="font-OutfitLight capitalize">
                          {rowData?.department}
                        </p>
                      </div>
                    )}
                    {rowData.level && (
                      <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                        <LevelIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                        <p className="font-OutfitMedium">Level</p>
                        <p className="font-OutfitLight capitalize">
                          {rowData?.level}
                        </p>
                      </div>
                    )}
                    {rowData?.location && (
                      <div className="font-Outfit flex flex-row items-center gap-1 text-sm text-black">
                        <LocationIcon className="h-3 w-3 fill-current text-stone-950" />{" "}
                        <p className="font-OutfitMedium">Location</p>
                        <p className="font-OutfitLight capitalize">
                          {rowData?.location}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex w-4/12 flex-col gap-2">
                    <div className="flex flex-row text-center text-sm text-stone-350">
                      {" "}
                      {emailEnriching || bothEnriching || deepEnriching ? (
                        <CircularProgress size={15} />
                      ) : null}{" "}
                      <p className="">Email</p>{" "}
                    </div>
                    {rowData?.emails?.length > 0 &&
                      rowData?.emails?.map((item, key) => {
                        return (
                          <div
                            key={key}
                            className="flex flex-row items-center text-sm  text-black"
                          >
                            {item.email}
                            <p className="font-Outfit ml-1 opacity-80">
                              {item.type === "Valid" ? (
                                <Chip
                                  icon={<CheckCircleIcon />}
                                  label="Valid"
                                  color="success"
                                  size="small"
                                  style={{ fontSize: 12, fontFamily: "Outfit" }}
                                />
                              ) : null}
                              {item.type === "Personal" ? (
                                <Chip
                                  icon={<CheckCircleIcon />}
                                  label="Personal"
                                  color="primary"
                                  size="small"
                                  style={{ fontSize: 12, fontFamily: "Outfit" }}
                                />
                              ) : null}
                              {item.type === "Risky" ? (
                                <Chip
                                  icon={<WarningIcon />}
                                  label="Risky"
                                  color="warning"
                                  size="small"
                                  style={{ fontSize: 12, fontFamily: "Outfit" }}
                                />
                              ) : null}
                              {item.type === "Unknown" ? (
                                <Chip
                                  icon={<WarningIcon />}
                                  label="Unknown"
                                  color="secondary"
                                  size="small"
                                  style={{ fontSize: 12, fontFamily: "Outfit" }}
                                />
                              ) : null}
                              {item.type === "Do not email" ? (
                                <Chip
                                  icon={<CancelIcon />}
                                  label="Do not email"
                                  color="error"
                                  size="small"
                                  style={{ fontSize: 12, fontFamily: "Outfit" }}
                                />
                              ) : null}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  <div className="flex w-4/12 flex-col gap-2">
                    <div className="flex flex-row text-center text-sm text-stone-350">
                      {" "}
                      {phoneEnriching || bothEnriching || deepEnriching ? (
                        <CircularProgress size={15} />
                      ) : null}{" "}
                      <p className="">Phone</p>{" "}
                    </div>
                    {rowData?.phones?.length > 0 &&
                      rowData?.phones?.map((item, key) => {
                        return (
                          <div
                            key={key}
                            className="text-sm text-black flex flex-row items-center"
                          >
                            {item.phone_number}
                            <p className="font-Outfit ml-1 opacity-80">
                              {item.status === "valid" ? (
                                <Chip
                                  icon={<CheckCircleIcon />}
                                  label="Valid"
                                  color="success"
                                  size="small"
                                  style={{ fontSize: 12, fontFamily: "Outfit" }}
                                />
                              ) : null}
                              {item.status === "hq" ? (
                                <Chip
                                  icon={<CheckCircleIcon />}
                                  label="HQ"
                                  color="primary"
                                  size="small"
                                  style={{ fontSize: 12, fontFamily: "Outfit" }}
                                />
                              ) : null}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default function SubProfileListTable({
  setLoading,
  listTitle,
  listType,
  listId,
  tableData,
  reload,
  searchFilter,
}) {
  const { deleteProfiles } = useList();
  const [visibleDeleteDialog, handleVisibleDeleteDialog] =
    React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const dense = false;
  const rowsPerPage = 10;
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const menuOptions = [
    {
      icon: MoveGroupIcon,
      label: "Move to the group",
    },
    {
      icon: RemoveTeamIcon,
      label: "Remove team member",
    },
  ];

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const openMenu = () => {
    return menuAnchorEl !== null;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n._id);
      setSelected(newSelected);
      return;
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDeleteSelected = async (confirmed) => {
    if (!(confirmed === true)) {
      handleVisibleDeleteDialog(true);
    } else {
      setLoading(true);
      const response = await deleteProfiles({ listId, ids: selected });
      console.log(selected);
      setLoading(false);
      if (response?.status) {
        toast.success(response?.message, { theme: "colored" });
        setSelected([]);
        reload();
      } else {
        toast.error(response?.message, { theme: "colored" });
      }
    }
  };

  const handleDownloadList = () => {
    if (listType === CONTACT_LIST_TYPE.KOMPASS_SEARCH)
      downloadKompassProfilesCSV(listTitle, tableData);
    if (listType === CONTACT_LIST_TYPE.CHROME_EXTENSION)
      downloadLiveProfilesCSV(listTitle, tableData);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;

  React.useEffect(() => {
    const rows = tableData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setVisibleRows([...rows]);
  }, [order, orderBy, page, rowsPerPage, tableData]);

  return (
    <>
      <Box sx={{ width: "100%", mt: "10px", px: "25px" }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          onDeleteSelected={handleDeleteSelected}
          onDownloadList={handleDownloadList}
          rowCount={visibleRows.length}
          selected={selected}
          visibleRows={visibleRows}
          tableData={tableData}
        />
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              borderCollapse: "separate",
              borderSpacing: "0 0.5em",
              border: "none",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={handleRequestSort}
              rowCount={visibleRows.length}
              listType={listType}
            />
            <TableBody
              sx={{
                "& .MuiTableCell-sizeMedium": {
                  padding: "5px 8px",
                  fontFamily: "Outfit",
                  fontSize: 14,
                },
              }}
            >
              {visibleRows?.length > 0 ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <SubProfileListTableRow
                      isItemSelected={isItemSelected}
                      row={row}
                      labelId={labelId}
                      handleClick={handleClick}
                      listType={listType}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      border: "none",
                    }}
                    colSpan={10}
                  >
                    <div className="font-Outfit col-span-10 p-5 text-center text-xl">
                      <p>No data</p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={menuAnchorEl}
          open={openMenu()}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              // width: "20ch",
            },
          }}
        >
          {menuOptions.map((option) => (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={handleMenuClose}
            >
              <ListItemIcon>
                <ReactSVG src={option.icon} />
              </ListItemIcon>
              <Typography variant="inherit">{option.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <div className="flex w-full justify-center py-4">
          <Pagination
            className="font-Outfit"
            count={
              tableData.length % 10 === 0
                ? Math.floor(tableData.length / rowsPerPage)
                : Math.floor(tableData.length / rowsPerPage) + 1
            }
            page={page + 1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            renderItem={(item) => (
              <PaginationItem
                slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-text": {
                fontFamily: "Outfit",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: 1.43,
                color: "#090C05",
              },
              "& .MuiPaginationItem-text.Mui-selected": {
                color: "#fff",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "gray",
                },
              },
            }}
          />
        </div>
      </Box>

      <ConfirmDeleteDialog
        open={visibleDeleteDialog}
        handleClose={() => {
          handleVisibleDeleteDialog(false);
        }}
        handleYes={() => {
          handleDeleteSelected(true);
          handleVisibleDeleteDialog(false);
        }}
      />
    </>
  );
}
