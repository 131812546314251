import MainLayout from "@/components/MainLayout";
import { ReactComponent as SettingIcon } from "@/assets/image/profile-popup/setting.svg";
import { ReactComponent as DownSVGIcon } from "@/assets/image/icons/down.svg";
import ContactCreatedComponent from "@/components/home/ContactCreatedComponent";
import CreditDeductedComponent from "@/components/home/CreditDeductedComponent";
import DataRevealedComponent from "@/components/analytics/DataRevealedComponent";
import UsageAnalyticComponent from "@/components/home/UsageAnalyticComponent";
import { useEffect, useState, useCallback, useContext } from "react";
import SelectDropdown from "@/components/common/SelectDropdown";
import AnalyticCalendarPopup from "@/components/popup/AnalyticCalendarPopup";
import useTeam from "@/hooks/useTeam";
import { AuthContext } from "@/context/AuthContext";
import useDashboard from "@/hooks/useDashboard";
import FilterPopup, { filterOptions } from "@/components/popup/FilterPopup";
import BaseContainer from "@/components/BaseContainer";
import MainTitleBar from "@/components/MainTitleBar";

export default function AnalyticsScene() {
  const [filter, setFilter] = useState("THIS_MONTH");
  const [anchorEl, setAnchorEl] = useState(null);
  const [calendarPopupAnchorEl, setCalendarPopupAnchorEl] = useState(false);
  const authContext = useContext(AuthContext);
  const { getDashboard } = useDashboard();
  const { getTeamMembers, getDataRevealed } = useTeam();
  const [members, setMembers] = useState([
    {
      value: authContext?.userInfo?._id,
      label: `${authContext?.userInfo?.userName} (Me)`,
    },
  ]);

  const [selectedUser, setSelectedUser] = useState({
    value: authContext?.userInfo?._id,
    label: `${authContext?.userInfo?.userName} (Me)`,
  });

  const [data, setData] = useState(null);
  const [revealedInfoData, setRevealedInfoData] = useState({});

  useEffect(() => {
    (async () => {
      const fetchData = async (currentUserId) => {
        let responses = await Promise.all([
          getDashboard({
            usageAnalyticsFilter: filter,
            contactsCreatedFilter: filter,
            creditsDeductedFilter: filter,
            currentUserId,
          }),
          getDataRevealed({
            currentUserId,
            period: filter,
          }),
        ]);

        setData(responses[0].data);
        setRevealedInfoData(responses[1].data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
      };

      await fetchData(selectedUser?.value);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser?.value, filter]);

  useEffect(() => {
    (async () => {
      const response = await getTeamMembers({
        status: "ACTIVE",
      });
      setMembers([
        ...members,
        ...response.data
          .map((member) => {
            return {
              value: member.userId,
              label: member.name,
            };
          })
          .filter(
            (member) =>
              members.findIndex((m) => m.value === member.value) === -1
          ),
      ]);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profilePopupClosed = () => {
    setCalendarPopupAnchorEl(null);
  };

  return (
    <MainLayout>
      <MainTitleBar>
        <div className="flex justify-between items-end">
          <div className="flex gap-2 items-center">
            <SettingIcon className="h-[34px] w-[34px]" />
            <p>Analytics</p>
          </div>
          <div className="flex items-center justify-start gap-2 xs:gap-4 flex-col xs:flex-row">
            <button
              className="flex items-center justify-start gap-2"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <div className="font-Outfit leading-tight text-base font-normal">
                {filterOptions[filter]}
              </div>
              <DownSVGIcon className="relative h-4 w-4" />
            </button>
            <FilterPopup
              anchorEl={anchorEl}
              handleClose={(value) => {
                if (value) {
                  setFilter(value);
                }
                setAnchorEl(null);
              }}
            />
            <div className="lg:w-64">
              <SelectDropdown
                options={members}
                selectedOption={selectedUser.value}
                onChange={(value) => {
                  setSelectedUser(
                    members.find((member) => member.value === value)
                  );
                }}
                defaultValue={selectedUser?.value}
                value={selectedUser.value}
              />
            </div>
          </div>
        </div>
      </MainTitleBar>
      <BaseContainer>
        <div className="grid grid-cols-1 md:grid-cols-4 ">
          <div className="mb-4 h-full border-r border-stone-250 bg-white pt-4 sm:col-span-4 sm:mb-1 lg:col-span-1">
            <div className="flex flex-col items-start justify-start">
              <UsageAnalyticComponent
                usageAnalytics={data?.usageAnalytics ?? {}}
                setFilter={setFilter}
                filter={filter}
                hideHeader
              />
            </div>
          </div>
          <div className="col-span-4 lg:col-span-3 flex flex-col gap-y-4 py-6 px-0 lg:pl-6">
            <DataRevealedComponent
              revealedInfoData={revealedInfoData}
              filter={filter}
              creditsUsed={data?.creditsUsed ?? []}
            />
            <ContactCreatedComponent
              contactsUsed={data?.contactsUsed ?? []}
              setFilter={setFilter}
              filter={filter}
              hideFilter
            />
            <CreditDeductedComponent
              setFilter={setFilter}
              filter={filter}
              creditsUsed={data?.creditsUsed ?? []}
              hideFilter
            />
          </div>
          <AnalyticCalendarPopup
            anchorEl={calendarPopupAnchorEl}
            handleClose={profilePopupClosed}
          />
        </div>
      </BaseContainer>
    </MainLayout>
  );
}
