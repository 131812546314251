import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { useHTTPRequestObject } from "@/hooks/useHTTPRequest";
import AddPaymentMethod from "./AddPaymentMethod";
import CardDetailsCard from "./CardDetailsCard";
import BackdropLoader from "@/components/common/BackdropLoader";
import { Button } from "@material-tailwind/react";
import { ArrowBack } from "@mui/icons-material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function PaymentMethodsList() {
  const [addForm, setAddForm] = useState(false);
  const fetchPaymentMethods = useHTTPRequestObject({
    link: "/billing/getPaymentMethods",
  });

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["PaymentMethods"],
    queryFn: () => fetchPaymentMethods(),
    refetchInterval: 30000,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {!addForm && (
        <>
          <div className="relative flex h-fit min-h-[100px] w-full flex-col items-center justify-center">
            {!data?.data?.length && (
              <div className="font-OutfitMedium w-full p-2 text-center text-xl">
                Nothing to display
              </div>
            )}
            {!isLoading && !isFetching ? (
              <>
                {data?.data?.map(({ id, card, customer }) => {
                  return (
                    <CardDetailsCard
                      id={id}
                      card={card}
                      customer={customer}
                      key={JSON.stringify(card)}
                    ></CardDetailsCard>
                  );
                })}
                <Button
                  onClick={() => {
                    setAddForm(true);
                  }}
                  className="mt-2 flex items-center justify-center gap-2 self-center rounded-md bg-stone-950 px-4 py-2"
                >
                  <div className="font-Outfit leading-tight text-base font-bold capitalize tracking-tight text-white">
                    Add card
                  </div>
                </Button>
              </>
            ) : (
              <BackdropLoader active={true}></BackdropLoader>
            )}
          </div>
        </>
      )}
      {addForm && (
        <div className=" flex w-full flex-col items-center justify-center gap-4 p-4">
          <div className="font-OutfitBold relative flex w-full flex-col items-center justify-center gap-2 p-4 text-2xl">
            <div className="absolute left-2 top-4 flex h-10 w-10  cursor-pointer items-center justify-center rounded-full p-1 transition hover:bg-stone-250">
              <ArrowBack
                className=""
                onClick={() => setAddForm(false)}
              ></ArrowBack>
            </div>

            <span className="leading-[100%]"> Add new card</span>
            <span className="font-Outfit flex w-full items-center justify-center text-sm leading-[100%] opacity-60">
              Fill out Stripe form to add another payment method
            </span>
          </div>

          <AddPaymentMethod stripe={stripePromise}></AddPaymentMethod>
        </div>
      )}
    </>
  );
}

export default PaymentMethodsList;
