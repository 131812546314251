import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as MainLogo } from "@/assets/image/icons/logo.svg";
import { Link } from "react-router-dom";
import useKompassRouter from "@/hooks/useKompassRouter";
import { Button } from "@material-tailwind/react";
import { hexToRGB, navList } from "@/utils/common";
import Navbar from "./landing/base/Navbar";
import { MobileNavBar } from "@/components/landing/base/MobileNavBar";

export default function LandingHeader(props) {
  const { isOpen, handleOpen, isSticky, handleClose, invertColor, backColor } =
    props;
  const theme = useTheme();
  const isTablet_ = useMediaQuery(theme.breakpoints.down("lg"));
  const [isOpenMobileProduct, setIsOpenMobileProduct] = useState(false);
  const [isOpenMobileResources, setIsOpenMobileResources] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const { kompassNavigate } = useKompassRouter();

  return (
    <>
      {isTablet_ && !isOpen && (
        <Box className="flex w-full items-center justify-between px-6 h-[65px] text-center">
          <Box className="flex h-[36px] w-[197.71px] items-center text-center">
            <Link to="/">
              <MainLogo />
            </Link>
          </Box>
          <IconButton size="medium" edge="end" onClick={handleOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      )}

      {isTablet_ && isOpen && (
        <Box className="px-6 h-screen overflow-scroll">
          <Box className="flex w-full items-center justify-between py-4 text-center">
            <Link
              to="/"
              className="flex h-[36px] w-[197.71px] items-center text-center"
            >
              <MainLogo />
            </Link>
            <IconButton size="medium" edge="end" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <div className="grid grid-cols-2 items-center gap-[8px] py-4">
            <Button
              onClick={() => {
                kompassNavigate("/signin");
              }}
              className="normal-case font-OutfitBold font-bold cursor-pointer rounded-lg border bg-transparent py-4 px-6 text-base leading-[1.2] tracking-[0.32px] border-stone-950 hover:bg-stone-950 hover:text-white text-black"
            >
              Login
            </Button>
            <Button
              onClick={() => {
                kompassNavigate("/demo");
              }}
              className="normal-case font-OutfitBold cursor-pointer border rounded-lg leading-[1.2] tracking-[0.32px] py-4 px-6 text-base bg-stone-950 text-white hover:text-black hover:bg-transparent border-stone-950"
            >
              Book a Demo
            </Button>
          </div>
          <nav className="flex flex-col items-start justify-start gap-6 mt-8">
            {navList.map((item, ind) => {
              if (item.router === "/prospecting") {
                return (
                  <Box
                    key={ind}
                    className="group flex flex-col items-start gap-2 w-full"
                    onClick={() => setIsOpenMobileProduct((prev) => !prev)}
                  >
                    <Box
                      className="flex items-center justify-center gap-2"
                      sx={{ position: isProduct ? "relative" : "initial" }}
                    >
                      <Box className="navbar-link">{item.label}</Box>
                      <span
                        aria-hidden="true"
                        className={`Navbar__menu-item-arrow group-hover: ${isOpenMobileProduct ? "rotate-180" : ""}`}
                      >
                        <svg
                          height="6"
                          width="8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 3.388L0 .387v2l4 3.001 4.001-3.001v-2z"
                            fill="#111"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </Box>
                    <div
                      className={` products w-full filter ${isOpenMobileProduct ? "block" : "hidden"}`}
                      aria-hidden="true"
                    >
                      <MobileNavBar isProduct={true} />
                    </div>
                  </Box>
                );
              }

              if (item.router === "/aboutme") {
                return (
                  <Box className="Navbar__menu-item group gap-8" key={ind}>
                    <Box
                      className="flex items-center justify-center gap-2 py-2"
                      sx={{ position: isProduct ? "relative" : "initial" }}
                      key={ind}
                    >
                      <Box className="navbar-link">{item.label}</Box>
                      <span
                        aria-hidden="true"
                        className="Navbar__menu-item-arrow group-hover:rotate-180"
                      >
                        <svg
                          height="6"
                          width="8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 3.388L0 .387v2l4 3.001 4.001-3.001v-2z"
                            fill="#111"
                            fill-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </Box>
                    <div
                      className="Navbar__mega-menu products absolute top-[75px] z-[10001] hidden rounded-md border border-stone-250 bg-white
                          drop-shadow-lg filter focus-within:block group-hover:block"
                      aria-hidden="true"
                    >
                      <Navbar isResources={true} />
                    </div>
                  </Box>
                );
              }

              if (item.router === "/faq") {
                return (
                  <Box
                    key={ind}
                    className="group flex flex-col items-start gap-2 w-full"
                    onClick={() => setIsOpenMobileResources((prev) => !prev)}
                  >
                    <Box
                      className="flex items-center justify-center gap-2"
                      sx={{ position: isProduct ? "relative" : "initial" }}
                      key={ind}
                    >
                      <Box className="navbar-link">{item.label}</Box>
                      <span
                        aria-hidden="true"
                        className={`Navbar__menu-item-arrow group-hover: ${isOpenMobileResources ? "rotate-180" : ""}`}
                      >
                        <svg
                          height="6"
                          width="8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 3.388L0 .387v2l4 3.001 4.001-3.001v-2z"
                            fill="#111"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </Box>
                    <div
                      className={` products w-full filter ${isOpenMobileResources ? "block" : "hidden"}`}
                      aria-hidden="true"
                    >
                      <MobileNavBar isResources={true} />
                    </div>
                  </Box>
                );
              }

              return (
                <Box className="border-transparent" key={ind}>
                  <Box
                    className="navbar-link"
                    onClick={() => {
                      kompassNavigate(item.router);
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                      document.body.style.overflow = "";
                    }}
                    onMouseOver={(e) => {
                      e.stopPropagation();

                      setIsProduct(false);
                    }}
                  >
                    {item.label}
                  </Box>
                </Box>
              );
            })}
          </nav>
        </Box>
      )}

      {!isTablet_ && (
        <Box
          sx={{
            backgroundColor: isSticky
              ? hexToRGB("#FFFFFF", 100) + " !important"
              :"transparent !important"
          }}
          className={`h-[75px] z-[100] w-full px-12 max-lg:px-4
            ${isSticky ? "sticky left-0 top-0" : "relative "}
          `}
        >
          <Box className="flex h-full items-center justify-between">
            <Box className="flex-1">
              <Link to={"/"}>
                {invertColor && !isSticky ? (
                  <MainLogo className="MainLogo_Pos fill-current text-white" />
                ) : (
                  <MainLogo className="MainLogo_Pos fill-current text-stone-950" />
                )}
              </Link>
            </Box>
            <Box className="font-Outfit Navbar__menu flex items-center justify-center gap-2 max-h-[38px] 2xl:max-h-[42px]">
              {navList.map((item, ind) => {
                if (item.router === "/prospecting") {
                  return (
                    <Box key={ind} className="Navbar__menu-item group gap-2">
                      <Box
                        className="flex items-center justify-center gap-2"
                        sx={{ position: isProduct ? "relative" : "initial" }}
                        key={ind}
                      >
                        <Box
                          className={`navbar-link ${invertColor && !isSticky && "navbar-link-invert"}`}
                        >
                          {item.label}
                        </Box>
                        <span
                          aria-hidden="true"
                          className="Navbar__menu-item-arrow group-hover:rotate-180"
                        >
                          <svg
                            height="6"
                            width="8"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 3.388L0 .387v2l4 3.001 4.001-3.001v-2z"
                              fill="#111"
                              fill-Rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </Box>
                      <div
                        className="Navbar__mega-menu products absolute top-[60px] z-[10001] hidden rounded-md border 
                          border-stone-250 bg-white drop-shadow-lg filter focus-within:block group-hover:block"
                        aria-hidden="true"
                      >
                        <Navbar isProduct={true} />
                      </div>
                    </Box>
                  );
                }

                if (item.router === "/aboutme") {
                  return (
                    <Box className="Navbar__menu-item group gap-8" key={ind}>
                      <Box
                        sx={{ position: isProduct ? "relative" : "initial" }}
                        key={ind}
                      >
                        <Box
                          className={`navbar-link ${invertColor && !isSticky && "navbar-link-invert"}`}
                        >
                          {item.label}
                        </Box>
                        <span
                          aria-hidden="true"
                          className="Navbar__menu-item-arrow group-hover:rotate-180"
                        >
                          <svg
                            height="6"
                            width="8"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 3.388L0 .387v2l4 3.001 4.001-3.001v-2z"
                              fill="#111"
                              fill-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </Box>
                      <div
                        className="Navbar__mega-menu products absolute top-[75px]  z-[10001] hidden rounded-md border border-stone-250 bg-white
                          drop-shadow-lg filter focus-within:block group-hover:block"
                        aria-hidden="true"
                      >
                        <Navbar isResources={true} />
                      </div>
                    </Box>
                  );
                }

                if (item.router === "/faq") {
                  return (
                    <Box className="Navbar__menu-item group gap-2" key={ind}>
                      <Box
                        className="flex items-center justify-center gap-2"
                        sx={{ position: isProduct ? "relative" : "initial" }}
                        key={ind}
                      >
                        <Box
                          className={`navbar-link ${invertColor && !isSticky && "navbar-link-invert"}`}
                        >
                          {item.label}
                        </Box>

                        <span
                          aria-hidden="true"
                          className="Navbar__menu-item-arrow group-hover:rotate-180"
                        >
                          <svg
                            height="6"
                            width="8"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 3.388L0 .387v2l4 3.001 4.001-3.001v-2z"
                              fill="#111"
                              fillRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </Box>
                      <div
                        className="Navbar__mega-menu products absolute top-[60px]  z-[10001] hidden rounded-md border
                          border-stone-250 bg-white drop-shadow-lg filter focus-within:block group-hover:block"
                        aria-hidden="true"
                      >
                        <Navbar isResources={true} />
                      </div>
                    </Box>
                  );
                }

                return (
                  <Box
                    className="Navbar__menu-item border-transparent hover:border-stone-950"
                    key={ind}
                  >
                    <Box
                      className={`navbar-link ${
                        invertColor && !isSticky && "navbar-link-invert"
                      } `}
                      onClick={() => {
                        kompassNavigate(item.router);
                        window.scroll({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                      onMouseOver={(e) => {
                        e.stopPropagation();
                        setIsProduct(false);
                      }}
                    >
                      {item.label}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box className="flex items-center gap-4 flex-1 justify-end">
              <Button
                onClick={() => {
                  kompassNavigate("/signin");
                }}
                className={`normal-case	 font-OutfitBold font-bold cursor-pointer rounded-lg border bg-transparent py-3 px-4 text-sm leading-[1.2] tracking-[0.32px] ${
                  invertColor && !isSticky
                    ? "hover:border-white text-white"
                    : "border-stone-950 hover:bg-stone-950 hover:text-white text-black"
                } `}
              >
                Login
              </Button>
              <Button
                onClick={() => {
                  kompassNavigate("/demo");
                }}
                className={`normal-case	font-outfit cursor-pointer border rounded-lg py-3 px-4 text-sm leading-[1.2] tracking-[0.32px] ${
                  invertColor && !isSticky
                    ? "bg-white text-stone-950"
                    : "bg-stone-950 text-white hover:text-black hover:bg-transparent border-stone-950"
                } `}
              >
                Book a Demo
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
