import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { CircularProgress } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useAIEngagement from "@/hooks/useAIEngagement";
import CustomSingleSelectAutoComplete from "../CustomSingleSelectAutoComplete";
import { toast } from "react-toastify";
import PreviewFilters from "./PreviewFilters";
import PreviewContacts from "./PreviewContacts";
import { AuthContext } from "@/context/AuthContext";

const defaultIcpTags = {
  location_country: [],
  location_name: [],
  location_continent: [], //new
  location_region: [], //new
  job_company_location_continent: [], //new
  job_company_location_country: [], //new
  job_company_location_region: [], //new
  job_title: [],
  job_title_levels: [],
  job_title_role: [],
  job_title_sub_role: [],
  industry: [],
  job_company_size: [],
  job_company_location_name: [],
  rangeInputs: {
    job_company_total_funding_raised: { min: "", max: "" },
  },
  job_company_inferred_revenue: [],
};

export default function TargetCustomerPersona({
  setSelectedStep,
  campaignId,
  setCampaignId,
  editCampaignData,
  isEdit,
}) {
  console.log(editCampaignData, "editCampaignData saddam");
  const { userInfo } = useContext(AuthContext);
  const [flexSearchInput, setFlexSearchInput] = useState("");
  const [loading, setLoading] = useState({
    flexSearch: false,
    targetList: false,
    continue: false,
    confirmFilters: false,
  });
  const [searchResults, setSearchResults] = useState({});
  const [targetList, setTargetList] = useState([]);
  const [selectedTargetList, setSelectedTargetList] = useState({});
  const [numberOfLeads, setNumberOfLeads] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [senderName, setSenderName] = useState(userInfo?.userName || "");
  const [senderJobTitle, setSenderJobTitle] = useState("");
  const [isStandardList, setIsStandardList] = useState(false);
  const [openPreviewFilters, setOpenPreviewFilters] = useState(false);
  const [openPreviewContacts, setOpenPreviewContacts] = useState(false);

  const [icpTags, setIcpTags] = useState(defaultIcpTags);

  useEffect(() => {
    if (editCampaignData) {
      setFlexSearchInput(editCampaignData.icpDescription || "");
      setSearchResults(editCampaignData || {});
      setNumberOfLeads(editCampaignData.listMaxSize || 0);
      setCampaignName(editCampaignData.campaignName || "");
      setIsStandardList(editCampaignData.listType === "standard");
      setIcpTags(editCampaignData.icpTags || defaultIcpTags);
      setSenderName(editCampaignData.senderName || "");
      setSenderJobTitle(editCampaignData.jobTitle || "");
      setTargetList(
        editCampaignData?.listRes?.map(list => ({
          value: list._id,
          label: list.listTitle,
          contacts: list.contacts,
        })) || [],
      );
      const selectedList = editCampaignData.listRes?.find(
        list => list._id == editCampaignData.listId,
      );
      setSelectedTargetList({
        value: selectedList?._id,
        label: selectedList?.listTitle,
        contacts: selectedList?.contacts,
      });
    }
  }, [editCampaignData]);

  const { flexSearch, getTargetList, updateCampaignData, confirmFilters } =
    useAIEngagement();

  // Flex search
  const handleFlexSearch = async () => {
    if (!flexSearchInput) {
      return;
    }
    setLoading({ ...loading, flexSearch: true });
    const response = await flexSearch({
      description: flexSearchInput,
      campaignId,
    });
    if (response.status) {
      setSearchResults(response);
      setIcpTags(response.icpTags || []);
      setCampaignId(response.campaignId); // store campaign id into store for next stages
    } else {
      toast.error("No records were found matching your search.");
      setIcpTags(response.icpTags || []);
      setCampaignId(response.campaignId);
    }
    setLoading({ ...loading, flexSearch: false });
  };

  // get target list
  const handleTargetList = async () => {
    setNumberOfLeads(0);
    setLoading({ ...loading, targetList: true });
    const response = await getTargetList();
    setTargetList(
      response.map(list => ({
        value: list._id,
        label: list.listTitle,
        contacts: list.contacts,
      })),
    );
    setSelectedTargetList(
      response.map(list => ({
        value: list._id,
        label: list.listTitle,
        contacts: list.contacts,
      }))[0],
    );
    setIsStandardList(true);
    setLoading({ ...loading, targetList: false });
  };

  // handle target list select
  const handleTargetListSelect = (event, value) => {
    setSelectedTargetList(value);
  };

  // save and continue
  const handleSaveAndContinue = async () => {
    if (!campaignName) {
      toast.error("Campaign name is required");
      return;
    }
    if (!senderName) {
      toast.error("Sender name is required");
      return;
    }
    if (!senderJobTitle) {
      toast.error("Sender job title is required");
      return;
    }

    // For select from list
    if (isStandardList) {
      if (numberOfLeads > selectedTargetList.contacts || numberOfLeads > 1000) {
        toast.error(
          "Number of leads can't be more than matching criteria or 1000",
        );
        return;
      }
      if (numberOfLeads <= 0) {
        toast.error("Number of leads can't be zero or negative");
        return;
      }
      setLoading({ ...loading, continue: true });
      const dataToUpdate = {
        campaignName,
        campaignId,
        listType: "standard",
        listId: selectedTargetList.value,
        listMaxSize: numberOfLeads,
        senderName,
        jobTitle: senderJobTitle,
      };
      const response = await updateCampaignData(dataToUpdate);
      if (!response.status) {
        setLoading({ ...loading, continue: false });
        return toast.error("Something went wrong !");
      }
      if (response.isNewCampaign) {
        setCampaignId(response.campaignId); // store campaign id into store for next stages
      }
      toast.success("Target Customer Persona added successfully !");
      setSelectedStep("2. Campaign Pitch");
      return setLoading({ ...loading, continue: false });
    }

    // For flex search
    if (!searchResults.campaignId) {
      toast.error("Please perform Flex Search or select from list");
      return;
    }
    if (numberOfLeads <= 0) {
      toast.error("Number of leads can't be zero or negative");
      return;
    }
    if (
      numberOfLeads > searchResults?.previewData?.totalResults ||
      numberOfLeads > 1000
    ) {
      toast.error(
        "Number of leads can't be more than matching criteria or 1000",
      );
      return;
    }
    setLoading({ ...loading, continue: true });
    const dataToUpdate = {
      campaignId: searchResults.campaignId,
      campaignName,
      listType: "ai_list",
      listId: null,
      listMaxSize: numberOfLeads,
      senderName,
      jobTitle: senderJobTitle,
    };
    const response = await updateCampaignData(dataToUpdate);
    if (!response.status) {
      setLoading({ ...loading, continue: false });
      return toast.error("Something went wrong !");
    }
    setLoading({ ...loading, continue: false });
    toast.success("Target Customer Persona added successfully !");
    setSelectedStep("2. Campaign Pitch");
  };

  // confirm filters
  const handleConfirmFilters = async () => {
    if (!searchResults?.campaignId) return;
    setLoading(prev => ({ ...prev, confirmFilters: true }));
    const dataToUpdate = {
      campaignId: searchResults.campaignId,
      icpTags,
    };
    const res = await confirmFilters(dataToUpdate);
    if (!res.status) {
      setSearchResults({
        ...searchResults,
        previewData: { totalResults: 0, data: [] },
      });
      toast.error("No records were found matching your filters.");
    } else {
      setSearchResults({ ...searchResults, previewData: res.data });
      setOpenPreviewFilters(false);
      toast.success("Filters updated successfully !");
    }
    setLoading(prev => ({ ...prev, confirmFilters: false }));
  };

  if (isEdit) {
    return (
      <div className="flex justify-center items-center h-[500px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <PreviewFilters
        icpTags={icpTags}
        setIcpTags={setIcpTags}
        open={openPreviewFilters}
        setOpen={setOpenPreviewFilters}
        handleConfirmFilters={handleConfirmFilters}
        loading={loading.confirmFilters}
      />
      <PreviewContacts
        open={openPreviewContacts}
        previewData={searchResults?.previewData?.data}
        setOpen={setOpenPreviewContacts}
      />
      <div className="campaign-card">
        <div className="flex gap-x-3 items-center">
          <h2 className="font-medium">Add Campaign Name</h2>
          <InfoOutlinedIcon />
        </div>
        <input
          type="text"
          value={campaignName}
          onChange={e => setCampaignName(e.target.value)}
          className="campaign-card-input mt-3 placeholder-[#a1a1aa]"
          placeholder="Type here..."
        />
      </div>

      <div className="campaign-card mt-8">
        <div className="flex gap-x-3 items-center">
          <h2 className="font-medium">Confirm Sender Details</h2>
          <InfoOutlinedIcon />
        </div>
        <h2 className="mt-5 font-medium text-sm">Sender Name</h2>
        <input
          type="text"
          value={senderName}
          onChange={e => setSenderName(e.target.value)}
          className="campaign-card-input mt-1 placeholder-[#a1a1aa]"
          placeholder="Type here..."
        />
        <h2 className="font-medium text-sm mt-7">Sender Job Title</h2>
        <input
          type="text"
          value={senderJobTitle}
          onChange={e => setSenderJobTitle(e.target.value)}
          className="campaign-card-input mt-1 placeholder-[#a1a1aa]"
          placeholder="Type here..."
        />
      </div>

      {isStandardList ? (
        <>
          <div className="campaign-card mt-8">
            <div className="flex gap-x-3 items-center ">
              <div className="flex items-center gap-3">
                <p className="font-medium">Select List of Leads</p>
                <InfoOutlinedIcon />
              </div>
              <Button
                className="ml-auto font-Outfit flex py-2 w-[150px] items-center justify-center rounded-md bg-black text-sm capitalize text-white"
                onClick={() => {
                  setIsStandardList(false);
                  setNumberOfLeads(0);
                }}
              >
                Use Flex Search
              </Button>
            </div>
            <p className="text-sm text-dgray-500 mb-4">
              Only email enriched contacts will be included
            </p>
            <CustomSingleSelectAutoComplete
              id="target-list-select"
              options={targetList}
              onSelectionChange={handleTargetListSelect}
              selectedValue={selectedTargetList}
              placeholder="Select List"
              isLabelAvailable={true}
              isIconVisible={false}
              isBorderVisible={true}
              bgColor="#f9f9f9"
              sx={{ width: "100%", cursor: "pointer" }}
            />
          </div>
          <div className="campaign-card mt-8">
            <div className="flex gap-x-3 items-center">
              <h2 className="font-medium">Total Leads in List</h2>
              <InfoOutlinedIcon />
            </div>
            <h1 className="text-lg font-semibold text-blue-500">
              {" "}
              {selectedTargetList?.contacts?.toLocaleString() || 0}{" "}
            </h1>
            <p className="text-sm font-medium mt-4">
              {" "}
              Leads to Select in Campaign{" "}
            </p>
            <input
              onChange={e => setNumberOfLeads(e.target.value)}
              value={numberOfLeads}
              type="number"
              className="campaign-card-input md:w-1/2 mt-2 placeholder-[#a1a1aa]"
              placeholder="Select Number (Free Text - restricted value for numbers)"
            />
            {numberOfLeads > selectedTargetList?.contacts ? (
              <p className="text-red-500 text-sm mt-2">
                Number of leads can't be more than matching criteria
              </p>
            ) : numberOfLeads > 1000 ? (
              <p className="text-red-500 text-sm mt-2">
                Number of leads can't be more than 1000
              </p>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className="campaign-card mt-8">
            <div className="flex gap-x-3 items-center">
              <h2 className="font-medium">KompassAI Flex Search</h2>
              <InfoOutlinedIcon />
            </div>
            <div className="flex items-center gap-3 text-sm mt-5 overflow-x-auto whitespace-nowrap">
              <p className="font-semibold flex-shrink-0">Suggestion:</p>
              <p className="campaign-card-suggestion">
                Looking to prospect C-Suite Executives for all Software
                Companies based in San Francisco?
              </p>
              <p className="campaign-card-suggestion">
                Looking for design agency in Detroit?
              </p>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center gap-3 mt-3">
              <input
                type="text"
                onChange={e => setFlexSearchInput(e.target.value)}
                value={flexSearchInput}
                className="campaign-card-input placeholder-[#a1a1aa]"
                placeholder="Type here..."
              />
              <div className="flex items-center gap-3">
                <Button
                  className="font-Outfit flex py-2 w-[150px] items-center justify-center rounded-md bg-black text-sm capitalize text-white"
                  onClick={handleFlexSearch}
                  disabled={loading.flexSearch}
                >
                  {loading.flexSearch ? (
                    <CircularProgress
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : (
                    <span className="whitespace-nowrap">Flex Search</span>
                  )}
                </Button>
                <p className="font-semibold">Or</p>
                <Button
                  className="font-Outfit flex py-2 w-[150px] items-center justify-center rounded-md bg-black text-sm capitalize text-white"
                  onClick={handleTargetList}
                  disabled={loading.targetList}
                >
                  {loading.targetList ? (
                    <CircularProgress
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : (
                    <span className="whitespace-nowrap">Select From List</span>
                  )}
                </Button>
              </div>
            </div>
          </div>

          <div className="campaign-card mt-8">
            <div className="flex gap-x-3 items-center">
              <h2 className="font-medium">Leads Matching Criteria</h2>
              <InfoOutlinedIcon />
            </div>
            <h1 className="text-lg font-semibold text-blue-500">
              {" "}
              {searchResults?.previewData?.totalResults?.toLocaleString() ||
                0}{" "}
            </h1>
            <p className="text-sm font-medium mt-4">
              {" "}
              Leads to Select in Campaign{" "}
            </p>
            <input
              onChange={e => setNumberOfLeads(e.target.value)}
              value={numberOfLeads}
              type="number"
              className="campaign-card-input md:w-1/2 mt-2 placeholder-[#a1a1aa]"
              placeholder="Select Number (Free Text - restricted value for numbers)"
            />
            <div className="flex items-center gap-3 mt-4">
              <Button
                className="font-Outfit flex py-2 w-[150px] items-center justify-center rounded-md bg-black text-sm capitalize text-white"
                onClick={() => setOpenPreviewFilters(true)}
              >
                Preview Filters
              </Button>
              <p className="font-semibold">Or</p>
              <Button
                className="font-Outfit flex whitespace-nowrap py-2 w-[150px] items-center justify-center rounded-md bg-black text-sm capitalize text-white"
                onClick={() => setOpenPreviewContacts(true)}
              >
                Preview Contacts
              </Button>
            </div>
            {numberOfLeads > searchResults.totalResults ? (
              <p className="text-red-500 text-sm mt-2">
                Number of leads can't be more than matching criteria
              </p>
            ) : numberOfLeads > 1000 ? (
              <p className="text-red-500 text-sm mt-2">
                Number of leads can't be more than 1000
              </p>
            ) : null}
          </div>
        </>
      )}
      <div className="flex justify-end">
        <button
          onClick={handleSaveAndContinue}
          className="mt-5 bg-blue-500 cursor-pointer hover:bg-blue-600 text-white text-sm py-2 px-16 rounded-md font-medium"
        >
          {loading.continue ? (
            <div className="flex items-center gap-3">
              <CircularProgress style={{ width: "20px", height: "20px" }} />{" "}
              <span>Saving...</span>
            </div>
          ) : (
            <span className="whitespace-nowrap">Next</span>
          )}
        </button>
      </div>
    </>
  );
}
