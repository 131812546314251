import { ReactComponent as ProfileIcon } from "@/assets/image/settings/profile.svg";
import { ReactComponent as BilingIcon } from "@/assets/image/settings/biling.svg";

export default function SettingsNavBar({
  isTeamMember,
  currentTabIndex,
  setCurrentTabIndex,
}) {
  return (
    <div className="w-full h-full flex flex-col justify-start items-center p-6 gap-1 border-b md:border-r md:border-b-0 border-stone-250">
      <div
        className={`w-full flex flex-row items-center rounded-md hover:bg-stone-150 ${
          currentTabIndex === 0 ? "bg-stone-150" : "bg-none"
        } text-center cursor-pointer gap-2 p-3`}
        onClick={() => {
          setCurrentTabIndex(0);
        }}
      >
        <ProfileIcon className="h-4 w-4" />
        <p className="text-md font-Outfit">Profile</p>
      </div>
      {!isTeamMember && (
        <div
          className={`w-full flex flex-row items-center rounded-md hover:bg-stone-150 ${
            currentTabIndex === 2 ? "bg-stone-150" : "bg-none"
          } text-center cursor-pointer gap-2 p-3`}
          onClick={() => {
            setCurrentTabIndex(2);
          }}
        >
          <BilingIcon className="h-4 w-4" />
          <p className="text-md font-Outfit">Billing</p>
        </div>
      )}
        {!isTeamMember && (
        <div
          className={`w-full flex flex-row items-center rounded-md hover:bg-stone-150 ${
            currentTabIndex === 3 ? "bg-stone-150" : "bg-none"
          } text-center cursor-pointer gap-2 p-3`}
          onClick={() => {
            setCurrentTabIndex(3);
          }}
        >
          <BilingIcon className="h-4 w-4" />
          <p className="text-md font-Outfit">Other Settings</p>
        </div>
      )}
    </div>
  );
}
