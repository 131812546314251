import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "@material-tailwind/react";
import PricingComponent from "@/components/pricing/PricingComponent/PricingComponent";
import PricingTable from "@/components/pricing/PricingTable";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import useBillingStore from "@/store/useBillingStore";
import { AuthContext } from "@/context/AuthContext";
import CustomCreditsSelector from "./CustomCreditsSelector";

const tiers = {
  free: {
    title: "Team",
    slogan: "Test drive it",
    theme: {
      border: "border-stone-250",
      main: "",
    },
  },
  professional: {
    title: "Professional",
    slogan: "Essential for finding your prospects",
    theme: {
      border: "border-[#F1A9FF]",
      main: "bg-[#F1A9FF]",
    },
  },
  premium: {
    title: "Premium",
    slogan: "Perfect for small teams with simple workflows",
    theme: {
      border: "border-accents-green",
      main: "bg-accents-green",
    },
    promo: "Most popular",
  },
  enterprise: {
    title: "Enterprise",
    slogan: "Ideal for prospecting at large scales",
    theme: {
      border: "border-[#9AB2FF]",
      main: "bg-[#9AB2FF]",
    },
  },
};

function FullPricingBlock(props) {
  const {
    tariffList,
    subscriptionInfo,
    customCreditsInfo,
    annual,
    loading,
    topPosition,
  } = props;
  const { changePrices, setRedirect, cleanPrices, changePlan, cleanPlan } =
    useBillingStore();
  const authContext = useContext(AuthContext);
  const [signed, setSigned] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState({
    planKey: null,
    price: null,
  });
  const currentPlan = tariffList?.find(
    (tariff) => tariff.key === selectedPlan.planKey
  );
  const hideFreePlan =
    signed && subscriptionInfo && subscriptionInfo.plan !== "Free";
  const fieldRef = useRef(null);
  const navigate = useNavigate();

  //Selecting a plan by clicking a button in a table or on a Plan card
  const handleSelection = (product, price_obj, isCustomUpgrade) => {
    if (!signed) {
      setRedirect("/billingpay");
      navigate("/signin");
      return;
    }

    changePrices({ main: price_obj });
    changePlan(product);
    //If not customizable, redirect to payment
    if (product.may_be_customized !== 1) {
      cleanPrices();
      changePrices({ main: price_obj });

      if (isCustomUpgrade) {
        navigate("/planupgrade", { state: { redirected: true } });
        return;
      }

      if (subscriptionInfo.plan !== "Free") {
        navigate("/planupgrade", { state: { redirected: true } });
        return;
      }

      navigate("/billingpay");
      return;
    } else if (selectedPlan.planKey === product.key) {
      cleanPrices();
      cleanPlan();
      setSelectedPlan({ planKey: null, price: null });
      return;
    } else {
      setSelectedPlan({ planKey: product.key, price: price_obj });
    }
  };

  // Selecting amount of extra credits on the slider
  const handleCustomSelection = () => {
    if (!signed) {
      setRedirect("/billingpay");
      navigate("/signin");
      return;
    }

    if (subscriptionInfo.plan !== "Free") {
      navigate("/planupgrade", { state: { redirected: true } });
      return;
    }

    if (subscriptionInfo?.plan?.toLowerCase() === selectedPlan.planKey) {
      navigate("/planupgrade", { state: { redirected: true } });
      return;
    }

    navigate("/billingpay");
  };

  useEffect(() => {
    (async () => {
      const signedIn = await authContext.isSignIn();
      setSigned(signedIn);
    })();
  }, []);

  useEffect(() => {
    if (subscriptionInfo?.customized_credits && customCreditsInfo) {
      Object.entries(subscriptionInfo.customized_credits).forEach(
        ([key, value]) => {
          // Find the matching credit information
          const priceInfo = customCreditsInfo.find((info) =>
            info.variations.some(
              (variation) => variation.credits[key] === value
            )
          );

          // If a match is found, get the corresponding variation
          if (priceInfo) {
            const matchingVariation = priceInfo.variations.find(
              (variation) => variation.credits[key] === value
            );

            // Update prices if a matching variation is found
            if (matchingVariation) {
              changePrices({
                [key]: {
                  price: matchingVariation.price,
                  interval: matchingVariation.interval,
                  lookup_key: matchingVariation.lookup_key,
                  credits: { [key]: value },
                },
              });
            }
          }
        }
      );
    }
  }, [subscriptionInfo, customCreditsInfo]);

  //Smoothly scroll sliders into view on selecting customizable plan
  useEffect(() => {
    if (selectedPlan.planKey) {
      fieldRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedPlan.planKey, fieldRef.current]);

  return (
    <div className="pt-12 xl:pt-16 flex h-fit w-full flex-col">
      <p className="mb-8 text-sm font-medium">
        * All features enabled until August 30, 2024 for all plans.
      </p>
      <div
        className={`w-full grid gap-x-6 xl:gap-x-10 gap-y-20 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ${hideFreePlan ? "lg:grid-cols-3" : "lg:grid-cols-4"} items-end justify-between justify-items-between xl:gap-8 self-center`}
      >
        {tariffList?.map((product, index) => {
          if (hideFreePlan && product.key === "free") return <></>;
          return (
            <PricingComponent
              loading={loading}
              key={product.id}
              current={
                subscriptionInfo?.plan?.toLowerCase() ===
                  product?.name?.toLowerCase() &&
                subscriptionInfo?.status !== "canceled"
              }
              selected={currentPlan?.key === product.key}
              tiers={tiers}
              handleSelection={handleSelection}
              product={product}
              planState={[selectedPlan, setSelectedPlan]}
              tierNumber={index}
              annual={annual}
              expandState={[expanded, setExpanded]}
            />
          );
        })}
      </div>
      <AnimatePresence>
        {currentPlan && currentPlan?.may_be_customized && (
          <motion.div
            ref={fieldRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mx-[42px] mb-[30px] mt-[72px] flex w-[calc(100%-84px)] scroll-m-[64px] flex-col"
          >
            <div className="font-OutfitSemiBold mb-[30px] text-[28px]">
              Customize Credits
            </div>
            {currentPlan.product === "enrich" && (
              <div className="flex flex-col gap-[70px]">
                <CustomCreditsSelector
                  annual={annual}
                  currentPlan={currentPlan}
                  optionsKey="customize_phone_credits"
                  priceKey="phones"
                  sliderTitle="Phone credits"
                  customCreditsInfo={customCreditsInfo}
                  timeout={false}
                ></CustomCreditsSelector>
                <CustomCreditsSelector
                  annual={annual}
                  currentPlan={currentPlan}
                  priceKey="emails"
                  optionsKey="customize_email_credits"
                  sliderTitle="Email credits"
                  customCreditsInfo={customCreditsInfo}
                  timeout={false}
                ></CustomCreditsSelector>
                <CustomCreditsSelector
                  annual={annual}
                  currentPlan={currentPlan}
                  priceKey="validations"
                  optionsKey="customize_email_validation_credits"
                  sliderTitle="Validation credits"
                  customCreditsInfo={customCreditsInfo}
                  timeout={false}
                ></CustomCreditsSelector>
              </div>
            )}
            {currentPlan.product === "ip" && (
              <div className="flex flex-col gap-[70px]">
                <CustomCreditsSelector
                  annual={annual}
                  currentPlan={currentPlan}
                  optionsKey="customize_ip_credits"
                  priceKey="ips"
                  sliderTitle="Ip credits"
                  customCreditsInfo={customCreditsInfo}
                  timeout={false}
                ></CustomCreditsSelector>
              </div>
            )}
            <Button
              onClick={handleCustomSelection}
              className={`text-md font-Outfit mt-6 flex w-[280px] items-center justify-center self-center rounded-lg bg-black p-[16px_24px] font-[700] uppercase tracking-wide text-white transition-all  `}
            >
              To Checkout
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
      {tariffList && tariffList.length > 0 && (
        <PricingTable
          tiers={tiers}
          annual={annual}
          current={currentPlan}
          hideFreePlan={hideFreePlan}
          planState={[selectedPlan, setSelectedPlan]}
          subscriptionInfo={subscriptionInfo}
          products={tariffList}
          handleSelection={handleSelection}
          topPosition={topPosition}
        />
      )}
    </div>
  );
}

export default FullPricingBlock;
