
import useAuth from "@/hooks/useAuth";

export function useHTTPRequest(
  link,
  method = "GET",
  unauthorizedRequest = false,
  headers = {},
  overrideBase = ""
) {
  const { getAuthToken } = useAuth();
  const asyncRequest = async (params = undefined) => {
    try {
      if (headers?.type === "file") {
        headers = {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data`,
        };
      } else {
        headers = {
          "Content-Type": "application/json",
        };
      }
      const param = {
        method,
        // headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        headers: {
          ...headers,
        },
      };
      if (!unauthorizedRequest) {
        const authToken = await getAuthToken();
        param.headers["Authorization"] = `Bearer ${authToken}`;
      }
      if (params) {
        param.body = params;
      }
      // const bb = new Busboy({ headers: { "content-type": contentType } });
      const _resData = await fetch(
        (overrideBase || process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT) + link,
        param
      );
      if (_resData.ok) {
        const data = await _resData.json();
        return data;
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (e) {
      console.log(e);
      const errorResponse = {
        status: false,
        message: e.message || "Something went wrong!",
      };
      throw errorResponse;
    }
  };
  return asyncRequest;
}

export function useHTTPRequestObject({
  link,
  method = "GET",
  unauthorizedRequest = false,
  headers = {},
  overrideBase = "",
}) {
  const { getAuthToken } = useAuth();
  const asyncRequest = async (params = undefined) => {
    try {
      const param = {
        method,
        // headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      };
      if (!unauthorizedRequest) {
        const authToken = await getAuthToken();
        param.headers["Authorization"] = `Bearer ${authToken}`;
      }
      if (params) {
        param.body = params;
      }
      const _resData = await fetch(
        (overrideBase || process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT) + link,
        param
      );
      if (_resData.ok) {
        const data = await _resData.json();
        return data;
      } else {
        const data = await _resData.json();
        console.log(data);
        throw data;
      }
    } catch (e) {
      console.log(e);
      const errorResponse = {
        status: false,
        message: e.message || "Something went wrong!",
      };
      throw errorResponse;
    }
  };
  return asyncRequest;
}