import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmDelete({
  open,
  setOpen,
  handleClickOpen,
  handleClose,
  handleConfirm,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this team member? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="flex items-center justify-center border-black bg-white border-[1px] bg-none text-sm text-black hover:bg-[#000] font-Outfit font-semibold  gap-2 p-[12px_24px] hover:text-white w-30"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await handleConfirm();
              handleClose();
            }}
            autoFocus
            className="flex items-center justify-center  border-[1px] bg-none text-sm text-white hover:text-white hover:bg-black font-Outfit font-semibold  p-[12px_24px] w-30"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
