import { ReactComponent as CustomCheckBox } from "@/assets/image/icons/checkbox_free.svg";
import { ReactComponent as DownSVGIcon } from "@/assets/image/icons/down.svg";
import cn from "classnames";
import { Button } from "@material-tailwind/react";
import Text from "@/components/Text";
import PlanDetails from "./PlanDetails";

export default function PricingComponent(props) {
  const {
    tiers,
    current,
    selected,
    product,
    annual,
    expandState,
    handleSelection,
  } = props;
  const theme = tiers[product.key].theme;
  const promo = product.key === "premium";
  const [showBenefits, setShowBenefits] = expandState;
  const free = product.key === "free";
  const tierIndex = Object.keys(tiers).indexOf(product.key);
  const prevTier = tierIndex === 0 ? null : Object.keys(tiers)[tierIndex - 1];
  const customizable = product.may_be_customized === 1 ? true : false;
  const features = tierIndex ? product.additionalFeatures : product.features;
  const price_obj =
    product.variations.length > 1
      ? product.variations.find(
          (x) => x.interval === (annual ? "year" : "month")
        )
      : product.variations[0];

  const isCustomUpgrade = current && !selected;

  const handleSelectPlan = () => {
    handleSelection(product, price_obj, isCustomUpgrade);
  };

  return (
    <div
      className={`${theme.border} relative flex h-full w-full flex-col justify-between rounded-lg border-[2px]  `}
    >
      {promo && (
        <div
          className={`absolute w-full bottom-full align-center flex ${theme.main} ${theme.border} h-[84px]} justify-center rounded-[8px] rounded-bl-none rounded-br-none text-black`}
        >
          <Text
            variant="Caption"
            className={`flex p-5 text-lg uppercase text-black drop-shadow-lg`}
          >
            Most popular
          </Text>
        </div>
      )}
      <div className="py-3 px-5 xl:py-4 xl:px-6 2xl:py-6 2xl:px-8 w-full h-full flex flex-col">
        <div className={`w-full rounded-lg`}>
          <div
            className={`relative flex w-full flex-col rounded-lg rounded-bl-none rounded-br-none bg-white ${theme.text} `}
          >
            <h2 className="font-OutfitBold text-3xl">{product.name}</h2>
            <Text
              variant="CaptionRegular"
              className="text-sm leading-[1.4] mt-4 text-stone-950 h-[32px]"
            >
              {tiers[product.key].slogan}
            </Text>
            <div className="mt-10 mb-4 flex gap-2">
              <span className="font-OutfitSemiBold text-[2.875rem] leading-[3.45rem] tracking-normal text-stone-950">
                $
                {product.key === "free"
                  ? product.variations[0].price / 100
                  : price_obj.price / 100}
              </span>
              <div className="mb-2 flex items-end text-base text-stone-950">
                <span className="font-OutfitBold">
                  /{annual ? "year" : "month"}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <PlanDetails
              stripePrice={price_obj}
              annual={annual}
              isEnterprise={customizable}
            />
            {free && (
              <Button
                disabled={true}
                className={cn(
                  `text-base leading-[1.2] font-Outfit mt-8 flex w-full items-center justify-center rounded-lg border-1 border-stone-950 bg-stone-950 px-6 py-4 font-bold capitalize text-white transition-all `,
                  {
                    "bg-white text-stone-950": true,
                  }
                )}
              >
                Default
              </Button>
            )}
            {!free && (
              <Button
                onClick={handleSelectPlan}
                className={cn(
                  `text-base leading-[1.2] font-Outfit mt-8 flex w-full items-center justify-center rounded-lg border-1 border-stone-950 bg-stone-950 px-6 py-4 font-bold capitalize text-white transition-all `,
                  {
                    "bg-white text-stone-950": current,
                  },
                  {
                    "bg-accents-blue border-accents-blue text-white": selected,
                  }
                )}
              >
                {isCustomUpgrade ? "Customize plan" : ""}
                {selected && "Selected"}
                {!(current || selected) && "Select plan"}
              </Button>
            )}
            <div
              className={`mt-12 flex flex-col overflow-hidden transition-[height] ${
                !showBenefits ? "h-0" : "h-[380px]"
              }`}
            >
              {prevTier && (
                <div className="font-OutfitBold mb-4 text-base text-stone-950">{`Everything in ${
                  prevTier.charAt(0).toLocaleUpperCase() + prevTier.slice(1)
                }, plus`}</div>
              )}
              {features.map((benefit) => (
                <div key={benefit} className="flex items-center mb-1">
                  <CustomCheckBox
                    className={`w-[22px] h-[22px] fill-zinc-100 stroke-stone-950 shrink-0`}
                  />
                  <div className="font-OutfitLight ml-2 text-base leading-md text-stone-950">
                    {benefit}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={cn("group flex w-full justify-center mt-auto ", {
            "!mt-12": customizable,
          })}
        >
          <div
            onClick={() => setShowBenefits(!showBenefits)}
            className="flex cursor-pointer select-none"
          >
            <div className="font-Outfit lg:text-base xl:text-xl leading-[1.35rem] group-hover:scale-[1.01] ">
              Show plan comparison
            </div>
            <div className="ml-4 flex items-center transition-all group-hover:rotate-180">
              <DownSVGIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
