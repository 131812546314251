import { permissions } from "./permissions";

import PricingScene from "@/pages/landing/PricingScene";
import LandingScene from "@/pages/landing/LandingScene";
import FAQScene from "@/pages/landing/FAQScreen";
import AboutMeScene from "@/pages/landing/AboutMeScene";
import BlogScene from "@/pages/landing/BlogScene";
import BlogPostScene from "@/pages/landing/BlogPostScene";
import ProspectingScene from "@/pages/landing/ProspectingScene";
import EmailVerificationScene from "@/pages/landing/EmailVerificationScene";
import AdvancedSearchScene from "@/pages/landing/AdvancedSearchScene";
import CSVExportScene from "@/pages/landing/CSVExportScene";
import IntegrationScene from "@/pages/landing/Integration";
import CompetitorsScene from "@/pages/landing/CompetitorsScene";

import SignIn from "@/pages/SignIn";
import ResetPassword from "@/pages/ResetPassword";
import SignUp from "@/pages/SignUp";
import EmailVerify from "@/pages/EmailVerify";

import HomeScene from "@/pages/main/HomeScene";
import SettingsScene from "@/pages/main/SettingsScene";
import TeamScene from "@/pages/main/TeamScene";
import InvitationsScene from "@/pages/main/InvitationsScene";
import IntegrationMainScene from "@/pages/main/IntegrationScene";
import PremiumPlanScene from "@/pages/main/PremiumPlanScene";
import AnalyticsScene from "@/pages/main/AnalyticsScene";
import KompassSearchScene from "@/pages/main/KompassSearchScene";
import ListsScene from "@/pages/main/ListsScene";
import BulkEnrichScene from "@/pages/main/BulkEnrichScene";
import EmailValidationScene from "@/pages/main/EmailValidationScene";
import BillingPayScene from "@/pages/main/BillingPayScene";
import PlanUpgradeScene from "@/pages/main/PlanUpgradeScene";
import UpgradeScene from "@/pages/main/UpgradeScene";
import CreditsScene from "@/pages/main/CreditsScene";
import DemoScene from "@/pages/landing/DemoScene";
import WebsiteVisitorsScene from "@/pages/main/WebsiteVisitorsScene";
import Profile from "@/components/profile/ProfileScene";
import { EmailWarmupScene } from "@/pages/main/EmailWarmupScene";
import InboxScene from "@/pages/main/ai_engagement/InboxScene";
import Campaigns from "@/pages/main/ai_engagement/Campaigns";
import MailBoxScene from "@/pages/main/ai_engagement/MailBoxScene";
import GlobalAnalytics from "@/pages/main/ai_engagement/GlobalAnalytics";
import CreateNewCampaign from "@/pages/main/ai_engagement/CreateNewCampaignScene";
import ContactActivityScene from "@/pages/main/ai_engagement/ContactActivityScene";
import CampaignContacts from "@/pages/main/ai_engagement/CampaignContacts";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import TermsAndConditions from "@/pages/TermsAndConditions";

// use 'access' field to handle permissions for the page.
// true: available for all permissions
// false: disabled for all permissions (hide route)
// premissions[]: array of available permissions

export const LANDING_ROUTES = {
  LANDING_HOME: {
    Component: LandingScene,
    path: "/",
    access: true,
  },
  LANDING_PRICING: {
    Component: PricingScene,
    path: "/pricing",
    access: true,
  },
  LANDING_FAQ: {
    Component: FAQScene,
    path: "/faq",
    access: true,
  },
  LANDING_BLOG: {
    Component: BlogScene,
    path: "/blog",
    access: true,
  },
  LANDING_BLOG_POST: {
    Component: BlogPostScene,
    path: "/blog/:postId/:slug",
    access: true,
  },
  LANDING_ABOUT_ME: {
    Component: AboutMeScene,
    path: "/aboutme",
    access: true,
  },
  LANDING_PROSPECTING: {
    Component: ProspectingScene,
    path: "/prospecting",
    access: true,
  },
  LANDING_EMAIL_VERIFICATION: {
    Component: EmailVerificationScene,
    path: "/emailverification",
    access: true,
  },
  LANDING_ADVANCED_SEARCH: {
    Component: AdvancedSearchScene,
    path: "/advancedsearch",
    access: true,
  },
  LANDING_CSV_EXPORT: {
    Component: CSVExportScene,
    path: "/csvexport",
    access: true,
  },
  LANDING_INTEGRATION: {
    Component: IntegrationScene,
    path: "/integration",
    access: true,
  },
  LANDING_COMPETITORS: {
    Component: CompetitorsScene,
    path: "/competitors",
    access: true,
  },
  LANDING_DEMO: {
    Component: DemoScene,
    path: "/demo",
    access: true,
  },
  LANDING_PROFILE: {
    Component: Profile,
    path: "/profile/:userId",
    access: true,
  },
};

export const LOGIN_ROUTES = {
  LOGIN_SIGN_IN: {
    Component: SignIn,
    path: "/signin",
    access: true,
  },
  LOGIN_SIGN_UP: {
    Component: SignUp,
    path: "/signup",
    access: true,
  },
  LOGIN_RESET: {
    Component: ResetPassword,
    path: "/reset",
    access: true,
  },
  LOGIN_VERIFICATION: {
    Component: EmailVerify,
    path: "/verification",
    access: true,
  },
  LOGIN_PRIVACY_POLICY: {
    Component: PrivacyPolicy,
    path: "/privacy-policy",
    access: true,
  },
  LOGIN_TERMS_AND_CONDITIONS: {
    Component: TermsAndConditions,
    path: "/terms-and-conditions",
    access: true,
  },
};

export const APP_ROUTES = {
  APP_HOME: {
    Component: HomeScene,
    path: "/home",
    access: true,
  },
  APP_EMAIL_VALIDATION: {
    Component: EmailValidationScene,
    path: "/email-validation",
    access: true,
  },
  APP_BULK_ENRICH: {
    Component: BulkEnrichScene,
    path: "/bulkenrich",
    access: true,
  },
  APP_SEARCH: {
    Component: KompassSearchScene,
    path: "/search",
    access: true,
  },
  APP_ANALYTICS: {
    Component: AnalyticsScene,
    path: "/analytics",
    access: true,
  },
  APP_LIST_SCENE: {
    Component: ListsScene,
    path: "/list",
    access: true,
  },
  APP_INTEGRATION: {
    Component: IntegrationMainScene,
    path: "/integrate/:integration",
    access: true,
  },
  APP_INTEGRATE: {
    Component: IntegrationMainScene,
    path: "/integrate/",
    access: true,
  },
  APP_INVITE: {
    Component: TeamScene,
    path: "/invite",
    access: [
      permissions.basicUser,
      permissions.teamOwner,
      permissions.superAdmin,
    ],
    props: { invite: true },
  },
  APP_TEAM: {
    Component: TeamScene,
    path: "/team",
    access: [
      permissions.basicUser,
      permissions.teamOwner,
      permissions.superAdmin,
    ],
  },
  APP_SETTINGS: {
    Component: SettingsScene,
    path: "/settings",
    access: true,
  },
  APP_INVITATION: {
    Component: InvitationsScene,
    path: "/invitations",
    access: [
      permissions.basicUser,
      permissions.teamOwner,
      permissions.superAdmin,
    ],
  },
  APP_PREMIUM_PLAN: {
    Component: PremiumPlanScene,
    path: "/premium-plan",
    access: false,
  },
  APP_UPGRADE: {
    Component: UpgradeScene,
    path: "/upgrade",
    access: [
      permissions.basicUser,
      permissions.teamOwner,
      permissions.superAdmin,
    ],
  },
  APP_CREDITS: {
    Component: CreditsScene,
    path: "/credits",
    access: [permissions.teamOwner],
  },
  APP_BILLING_PAY: {
    Component: BillingPayScene,
    path: "/billingpay",
    access: [
      permissions.basicUser,
      permissions.teamOwner,
      permissions.superAdmin,
    ],
  },
  APP_PLAN_UPGRADE: {
    Component: PlanUpgradeScene,
    path: "/planupgrade",
    access: [
      permissions.basicUser,
      permissions.teamOwner,
      permissions.superAdmin,
    ],
  },
  APP_WEBSITE_VISITORS: {
    Component: WebsiteVisitorsScene,
    path: "/websitevisitors",
    access: true,
  },
  APP_AI_ENGAGEMENT_INBOX: {
    Component: InboxScene,
    path: "/ai-engagement/inbox",
    access: true,
  },
  APP_AI_ENGAGEMENT_CAMPAIGNS: {
    Component: Campaigns,
    path: "/ai-engagement/campaigns",
    access: true,
  },
  APP_AI_ENGAGEMENT_MAILBOX: {
    Component: MailBoxScene,
    path: "/ai-engagement/mailbox",
    access: true,
  },
  APP_AI_ENGAGEMENT_GLOBAL_ANALYTICS: {
    Component: GlobalAnalytics,
    path: "/ai-engagement/global-analytics",
    access: true,
  },
  APP_AI_ENGAGEMENT_CREATE_CAMPAIGN: {
    Component: CreateNewCampaign,
    path: "/ai-engagement/create-campaign",
    access: true,
  },
  APP_AI_ENGAGEMENT_CONTACT_ACTIVITY: {
    Component: ContactActivityScene,
    path: "/campaigns/:campaignId/contacts/:activityId",
    access: true,
  },
  APP_AI_ENGAGEMENT_CONTACTS: {
    Component: CampaignContacts,
    path: "/campaigns/:campaignId",
    access: true,
  },
  APP_EMAIL_WARMUP: {
    Component: EmailWarmupScene,
    path: "/email-warmup/*",
    access: true,
  },
};
