export const handleCRMPushData = async (
  method,
  service,
  profileType,
  selectedIds,
  token
) => {
  const urlParams = new URLSearchParams();
  urlParams.set("pushMethod", method);
  urlParams.set("profileType", profileType);
  const res = await fetch(
    `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/${service}/data?${urlParams.toString()}`,
    {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: JSON.stringify({ ids: selectedIds }),
    }
  );
  const resJSON = await res.json();
  return resJSON;
};
