import { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import useBulk from "@/hooks/useBulk";
import ConfirmDialog from "@/components/bulk/ConfirmDialog";
import FileUploaderWidget from "@/components/bulk/FileUploaderWidget";
import { ReactComponent as CsvSVGIcon } from "@/assets/image/bulk/csv.svg";
import { ReactComponent as ContactSVGIcon } from "@/assets/image/bulk/contact.svg";
import { ReactComponent as TableSearchSVG } from "@/assets/image/bulk/table_search.svg";
import { ReactComponent as TableLinkedinSVG } from "@/assets/image/bulk/table_linkedin.svg";
import { ReactComponent as InformationSVG } from "@/assets/image/bulk/info.svg";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import AlertDialog from "../common/AlertDialog";
import useKompassRouter from "@/hooks/useKompassRouter";
import { ENRICHMENT_TYPE, KompassString, S3_URL } from "@/utils/constants";
import { goToTop, trimKeys } from "@/utils/common";
import MainLoader from "../MainLoader";

export default function BulkUploadWidget({
  currentWidgetIndex,
  setCurrentWidgetIndex,
}) {
  const { bulkEnrichByLinkedin, bulkEnrichBySearch } = useBulk();
  const [enrichOption, setEnrichOption] = useState(0); // 0: csv/xls/xlsx, 1: linkedin profile
  const [visibleConfirmDlg, setVisibleConfirmDlg] = useState(false);
  const [alertConfirmDlg, setVisibleAlertDlg] = useState(false);
  const [listName, setListName] = useState("");
  const [linkedinUrls, setLinkedinUrls] = useState([]);
  const [attachedFileInfo, setAttachedFileInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEmailIncluded, setEmailIncluded] = useState(true);
  const [isPhoneIncluded, setPhoneIncluded] = useState(true);
  const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed?", "GET");
  const { kompassNavigate } = useKompassRouter();

  const { data } = useQuery({
    queryKey: ["CreditsUsage"],
    queryFn: () => getUsageData(),
  });

  const alertMessage = () => {
    const number =
      attachedFileInfo?.inputs?.length || linkedinUrls?.length || 0;
    const pluralize = (count) => (count > 1 ? "s" : "");

    if (isEmailIncluded && !isPhoneIncluded) {
      return `${number} email credit${pluralize(number)} will be used.`;
    }
    if (!isEmailIncluded && isPhoneIncluded) {
      return `${number} phone credit${pluralize(number)} will be used.`;
    }
    if (isEmailIncluded && isPhoneIncluded) {
      return `${number} phone credit${pluralize(number)} & ${number} email credit${pluralize(number)} will be used.`;
    }
  };

  const options = [
    {
      icon: "csv",
      header: "I have a CSV to Upload",
      content:
        "Upload your own list of contacts and we’ll match profiles with accurate information",
    },
    {
      icon: "contact",
      header: "I’d like to paste a list of LinkedIn URL’s",
      content:
        "Give us a list of LinkedIn URL’s and we’ll find accurate information for each profile",
    },
  ];

  useEffect(() => {
    setAttachedFileInfo(null);
  }, [currentWidgetIndex]);

  const onOptionSelected = (option) => {
    setEnrichOption(option);
  };

  const onFinishClicked = async () => {
    if (listName?.length === 0) {
      toast.error(KompassString.BukUploadError("fill the list name"), {
        theme: "colored",
      });
    } else if (linkedinUrls?.length === 0 && enrichOption === 1) {
      toast.error(KompassString.BukUploadError("provide the LinkedIn URLs"), {
        theme: "colored",
      });
    } else if (attachedFileInfo?.inputs?.length > 500 && enrichOption === 0) {
      toast.error("Uploaded file contains more than 500 entries", {
        theme: "colored",
      });
    } else if (linkedinUrls?.length > 25 && enrichOption === 1) {
      toast.error("It is possible to enrich max 25 profiles at a time", {
        theme: "colored",
      });
    } else if (attachedFileInfo?.inputs?.length === 0 && enrichOption === 0) {
      toast.error(KompassString.BukUploadError("attach the File"), {
        theme: "colored",
      });
    } else if (!isEmailIncluded && !isPhoneIncluded) {
      toast.error("Please choose which field to enrich", {
        theme: "colored",
      });
    } else {
      if (attachedFileInfo?.inputs?.length > data?.data?.emails?.available) {
        setVisibleAlertDlg(true);
        setVisibleConfirmDlg(false);
      } else {
        setVisibleConfirmDlg(true);
      }
    }
  };

  const onBulkConfirmed = async () => {
    goToTop();
    setLoading(true);

    let enrichType;
    if (isEmailIncluded && !isPhoneIncluded)
      enrichType = ENRICHMENT_TYPE.EMAIL_ONLY;
    if (!isEmailIncluded && isPhoneIncluded)
      enrichType = ENRICHMENT_TYPE.PHONE_ONLY;
    if (isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.ALL;
    if (!isEmailIncluded && !isPhoneIncluded) return;

    if (enrichOption === 0) {
      if (attachedFileInfo?.bulkType === "linkedin") {
        await bulkEnrichByLinkedin({
          contactTitle: listName,
          inputs: attachedFileInfo?.inputs?.map((input) => ({ url: input })),
          enrichType,
        });
      }
      if (attachedFileInfo?.bulkType === "search") {
        await bulkEnrichBySearch({
          contactTitle: listName,
          inputs: attachedFileInfo?.inputs,
          enrichType,
        });
      }
    } else if (enrichOption === 1) {
      await bulkEnrichByLinkedin({
        contactTitle: listName,
        inputs: linkedinUrls?.map((url) => ({ url })),
        enrichType,
      });
    }

    setTimeout(() => {
      setLoading(false);
      setCurrentWidgetIndex(3);
    }, 3000);
  };

  const extractLinkedinUrlsFromText = (value) => {
    setLinkedinUrls(value.split("\n"));
  };

  const handleFileContent = (fileName, bulkType, fileContent) => {
    let attachedInfo = {
      name: fileName,
      content: fileContent,
      bulkType,
    };

    const inputs = [];
    if (bulkType === "linkedin") {
      for (const input of fileContent) {
        const trimmedInput = trimKeys(input);
        inputs.push(trimmedInput?.LinkedinURLs);
      }
    }
    if (bulkType === "search") {
      for (const input of fileContent) {
        const trimmedInput = trimKeys(input);
        inputs.push({
          name: trimmedInput?.Name,
          company: trimmedInput?.Company,
          location: trimmedInput?.Location,
        });
      }
    }
    attachedInfo["inputs"] = inputs;

    setAttachedFileInfo(attachedInfo);
  };

  return (
    <>
      {loading && <MainLoader />}

      {currentWidgetIndex === 1 && (
        <div className="w-full xl:max-w-[1090px] flex flex-col items-center justify-center mx-auto">
          <div className="flex flex-col w-full rounded-md border-[1px] border-[#E6E6E6] p-6 my-24 gap-8">
            <div className="flex flex-col font-Outfit text-left font-bold text-3xl">
              What format is your contact list in?
            </div>
            <div className="flex flex-col md:flex-row font-Outfit text-center text-lg justify-between gap-5">
              {options.map((option, index) => {
                return (
                  <div
                    onClick={() => {
                      onOptionSelected(index);
                    }}
                    className="w-full group justify-start rounded-lg gap-3 inline-flex bg-white border-[1px] border-[#E6E6E6] p-5 transition-all duration-300 cursor-pointer hover:bg-[#000] aria-selected:bg-[#000]"
                    aria-selected={index === enrichOption}
                  >
                    {index === 0 ? (
                      <CsvSVGIcon className="w-[42px] h-[42px] shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300" />
                    ) : (
                      <ContactSVGIcon className="w-[42px] h-[42px] shrink-0 group-aria-selected:fill-current group-aria-selected:text-white group-hover:fill-current group-hover:text-white transition-all duration-300" />
                    )}
                    <div className="flex flex-col gap-2">
                      <div className="font-Outfit select-none self-stretch text-left text-xl font-medium text-gray-950 transition-all  duration-300 group-hover:text-white  group-aria-selected:text-white">
                        {option.header}
                      </div>
                      <div className="font-Outfit select-none text-left text-xl font-light text-gray-950 transition-all duration-300 group-hover:text-white group-aria-selected:text-white">
                        {option.content}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr></hr>
            {enrichOption === 0 && (
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                  <p className="font-Outfit text-left font-bold text-3xl">
                    What KompassAI Needs
                  </p>
                  <div className="flex flex-col gap-2 font-Outfit text-base font-light leading-md">
                    <p>Please upload your contact list in CSV format.</p>
                    <p>
                      In order to match an accurate list of people with your
                      data we suggest that each row have either:
                    </p>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full md:space-between">
                  <TableSearchSVG />
                  <div className="my-auto flex items-center justify-center">
                    <p className="font-Outfit text-base font-light leading-md">
                      or
                    </p>
                  </div>
                  <TableLinkedinSVG />
                </div>
                <hr></hr>
                <div className="flex flex-col">
                  <p className="font-Outfit text-left font-bold text-3xl mb-2">
                    Use our template for the most accurate results
                  </p>
                  <span className="font-Outfit text-left text-base font-light leading-md mb-4">
                    Download our CSV template for correct column format.
                  </span>
                  <Button
                    onClick={() => {
                      window.open(S3_URL.KOMPASS_BULK_TEMPLATE, "_blank");
                    }}
                    className="font-Outfit w-fit py-3 px-6 rounded-lg border-[1px] border-[#000] bg-white text-base leading-md font-bold tracking-[0.32px] normal-case text-gray-950"
                  >
                    Kompass AI CSV Template
                  </Button>
                </div>
              </div>
            )}
            {enrichOption === 1 && (
              <>
                <div className="flex flex-col gap-2">
                  <span className="font-Outfit text-left text-base font-medium">
                    List name
                  </span>
                  <input
                    onChange={(e) => {
                      setListName(e.currentTarget.value);
                    }}
                    className="outline-none py-3 pl-4 pr-6 font-Outfit text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-stone-250 border bg-stone-150 rounded-[8px]"
                    placeholder="My Contacts"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center gap-1">
                    <span className="text-base font-medium font-Outfit ">
                      Enter the Linkedin URLs (Maximum 25)
                    </span>
                    <span className="text-base font-Outfit text-gray-500">
                      One per line
                    </span>
                  </div>
                  <textarea
                    onChange={(e) => {
                      extractLinkedinUrlsFromText(e.currentTarget.value);
                    }}
                    className="outline-none resize-none font-Outfit py-3 pl-4 pr-6 text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-stone-250 border bg-stone-150 rounded-[8px]"
                    placeholder={`https://www.linkedin.com/in/maikal-yamauchi/\nhttps://www.linkedin.com/in/imogen-low/\n....`}
                    rows={7}
                  />
                </div>
                <div className="flex flex-row gap-4">
                  <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                    <Checkbox
                      checked={isEmailIncluded}
                      onChange={(event) => {
                        setEmailIncluded(event.currentTarget.checked);
                      }}
                      className="w-5 h-5"
                      size="small"
                    />
                    Email
                  </div>
                  <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                    <Checkbox
                      checked={isPhoneIncluded}
                      onChange={(event) => {
                        setPhoneIncluded(event.currentTarget.checked);
                      }}
                      className="w-5 h-5"
                      size="small"
                    />
                    Phone
                  </div>
                </div>
              </>
            )}
            <hr></hr>
            <div className="flex flex-col gap-4">
              <p className="font-Outfit text-left text-base font-light leading-md">
                You will only be charged for accurate matches.
              </p>
              {enrichOption === 0 && (
                <div className="flex flex-row justify-between">
                  <Button
                    onClick={() => {
                      setCurrentWidgetIndex(0);
                    }}
                    className="bg-white border-[1px] border-[#000] font-Outfit rounded-md py-2 px-6 normal-case font-medium text-base leading-md text-stone-950"
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => {
                      setCurrentWidgetIndex(2);
                    }}
                    className="text-white bg-blue-500 font-Outfit rounded-md py-2 px-6 normal-case font-medium text-base leading-md"
                  >
                    Next
                  </Button>
                </div>
              )}
              {enrichOption === 1 && (
                <div className="flex flex-row justify-between">
                  <Button
                    onClick={() => {
                      setCurrentWidgetIndex(0);
                    }}
                    className="w-fit bg-white border-[1px] border-[#000] font-Outfit rounded-md py-2 px-6 normal-case font-medium text-base leading-md text-stone-950"
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={onFinishClicked}
                    className="font-Outfit h-11 w-56 flex items-center justify-center rounded-md bg-blue-500 text-base font-bold leading-md capitalize text-white"
                  >
                    Finish
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {currentWidgetIndex === 2 && (
        <div className="w-full xl:max-w-[1090px] flex flex-col items-center justify-center mx-auto text-gray-950">
          <div className="flex w-full md:max-w-[775px] flex-col rounded-md border-[1px] border-stone-250 p-6 my-16 lg:my-24 gap-8">
            <span className="font-Outfit text-left font-bold text-3xl">
              Bulk Enrich
            </span>
            <div className="flex flex-col gap-2">
              <span className="font-Outfit text-left text-base font-medium">
                List name
              </span>
              <input
                onChange={(e) => {
                  setListName(e.currentTarget.value);
                }}
                className="outline-none py-3 pl-4 pr-6 font-Outfit text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-stone-250 border bg-stone-150 rounded-[8px]"
                placeholder="My Contacts"
              />
            </div>
            <hr className="border-stone-250"></hr>
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between gap-1 font-Outfit ">
                <span className="text-base font-medium">
                  Or upload your file (Maximum 500)
                </span>
                <span className="text-gray-500 text-base">
                  Uploader supports CSV, TXT, XLS, or XLSX
                </span>
              </div>
              {attachedFileInfo ? (
                <FileUploaderWidget attachedFileName={attachedFileInfo?.name} />
              ) : (
                <FileUploaderWidget onFileContent={handleFileContent} />
              )}
              <div className="flex flex-row items-center gap-1">
                <InformationSVG />
                <span className="font-Outfit text-left text-xs text-stone-350">
                  If your file contains multple columnes, we`ll automatically
                  detect the one containing the email addresses
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                <Checkbox
                  checked={isEmailIncluded}
                  onChange={(event) => {
                    setEmailIncluded(event.currentTarget.checked);
                  }}
                  className="w-5 h-5"
                  size="small"
                />
                Email
              </div>
              <div className="flex flex-row items-center gap-1 font-Outfit text-base">
                <Checkbox
                  checked={isPhoneIncluded}
                  onChange={(event) => {
                    setPhoneIncluded(event.currentTarget.checked);
                  }}
                  className="w-5 h-5"
                  size="small"
                />
                Phone
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <Button
                onClick={() => {
                  setCurrentWidgetIndex(1);
                }}
                className="w-fit bg-white border-[1px] border-[#000] font-Outfit rounded-md py-2 px-6 normal-case font-medium text-base leading-md text-stone-950"
              >
                Previous
              </Button>
              <Button
                onClick={onFinishClicked}
                className="font-Outfit h-11 w-56 flex items-center justify-center rounded-md bg-blue-500 text-base font-bold leading-md capitalize text-white"
              >
                Finish
              </Button>
            </div>
          </div>
        </div>
      )}

      <ConfirmDialog
        open={visibleConfirmDlg}
        message={alertMessage()}
        handleClose={() => {
          setVisibleConfirmDlg(false);
        }}
        handleOK={() => {
          setVisibleConfirmDlg(false);
          onBulkConfirmed();
        }}
      />
      <AlertDialog
        open={alertConfirmDlg}
        message={KompassString.Asking4Credits}
        handleClose={() => {
          setCurrentWidgetIndex(3);
          setVisibleAlertDlg(false);
        }}
        handleOK={() => {
          setVisibleAlertDlg(false);
          kompassNavigate("/settings?tab=1");
        }}
      />
    </>
  );
}
