import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { BASE_BUTTON_STYLES } from "@/utils/common";
import { WarmupModal } from "../email_warmup/summary/WarmupModal";
import Button from "@mui/material/Button";
import useAIEngagement from "@/hooks/useAIEngagement";
import BackdropLoader from "../common/BackdropLoader";
import { toast } from "react-toastify";

export default function AllTabs({ onClickNewAccount, isNewAccountButton }) {
  const navigate = useNavigate();
  const location = useLocation();

  // Mapping of paths to tab indexes
  const pathToTabIndex = {
    "/ai-engagement/inbox": 0,
    "/ai-engagement/campaigns": 1,
    "/ai-engagement/mailbox": 2,
    "/ai-engagement/global-analytics": 3,
  };

  // Determine the initial active tab based on the current path
  const initialTab = pathToTabIndex[location.pathname] ?? false;
  const [activeTab, setActiveTab] = useState(initialTab);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { connectWithThirdPartyService, reFetchAllEmail } = useAIEngagement();

  useEffect(() => {
    setActiveTab(pathToTabIndex[location.pathname] ?? false);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);

    // Navigation logic based on the selected tab
    switch (newValue) {
      case 0:
        navigate("/ai-engagement/inbox");
        break;
      case 1:
        navigate("/ai-engagement/campaigns");
        break;
      case 2:
        navigate("/ai-engagement/mailbox");
        break;
      case 3:
        navigate("/ai-engagement/global-analytics");
        break;
      default:
        break;
    }
  };

  const tabs = ["Unibox", "Campaigns", "Mailbox", "Global Analytics"];

  const getConnectedWithService = async (type) => {
    try {
      setLoading(true);
      const response = await connectWithThirdPartyService({ authType: type });
      console.log("response", response);
      if ((response?.data || false) && response?.data?.authUrl) {
        const a = document.createElement("a");
        a.href = response?.data?.authUrl || "";
        a.target = "_blank";
        a.click();
        a.remove();
      }
    } catch (error) {
      console.log("-error", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAllEmails = async () => {
    try {
      const response = await reFetchAllEmail();
      // if (response?.status === true) {
      //   toast.success(response?.message, {
      //     theme: "colored",
      //   })
      // } else {
      //   toast.error(response?.message, {
      //     theme: "colored",
      //   })
      // }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (activeTab == 0) fetchAllEmails();
  }, [activeTab]);

  return (
    <Box sx={{ width: "100%" }}>
      {loading && (
        <BackdropLoader clsName="!z-[9999]" active={true}></BackdropLoader>
      )}
      <Box
        sx={{
          borderBottom: "1px solid #ccc",
          width: "100vw",
          marginLeft: "-50vw",
          left: "50%",
          position: "relative",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          disableRipple
          sx={{
            ".MuiTabs-indicator": {
              backgroundColor: "#4873fa",
              height: "3px",
            },
            ".MuiTabs-flexContainer": {
              alignItems: "center",
              marginLeft: "8%",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              disableRipple
              onClick={() => {
                if (tab === "Unibox" && activeTab == 0) fetchAllEmails();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: activeTab === index ? "#4873fa" : "black",
                "&.Mui-selected": {
                  color: "#4873fa",
                },
              }}
            />
          ))}

          {isNewAccountButton && (
            <button
              className="rounded-[8px] py-2 px-4 border focus:bg-white font-Outfit leading-[1.4] ml-auto mr-28 focus:text-[#090C05]  text-[#fff] bg-[#090C05] border-[#090C05] focus:border-[#090C05] hover:text-[#fff]"
              onClick={() => setOpenModal(true)}
            >
              Add New Account
            </button>
          )}
          <WarmupModal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            title={"Choose your email provider"}
          >
            <div>
              <Button
                type="button"
                sx={{
                  ...BASE_BUTTON_STYLES,
                  backgroundColor: "transparent",
                  color: "#090C05",
                  ":hover": {
                    backgroundColor: "#090C05",
                    color: "#fff",
                  },
                }}
                onClick={() => getConnectedWithService("outlook")}
              >
                Outlook
              </Button>{" "}
              <Button
                type="button"
                sx={{
                  ...BASE_BUTTON_STYLES,
                  backgroundColor: "transparent",
                  color: "#090C05",
                  ":hover": {
                    backgroundColor: "#090C05",
                    color: "#fff",
                  },
                }}
                onClick={() => getConnectedWithService("gmail")}
              >
                Gmail
              </Button>
            </div>
          </WarmupModal>
        </Tabs>
      </Box>
    </Box>
  );
}
