import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "10px",
    maxWidth: "none",

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "900px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1010px",
    },
    "@media (min-width: 1024px)": {
      paddingBottom: "60px",
    },
  },
}));

export const AdvancedFilters = ({ open, onClose, children }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BootstrapDialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <button
        onClick={onClose}
        className="absolute right-0 top-0 flex flex-row justify-end pr-4 pt-4 z-10"
      >
        <CloseSVGIcon className="relative h-4 w-4" />
      </button>
      <DialogContent sx={{ padding: 0, position: "relative" }}>
        <h3 className="font-OutfitBold text-center pt-[24px] text-[24px] 2xl:text-[28px] leading-[1.2] font-bold text-black">
          Advanced Search Filters
        </h3>
        {children}
      </DialogContent>
    </BootstrapDialog>
  );
};
