import Reac from "react";

const tabData = [
  { label: "Analytics" },
  { label: "Website Visitors" },
  { label: "Profile Exports" },
  { label: "Target Customer Persona" },
  { label: "Account Details" },
];

function NavigationTabs({ setTabOpen, tabOpen }) {
  return (
    <nav className="flex flex-col gap-4 px-10 items-start md:flex-row md:items-center md:gap-6 text-base leading-tight text-black border-b">
      {tabData.map((tab, index) => (
        <button
          key={index}
          className={`gap-2 self-stretch py-4 my-auto ${
            tabOpen === tab.label
              ? "border-b-2 border-blue-500 text-blue-500"
              : ""
          }`}
          onClick={() => {
            setTabOpen(tab.label);
            tabData.forEach(function (t) {
              if (t.label === tab.label) {
                t.isActive = true;
              } else {
                t.isActive = false;
              }
            });
          }}
        >
          {tab.label}
        </button>
      ))}
    </nav>
  );
}

export default NavigationTabs;
