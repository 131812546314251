import React, { useState } from "react";
import { Menu, MenuItem, Modal, Box, Typography, CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@mui/material';
import ProgressChart from '@/components/ai_engagement/campaignsTab/ProgressChart';
import { ReactComponent as SentIcon } from "@/assets/image/ai_engagement/sentIcon.svg";
import { ReactComponent as OpenModalIcon } from "@/assets/image/ai_engagement/openModalIcon.svg";
import { ReactComponent as ClickRateIcon } from "@/assets/image/ai_engagement/clickRateIcon.svg";
import { ReactComponent as OpenedIcon } from "@/assets/image/ai_engagement/openedIcon.svg";
import { ReactComponent as RepliedIcon } from "@/assets/image/ai_engagement/repliedIcon.svg";
import { ReactComponent as MenuIcon } from "@/assets/image/ai_engagement/menuIcon.svg";
import { ReactComponent as PauseIcon } from "@/assets/image/ai_engagement/pauseIcon.svg";
import { ReactComponent as PlayIcon } from "@/assets/image/ai_engagement/playIcon.svg";
import { ReactComponent as RenameIcon } from "@/assets/image/ai_engagement/renameIcon.svg";
import { ReactComponent as DeleteRedIcon } from "@/assets/image/ai_engagement/deleteRedIcon.svg";
import useAIEngagement from "@/hooks/useAIEngagement";
import { formatDate } from "@/utils/common";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const CampaignRow = ({ id, title, status, createdAt, analytics, ctaLink, campaignsData, setCampaignsData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalType, setModalType] = useState(null); 
  const [renameValue, setRenameValue] = useState(title);
  const [loading, setLoading] = useState({ deleting: false, renaming: false });

  const { activateCampaign, deactivateCampaign, updateCampaignData } = useAIEngagement();
  const navigate = useNavigate();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const openModal = (type) => {
    setModalType(type);
    if (type === 'rename') setRenameValue(title);
  };

  const closeModal = () => setModalType(null);

  const handleAction = async (action) => {
    handleClose();
    switch (action) {
      case 'activate':
        await handleActivateCampaign();
        break;
      case 'deactivate':
        await handleDeactivateCampaign();
        break;
      case 'delete':
        openModal('delete');
        break;
      case 'rename':
        openModal('rename');
        break;
      default:
        console.error('Unknown action:', action);
    }
  };

  const handleActivateCampaign = async () => {
    if(!ctaLink){
      toast.error('Please complete the campaign setup before activating');
      return;
    }
    const promise = activateCampaign(id);
    toast.promise(promise, {
      pending: 'Activating campaign...',
      success: {
        render() {
          const updatedCampaigns = campaignsData.map((campaign) =>
            campaign.campaignId === id ? { ...campaign, campaignStatus: 'active' } : campaign
          );
          setCampaignsData(updatedCampaigns);
          return 'Campaign activated successfully';
        }
      },
      error: 'Failed to activate campaign',
    });
  };

  const handleDeactivateCampaign = async () => {
    const promise = deactivateCampaign(id);
    toast.promise(promise, {
      pending: 'Deactivating campaign...',
      success: {
        render() {
          const updatedCampaigns = campaignsData.map((campaign) =>
            campaign.campaignId === id ? { ...campaign, campaignStatus: 'stopped' } : campaign
          );
          setCampaignsData(updatedCampaigns); 
          return 'Campaign deactivated successfully';
        }
      },
      error: 'Failed to deactivate campaign',
    });
  };

  const handleRename = async () => {
    setLoading(prev => ({ ...prev, renaming: true }));
    try {
      await updateCampaignData({ campaignId: id, campaignName: renameValue });
      const updatedCampaigns = campaignsData.map((campaign) =>
        campaign.campaignId === id ? { ...campaign, campaignName: renameValue } : campaign
      );
      setCampaignsData(updatedCampaigns);
      toast.success('Campaign renamed successfully');
      closeModal();
    } catch (err) {
      toast.error('Failed to rename campaign');
    }
    setLoading(prev => ({ ...prev, renaming: false }));
  };

  const handleDelete = async () => {
    setLoading(prev => ({ ...prev, deleting: true }));
    try {
      await deactivateCampaign(id,true);
      toast.success('Campaign deleted successfully');
      const updatedCampaigns = campaignsData.filter((campaign) => campaign.campaignId !== id);
      setCampaignsData(updatedCampaigns);
      closeModal();
    } catch (err) {
      toast.error('Failed to delete campaign');
    }
    setLoading(prev => ({ ...prev, deleting: false }));
  };

  const createMenuItem = (key, icon, text, onClick) => (
    <MenuItem
      key={key}
      onClick={onClick}
      className="flex items-center "
      selected={false}
      sx={{
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: '#f3f4f6',
        },
      }}
    >
      <p className="mr-1">{icon}</p>
      <p className={text === 'Delete' ? 'text-red-500' : ''}>{text}</p>
    </MenuItem>
  );

  const getStatusMenuItem = () => {
    const statusConfig = {
      active: { icon: <PauseIcon className='w-5 h-5 mr-1' />, text: 'Deactivate', action: 'deactivate' },
      stopped: { icon: <PlayIcon className='w-4 h-4 mr-1' />, text: 'Activate', action: 'activate' },
    };

    if(!statusConfig[status]) return null;

    const { icon, text, action } = statusConfig[status] || {};
    return createMenuItem('status-action', icon, text, () => handleAction(action));
  };

  const menuItems = [
    getStatusMenuItem(),
    createMenuItem('rename', <RenameIcon className='w-5 h-5 mr-1' />, 'Rename', () => handleAction('rename')),
    createMenuItem('delete', <DeleteRedIcon className='w-5 h-5 mr-1' />, 'Delete', () => handleAction('delete')),
  ];

  const handleTitleClick = () => {
    if (status === 'initializing') {
      navigate(`/ai-engagement/create-campaign?campaignId=${id}`);
    } else {

      navigate(`/campaigns/${id}`);
    }
  };

  return (
    <>
      <tr className="bg-white border-b border-dgray-200">
        <td className=" py-2 flex items-center">
          <ProgressChart percentage={Math.round(analytics.completionRate || 0)} />
          <div>
            <div className='flex items-center gap-2 cursor-pointer' onClick={handleTitleClick}>
              <h2 className='text-md text-blue-500'>{title}</h2>
              <OpenModalIcon className='w-5 h-5 mt-0.5' />
            </div>
            <div className='text-[#929292] flex text-sm items-center mt-1 gap-1'>
              <p>{status} |</p>
              <p>Created At: {formatDate(createdAt)} |</p>
              <p>{analytics.sequenceSteps || 0} sequences</p>
            </div>
          </div>
        </td>
        <td className="px-4 py-2">
          <div>
            <p className='text-md font-semibold'>{analytics.emailsSent}</p>
            <div className='flex text-[#929292] items-center gap-1 mt-1'>
              <SentIcon className='w-4 h-4' />
              <p className='text-sm'>Sent</p>
            </div>
          </div>
        </td>
        <td className="px-4 py-2">
          <div>
            <p className='text-md font-semibold'>{analytics.emailsClicked || 0} <sub className='text-[#929292] text-sm'>{analytics.clickRate || 0}%</sub></p>
            <div className='flex text-[#929292] items-center gap-1 mt-1'>
              <ClickRateIcon className='w-4 h-4' />
              <p className='text-sm'>Click Rate</p>
            </div>
          </div>
        </td>
        <td className="px-4 py-2">
          <div>
            <p className='text-md font-semibold'>{analytics.emailsOpened || 0}<sub className='text-[#929292] text-sm'> {analytics.openRate || 0}%</sub></p>
            <div className='flex text-[#929292] items-center gap-1 mt-1'>
              <OpenedIcon className='w-4 h-4' />
              <p className='text-sm'>Opened</p>
            </div>
          </div>
        </td>
        <td className="px-4 py-2">
          <div>
            <p className='text-md font-semibold'>{analytics.replies || 0} <sub className='text-[#929292] text-sm'>{analytics.replyRate || 0}%</sub></p>
            <div className='flex text-[#929292] items-center gap-1 mt-1'>
              <RepliedIcon className='w-4 h-4' />
              <p className='text-sm'>Replied</p>
            </div>
          </div>
        </td>
        <td className="px-4 py-2">
          <div>
            <MenuIcon className='cursor-pointer hover:opacity-85' onClick={handleClick} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              {menuItems}
            </Menu>
          </div>
        </td>
      </tr>

      {/* Modals */}
      <Dialog open={modalType === 'rename'} onClose={closeModal} maxWidth='sm' fullWidth className="">
        <DialogTitle className="font-semibold text-xl">Rename Campaign</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <input
            value={renameValue}
            onChange={(e) => setRenameValue(e.target.value)}
            className="w-full campaign-card-input mt-2 placeholder:text-dgray-500"
            placeholder="Enter new campaign name"
          />
          <div className="flex justify-end mt-8">
            <button className="bg-blue-500 hover:bg-blue-600  text-white px-4 py-2 rounded-md mr-2" disabled={loading.renaming} onClick={handleRename}>
              {loading.renaming ?
                <div className='flex items-center gap-3'>
                  <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Renaming...</span>
                </div>
                :
                <span className="whitespace-nowrap">Rename</span>
              }
            </button>
            <button className="hover:bg-dgray-100 text-black px-4 py-2 rounded-md border border-stone-400" onClick={closeModal}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
      <Modal open={modalType === 'delete'} onClose={closeModal}>
        <Box sx={modalStyle("500px")}>
          <Typography id="modal-title" className="font-semibold text-xl" variant="h6">Delete Campaign</Typography>
          <p className="text mt-4">Are you sure you want to delete this campaign?</p>
          <div className="flex justify-end mt-8">
            <button disabled={loading.deleting} className="bg-red-500 text-white hover:bg-red-600 px-4 py-2 rounded-md mr-2" onClick={handleDelete}>
              {loading.deleting ?
                <div className='flex items-center gap-3'>
                  <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Deleting...</span>
                </div>
                :
                <span className="whitespace-nowrap">Delete</span>
              }
            </button>
            <button className="hover:bg-dgray-100 text-black px-4 py-2 rounded-md border border-stone-400" onClick={closeModal}>Cancel</button>
          </div>
        </Box>
      </Modal>
      <Dialog open={modalType === 'delete'} onClose={closeModal} maxWidth='sm' fullWidth className="">
        <DialogTitle className="font-semibold text-xl">Delete Campaign</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <p className="text mt-4">Are you sure you want to delete this campaign?</p>
          <div className="flex justify-end mt-8">
            <button className="bg-red-500 hover:bg-red-600  text-white px-4 py-2 rounded-md mr-2" disabled={loading.deleting} onClick={handleDelete}>
              {loading.deleting ?
                <div className='flex items-center gap-3'>
                  <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Deleting...</span>
                </div>
                :
                <span className="whitespace-nowrap">Delete</span>
              }
            </button>
            <button className="hover:bg-dgray-100 text-black px-4 py-2 rounded-md border border-stone-400" onClick={closeModal}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const modalStyle = (width) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: width,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  outline: 'none',
});


export default CampaignRow;
