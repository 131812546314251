import { useState } from "react";
import { Button } from "@material-tailwind/react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import EmailValidationResultsTable from "@/components/email_validation/EmailValidationResultsTable";
import SingleEmailValidation from "@/components/email_validation/SingleEmailValidation";
import EmailUploadWidget from "@/components/email_validation/EmailUploadWidget";
import MainTitleBar from "@/components/MainTitleBar";
import mainArrowSvg from "@/assets/image/base/mainArrow.svg";

export default function EmailValidationScene() {
  const [currentWidgetIndex, setCurrentWidgetIndex] = useState(0);

  return (
    <MainLayout>
      {currentWidgetIndex === 0 && (
        <>
          <MainTitleBar>
            <div className="flex justify-between items-end">
              <p>Email validation</p>
              <Button
                onClick={() => {
                  setCurrentWidgetIndex(1);
                }}
                className="main-button items-end"
              >
                Start new upload
                <img
                  src={mainArrowSvg}
                  alt="DownArrowIcon"
                  width={18}
                  height={18}
                  className="rotate-90"
                />
              </Button>
            </div>
          </MainTitleBar>
          <BaseContainer>
            <SingleEmailValidation />
            <div className="relative flex w-full flex-col items-center justify-center">
              <div className="mb-[50px] flex h-[1px] w-full bg-[#E8E7E7]"></div>
              <div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
                <div className="flex flex-col items-center gap-1 sm:items-start">
                  <div className="font-OutfitBold flex max-w-[520px] flex-col text-3xl font-bold">
                    File uploads
                  </div>
                  <div className="font-OutfitLight flex w-fit flex-col text-base font-light">
                    View results of recent file uploads and bulk verifications
                  </div>
                </div>
              </div>
              <EmailValidationResultsTable />
            </div>
          </BaseContainer>
        </>
      )}

      {(currentWidgetIndex === 1 || currentWidgetIndex === 2) && (
        <BaseContainer>
          <EmailUploadWidget
            currentWidgetIndex={currentWidgetIndex}
            setCurrentWidgetIndex={setCurrentWidgetIndex}
          />
        </BaseContainer>
      )}

      {currentWidgetIndex === 3 && (
        <BaseContainer>
          <div className="leading-loose flex w-full flex-row justify-between gap-2 bg-white p-6 drop-shadow-lg filter">
            <p className="font-OutfitBold leading-loose text-3xl text-gray-950">
              Bulk Enrich
            </p>
            <div className="flex flex-row gap-2">
              <Button
                onClick={() => {
                  setCurrentWidgetIndex(1);
                }}
                className="font-Outfit h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-base font-bold uppercase text-white"
              >
                Start new uploading
              </Button>
            </div>
          </div>
          <EmailValidationResultsTable />
        </BaseContainer>
      )}
    </MainLayout>
  );
}
