import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";

import SearchIcon from "@/assets/image/team/search.svg";

export default function SearchInput(props) {
  const { onInputChanged, containerStyles } = props;

  return (
    <div
      className={`w-full md:w-[350px] flex flex-row gap-2 items-center ${containerStyles ? containerStyles : ""}`}
    >
      <div
        className={`flex flex-row px-4 py-2 rounded-[40px] w-full md:w-[350px] max-w-[350px] items-center bg-white border-[1px] border-stone-250 ${containerStyles ? containerStyles : ""}`}
      >
        <ReactSVG src={SearchIcon} />
        <input
          type="text"
          className="w-full rounded-xl outline-none pl-1 font-Outfit font-normal text-base"
          placeholder={
            props?.placeholder
              ? props.placeholder
              : "Search for contact or company"
          }
          onChange={(event) => {
            onInputChanged(event.currentTarget.value);
          }}
        />
      </div>
    </div>
  );
}
