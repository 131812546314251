export default function LastPaginationIcon() {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
  >
    <path
      fill="#000"
      fillOpacity={0.87}
      d="M4.5 13.5 10.875 9 4.5 4.5v9Zm7.5-9v9h1.5v-9H12Z"
    />
  </svg>
}