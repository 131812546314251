import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import MuiSlider from "./MuiSlider";
import { NavLink } from "react-router-dom";
import ListBox from "@/components/landing/ListBox";
import Lottie from "react-lottie";
import bulkAnimated from "@/assets/animations/bulk-enrich.json";
import kompassSearchAnimated from "@/assets/animations/kompass-search.json";
import integrationsAnimated from "@/assets/animations/integrations.json";
import emailVerificationAnimated from "@/assets/animations/email-validation.json";
import chromeExtensionAnimated from "@/assets/animations/chrome-extension.json";
import { goToTop } from "@/utils/common";

const IMAGES = [
  kompassSearchAnimated,
  chromeExtensionAnimated,
  emailVerificationAnimated,
  bulkAnimated,
  integrationsAnimated,
];

const EASY_SEARCH = [
  { name: "Advanced Search" },
  { name: "Linkedin Search" },
  { name: "Email Verification" },
];

const PERFECT_COMPATIBILITY = [
  { name: "CSV Enrich" },
  { name: "Integrations" },
];

const geAnimationOptions = (animationData) => {
  return {
    loop: true,
    autoplay: true,
    animationData: animationData,
    speed: 2,
  };
};

const PerfectLead = () => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const TEXTS = [
    "Search our database of over 500 million profiles and 20 million companies by location, industry, company details and more.",
    "Prospect on LinkedIn and LinkedIn Navigator to get real-time data on  companies and contacts from across the world.",
    "Reliable, fast and secure email validation service to remove non-existent emails from your prospecting lists and ensure you maintain your domain credibility.",
  ];
  const TITLES = [
    "Get instant access to the KompassAI database",
    "Stay up-to-date with live LinkedIn Prospecting",
    "Maintain your email IP and domain reputation",
  ];
  const LINKS = ["/advancedsearch", "/prospecting", "/emailverification"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalForSlides = () => {
      const animationDurationInSeconds =
        currentIndex === 0 ? 15 : currentIndex === 1 ? 18 : 11;
      const frameRate = 30;
      const totalFrames = animationDurationInSeconds * frameRate;
      const frameInterval = 1000 / frameRate;

      const sliderProgressInterval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 100 / totalFrames);
      }, frameInterval);

      if (progress >= 100) {
        clearInterval(sliderProgressInterval);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
        setProgress(0);
      }

      return () => {
        clearInterval(sliderProgressInterval);
      };
    };

    if (isDesktop) {
      return intervalForSlides();
    }
  }, [isDesktop, progress, currentIndex]);

  return (
    <Box className="flex justify-center flex-col mt-24">
      <Box className="mx-12">
        <Box className="perfect-lead-label">
          <div>Easy Search</div>
        </Box>
        <Box className="w-full flex items-center justify-center lg:justify-start mb-12">
          <h2 className="perfect-lead-title main-subtitle">
            Tools to help you prospect
            <br />
            with precision
          </h2>
        </Box>
        <Box>
          <ListBox
            list={EASY_SEARCH}
            setCurrentIndex={(idx) => setCurrentIndex(idx)}
          />
          <Box className="lg:flex flex-wrap font-Outfit text-xl text-stone-350 mb-12 hidden">
            <Box
              className="flex flex-col items-center w-[268px] px-2 gap-2 cursor-pointer"
              onClick={() => {
                setCurrentIndex(0);
                setProgress(0);
              }}
            >
              {currentIndex === 0 ? (
                <div className={`text-stone-950`}>Advanced Search</div>
              ) : (
                <div> Advanced Search </div>
              )}
              <MuiSlider progress={currentIndex === 0 ? progress : 0} />
            </Box>
            <Box
              className="flex flex-col items-center w-[268px] px-2 gap-2 cursor-pointer"
              onClick={() => {
                setCurrentIndex(1);
                setProgress(0);
              }}
            >
              {currentIndex === 1 ? (
                <div className={`text-stone-950`}>Chrome Extension</div>
              ) : (
                <div>Chrome Extension</div>
              )}
              <MuiSlider progress={currentIndex === 1 ? progress : 0} />
            </Box>
            <Box
              className="flex flex-col items-center w-[268px] px-2 gap-2 cursor-pointer"
              onClick={() => {
                setCurrentIndex(2);
                setProgress(0);
              }}
            >
              {currentIndex === 2 ? (
                <div className={`text-stone-950`}>Email Verification</div>
              ) : (
                <div>Email Verification</div>
              )}

              <MuiSlider progress={currentIndex === 2 ? progress : 0} />
            </Box>
          </Box>
          <div className="grid grid-cols-1 sm:grid-cols-7 gap-12 lg:gap-20 sm:py-12 lg:py-4">
            <Box className="col-span-1 lottie-wrapper order-2 sm:order-1 sm:col-span-3 flex items-center sm:items-start">
              <Lottie options={geAnimationOptions(IMAGES[currentIndex])} />
            </Box>
            <Box className="col-span-1 order-1 sm:order-2 sm:col-span-4">
              <h3 className="perfect-lead-item-title">
                {TITLES[currentIndex]}
              </h3>

              <p className="perfect-lead-item-description">
                {TEXTS[currentIndex]}
              </p>
              <NavLink
                to={LINKS[currentIndex]}
                onClick={goToTop}
                className="perfect-lead-item-button"
              >
                Learn More
              </NavLink>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

const PowerCompatibility = () => {
  const TEXTS = [
    "Enrich and enhance your existing prospects directly through a CSV upload that will validate records real-time and provide you with the freshest data.",
    "Seamlessly integrate your data across several platforms such as Salesforce, Hubspot,  Outreach among others. ",
  ];
  const TITLES = [
    "Enrich thousands of records instantly",
    "Keep your CRM data fresh for prospecting",
  ];
  const LINKS = ["/csvexport", "/integration"];
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalForSlides = () => {
      const animationDurationInSeconds = currentIndex === 0 ? 15 : 11;
      const frameRate = 30;
      const totalFrames = animationDurationInSeconds * frameRate;
      const frameInterval = 1000 / frameRate;

      const sliderProgressInterval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 100 / totalFrames);
      }, frameInterval);

      if (progress >= 100) {
        clearInterval(sliderProgressInterval);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
        setProgress(0);
      }

      return () => {
        clearInterval(sliderProgressInterval);
      };
    };

    if (isDesktop) {
      return intervalForSlides();
    }
  }, [isDesktop, progress, currentIndex]);

  return (
    <Box className="flex justify-center flex-col mt-12 pt-16 pb-12 px-12 bg-[#F4F4F8]">
      <Box className="perfect-lead-label">
        <div>Perfect Compatibility</div>
      </Box>
      <Box className="w-full flex items-center justify-center lg:justify-start mb-12">
        <div className="perfect-lead-title">
          Flexibly prospect clients,
          <br /> your way
        </div>
      </Box>
      <Box>
        <ListBox
          list={PERFECT_COMPATIBILITY}
          setCurrentIndex={(idx) => setCurrentIndex(idx)}
        />
        <Box className="lg:flex flex-wrap font-Outfit text-xl text-stone-350 mb-12 hidden">
          <Box
            className="flex flex-col items-center w-[268px] px-2 gap-2 cursor-pointer"
            onClick={() => {
              setCurrentIndex(0);
              setProgress(0);
            }}
          >
            {currentIndex === 0 ? (
              <div className={`text-stone-950`}>Bulk Enrich</div>
            ) : (
              <div>Bulk Enrich</div>
            )}
            <MuiSlider progress={currentIndex === 0 ? progress : 0} />
          </Box>

          <Box
            className="flex flex-col items-center w-[268px] px-2 gap-2 cursor-pointer"
            onClick={() => {
              setCurrentIndex(1);
              setProgress(0);
            }}
          >
            {currentIndex === 1 ? (
              <div className={`text-stone-950`}>Integrations</div>
            ) : (
              <div>Integrations</div>
            )}
            <MuiSlider progress={currentIndex === 1 ? progress : 0} />
          </Box>
        </Box>
        <div className="grid grid-cols-1 sm:grid-cols-7 gap-12 lg:gap-20 sm:py-12 lg:py-4">
          <Box className="col-span-1 sm:col-span-4">
            <h3 className="perfect-lead-item-title">{TITLES[currentIndex]}</h3>
            <p className="perfect-lead-item-description">
              {TEXTS[currentIndex]}
            </p>
            <NavLink
              to={LINKS[currentIndex]}
              onClick={goToTop}
              className="perfect-lead-item-button"
            >
              Learn More
            </NavLink>
          </Box>
          <Box className="col-span-1 lottie-wrapper sm:col-span-3 flex items-center sm:items-start">
            <Lottie options={geAnimationOptions(IMAGES[3 + currentIndex])} />
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export { PerfectLead, PowerCompatibility };
