import React from 'react'
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomAutocomplete from '../CustomAutocomplete';
import { continentList, countryList, departmentList, industryList, levelList, numberOfEmployeesList, revenueList, subDepartmentList } from '@/utils/filter';
import { Country, State, City } from "country-state-city";

export default function PreviewFilters({ icpTags, setIcpTags, open, setOpen, handleConfirmFilters, loading }) {

  const [statesList, setStatesList] = React.useState([]);
  const [companyStatesList, setCompanyStatesList] = React.useState([]);
 
  // Generic function to handle select and remove
  const handleSelect = (category, newValue) => {
    if (newValue === null) return;
    if (category === 'job_title_role' || category === 'job_title_sub_role') {
      setIcpTags({ ...icpTags, [category]: addUnderscore(newValue) });
    } else if (category === 'job_company_size' || category === 'job_company_inferred_revenue') {
      setIcpTags({ ...icpTags, [category]: newValue });
    } else {
      setIcpTags({ ...icpTags, [category]: lowercaseAllWords(newValue) });
    }
  };
  const handleRemove = (category, valueToRemove) => {
    setIcpTags({
      ...icpTags,
      [category]: icpTags[category].filter((value) => value !== valueToRemove)
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // country selection
  const handleCountrySelect = (e, newValue) => {
    newValue.forEach((countryName) => {
      const country = Country.getAllCountries().find(c => c.name.toLowerCase() === countryName.toLowerCase());
      if (country) {
        const states = State.getStatesOfCountry(country.isoCode);
        setStatesList([...statesList, ...states.map(state => state.name.toLowerCase())]);
      }
    });
    handleSelect('location_country', newValue);
  }

  const handleCountryRemove = (countryToRemove) => {
    const country = Country.getAllCountries().find(c => c.name.toLowerCase() === countryToRemove.toLowerCase());
    if (country) {
      const states = State.getStatesOfCountry(country.isoCode);
      setStatesList(statesList.filter(state => !states.map(s => s.name.toLowerCase()).includes(state)));
      setIcpTags({
        ...icpTags,
        location_region: icpTags.location_region.filter(region => !states.map(s => s.name.toLowerCase()).includes(region)),
        location_country: icpTags.location_country.filter(country => country !== countryToRemove.toLowerCase())
      });
    }
  };

  // personal location selection
  const handlePersonalLocationSelect = (e, newValue) => {
    if (!newValue || icpTags.location_name.includes(newValue.name)) return;
    setIcpTags({ ...icpTags, location_name: [...icpTags.location_name, newValue.name] })
  }
  const handlePersonalLocationRemove = (personalLocationToRemove) => handleRemove('location_name', personalLocationToRemove);

  // job title selection
  const handleJobTitleSelect = (e, newValue) => {
    if (!newValue || icpTags.job_title.includes(newValue.name)) return;
    setIcpTags({ ...icpTags, job_title: [...icpTags.job_title, newValue.name] })
  }
  const handleJobTitleRemove = (jobTitleToRemove) => handleRemove('job_title', jobTitleToRemove);

  // job level selection
  const handleJobLevelSelect = (e, newValue) => handleSelect('job_title_levels', newValue);
  const handleJobLevelRemove = (jobLevelToRemove) => handleRemove('job_title_levels', jobLevelToRemove.toLowerCase());

  // department selection
  const handleDepartmentSelect = (e, newValue) => handleSelect('job_title_role', newValue);
  const handleDepartmentRemove = (departmentToRemove) => handleRemove('job_title_role', addUnderscoreToText(departmentToRemove));

  // sub department selection
  const handleSubDepartmentSelect = (e, newValue) => handleSelect('job_title_sub_role', newValue);
  const handleSubDepartmentRemove = (subDepartmentToRemove) => handleRemove('job_title_sub_role', addUnderscoreToText(subDepartmentToRemove));

  // industries selection
  const handleIndustrySelect = (e, newValue) => handleSelect('industry', newValue);
  const handleIndustryRemove = (industryToRemove) => handleRemove('industry', industryToRemove.toLowerCase());

  // handle company size
  const handleCompanySizeSelect = (e, newValue) => handleSelect('job_company_size', newValue);
  const handleCompanySizeRemove = (companySizeToRemove) => handleRemove('job_company_size', companySizeToRemove);

  // handle company location
  const handleCompanyLocationSelect = (e, newValue) => {
    if (!newValue || icpTags.job_company_location_name.includes(newValue.name)) return;
    setIcpTags({ ...icpTags, job_company_location_name: [...icpTags.job_company_location_name, newValue.name] })
  }
  const handleCompanyLocationRemove = (companyLocationToRemove) => handleRemove('job_company_location_name', companyLocationToRemove);

  // handle company revenue
  const handleCompanyRevenueSelect = (e, newValue) => handleSelect('job_company_inferred_revenue', newValue);
  const handleCompanyRevenueRemove = (companyRevenueToRemove) => handleRemove('job_company_inferred_revenue', companyRevenueToRemove);

  // handle company continent
  const handleCompanyContinentSelect = (e, newValue) => handleSelect('job_company_location_continent', newValue);
  const handleCompanyContinentRemove = (companyContinentToRemove) => handleRemove('job_company_location_continent', companyContinentToRemove.toLowerCase());

  // handle company country
  const handleCompanyCountrySelect = (e, newValue) => {
    newValue.forEach((countryName) => {
      const country = Country.getAllCountries().find(c => c.name.toLowerCase() === countryName.toLowerCase());
      if (country) {
        const states = State.getStatesOfCountry(country.isoCode);
        setCompanyStatesList([...companyStatesList, ...states.map(state => state.name.toLowerCase())]);
      }
    });
    handleSelect('job_company_location_country', newValue);
  }
  const handleCompanyCountryRemove = (companyCountryToRemove) => {
    const country = Country.getAllCountries().find(c => c.name.toLowerCase() === companyCountryToRemove.toLowerCase());
    if (country) {
      const states = State.getStatesOfCountry(country.isoCode);
      setCompanyStatesList(companyStatesList.filter(state => !states.map(s => s.name.toLowerCase()).includes(state)));
      setIcpTags({
        ...icpTags,
        job_company_location_region: icpTags.job_company_location_region.filter(region => !states.map(s => s.name.toLowerCase()).includes(region)),
        job_company_location_country: icpTags.job_company_location_country.filter(country => country !== companyCountryToRemove.toLowerCase())
      });
    }
  }

  // handle continent
  const handleContinentSelect = (e, newValue) => handleSelect('location_continent', newValue);
  const handleContinentRemove = (continentToRemove) => handleRemove('location_continent', continentToRemove.toLowerCase());

  // handle state
  const handleStatesSelect = (e, newValue) => handleSelect('location_region', newValue);
  const handleStatesRemove = (stateToRemove) => handleRemove('location_region', stateToRemove.toLowerCase());

  // handle company state
  const handleCompanyStatesSelect = (e, newValue) => handleSelect('job_company_location_region', newValue);
  const handleCompanyStatesRemove = (stateToRemove) => handleRemove('job_company_location_region', stateToRemove.toLowerCase());


  const capitalizeAllWords = (array) => {
    return array?.map((element) => element?.split(' ')?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
  }
  const lowercaseAllWords = (array) => {
    return array?.map((element) => element?.toLowerCase());
  }
  const removeUnderscore = (array) => {
    return array?.map((element) => element?.split('_')?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
  }
  const addUnderscoreToText = (text) => {
    return text?.split(' ')?.map((word) => word?.toLowerCase()).join('_');
  }
  const addUnderscore = (array) => {
    return array?.map((element) => element?.split(' ')?.map((word) => word?.toLowerCase()).join('_'));
  }


  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" scroll='body'>
        <DialogTitle sx={{ m: 0, p: 2 }} className='!text-4xl !font-semibold text-center' id="customized-dialog-title">
          Preview Filters
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <h1 className='mt-2 text-2xl font-semibold'>Contact Info</h1>
          {/* continents selection */}
          <h2 className="font-medium mt-8">Continents</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="continents-select"
              options={capitalizeAllWords(continentList.map((country) => country.value))}
              selectedValues={capitalizeAllWords(icpTags.location_continent)}
              onSelectionChange={handleContinentSelect}
              onRemove={handleContinentRemove}
              placeholder="Select continents"
              sx={{ width: '100%' }}
            />
          </div>
          {/* country selection */}
          <h2 className="font-medium mt-8">Countries</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="countries-select"
              options={capitalizeAllWords(countryList.map((country) => country.value))}
              selectedValues={capitalizeAllWords(icpTags.location_country)}
              onSelectionChange={handleCountrySelect}
              onRemove={handleCountryRemove}
              placeholder="Select countries"
              sx={{ width: '100%' }}
            />
          </div>
          {/* state selection */}
          <h2 className="font-medium mt-8">State/Province</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="state-select"
              options={capitalizeAllWords(statesList)}
              selectedValues={capitalizeAllWords(icpTags.location_region)}
              onSelectionChange={handleStatesSelect}
              onRemove={handleStatesRemove}
              placeholder="Select State/Province"
              sx={{ width: '100%' }}
            />
          </div>
          {/* personal location selection */}
          <h2 className="font-medium mt-8">Personal Location</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="personalLocation"
              selectedValues={icpTags.location_name}
              onSelectionChange={handlePersonalLocationSelect}
              onRemove={handlePersonalLocationRemove}
              placeholder="Select Personal Location"
              sx={{ width: '100%' }}
              isUseAPI={true}
            />
          </div>

          <h1 className='mt-8 text-2xl font-semibold'>Job Info</h1>
          {/* job title selection */}
          <h2 className="font-medium mt-8">Job Title</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="job-title-select"
              selectedValues={icpTags.job_title}
              onSelectionChange={handleJobTitleSelect}
              onRemove={handleJobTitleRemove}
              placeholder="Select job title"
              sx={{ width: '100%' }}
              isUseAPI={true}
              searchBy='title'
            />
          </div>
          {/* job level selection */}
          <h2 className="font-medium mt-8">Job Level</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="job-level-select"
              options={capitalizeAllWords(levelList.map((level) => level.value))}
              selectedValues={capitalizeAllWords(icpTags.job_title_levels)}
              onSelectionChange={handleJobLevelSelect}
              onRemove={handleJobLevelRemove}
              placeholder="Select job level"
              sx={{ width: '100%' }}
            />
          </div>
          {/* department selection */}
          <h2 className="font-medium mt-8">Department</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="department-select"
              options={removeUnderscore(departmentList.map((element) => element.value))}
              selectedValues={removeUnderscore(icpTags.job_title_role)}
              onSelectionChange={handleDepartmentSelect}
              onRemove={handleDepartmentRemove}
              placeholder="Select Department"
              sx={{ width: '100%' }}
            />
          </div>
          {/* sub department selection */}
          <h2 className="font-medium mt-8">Sub Department</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="subdepartment-select"
              options={removeUnderscore(subDepartmentList.map((element) => element.value))}
              selectedValues={removeUnderscore(icpTags.job_title_sub_role)}
              onSelectionChange={handleSubDepartmentSelect}
              onRemove={handleSubDepartmentRemove}
              placeholder="Select Sub Department"
              sx={{ width: '100%' }}
            />
          </div>

          <h1 className='mt-8 text-2xl font-semibold'>Company Info</h1>
          {/* company continent selection */}
          <h2 className="font-medium mt-8">Company Continent</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="c-continent-select"
              options={capitalizeAllWords(continentList.map((element) => element.value))}
              selectedValues={capitalizeAllWords(icpTags.job_company_location_continent)}
              onSelectionChange={handleCompanyContinentSelect}
              onRemove={handleCompanyContinentRemove}
              placeholder="Select Continent"
              sx={{ width: '100%' }}
            />
          </div>
          {/* company country selection */}
          <h2 className="font-medium mt-8">Company Country</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="c-country-select"
              options={capitalizeAllWords(countryList.map((element) => element.value))}
              selectedValues={capitalizeAllWords(icpTags.job_company_location_country)}
              onSelectionChange={handleCompanyCountrySelect}
              onRemove={handleCompanyCountryRemove}
              placeholder="Select Country"
              sx={{ width: '100%' }}
            />
          </div>
          {/* company state selection */}
          <h2 className="font-medium mt-8">Company State/Province</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="c-state-select"
              options={capitalizeAllWords(companyStatesList)}
              selectedValues={capitalizeAllWords(icpTags.job_company_location_region)}
              onSelectionChange={handleCompanyStatesSelect}
              onRemove={handleCompanyStatesRemove}
              placeholder="Select Company State/Province"
              sx={{ width: '100%' }}
            />
          </div>
          {/* industries selection */}
          <h2 className="font-medium mt-8">Industry</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="industry-select"
              options={capitalizeAllWords(industryList.map((element) => element.value))}
              selectedValues={capitalizeAllWords(icpTags.industry)}
              onSelectionChange={handleIndustrySelect}
              onRemove={handleIndustryRemove}
              placeholder="Select Industry"
              sx={{ width: '100%' }}
            />
          </div>
          {/* company size selection */}
          <h2 className="font-medium mt-8">Size</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="size-select"
              options={numberOfEmployeesList.map((element) => element.value)}
              selectedValues={icpTags.job_company_size}
              onSelectionChange={handleCompanySizeSelect}
              onRemove={handleCompanySizeRemove}
              placeholder="Select Company Size"
              sx={{ width: '100%' }}
            />
          </div>
          {/* company location selection */}
          <h2 className="font-medium mt-8">Company Location</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="company-location"
              selectedValues={icpTags.job_company_location_name}
              onSelectionChange={handleCompanyLocationSelect}
              onRemove={handleCompanyLocationRemove}
              placeholder="Select Company Location"
              sx={{ width: '100%' }}
              isUseAPI={true}
              searchBy='location'
            />
          </div>
          {/* company funding raised selection */}
          <h2 className="font-medium mt-8">Funding Raised</h2>
          <div className='flex gap-3 campaign-card mt-3'>
            <input
              type="number"
              onChange={(e) => setIcpTags({
                ...icpTags,
                rangeInputs: {
                  ...icpTags.rangeInputs,
                  job_company_total_funding_raised: {
                    ...icpTags.rangeInputs?.job_company_total_funding_raised,
                    min: e.target.value
                  }
                }
              })}
              value={icpTags.rangeInputs?.job_company_total_funding_raised.min}
              className='campaign-card-input !w-[160px] placeholder-[#a1a1aa]'
              placeholder='min'
            />
            <input
              type="number"
              onChange={(e) => setIcpTags({
                ...icpTags,
                rangeInputs: {
                  ...icpTags.rangeInputs,
                  job_company_total_funding_raised: {
                    ...icpTags.rangeInputs?.job_company_total_funding_raised,
                    max: e.target.value
                  }
                }
              })}
              value={icpTags.rangeInputs?.job_company_total_funding_raised.max}
              className='campaign-card-input !w-[160px] placeholder-[#a1a1aa]'
              placeholder='max'
            />
          </div>
          {/* company revenue selection */}
          <h2 className="font-medium mt-8">Company Revenue</h2>
          <div className="campaign-card mt-3">
            <CustomAutocomplete
              id="revenue-select"
              options={revenueList.map((element) => element.value)}
              selectedValues={icpTags.job_company_inferred_revenue}
              onSelectionChange={handleCompanyRevenueSelect}
              onRemove={handleCompanyRevenueRemove}
              placeholder="Select company revenue"
              sx={{ width: '100%' }}
            />
          </div>
          <div className='flex justify-center'>
            <button
              className="font-Outfit flex py-2 my-7 w-[350px] items-center justify-center rounded-md bg-black text text-white"
              onClick={handleConfirmFilters}
              disabled={loading}
            >
              {loading ?
                <div className='flex items-center gap-3'>
                  <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Saving...</span>
                </div>
                :
                <span className="whitespace-nowrap">Confirm Filters</span>
              }
            </button>

          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

