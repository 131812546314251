import useAuth from "@/hooks/useAuth";

const useIPsDeanonymization = () => {
  const { getAuthToken } = useAuth();

  const getWebsiteVisitors = async (params) => {
    let response = null;
    const authToken = await getAuthToken();

    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses/getEnrichedData?${params?.query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      response = await _resData.json();
      console.log(response.data)
      if (response.status) {
        response = response.data;
      } else {
        throw new Error(response?.message);
      }
    } catch (e) {
      console.log(e);
    }
    return response;
  };

  const enrichIP = async (params) => {
    // console.log("===============enrichIP===============");

    const authToken = await getAuthToken();
    let response = null;
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses/enrich`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(params),
        }
      );

      response = await _resData.json();
      if (!response.status) {
        throw new Error(response?.message);
      }
    } catch (e) {
      console.log(e);
    }
    return response;
  };

  const updateAllowedDomains = async (params) => {
    // console.log("===============updateAllowedDomains===============");
    const authToken = await getAuthToken();
    let response = null;
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(params),
        }
      );

      response = await _resData.json();
      if (response.status) {
        response = response.message;
      } else {
        throw new Error(response?.message);
      }
    } catch (e) {
      console.log(e);
    }
    return response;
  };

  const createUserEntry = async () => {
    // console.log("===============updateAllowedDomains===============");
    const authToken = await getAuthToken();
    let response = null;
    try {
      const _resData = await fetch(
        `${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses/create-entry`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        }
      );

      response = await _resData.json();
      console.log(response);
      if (response.status) {
        response = response.message;
      } else {
        throw new Error(response?.message);
      }
    } catch (e) {
      console.log(e);
    }
    return response;
  };

  return {
    getWebsiteVisitors,
    enrichIP,
    updateAllowedDomains,
    createUserEntry
  };
};

export default useIPsDeanonymization;
