import SearchIcon from "@/assets/image/icons/search.svg";

export default function SearchBar({ filter, setFilter }) {
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setFilter(value);
  };

  return (
    <div className="flex gap-4 pl-6 pr-3 items-center rounded-[8px] items-center border border-[#ddd] bg-white w-full md:max-w-[356px] h-[32px] lg:h-[48px]">
      <img src={SearchIcon} alt="search" className="w-[12px] h-[12px]" />
      <input
        value={filter}
        onChange={handleSearchChange}
        className="outline-none font-Outfit text-[12px] md:text-[14px] lg:text-[16px] text-stone-950 font-light w-full placeholder:text-[#8F9796]"
        placeholder="Search the article"
      />
    </div>
  );
}
