import { goToTop } from "@/utils/common";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export default function ConnectPanel({ background, accent }) {
  return (
    <div
      className={`flex w-full flex-col items-center ${background} gap-16 rounded-t-[2.5rem] py-16 px-12`}
    >
      <div className="font-OutfitBold relative text-center text-[4rem] 2xl:text-[4.5rem] leading-[5rem] text-stone-950">
        Connect to the largest and <br />
        most accurate
        <div
          className={`inline-block ${accent} ml-4 -rotate-6 rounded-xl px-3 text-[2.25rem] leading-[3.6rem] text-white drop-shadow-[0_15px_15px_rgba(0,0,0,0.4)]`}
        >
          Database
        </div>
      </div>
      <div className="flex flex-col xs:flex-row gap-4">
        <Link to="/signup">
          <Button
            className="w-[245px] xs:w-auto normal-case font-OutfitBold rounded-md border border-stone-950 bg-transparent px-8 py-2 text-sm text-stone-950"
            onClick={goToTop}
          >
            Try Today
          </Button>
        </Link>
        <Link to="/pricing">
          <Button
            className="w-[245px] xs:w-auto normal-case font-OutfitBold rounded-md bg-stone-950 px-4 py-2 text-base text-white"
            onClick={goToTop}
          >
            Create a Custom Plan
          </Button>
        </Link>
      </div>
    </div>
  );
}
