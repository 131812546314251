import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import * as React from "react";
import ListItem from "@mui/material/ListItem";
import { getShortName } from "@/utils/common";

export const SidebarUserItem = ({
  label,
  open,
  authContext,
  onClick = () => {},
}) => {
  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 50,
          gap: open ? 2 : 0,
          overflow: "hidden",
          justifyContent: "center",
          borderRadius: 2,
          "&:hover": {
            backgroundColor: "rgba(188, 188, 188, 0.26)",
            "& .mui-item-text, & .mui-icon": {
              color: "white", // Change to your desired hover color
            },
          },
        }}
        onClick={onClick}
      >
        <div className="font-Outfit shrink-0 inline-flex h-[32px] w-[32px] items-center justify-center rounded-full bg-bluegrey-200 text-base font-extrabold text-blue-500 mui-icon">
          {getShortName(authContext?.userInfo?.userName)}
        </div>
        <ListItemText
          className={`!font-Outfit mui-item-text !text-[20px] text-[#7E7E7E] flex`}
          primary={label}
          primaryTypographyProps={{
            style: {
              fontWeight: 400,
              font: "Outfit",
              fontSize: "14px",
              margin: 0,
            },
          }}
          sx={{
            opacity: open ? 1 : 0,
            fontWeight: 500,
            margin: 0,
            transition: "all 150ms cubic-bezier(0.1, 0.03, 0.4, 0.97)",
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
