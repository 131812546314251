import LandingLayout from "@/components/LandingLayout";
import { useResizeDetector } from "react-resize-detector";
import ProfileHeader from "./components/ProfileHeader";
import MainLoader from "@/components/MainLoader";
import "./style.css";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useProfile from "@/hooks/useProfile";
const ProfileAbout = React.lazy(() => import("./components/ProfileAbout"));
const ProfileComppany = React.lazy(() => import("./components/ProfileCompany"));
const ProfileSearch = React.lazy(() => import("./components/ProfileSearch"));
const ProfileFaq = React.lazy(() => import("./components/ProfileFaq"));
const ProfileContact = React.lazy(() => import("./components/ProfileContact"));
const ProfileLinkedIn = React.lazy(
  () => import("./components/ProfileLinkedIn")
);
const ProfileSmilarProfiles = React.lazy(
  () => import("./components/ProfileSmilarProfiles")
);

const tempUserData = {
  firstName: "Sharad",
  lastName: "Kajaria",
  id: "57c5019fe4b0fc2acf1ea372",
  city: "New York",
  company: {
    name: "123Stores",
    website: "http://www.123stores.com",
    logo: "https://s3-us-west-1.amazonaws.com/adapt-images/company/173ae1fca2cf0b4ef9105c75.png",
    phoneNumber: [
      {
        number: "+1 646-593-8983",
        type: "company_line",
      },
    ],
    street: "38 37th St #41",
    city: "Long Island City",
    state: "New york",
    country: "United States",
    zipcode: "11101",
    industry: "Retail",
    subIndustry: [],
    headCount: "25 - 100",
    revenue: "$100 - 250M",
  },
  country: "United States",
  department: ["Finance & Administration"],
  emailDeliverabilityScore: 95,
  facebook: "facebook.com/123storesmegaonlinestore",
  level: "C-Level",
  linkedin: "linkedin.com/in/sharad-kajaria-09aa08",
  state: "New York",
  title: "Chief Executive Officer and Founder",
  twitter: null,
  shortBio:
    "Cross Country Coach and Assistant Girl's Track and Field Coach at Edgewood High School",
  similarProfiles: [
    {
      _id: "666a30a06ebb26a0e1598654",
      id: "5d9e3d08c9e77c000188efe3",
      firstName: "Alexandr",
      lastName: "Wang",
      title: "Chief Executive Officer and Founder",
    },
    {
      _id: "666b50d56ebb26a0e19e37b6",
      id: "5dd6097246e0fb0001b7e487",
      firstName: "Larry",
      lastName: "Graham",
      title: "Chief Executive Officer and Founder",
    },
    {
      _id: "666adf316ebb26a0e10a38ef",
      id: "5f7d323fc9e77c0001fcccd5",
      firstName: "Mahammad",
      lastName: "Kekalov",
      title: "Chief Executive Officer and Founder",
    },
    {
      _id: "666b6c856ebb26a0e1c270ba",
      id: "62d5817246e0fb0001711841",
      firstName: "Andrea",
      lastName: "Goudal",
      title: "Chief Executive Officer and Founder",
    },
    {
      _id: "666bff566ebb26a0e15a22ee",
      id: "653fe99646e0fb0001e65d55",
      firstName: "Siddeeqah",
      lastName: "Brown",
      title: "Chief Executive Officer and Founder",
    },
  ],
};

export default function Profile() {
  const { userId } = useParams();
  const [userData, setUserData] = useState(tempUserData);
  const [loading, setLoading] = useState(false);
  const { getKompassProfile } = useProfile();
  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: "debounce",
    refreshRate: 1000,
  });
  //Selecting a plan by clicking a button in a table or on a Plan card

  const canonicalUrl = "https://www.kompassai.com/profile/" + userId;
  const metaDescription = `KompassAI profile page for ${userId}.`;

  const ogData = {
    title: "KompassAI Profile",
    description: `KompassAI profile page for ${userId}.`,
    url: "https://www.kompassai.com/profile/" + userId,
    image: "https://www.kompassai.com/images/kompassai-logo.png",
    siteName: "KompassAI",
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getKompassProfile({
        type: "url",
        userName: "",
        userId: "",
        url: userId,
      });
      if (res.data.city === null) res.data.city = "***";
      if (res.data.state === null) res.data.state = "***";
      if (res.data.country === null) res.data.country = "***";
      await setUserData(res.data);
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <LandingLayout>
      <Helmet>
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={metaDescription} />
      </Helmet>
      {loading && <MainLoader />}
      {!loading && (
        <div className="w-full flex justify-center profile-container relative">
          <div className="absolute top-0 left-0 w-full h-[300px] bg-gradient-to-r from-[rgba(72,115,250,1)] to-[rgba(105,86,191,1)] via-[rgba(171,177,235,1)] opacity-50"></div>
          <div
            ref={ref}
            className="flex w-full flex-col items-center pt-12 md:pt-[3.75rem] pb-[4.75rem] w-4/5 z-50 px-[5rem]"
          >
            <div class="w-full mb-16">
              <ProfileHeader {...userData} />
            </div>
            <div className="flex w-full gap-8">
              <div className="w-2/3 flex flex-col gap-8">
                <ProfileAbout {...userData} />
                <ProfileComppany {...userData} />
                <ProfileSearch {...userData} setLoading={setLoading} />
                <ProfileFaq {...userData} />
              </div>
              <div className="w-1/3 flex flex-col gap-8">
                <ProfileContact {...userData} setLoading={setLoading} />
                <ProfileLinkedIn {...userData} />
                <ProfileSmilarProfiles {...userData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </LandingLayout>
  );
}
