import React, { useEffect } from 'react'
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import AllTabs from '@/components/ai_engagement/AllTabs';
import ContactDetails from '@/components/ai_engagement/contactActivity/ContactDetails';
import AllActivities from '@/components/ai_engagement/contactActivity/AllActivities';
import useAIEngagement from '@/hooks/useAIEngagement';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

export default function ContactActivityScene() {
  const [activities, setActivities] = React.useState([]);
  const [targetContact, setTargetContact] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // get path params
  const { activityId, campaignId } = useParams()
  const navigate = useNavigate()

  const { getContactActivities, getTargetContacts } = useAIEngagement();

  useEffect(() => {
    const fetchActivities = async () => {
      const response = await getContactActivities(activityId);
      setActivities(response.data);
      const res = await getTargetContacts(campaignId);
      const result = res.data.filter(contact => contact._id === activityId);
      setTargetContact(result);
      setLoading(false);
    }
    fetchActivities();
  }, []);

  return (
    <MainLayout>
      <MainTitleBar>
        <p>AI Engagement</p>
      </MainTitleBar>

      <div className="w-full bg-white mt-4 pb-10">
        <BaseContainer>
          <AllTabs />
          <p className='mt-5 text-dgray-500 flex gap-1'>
             <span onClick={() => navigate('/ai-engagement/campaigns')} className='cursor-pointer hover:text-dgray-600'>Campaigns</span>
             / 
            <span onClick={() => navigate(`/campaigns/${campaignId}`)} className='cursor-pointer hover:text-dgray-600'>Leads</span>
             / 
             <span className='text-primary-blue'>Activity</span>
          </p>
          {loading ?
            <>
              <div className='flex justify-center items-center h-[400px]'>
                <CircularProgress />
              </div>
            </>
            :
            activities?.length > 0 ?
              <div className='flex flex-col pre-lg:flex-row mt-5'>
                <div className='w-full lg:w-[32%]'>
                  <ContactDetails targetContact={targetContact[0]} />
                </div>
                <div className='justify-center hidden lg:flex w-[4%]'>
                  <div className='border-l border-stone-400'></div>
                </div>
                <div className='w-full lg:w-[62%] mt-10 pre-lg:mt-0'>
                  <AllActivities activities={activities} setActivities={setActivities} />
                </div>
              </div>
              :
              <div className='flex justify-center gap-8 items-center h-[400px]'>
                <p>No activities found</p>
              </div>
          }
        </BaseContainer>
      </div>
    </MainLayout>
  )
}