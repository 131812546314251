import LandingLayout from "@/components/LandingLayout";
import Calendar from "@/components/demo/Calendar";

export default function DemoScene() {
  return (
    <LandingLayout>
      <div className="w-full h-full py-10 md:py-20">
        <Calendar />
      </div>
    </LandingLayout>
  );
}
