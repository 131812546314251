import QuestionTag from "./QuestionTag";
import { FAQ_LIST } from "@/utils/constants";

export default function QuestionPanel({
  background = "bg-white",
  isTitleShown = true,
}) {
  return (
    <div className="flex flex-col items-center bg-white py-20 overflow-hidden">
      {isTitleShown && (
        <h2 className="main-title mb-16">Questions you might have</h2>
      )}
      {FAQ_LIST.map((FAQ) => (
        <QuestionTag question={FAQ.question} answer={FAQ.answer} />
      ))}
    </div>
  );
}
