export default function ComparisonGraph() {
  return (
    <div className="py-5 mx-auto max-w-[1100px] grid grid-cols-[auto_auto] grid-rows-[max-content_max-content_auto] text-stone-950 font-Outfit text-[16px] lg:text-[20px] xl:text-[24px] leading-[1.2]">
      <div className="py-2 h-full flex items-center justify-end gap-2">
        <p className="whitespace-nowrap font-bold">KompassAI</p>
        <div className="w-[6px] lg:w-[8px] h-[2px] lg:h-[3px] bg-stone-950 shrink-0"></div>
      </div>
      <div className="py-2 flex items-center border-l-[2px] lg:border-l-[3px] border-stone-950">
        <div className="w-full h-full bg-accents-blue2 border border-accents-blue2 rounded-r-[6px] flex items-center justify-end pr-5 py-1">
          <p className="font-extrabold text-white">91%</p>
        </div>
      </div>
      <div className="py-2 h-full flex items-center justify-end gap-2">
        <p className="whitespace-nowrap">Top Competitors</p>
        <div className="w-[6px] lg:w-[8px] h-[2px] lg:h-[3px] bg-stone-950 shrink-0"></div>
      </div>
      <div className="py-2 pr-20 flex items-center border-l-[2px] lg:border-l-[3px] border-stone-950">
        <div className="w-full h-full rounded-r-[6px] flex items-center justify-end pr-5 relative bg-[#c2d0ff] border-r border-y border-accents-blue py-1">
          <div className="z-10 absolute left-0 w-[90%] bg-accents-blue h-full rounded-r-[6px]"></div>
          <p className="z-20 relative font-extrabold text-white">50-70%</p>
        </div>
      </div>
      <div className="pr-2 py-4 h-full flex items-center sm:items-end justify-end">
        <p className="whitespace-nowrap font-bold">Contact Fill Rate</p>
      </div>
      <div className="pl-2 py-4 h-full flex items-center sm:items-end">
        <p className="text-[12px] lg:text-[16px] xl:text-[20px] leading-[1.4]">
          Tested on a random sample of tech executives based in San Francisco.
        </p>
      </div>
    </div>
  );
}
