import React, { useEffect, useRef, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import MuiSlider from "./base/MuiSlider";
import heroAnimation from "@/assets/animations/landing-hero.json";
import Lottie from "lottie-react";

const totalFrames = 1380;
const frameRate = 30;
const firstCheckPoint = 180;
const secondCheckPoint = 776;

export default function Introduction() {
  const [progress, setProgress] = useState(0);
  const [animationDuration, setAnimationDuration] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const lottieRef = useRef();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:1023px)"
  );
  const sliderStyles = {
    height: isSmallScreen ? "5px" : isMediumScreen ? "7px" : "10px",
    padding: "0px",
    margin: "0px",
  };
  const handleButtonClick = (frameNumber) => {
    if (lottieRef.current) {
      lottieRef.current.goToAndPlay(frameNumber, true);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const introVideo = document.querySelector(".introduction-video");
      const introBack = document.querySelector(".introduction-back");

      if (scrollPosition <= 300) {
        introBack.classList.remove("scaled");
        introVideo.classList.remove("scaled");
        introVideo.classList.remove("top-center");
      } else {
        introBack.classList.add("scaled");
        introVideo.classList.add("scaled");
        introVideo.classList.add("top-center");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (currentIndex === 0) lottieRef.current.play();
    let duration;
    switch (currentIndex) {
      case 1:
        duration = ((secondCheckPoint - firstCheckPoint) / frameRate) * 1000;
        handleButtonClick(firstCheckPoint);
        break;
      case 2:
        duration = ((totalFrames - secondCheckPoint) / frameRate) * 1000;
        handleButtonClick(secondCheckPoint);
        break;
      default:
        duration = (firstCheckPoint / frameRate) * 1000;
        handleButtonClick(0);
        break;
    }
    setAnimationDuration(duration);
  }, [currentIndex]);

  useEffect(() => {
    if (animationDuration === 0) return;

    const frameInterval = animationDuration / 400;
    const increment = 0.25;

    const sliderProgressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(sliderProgressInterval);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
          setProgress(0);
          return 0;
        }
        return prevProgress + increment;
      });
    }, frameInterval);

    return () => {
      clearInterval(sliderProgressInterval);
    };
  }, [currentIndex, animationDuration]);

  return (
    <Box className="introduction-back mt-10 xl:mt-12 2xl:mt-16">
      <div className="introduction-video w-[95vw] md:w-[90vw] max-w-[1700px]">
        <Lottie
          animationData={heroAnimation}
          loop={true}
          autoPlay={false}
          lottieRef={lottieRef}
        />
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center w-[90%] bg-white p-3 gap-3 lg:p-5 lg:gap-5 opacity-80 rounded-[15px] border border-stone-950">
          <Box
            className="flex flex-col items-center w-full cursor-pointer bg-white"
            onClick={() => {
              setProgress(0);
              setCurrentIndex(0);
              handleButtonClick(0);
            }}
          >
            <MuiSlider
              progress={currentIndex === 0 ? progress : 0}
              styles={sliderStyles}
            />
          </Box>
          <Box
            className="flex flex-col items-center w-full cursor-pointer bg-white"
            onClick={() => {
              setProgress(0);
              setCurrentIndex(1);
              handleButtonClick(firstCheckPoint);
            }}
          >
            <MuiSlider
              progress={currentIndex === 1 ? progress : 0}
              styles={sliderStyles}
            />
          </Box>
          <Box
            className="flex flex-col items-center w-full cursor-pointer bg-white"
            onClick={() => {
              setProgress(0);
              setCurrentIndex(2);
              handleButtonClick(secondCheckPoint);
            }}
          >
            <MuiSlider
              progress={currentIndex === 2 ? progress : 0}
              styles={sliderStyles}
            />
          </Box>
        </div>
      </div>
    </Box>
  );
}
