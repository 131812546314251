import { useState, useContext } from "react";
import { AuthContext } from "@/context/AuthContext";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import SettingsNavBar from "@/components/settings/SettingsNavBar";
import ProfileTabComponent from "@/components/settings/ProfileTabComponent";
import { ReactComponent as SettingIcon } from "@/assets/image/profile-popup/setting.svg";
import AccountTabComponent from "@/components/settings/AccountTabComponent";
import BillingTabComponent from "@/components/settings/BillingTabComponent";
import OtherSettingTabComponent from "@/components/settings/OtherSettingComponent";
import MainTitleBar from "@/components/MainTitleBar";

export default function SettingsScene() {
  const { isTeamMember } = useContext(AuthContext);
  const [currentTabIndex, setCurrentTabIndex] = useState(isTeamMember ? 0 : 2);

  return (
    <MainLayout>
      <MainTitleBar>
        <div className="flex items-end justify-start gap-2">
          <SettingIcon className="w-[34px] h-[34px]" />
          <p>Account & Settings</p>
        </div>
      </MainTitleBar>

      <BaseContainer>
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="col-span-1">
            <SettingsNavBar
              isTeamMember={isTeamMember}
              currentTabIndex={currentTabIndex}
              setCurrentTabIndex={setCurrentTabIndex}
            />
          </div>
          <div className="col-span-3">
            {currentTabIndex === 0 && <ProfileTabComponent />}
            {currentTabIndex === 1 && <AccountTabComponent />}
            {!isTeamMember && currentTabIndex === 2 && <BillingTabComponent />}
            {currentTabIndex === 3 && <OtherSettingTabComponent />}
          </div>
        </div>
      </BaseContainer>
    </MainLayout>
  );
}
