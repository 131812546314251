import TopBar from "@/components/landing/TopBar";
import AnswerPanel from "@/components/prospecting/AnswerPanel";
import Product from "@/components/prospecting/Products";
import LandingLayout from "@/components/LandingLayout";
import SupportPanel from "@/components/landing/SupportPanel";
import WhyKompassAIPanel from "@/components/landing/WhyKompassAIPanel";
import Datapoints from "@/components/landing/common/Datapoints";
import ConnectPanel from "@/components/landing/ConnectPanel";

const topBarData = {
  tag: "LinkedIn Prospecting Tool",
  title: "Find your prospects on LinkedIn with our integrated Chrome extension",
  subTitle:
    "Optimize your prospecting by letting us help you find the decision makers that matter on LinkedIn.",
};

const description = {
  heading: "What is the KompassAI Chrome Extension?",
  subHeading:
    "Scrape data directly from LinkedIn and leverage the world's largest business directory. Our Chrome browser extension cleans the data to ensure you get high-quality, consistent, structured and easily manipulable contact info that's easy to integrate into your database.",
};

export default function ProspectingScene() {
  return (
    <LandingLayout pageName="prospecting" background="#F8E665">
      <div>
        <TopBar
          data={topBarData}
          background="bg-accents-yellow"
          productName="prospecting"
        />
        <SupportPanel />
        <WhyKompassAIPanel data={description} />
        <AnswerPanel />
        <Datapoints
          background="bg-accents-yellow"
          opacity="bg-opacity-25"
          paddingTop="!pt-0"
        />
        <Product pageName="prospecting" />
        <ConnectPanel background="bg-accents-yellow" accent="bg-accents-gold" />
      </div>
    </LandingLayout>
  );
}
