import React from "react";
import classNames from "classnames";

export const WarmupSubHeader = ({ title, activeTitle = "", onClick }) => {
  const isMultipleTitles = Array.isArray(title);
  return isMultipleTitles ? (
    <div className="flex gap-2">
      {title.map((label) => {
        const isActiveSubPage = activeTitle === label;

        return (
          <button
            key={label}
            className={classNames("email-warmup-subtitle font-Outfit", {
              "border-b border-gray-950": isActiveSubPage,
            })}
            onClick={() => {
              if (!isActiveSubPage) {
                onClick(label);
              }
            }}
          >
            {label}
          </button>
        );
      })}
    </div>
  ) : (
    <div className="email-warmup-subtitle font-Outfit">{title}</div>
  );
};
