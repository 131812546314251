import React, { useState, useMemo, useEffect } from "react";
import PricingSlider from "./PricingSlider";
import useBillingStore from "@/store/useBillingStore";
import { debounce, throttle } from "lodash";
const findKey = (key, target) => {
  if (!key || !target) return;
  return target.find((obj) => {
    return obj.key === key;
  });
};

const findIndex = (array, target) => {
  if (!array.length || !target) return 0;
  const index = array.findIndex(
    (entry) => entry.lookup_key === target.lookup_key
  );
  return index !== -1 ? index : 0;
};

const filterByPeriod = (target, annual) => {
  return target.variations.filter((obj) => {
    return annual ? obj.interval === "year" : obj.interval === "month";
  });
};

function CustomCreditsSelector(props) {
  const { changePrices, cleanPrices } = useBillingStore();
  const {
    annual,
    customCreditsInfo,
    currentPlan,
    optionsKey,
    sliderTitle,
    priceKey,
    timeout,
  } = props;
  const { selectedPrices } = useBillingStore();
  const currentPlanVariation = filterByPeriod(currentPlan, annual)[0];
  const values = useMemo(() => {
    if (!customCreditsInfo) return [];

    let options = findKey(optionsKey, customCreditsInfo);
    options = filterByPeriod(options, annual);
    options.unshift({
      credits: {
        [priceKey]: 0,
      },
      interval: annual ? "year" : "month",
      lookup_key: "",
      price: 0,
    });

    return options;
  }, [customCreditsInfo, annual]);

  const [customPriceIndex, setCustomPriceIndex] = useState(
    findIndex(values, selectedPrices[priceKey])
  );
  const [customPriceIndexDebounced, setCustomPriceIndexDebounced] = useState(
    findIndex(values, selectedPrices[priceKey])
  );
  const debounceSetSliderValue = useMemo(() => {
    return debounce(
      (sliderValue) => setCustomPriceIndexDebounced(sliderValue),
      timeout || 0
    );
  }, []);
  useEffect(() => {
    const constructedPrice = {
      [priceKey]: values[customPriceIndexDebounced],
    };
    //Synchronize slider state in case user wants to get funky with it
    //and gets stale number on the slider
    if (customPriceIndexDebounced !== customPriceIndex) {
      setCustomPriceIndex(customPriceIndexDebounced);
    }
    const debounceChangePrice = debounce(
      (constructedPrice) => changePrices(constructedPrice),
      1000
    );

    debounceChangePrice(constructedPrice);
  }, [customPriceIndexDebounced]);
  useEffect(() => debounceSetSliderValue(customPriceIndex), [customPriceIndex]);
  return (
    <>
      {values.length && (
        <PricingSlider
          annual={annual}
          currentPlan={currentPlanVariation}
          options={values}
          name={sliderTitle}
          state={[customPriceIndex, setCustomPriceIndex]}
        ></PricingSlider>
      )}
    </>
  );
}

export default CustomCreditsSelector;
