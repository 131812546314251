import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, styled } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { CircularProgress } from "@mui/material";
import useAutocomplete from "@/hooks/useAutocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BootstrapAutocompleteInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderRadius: 7,
        position: "relative",
        backgroundColor: "white",
        borderColor: "#80bdff",
        border: "none",
        fontSize: 12,
        fontFamily: "Outfit",
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 7,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
});

export default function AutocompleteInput({
    value = "",
    onNameFilterChange,
    searchBy,
    placeholder = null,
    isUseAPI = true,
    initialOptions = [],
    isWebsiteAutocomplete = false,
    clearTrigger,
    multiple = false, // Add a multiple prop to handle multiple selection
}) {
    const { searchAutoComplete } = useAutocomplete();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState(initialOptions);
    const [filterText, setFilterText] = React.useState(multiple ? "" : value);
    const [apiLoading, setApiLoading] = React.useState(false);
    const [lastTimerId, setLastTimerId] = React.useState(null);

    const capitalizeWords = (input) => {
        return input
            .split(", ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(", ");
    };

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        setFilterText("");
    }, [clearTrigger]);

    React.useEffect(() => {
        if (!multiple) {
            setFilterText(value?.trim() && !isWebsiteAutocomplete ? capitalizeWords(value) : value);
        }
    }, [value]);

    React.useEffect(() => {
        if (filterText || (filterText?.length > 0 && isUseAPI)) {
            if (lastTimerId) clearTimeout(lastTimerId);

            const newLastTimerId = setTimeout(() => {
                loadDataByFilter();
            }, 500);
            setLastTimerId(newLastTimerId);
        }
    }, [filterText]);

    const theme = createTheme({
        palette: {
            primary: {
                main: "#3b82f6",
                contrastText: "#fff",
                contrast: "#3b82f6",
                light: "#3b82f6",
            },
        },
    });

    const loadDataByFilter = async () => {
        setApiLoading(true);

        const response = await searchAutoComplete({
            params: {
                query: filterText,
            },
            searchParam: searchBy,
        });
        if (response?.status) {
            setOptions([...response?.data]);
        }

        setApiLoading(false);
    };

    return (
        <StyledAutocomplete
            multiple={multiple}
            sx={{ paddingY: 0 }}
            disableCloseOnSelect={multiple}
            value={multiple ? (value?.length ? value : []) : value}
            options={options}
            getOptionLabel={(option) => {
                return isWebsiteAutocomplete ? option?.meta?.website : option?.name;
            }}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        {multiple && (
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={value?.includes(option.name) || false}
                            />
                        )}
                        <div className="flex items-center justify-between w-full">
                            <div className="font-Outfit flex flex-col text-sm">
                                <p className="capitalize">{option.name}</p>
                                <p className="text-xs">{option?.meta?.website}</p>
                            </div>
                            <p>({option?.count.toLocaleString("en-US")})</p>
                        </div>
                    </li>
                );
            }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={apiLoading}
            renderInput={(params) => (
                <BootstrapAutocompleteInput
                    {...params}
                    sx={{ paddingTop: "0px !important", paddingBottom: "0px !important" }}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {apiLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13 && filterText?.length > 3) {
                            loadDataByFilter();
                        }
                    }}
                    onChange={(event) => {
                        setFilterText(event.currentTarget.value);
                        // onNameFilterChange(event.currentTarget.value);
                    }}
                />
            )}
            renderTags={(value, getTagProps) => {
                return value?.map((option, index) => (
                    <ThemeProvider theme={theme} key={index}>
                        <Chip
                            label={option}
                            size="small"
                            color="primary"
                            {...getTagProps({ index })}
                            sx={{
                                "& span": {
                                    fontFamily: "Outfit",
                                    fontSize: "10px",
                                    textTransform: "capitalize",
                                },
                            }}
                        />
                    </ThemeProvider>
                ));
            }}
            onChange={(event, newValue) => {
                if (multiple) {
                    const lastNewValueItem = newValue[newValue.length - 1];
                    if (!value?.includes(lastNewValueItem?.name || lastNewValueItem)) {
                        const values = newValue?.map((item) => {
                            return item?.name || item;
                        });
                        onNameFilterChange(values);
                    } else if (lastNewValueItem?.name) {
                        onNameFilterChange(value?.filter((text) => text !== lastNewValueItem?.name));
                    } else {
                        onNameFilterChange(newValue);
                    }

                    setFilterText("");
                } else {
                    setFilterText(isWebsiteAutocomplete ? (newValue?.meta?.website ?? "") : (newValue?.name ?? ""));
                    onNameFilterChange(isWebsiteAutocomplete ? (newValue?.meta?.website ?? "") : (newValue?.name ?? ""));
                }
            }}
            inputValue={filterText}
        />
    );
}
