import TeammemberIcon1 from "../../assets/image/about/team1.png";
import TeammemberIcon2 from "../../assets/image/about/team2.png";
import TeammemberIcon3 from "../../assets/image/about/team3.png";
import TeammemberIcon4 from "../../assets/image/about/team4.png";
import BaseContainer from "../BaseContainer";

export default function MeetTeam(props) {
  return (
    <div className="bg-emerald-200 p-[40px] md:p-[80px]">
      <BaseContainer>
        <div className="flex w-full grid-cols-2 flex-col gap-[80px] xl:grid">
          <div className="flex flex-col">
            <div className="font-Outfit text-3xl font-[700] text-emerald-500 lg:text-6xl">
              Meet our team
            </div>
            <div className="font-Outfit mt-20 text-lg font-[700] text-emerald-500 opacity-70  md:text-xl">
              You usually need to open a local entity to hire an employee in
              another country. It can take a lot of time, along with the ongoing
              payroll and HR admin. With our Employer of Record product, we hire
              employees on your behalf who work for you just like the rest of
              your team.
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center rounded-[30px] bg-white p-[24px] md:flex-row">
              <img
                className="h-[100px] w-[100px] rounded-[24px] md:h-[200px] md:w-[200px]"
                src={TeammemberIcon1}
                alt=""
              />

              <div className="flex w-full flex-col items-center justify-between md:mx-[32px] md:items-start">
                <div className="font-Outfit text-[24px] font-[700] text-emerald-500  md:text-[28px]">
                  Jaylon Press
                </div>
                <div className="font-Outfit mb-[40px] text-[16px] font-[400] text-emerald-500  opacity-40 md:text-[22px]">
                  Co-Founder & President
                </div>
                <div className="font-Outfit rounded-[16px] bg-emerald-200 p-[16px] text-[16px] font-[400] text-emerald-500  md:text-[20px]">
                  “Who dares, wins.”
                </div>
              </div>
            </div>
            <div className="mt-[16px] flex flex-col items-center rounded-[30px] bg-white p-[24px] md:flex-row">
              <img
                className="h-[100px] w-[100px] rounded-[24px] md:h-[200px] md:w-[200px]"
                src={TeammemberIcon2}
                alt=""
              />

              <div className="flex w-full flex-col items-center justify-between md:mx-[32px] md:items-start">
                <div className="font-Outfit text-[24px] font-[700] text-emerald-500  md:text-[28px]">
                  Jakob Levin
                </div>
                <div className="font-Outfit mb-[40px] text-[16px] font-[400] text-emerald-500  opacity-40 md:text-[22px]">
                  Co-Founder & President
                </div>
                <div className="font-Outfit rounded-[16px] bg-emerald-200 p-[16px] text-[16px] font-[400] text-emerald-500  md:text-[20px]">
                  “Everything you want waits on the other side of consistency.”
                </div>
              </div>
            </div>
            <div className="mt-[16px] flex flex-col items-center rounded-[30px] bg-white p-[24px] md:flex-row">
              <img
                className="h-[100px] w-[100px] rounded-[24px] md:h-[200px] md:w-[200px]"
                src={TeammemberIcon3}
                alt=""
              />

              <div className="flex w-full flex-col items-center justify-between md:mx-[32px] md:items-start">
                <div className="font-Outfit text-[24px] font-[700] text-emerald-500  md:text-[28px]">
                  Maria George
                </div>
                <div className="font-Outfit mb-[40px] text-[16px] font-[400] text-emerald-500  opacity-40 md:text-[22px]">
                  VP Customer Success
                </div>
                <div className="font-Outfit rounded-[16px] bg-emerald-200 p-[16px] text-[16px] font-[400] text-emerald-500  md:text-[20px]">
                  “Unleashing the power of entrepreneurship...”
                </div>
              </div>
            </div>
            <div className="mt-[16px] flex flex-col items-center rounded-[30px] bg-white p-[24px] md:flex-row">
              <img
                className="h-[100px] w-[100px] rounded-[24px] md:h-[200px] md:w-[200px]"
                src={TeammemberIcon4}
                alt=""
              />

              <div className="flex w-full flex-col items-center justify-between md:mx-[32px] md:items-start">
                <div className="font-Outfit text-[24px] font-[700] text-emerald-500  md:text-[28px]">
                  Lincoln Rhiel Madsen
                </div>
                <div className="font-Outfit mb-[40px] text-[16px] font-[400] text-emerald-500  opacity-40 md:text-[22px]">
                  VP Sales
                </div>
                <div className="font-Outfit rounded-[16px] bg-emerald-200 p-[16px] text-[16px] font-[400] text-emerald-500  md:text-[20px]">
                  "If you’re offered a seat on a rocket ship, don’t ask what
                  seat! Just get on."
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseContainer>
    </div>
  );
}
