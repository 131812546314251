import { useContext, useState, useRef } from "react";
import { Button } from "@material-tailwind/react";
import PhoneInput from "react-phone-input-2";
import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// import Google from '@/components/auth/Google';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGoogleLogin } from "@react-oauth/google";
import ReCAPTCHA from "react-google-recaptcha";

import { AuthContext } from "@/context/AuthContext";

import AuthLayout from "@/components/AuthLayout";

import { ReactComponent as Logo } from "@/assets/image/icons/logo.svg";
import { ReactComponent as HidePassword } from "@/assets/image/icons/hide.svg";
import GoogleIcon from "@/assets/image/icons/google.svg";
import Text from "@/components/Text";

import "@/components/phone-input/style.css";

import { COGNITO_GOOGLE_DEFAULT_PASSWORD } from "@/utils/constants";

import useKompassRouter from "@/hooks/useKompassRouter";
import { kompassColors } from "@/theme/palette";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid Email")
    .required("Email is required"),
  userName: yup.string().required("Last Name is required"),
  companyName: yup.string().required("Company Name is required"),
  // mobile: yup.string().required('Mobile Number is required'),
  agreement: yup
    .boolean()
    .required()
    .oneOf(
      [true],
      "Please accept the KompassAI Terms of Service before continuing",
    )
    .default(false),
  password: yup
    .string()
    .required("Password is required")
    .min(10, "Password must be at least 10 characters")
    .max(32, "Password must be at most 32 characters")
    .matches(/[a-z]+/, "password must contain at least 1 lowercase character")
    .matches(/[A-Z]+/, "password must contain at least 1 uppercase character")
    .matches(
      /[@$!%*#?&]+/,
      "password must contain at least one special character",
    )
    .matches(/\d+/, "password must contain at least one number"),
  // confirmPassword: yup.string().required('Confirm password is required')
});

export default function SignUp() {
  const { kompassNavigate } = useKompassRouter();

  const authContext = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setisLoading] = useState(false);
  const [isGoogleAuth, setIsGoogleAuth] = useState(false);
  const [mobile, setMobile] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const captchaRef = useRef(null);

  const gotoSignin = () => {
    kompassNavigate("/signin");
  };

  const onSubmitHandler = data => {
    if (data.confirmPassword !== data.password)
      toast.error("Please check your confirm password", { theme: "colored" });
    if (data.mobile === "")
      toast.error("Please check your mobile number", { theme: "colored" });
    handleRegister(data);
  };

  const handleRegister = async data => {
    const captchaToken = captchaRef.current.getValue();
    if (captchaToken === "" || captchaToken == null) {
      toast.error("Please check your captcha", { theme: "colored" });
      return;
    }

    try {
      setisLoading(true);

      data = { ...data, mobile, isGoogleAuth };
      await authContext.signUpWithEmail(data.email, data.password, data, {
        captcha: captchaToken,
      });

      setisLoading(false);

      kompassNavigate("/verification");
    } catch (err) {
      setisLoading(false);

      console.log(err);
      if (err instanceof Error) {
        if (
          err.message.includes(
            "PreAuthentication failed with error Recaptcha verification failed.",
          )
        ) {
          toast.error("Recaptcha verification failed", { theme: "colored" });
        } else if (err.message.includes("Missing Captcha Value")) {
          toast.error("Missing Captcha Value", { theme: "colored" });
        } else if (err.message.includes("User doesn't exist")) {
          toast.error("User doesn't exist", { theme: "colored" });
        } else {
          toast.error(err.message, { theme: "colored" });
        }
        captchaRef.current.reset();
      }
    }
  };

  const googleSignup = useGoogleLogin({
    onSuccess: async codeResponse => {
      try {
        setisLoading(true);
        const response = await fetch(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer  ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          },
        );
        const _resData = await response.json();
        setisLoading(false);
        setIsGoogleAuth(true);

        reset({
          email: _resData.email,
          userName: _resData.name,
          password: COGNITO_GOOGLE_DEFAULT_PASSWORD,
          confirmPassword: COGNITO_GOOGLE_DEFAULT_PASSWORD,
        });
      } catch (err) {
        setisLoading(false);
        setIsGoogleAuth(false);
        console.log(err);
        if (err instanceof Error) {
          if (
            err.message.includes(
              "PreAuthentication failed with error Recaptcha verification failed.",
            )
          ) {
            toast.error("Recaptcha verification failed", { theme: "colored" });
          } else if (err.message.includes("Missing Captcha Value")) {
            toast.error("Missing Captcha Value", { theme: "colored" });
          } else if (err.message.includes("User doesn't exist")) {
            toast.error("User doesn't exist", { theme: "colored" });
          } else {
            toast.error(err.message, { theme: "colored" });
          }
          captchaRef.current.reset();
        }
      }
    },
    onError: error => console.log("Login Failed:", error),
  });

  return (
    <AuthLayout>
      <div className="flex w-full">
        <Link to="/">
          <Logo className="mb-12 w-[185px] fill-current text-gray-900" />
        </Link>
      </div>
      <div className="w-full rounded-xl bg-white">
        <Text variant="Header4" className="auth-title">
          Create a free account
        </Text>
        <div className="auth-description">
          Get free contact details of decision-markers
        </div>
        <hr className="my-8 h-px bg-stone-250 w-full" />
        <form onSubmit={handleSubmit(onSubmitHandler)} className="auth-form">
          <div className="w-full">
            <div className="auth-label">Email address</div>
            <div className="auth-input-container">
              <input
                {...register("email")}
                className="auth-input"
                placeholder="e.g. johndoe@kompass.ai"
                disabled={isLoading || isGoogleAuth}
              />
            </div>
            {errors.email && (
              <p className="auth-error">{errors.email.message?.toString()}</p>
            )}
          </div>
          <div className="w-full">
            <div className="auth-label">Your full name</div>
            <div className="auth-input-container">
              <input
                {...register("userName")}
                className="auth-input"
                placeholder="e.g. John Doe"
                disabled={isLoading || isGoogleAuth}
              />
            </div>
            {errors.userName && (
              <p className="auth-error">
                {errors.userName.message?.toString()}
              </p>
            )}
          </div>
          <div className="w-full">
            <div className="auth-label ">Company name</div>
            <div className="auth-input-container">
              <input
                {...register("companyName")}
                className="auth-input"
                placeholder="e.g. KompassAI"
                disabled={isLoading}
              />
            </div>
            {errors.companyName && (
              <p className="auth-error">
                {errors.companyName.message?.toString()}
              </p>
            )}
          </div>
          <div className="w-full">
            <div className="auth-label ">Phone number</div>
            <div className="auth-input-container">
              <PhoneInput
                className="font-Outfit w-full rounded-xl text-[16px] placeholder-stone-250 outline-none"
                placeholder="Enter phone number"
                country="us"
                value={mobile}
                onChange={setMobile}
                disabled={isLoading}
                countryCodeEditable={false}
              />
            </div>
          </div>
          <div className="w-full">
            {isGoogleAuth === false ? (
              <>
                <div className="auth-label ">Choose a password</div>
                <div className="auth-input-container">
                  <input
                    {...register("password")}
                    type={!showPassword ? "password" : "text"}
                    className="auth-input"
                    placeholder=""
                    disabled={isLoading}
                  />
                  <span
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <HidePassword
                      fill={showPassword ? "#E7436A" : "#929292"}
                      className="absolute top-1/2 transform -translate-y-1/2 right-4"
                    />
                  </span>
                </div>
                {errors.password && (
                  <p className="auth-error">
                    {errors.password.message?.toString()}
                  </p>
                )}

                <div className="auth-label mt-4">Confirm your password</div>
                <div className="auth-input-container">
                  <input
                    {...register("confirmPassword")}
                    type={!showPassword ? "password" : "text"}
                    className="auth-input"
                    placeholder=""
                    disabled={isLoading}
                  />
                </div>
                {errors.confirmPassword && (
                  <p className="auth-error">
                    {errors.confirmPassword.message?.toString()}
                  </p>
                )}
              </>
            ) : null}
          </div>
          <div>
            <div className="mt-2 flex w-full flex-row items-center justify-center rounded-[40px] border-none border-[#E8E7E7] bg-white p-[8px_12px]">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_KOMPASSAI_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
                Size="Compact"
              />
            </div>
            <div className="font-Outfit text-md mt-8 w-full text-center leading-[1.4] text-stone-350">
              <Checkbox
                {...register("agreement")}
                indeterminate={false}
                checked={watch("agreement")}
                onChange={e => setValue("agreement", e.target.checked)}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
                style={{ padding: 0 }}
                sx={{
                  color: kompassColors.textLight,
                  "&.Mui-checked": {
                    color: kompassColors.blue,
                  },
                  "&.MuiCheckbox-indeterminate": {
                    color: kompassColors.blue,
                  },
                }}
              />
              By creating an account, I agree to the{" "}
              <span className="cursor-pointer select-none text-blue-500">
                Terms of service
              </span>{" "}
              and{" "}
              <span className="cursor-pointer select-none text-blue-500">
                Privacy policy
              </span>
              . I also agree to receive emails and communication relating to
              KompassAI services and offers.
              {errors.agreement && (
                <p className="auth-error">{errors.agreement.message}</p>
              )}
            </div>
            <Button
              type="submit"
              className="submit-button"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress
                  size="1.2rem"
                  className="mr-2"
                  style={{ color: "white" }}
                />
              ) : null}
              GET STARTED
            </Button>
          </div>
        </form>
        <div className="font-Outfit font-md mt-8 flex w-full justify-center text-[#8B9E9F]">
          Or sign up with your work email
        </div>
        <Button onClick={googleSignup} className="google-button">
          <img
            className="h-[20px] w-[20px]"
            src={GoogleIcon}
            alt="google icon"
          />
          <div>Signup via Google</div>
        </Button>
        <div className="font-Outfit text-md mt-8 leading-[1.2] flex w-full justify-center text-stone-950">
          Already have an account?
          <span
            onClick={gotoSignin}
            className="ml-1 cursor-pointer select-none text-blue-500"
          >
            Sign in
          </span>
        </div>
      </div>
    </AuthLayout>
  );
}
