import { ReactComponent as DownSVGIcon } from "@/assets/image/icons/down.svg";
import { ReactComponent as CreditSVGIcon } from "@/assets/image/home/icon-credit.svg";
import { ReactComponent as ContactSVGIcon } from "@/assets/image/home/icon-saved-contacts.svg";
import { ReactComponent as CollectedSVGIcon } from "@/assets/image/home/icon-collected-detail.svg";
import FilterPopup, { filterOptions } from "@/components/popup/FilterPopup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function UsageAnalyticComponent({
  usageAnalytics,
  filter,
  setFilter,
  hideHeader = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col items-center justify-start gap-4 p-4 2xl:p-6 pl-0">
      {hideHeader ? (
        ""
      ) : (
        <div className="flex flex-col items-start justify-start gap-4 self-stretch">
          <div className="font-Outfit self-stretch text-md 2xl:text-lg font-normal text-black">
            Usage analytics
          </div>
          <div className="inline-flex items-center justify-between self-stretch">
            <button
              className="flex items-center justify-start gap-2"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <div className="font-Outfit text-sm 2xl:text-base font-normal text-black">
                {filterOptions[filter]}
              </div>
              <DownSVGIcon className="relative h-4 w-4" />
            </button>
            <button
              className="font-Outfit text-sm 2xl:text-base font-normal text-blue-500"
              onClick={() => {
                navigate("/analytics");
              }}
            >
              View all
            </button>
          </div>
        </div>
      )}

      <div className="flex flex-col items-start justify-start gap-4 self-stretch">
        <div className="inline-flex items-center justify-start gap-6 self-stretch p-4">
          <CreditSVGIcon className="h-10 w-10 rounded-lg bg-white p-2 drop-shadow-lg" />

          <div className="inline-flex flex-col items-start justify-start">
            <div className="font-Outfit leading-relaxed text-right text-xl font-normal text-black">
              {usageAnalytics?.creditsUsage?.used} /{" "}
              {usageAnalytics?.creditsUsage?.total}
            </div>
            <div className="font-Outfit leading-none text-xs font-normal text-black opacity-60">
              Credits used
            </div>
          </div>
        </div>
        <hr className="h-px w-full border-0 bg-stone-250" />

        <div className="inline-flex items-center justify-start gap-6 self-stretch p-4">
          <ContactSVGIcon className="h-10 w-10 rounded-lg bg-white p-2 drop-shadow-lg" />
          <div className="inline-flex flex-col items-start justify-start">
            <div className="font-Outfit leading-relaxed text-right text-xl font-normal text-black">
              {usageAnalytics?.savedContacts}
            </div>
            <div className="font-Outfit leading-none text-xs font-normal text-black opacity-60">
              Saved contacts
            </div>
          </div>
        </div>
        <hr className="h-px w-full border-0 bg-stone-250" />

        <div className="inline-flex items-center justify-start gap-6 self-stretch p-4">
          <CollectedSVGIcon className="h-10 w-10 rounded-lg bg-white p-2 drop-shadow-lg" />
          <div className="inline-flex flex-col items-start justify-start">
            <div className="font-Outfit leading-relaxed text-right text-xl font-normal text-black">
              {usageAnalytics?.collectedDetails}
            </div>
            <div className="font-Outfit leading-none text-xs font-normal text-black opacity-60">
              Collected details
            </div>
          </div>
        </div>
      </div>
      <FilterPopup
        anchorEl={anchorEl}
        handleClose={(value) => {
          if (value) {
            setFilter((val) => ({
              ...val,
              usageAnalyticsFilter: value,
            }));
          }
          setAnchorEl(null);
        }}
      />
    </div>
  );
}
