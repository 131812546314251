import React from "react";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { ReactComponent as SettingsIcon } from "@/assets/image/settings-icon.svg";
import { ReactComponent as PlayIcon } from "@/assets/image/play_arrow.svg";
import { ReactComponent as PauseIcon } from "@/assets/image/pause-icon.svg";
import { capitalizeString } from "@/utils/common";

export const WarmupProfile = ({
  isStatusLoading,
  emailAccount,
  handleChangeStatusButtonClick,
}) => {
  const isWarmupPaused =
    emailAccount.status_code === "paused" ||
    emailAccount.status_code === "pending";
  return (
    <div className="p-[20px] 2xl:pt-[25px] bg-stone-150 flex justify-between items-center">
      <div className="flex flex-col gap-2">
        <h1 className="font-OutfitBold text-[20px] 2xl:text-[24px] font-semibold text-black flex items-center gap-[4px]">
          {emailAccount.name}
        </h1>
        <div className="flex items-center gap-[8px]">
          <p className="warmup-email-settings-statistic font-Outfit">
            {emailAccount.email}
          </p>
          <hr className="warmup-divider-dot" />
          <p className="warmup-email-settings-statistic font-Outfit">
            {emailAccount.spam_rate_in_last_14_days}% Spam
          </p>
          <hr className="warmup-divider-dot" />
          <p className="warmup-email-settings-statistic font-Outfit">
            ID:{emailAccount.filter_id}
          </p>
          <hr className="warmup-divider-dot" />
          <p
            className={classNames("warmup-email-status-text font-Outfit", {
              "bg-[#FEF0C7] text-[#DC6803]":
                emailAccount.status_code === "paused",
              "bg-[#2e7d32] text-white": emailAccount.status_code === "active",
              "bg-[#D4D4D4]": emailAccount.status_code === "pending",
            })}
          >
            {capitalizeString(emailAccount.status_code)}
          </p>
        </div>
      </div>
      <Button
        sx={{
          fontFamily: "Outfit",
          borderRadius: "8px",
          fontWeight: 400,
          fontSize: "14px",
          py: "5px",
          px: "12px",
          border: "1px solid #090C05",
          backgroundColor: "#090C05",
          color: "#fff",
          display: "flex",
          textTransform: "none",
          justifyContent: "center",
          alignItems: "center",
          width: "210px",
          gap: "8px",
          ":hover": {
            backgroundColor: "transparent",
            color: "#090C05",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            backgroundColor: "#090C05",
            color: "#fff",
          },
        }}
        onClick={handleChangeStatusButtonClick}
        disabled={isStatusLoading}
      >
        {isWarmupPaused ? (
          <PlayIcon className="w-[24px] h-[24px]" />
        ) : (
          <PauseIcon className="w-[16px] h-[16px]" />
        )}
        {isWarmupPaused ? "Start" : "Stop"} Sending Emails
      </Button>
    </div>
  );
};
